import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import storeDev from 'data/store';
import { iStore } from 'domain/interfaces/models';
import { makeReduxRefreshAuth } from 'main/factories/usecases/auth/RefreshAuthFactory';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
} from '../../../data/protocols/http';

class AxiosHttpClient implements HttpClient {
  /**
   * Request for external API.
   * @param data data to make the request.
   */
  public request = async (data: HttpRequest): Promise<HttpResponse> => {
    let axiosResponse: AxiosResponse;

    const store: iStore = storeDev.getState();

    const { accessToken } = store.auth;

    let authorizationHeader = data.headers;

    if (
      accessToken &&
      (!data.headers ||
        (typeof data.headers === 'object' && Object.keys(data.headers).length))
    ) {
      authorizationHeader = {
        Authorization: `Bearer ${accessToken}`,
        ...data.headers,
      };
    }

    try {
      axiosResponse = await axios.request({
        url: data.url,
        method: data.method,
        data: data.body,
        headers: authorizationHeader,
        responseType: data.responseType,
      });
    } catch (error: any) {
      if (error?.response?.status === 502 || error?.response?.status === 503) {
        toast.error('Falha interna no servidor, tente novamente em minutos.');
      }

      if (
        !data.url.includes('/auth/refresh') &&
        error.response?.status === 401 &&
        error.response?.data?.message === 'Token is incorrect' &&
        store.auth.user?.id &&
        store.auth.refreshToken
      ) {
        makeReduxRefreshAuth().refresh({
          refreshToken: store.auth.refreshToken,
        });
      }

      axiosResponse = error?.response;
    }

    return {
      statusCode: axiosResponse?.status,
      body: axiosResponse?.data,
    };
  };
}

export default AxiosHttpClient;
