import { defaultTheme } from 'presentation/base/themes';
import styled, { css, keyframes } from 'styled-components';

type StyleProps = {
  variant?: 'primary' | 'secondary';
};

const downToUpAnimation = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

export const Content = styled.div<StyleProps>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: transparent;

  padding: 5rem 4rem;

  align-items: center;
  justify-content: center;

  color: ${({ theme }) => theme.colors.white1};

  @media (max-width: 768px) {
    padding: 0;
    max-width: ${({ variant }) => (variant === 'primary' ? '32rem' : '38rem')};
  }
`;

export const TitleMessage = styled.div<StyleProps>`
  width: 100%;
  display: flex;
  flex-direction: ${({ variant }) =>
    variant === 'primary' ? 'column' : 'row'};
  align-items: center;
  justify-content: ${({ variant }) =>
    variant === 'primary' ? 'space-between' : 'center'};

  margin-bottom: ${({ variant }) => (variant === 'primary' ? '0' : '6.1rem')};

  & .message {
    font-size: 1.8rem;
    font-weight: bold;
    margin: ${({ variant }) => (variant === 'primary' ? '2rem 0' : '0')};
    text-align: center;
  }

  & .subtitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: ${({ variant }) => (variant === 'primary' ? '3rem' : '0')};
    text-align: center;
    flex-wrap: wrap;
    font-size: 1.4rem;
  }

  & svg.icon-calendar-success-create-event {
    width: 10rem;
    height: 10rem;
  }

  ${({ variant }) =>
    variant === 'secondary' &&
    css`
      & .message,
      .subtitle {
        margin-left: 3rem;
        text-align: left;
      }

      & .subtitle {
        font-size: 1.4rem;
      }
      /* max-width: 36rem; */
    `}
`;

export const Inputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 2.4rem;

  padding: 0 2.4rem;
`;

export const ModalContent = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;

  width: 40rem;
  /* height: 60rem; */

  background: ${({ theme }) => theme.colors.black2};

  border-radius: 1rem;

  color: ${({ theme }) => theme.colors.white1};

  transform: translateY(0);
  animation: ${downToUpAnimation} 0.3s ease-out;

  @media (max-width: 425px) {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0;
    width: 100%;
    height: 67rem;
  }
`;

const padding = css`
  padding: 1.2rem 1.6rem;
`;

// TODO: Make this compoment more generic
export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* ${padding} */

  // put this css on theme
  font-size: 1.8rem;
  font-weight: 600;
`;

type ContentInputProps = {
  error?: boolean;
  direction?: 'column' | 'row';
};

export const ContentInput = styled.div.attrs({
  className: 'input-content',
})<ContentInputProps>`
  width: 100%;
  display: flex;
  flex-direction: ${({ direction }) => direction || 'column'};
  gap: 1.2rem;

  margin-top: 2rem;

  @media (max-width: 425px) {
    & .logo-dropzone-create-event {
      height: 18rem;
    }
  }
`;

// TODO: Create a component for this
export const IconHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 3.6rem;
  height: 3.6rem;

  background: ${({ theme }) => theme.colors.black8};

  svg {
    width: 1.6rem;
    aspect-ratio: 1;
  }
`;

export const LabelHeader = styled.label`
  font-size: 1.8rem;
  font-weight: 600;
  align-self: center;
  margin: 0;
`;

export const InfoTitle = styled.label`
  font-size: 1.6rem;
  font-weight: 600;
  margin: 1.6rem 0;
`;

export const ValueContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Label = styled.label<{ required?: boolean }>`
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 1.2rem;
  ${({ required, theme }) =>
    required
      ? css`
          &:after {
            content: '*';
            color: ${theme.colors.red1};
          }
        `
      : css`
          &:after {
            content: ' (opcional)';
          }
        `};
`;

export const Title = styled.label`
  display: flex;
  align-center: center;
  justify-content: center;
  gap: 1.2rem;
  margin: 0;
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  background: transparent;

  padding: 1.1rem;
  border-radius: 0.5rem;
  outline: none;

  &:hover {
    background: ${({ theme }) => theme.colors.black3};
    color: ${({ theme }) => theme.colors.white2};
  }

  &:focus-visible {
    box-sizing: border-box;
    padding: 0.9rem;
    background: ${({ theme }) => theme.colors.black3};
    border: 0.2rem solid ${({ theme }) => theme.colors.blue1};

    box-shadow: 0 0 1rem ${({ theme }) => theme.colors.blue1};

    svg path {
      fill: ${({ theme }) => theme.colors.white2};
    }
  }

  &:active {
    border: 0;
    padding: 1.1rem;
    background: ${({ theme }) => theme.colors.black3};

    svg path {
      fill: ${({ theme }) => theme.colors.blue1};
    }

    box-shadow: initial;
  }

  svg {
    width: 1.6rem;
    aspect-ratio: 1;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 0.1rem;
  background: ${({ theme }) => theme.colors.black3};
  z-index: 0;
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  /* padding: 0 4rem; */

  gap: 1rem;

  overflow-y: auto;

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 0.1rem;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;

  gap: 1.5rem;

  flex-wrap: wrap;
`;

interface CheckBoxStyledProps {
  active?: boolean;
}

export const CheckBoxStyled = styled.button<CheckBoxStyledProps>`
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.3rem;

  border: 0;
  background: transparent;

  &:focus-visible {
    background: ${({ theme }) => theme.colors.black3};
    outline: 0.2rem solid ${({ theme }) => theme.colors.blue1};
    box-shadow: 0 0 1rem ${({ theme }) => theme.colors.blue1};
  }
`;

interface CircleFillProps {
  color: keyof typeof defaultTheme.colors;
  active?: boolean;
}

export const CircleFill = styled.div<CircleFillProps>`
  width: 2.4rem;
  height: 2.4rem;

  background-color: ${({ color, theme }) => theme.colors[color]};

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 0.3rem;

  ${({ active, theme }) =>
    active &&
    css`
      outline: 0.2rem solid ${theme.colors.blue1};
      box-shadow: 0 0 1rem ${theme.colors.blue1};
    `};

  padding: 0.7rem 0.6rem;

  & .icon-check-color {
    opacity: ${({ active }) => (active ? 1 : 0)};
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 2.4rem;

  border-top: 0.1rem solid ${({ theme }) => theme.colors.black3};

  button[type='button'] {
    width: 100%;
  }

  & .btn-template:not(:last-child) {
    margin-bottom: 1.2rem;
  }
`;

export const ConfettiContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
`;
