import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { SendImageReaction as UsecaseRemoteSendImageReaction } from 'domain/usecases/reaction/remote';
import { SendImageReaction as UsecaseReduxSendImageReaction } from 'domain/usecases/reaction/redux';

import { RemoteSendImageReaction } from 'data/repository/reaction';
import { ReduxSendImageReaction } from 'data/store/reducer/reaction/usecases';

/**
 * send request via API.
 */
export const makeRemoteSendImageReaction = (): UsecaseRemoteSendImageReaction =>
  new RemoteSendImageReaction(
    makeApiUrl('/reactions/{reactionId}/image'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
export const makeReduxSendImageReaction = (): UsecaseReduxSendImageReaction =>
  new ReduxSendImageReaction();
