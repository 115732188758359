import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import EventSmallPreview from 'presentation/components/EventSmallPreview';
import { IconFilter, IconBiggerSearch } from 'presentation/base/icons';
import { useSelector } from 'react-redux';
import { useQuery } from 'presentation/hooks/useQuery';
import Header from 'presentation/components/Header';
import { iStore } from 'domain/interfaces/models';
import {
  SearchContainer,
  SearchHeader,
  SearchTitle,
  BackArrow,
  Divider,
  FilterButton,
  NotFoundSearch,
  ContentIcon,
  NotFoundResultSearch,
  Suggestion,
  CarouselExample2,
  ContainerFilteredEvents,
  Container,
} from './styles/StyledSearchPage';

const SearchPage: React.FC = () => {
  const { loading, results } = useSelector((store: iStore) => store.event);
  const [hover, setHover] = useState(false);

  const history = useHistory();
  const query = useQuery();

  const search = query.get('term');

  const filteredEvents = results?.filter(event =>
    event.name.includes(search ?? ''),
  );

  return (
    <Container>
      <Header />
      <SearchContainer>
        <SearchHeader>
          <SearchTitle>
            <BackArrow onClick={() => history.push('/')} />
            <Divider />
            {`Resultados encontrados para "${search}"`}
          </SearchTitle>
          <FilterButton>
            <IconFilter />
            Filtrar
          </FilterButton>
        </SearchHeader>
        {filteredEvents?.length && filteredEvents?.length > 0 ? (
          <ContainerFilteredEvents>
            {filteredEvents?.map(event => (
              <CarouselExample2 isHovering={hover}>
                <EventSmallPreview
                  key={event.id}
                  skeleton={loading}
                  className="item"
                  handleHover={setHover}
                  image={event.thumbnail}
                  event={event}
                />
              </CarouselExample2>
            ))}
          </ContainerFilteredEvents>
        ) : (
          <NotFoundSearch>
            <ContentIcon>
              <IconBiggerSearch />
            </ContentIcon>
            <NotFoundResultSearch>
              Sua busca não retornou nenhum resultado
            </NotFoundResultSearch>
            <Suggestion>
              Tente verificar a ortografia ou buscar por outra palavra-chave
            </Suggestion>
          </NotFoundSearch>
        )}
      </SearchContainer>
    </Container>
  );
};

export default SearchPage;
