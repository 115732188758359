/* eslint-disable no-plusplus */
export const makeArrayLengthMultipleOfN = <T>(
  arr: Array<T>,
  n: number,
): Array<T> => {
  const remainder = arr.length % n;

  if (remainder === 0) return arr;

  const missingItems = n - remainder;
  const newArr = [...arr];

  for (let i = 0; i < missingItems; i++) {
    newArr.push(undefined as unknown as T);
  }

  return newArr;
};
