import styled from 'styled-components';
import { IconBackArrow, NewIconArrowLeft } from 'presentation/base/icons';
import media from 'utils/mediaqueries';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 6rem;
`;

export const SubHeaderDiv = styled.div`
  display: flex;
  width: 100%;
  height: 6rem;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 48px;
  background-color: ${props => props.theme.colors.black2};
  border-top: 1px solid ${props => props.theme.colors.black3};

  ${media.lessThan('md')} {
    padding: 20px 21px;
  }

  ${media.lessThan('sm')} {
    padding-left: 3rem;
    padding-right: 1.2rem;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  height: 100%;

  color: #ffffff;
`;

export const BackArrow = styled(NewIconArrowLeft)`
  width: 2rem;
  height: 2rem;

  margin-right: 2.2rem;

  cursor: pointer;

  ${media.lessThan('sm')} {
    width: 1.6rem;
    height: 1.6rem;

    margin-right: 15px;
  }
`;

export const CategoryTitle = styled.h5`
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;

  color: ${({ theme }) => theme.colors.white2};
`;
