import React, { cloneElement } from 'react';
import { FloatButtonProps } from './types';
import { Container } from './styles';

const FloatButton: React.FC<FloatButtonProps> = ({
  children,
  icon: Icon,
  ...rest
}) => {
  return (
    <Container {...rest} onClick={rest.action}>
      <Icon className="icon-float-button" />
      <p className="label-float-button">{children}</p>
    </Container>
  );
};

export default FloatButton;
