import { GetAllFriendship } from 'domain/usecases/friendship/redux/GetAllFriendship';
import {
  iActionGetAll,
  iActionGetAllFailed,
  iActionGetAllSuccess,
  FriendshipTypes,
} from 'domain/interfaces/redux/friendship/getAll';

export const getAllRequest = (
  payload: GetAllFriendship.Params,
): iActionGetAll => ({
  type: FriendshipTypes.GETALL,
  payload,
});

export const getAllSuccess = (
  params: GetAllFriendship.Model,
): iActionGetAllSuccess => ({
  type: FriendshipTypes.GETALL_SUCCESS,
  payload: params,
});

export const getAllFailed = (): iActionGetAllFailed => ({
  type: FriendshipTypes.GETALL_FAILED,
});
