import { CreateEvent } from 'domain/usecases/event/redux/CreateEvent';
import {
  iActionCreate,
  iActionCreateFailed,
  iActionCreateSuccess,
  EventTypes,
} from 'domain/interfaces/redux/event/create';

export const createRequest = (payload: CreateEvent.Params): iActionCreate => ({
  type: EventTypes.CREATE,
  payload,
});

export const createSuccess = (
  params: CreateEvent.Model,
): iActionCreateSuccess => ({
  type: EventTypes.CREATE_SUCCESS,
  payload: params,
});

export const createFailed = (): iActionCreateFailed => ({
  type: EventTypes.CREATE_FAILED,
});
