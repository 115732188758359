import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetAllCategory as UsecaseRemoteGetAllCategory } from 'domain/usecases/category/remote';
import { GetAllCategory as UsecaseReduxGetAllCategory } from 'domain/usecases/category/redux';

import { RemoteGetAllCategory } from 'data/repository/category';
import { ReduxGetAllCategory } from 'data/store/reducer/category/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetAllCategory = (): UsecaseRemoteGetAllCategory =>
  new RemoteGetAllCategory(makeApiUrl('/categories/'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxGetAllCategory = (): UsecaseReduxGetAllCategory =>
  new ReduxGetAllCategory();
