import React from 'react';
import { Event } from '.';
import {
  AboutContainer,
  AboutDescription,
  AboutInfoContainer,
  AboutTitle,
  Divider,
  TagContainer,
  Tag,
} from './styles';

interface AboutProps {
  event: Event;
}

export const About: React.FC<AboutProps> = ({ event }) => {
  return (
    <AboutContainer>
      <div className="content">
        <AboutInfoContainer>
          <AboutTitle>{event?.name}</AboutTitle>
          <AboutDescription capitalize>{event?.date}</AboutDescription>
        </AboutInfoContainer>
        <Divider />
        <AboutInfoContainer>
          <AboutTitle>Dados gerais</AboutTitle>
          <AboutDescription>
            {'Tipo de conteudo: '}
            {event?.type}
          </AboutDescription>
          <AboutDescription>
            {'Categoria: '}
            {event?.category}
          </AboutDescription>
          <AboutDescription>
            {'Subcategoria: '}

            {event?.subCategory}
          </AboutDescription>
          <AboutDescription>
            {'Responsavel: '}
            {event.organization}
          </AboutDescription>
        </AboutInfoContainer>
        <Divider />
        <AboutInfoContainer>
          <AboutTitle>Descrição</AboutTitle>
          <AboutDescription>{event?.descr}</AboutDescription>
        </AboutInfoContainer>
        <AboutInfoContainer>
          <TagContainer>
            {event?.tags?.map(label => (
              <Tag key={label}>{label}</Tag>
            ))}
          </TagContainer>
        </AboutInfoContainer>
      </div>
    </AboutContainer>
  );
};

export default About;
