// eslint-disable-next-line @typescript-eslint/no-empty-interface
import React from 'react';

import { Container, Title, Description, Image, Body } from './styles';

interface ownProps {
  title: string;
  description: string;
  image: Object;
}

const CategoryBackground: React.FC<ownProps> = ({
  title,
  description,
  image,
}) => {
  return (
    <Container>
      <Image style={{ backgroundImage: `url(${image})` }} />
      <Body>
        <div className="shade" />
        <Title>{title}</Title>
        <Description>
          <span>{description}</span>
        </Description>
      </Body>
    </Container>
  );
};

export default CategoryBackground;
