import { Link } from 'react-router-dom';
import styled from 'styled-components';
import media from 'utils/mediaqueries';

export const Container = styled.form`
  width: 40.8rem;

  background: ${({ theme }) => theme.colors.black2};

  border-radius: 10px;
`;

export const Content = styled.div`
  ${media.lessThan('sm')} {
    padding: 20px 20px 0;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 2.4rem;
`;

export const Title = styled.h1`
  font-size: 1.8rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white1};
`;

export const TitleContent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Body = styled.div`
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
`;

export const ContainerInputs = styled.div`
  width: 100%;
  padding: 0 2.4rem 2.4rem 2.4rem;
`;

export const ContainerSocialButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2.4rem;
  gap: 1.6rem;
  padding-bottom: 2.4rem;

  #socialMediaIcons:hover {
    opacity: 0.5;
  }
`;

export const TitleSocialMedia = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.white1};
`;

export const SocialMedia = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.4rem;
`;

export const SocialMediaButtons = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors.white1};
  }
`;

export const ChangeMode = styled.div`
  width: 100%;
  display: flex;
  height: 3.4rem;
  overflow: hidden;
  margin: 3rem 0 3.7rem 0;
`;

export const PaddingContainer = styled.div`
  padding: 0 2.4rem;
`;

interface ownProps {
  active: boolean;
  index: number;
}
export const OptionsMode = styled.div<ownProps>`
  border-radius: ${({ index, active }) => {
    if (index === 0 && active) {
      return '5px 0 0 5px';
    }
    if (index === 0 && !active) {
      return '5px 0 0 5px';
    }
    return '0 5px 5px 0';
  }};

  width: 100%;
  padding: 7px 0 7px 0;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: ${({ active, theme }) =>
    active ? theme.colors.white1 : theme.colors.black5};
  color: ${({ active, theme }) =>
    active ? theme.colors.black1 : theme.colors.white1};

  &:hover {
    background-color: ${({ theme, active }) => !active && theme.colors.blue1};
    color: ${({ theme, active }) => !active && theme.colors.white1};
  }
`;

export const ContentInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const ContainerLoginProblem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RememberMe = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
`;

export const CheckboxTitle = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.white2};
`;

export const LoginProblem = styled.div`
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.white2};
`;

export const ActionsLogin = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

export const Agreements = styled.div`
  width: 100%;
  max-width: 95%;
  display: flex;

  font-size: 12px;
  line-height: 18px;

  color: ${({ theme }) => theme.colors.white1};

  margin: 2rem 0 3rem 0;

  span {
    width: 100%;
    margin-left: 1.5rem;
  }
`;

export const LinkToAgreements = styled(Link)`
  text-decoration: none;
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.pink1};
  white-space: wrap;

  &:hover {
    text-decoration: none;
  }
`;
