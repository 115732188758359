import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetEventById as UsecaseRemoteGetEventById } from 'domain/usecases/event/remote';

import { RemoteGetEventById } from 'data/repository/event';

/**
 * send request via API.
 */
export const makeRemoteGetEventById = (): UsecaseRemoteGetEventById =>
  new RemoteGetEventById(makeApiUrl('/events/{eventId}'), makeHttpClient());
