export enum EventTypes {
  CREATE = '@event/CREATE',
  CREATE_SUCCESS = '@event/CREATE_SUCCESS',
  CREATE_FAILED = '@event/CREATE_FAILED',
  GETALL = '@event/GETALL',
  GETALL_SUCCESS = '@event/GETALL_SUCCESS',
  GETALL_FAILED = '@event/GETALL_FAILED',
  DELETE = '@event/DELETE',
  DELETE_SUCCESS = '@event/DELETE_SUCCESS',
  DELETE_FAILED = '@event/DELETE_FAILED',
}
