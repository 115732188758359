/* eslint-disable consistent-return */
/* eslint-disable prettier/prettier */
import { CategoryData } from 'domain/interfaces/models/Category';
import { makeRemoteGetAllCategory } from 'main/factories/usecases/category/GetAllCategoryFactory';
import { defaultTheme } from 'presentation/base/themes';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import SubcategoryOptions from '../SubcategoryOptions';
import { Input, Select } from '../UI';
import { Message } from '../UI/Input/styles';
import { iForm } from './NewEditEvent';
import {
  CategoriesContent,
  Category,
  Container,
  Content,
  InputView,
  MultipleInputValuesView,
  SubTitle,
  Title,
} from './styles/ContentDataStyled';
import { colourStyles } from './styles/tagSelectStyle';

interface CurrentContentData {
  name: string;
  id: number;
  childType: null | 'SINGLE' | 'MULTIPLE';
}

interface SelectValue {
  label: string;
  value: string;
}

interface Keyword {
  inputValue: string;
  value: SelectValue[];
}

interface ContentDataProps {
  eventForm: iForm;
  // categoriesSelected: any;
  // onChangeCategoriesSelected: Function;
  updateForm: (value: any) => void;
}

const ContentData: React.FC<ContentDataProps> = ({
  // categoriesSelected,
  // onChangeCategoriesSelected,
  updateForm,
  eventForm,
}) => {
  const [listCurrentCategory, setListCurrentCategory] = useState<
    CategoryData[]
  >([]);
  const [listCurrentSubcategories, setCurrentListSubcategories] = useState<
    any[]
  >([]);
  const [currentCategory, setCurrentCategory] = useState<SelectValue>({
    label: '',
    value: '-1',
  } as SelectValue);

  const [keywords, setKeywords] = useState<Keyword>({
    inputValue: '',
    value: [],
  });

  const [singleCategory, setSingleCategory] = useState<CategoryData>(
    {} as CategoryData,
  );

  const {
    register,
    setValue,
    setError,
    getValues,
    watch,
    formState: { errors, dirtyFields, isDirty },
  } = useFormContext();

  const [contentType, setContentType] = useState<CurrentContentData>({
    id: -1,
    name: '',
    childType: getValues()?.categories[0]?.childType ?? null,
  } as CurrentContentData);

  const [multipleCategory, setMultipleCategory] = useState<any[]>([]);

  const contentOptions = [
    {
      id: 1,
      name: 'esportes',
      childType: 'SINGLE',
    },
    {
      id: 2,
      name: 'musica',
      childType: 'MULTIPLE',
    },
    {
      id: 3,
      name: 'entretenimento',
      childType: 'INPUT',
    },
  ];

  const components = {
    DropdownIndicator: null,
  };

  const createOption = (label: string) => ({
    label,
    value: label,
  });

  const getVariant = useCallback(() => {
    if (watch('content')?.id === 2) {
      return true;
    }

    if (watch('content')?.id === 3) {
      return false;
    }
  }, [watch]);

  const CategoriesFormatted = () => {
    if (listCurrentCategory) {
      const categoriesFormatted: SelectValue[] = listCurrentCategory.map(
        (category: CategoryData) => {
          return {
            label: category.name,
            value: String(category.id),
          };
        },
      );
      return categoriesFormatted;
    }
    return [{ value: '', label: '' }];
  };

  const handleChange = (value: SelectValue[]) => {
    setKeywords({ ...keywords, value });
    updateForm({ ...eventForm, keywords: value.map(item => item.value) });
  };

  const handleInputChange = (inputValue: string) => {
    const splitInputValue = inputValue.split(',');

    if (splitInputValue.length <= 1)
      return setKeywords({ ...keywords, inputValue });

    const newKeywords = [...keywords.value, createOption(splitInputValue[0])];

    updateForm({
      ...eventForm,
      keywords: newKeywords.map(item => item.value),
    });

    return setKeywords({
      inputValue: '',
      value: [...keywords.value, createOption(splitInputValue[0])],
    });
  };

  useEffect(() => {
    if (!Number(getValues()?.category?.value)) return;

    makeRemoteGetAllCategory()
      .getAll({ parent: Number(getValues()?.category?.value) })
      .then(res => {
        if (res.length) {
          if (res.length === getValues()?.multipleCategories.length) return;
          const newValue = res.map(item => {
            const newItem = getValues()?.multipleCategories.find(
              (obj: any) => obj.name === item.name,
            );

            if (newItem) {
              return {
                id: item.id,
                name: newItem.name,
                checked: true,
              };
            }

            return {
              id: item.id,
              name: item.name,
              checked: false,
            };
          });

          setMultipleCategory(newValue);
        }
      })
      .catch(err => console.log(err));
  }, []);

  // ! Category

  useEffect(() => {
    if (!Number(getValues()?.content?.id)) return;
    makeRemoteGetAllCategory()
      .getAll({ parent: Number(getValues()?.content?.id) })
      .then(res => setListCurrentCategory(res))
      .catch(err => console.log(err));

    setCurrentCategory({ label: '', value: '-1' });
  }, [getValues()?.content]);

  // ! Subcategory

  useEffect(() => {
    makeRemoteGetAllCategory()
      .getAll({
        parent: Number(getValues()?.category?.value),
      })
      .then(res => {
        if (res.length) {
          const newValue = res.map(item => {
            return {
              id: item.id,
              name: item.name,
              checked: false,
            };
          });
          if (getValues()?.multipleCategories.length > 0) {
            setMultipleCategory(getValues()?.multipleCategories);
          } else {
            setMultipleCategory(newValue);
          }
        } else {
          setMultipleCategory([]);
        }
      })
      .catch(err => console.error(err));
  }, [getValues()?.category]);

  // ! Fill in form

  useEffect(() => {
    if (
      !multipleCategory.length &&
      getValues()?.category?.value &&
      getValues()?.subCategory
    ) {
      const newObj = [
        {
          categoryId: Number(getValues()?.category?.value),
          subCategory: getValues()?.subCategory,
          type: 'SUBCATEGORY',
        },
      ];
      setValue('categories', newObj);
      return;
    }

    if (Object.entries(singleCategory).length) {
      setValue('multipleCategories', multipleCategory);

      const newObj = [
        {
          categoryId: Number(getValues()?.category?.value),
          subCategory: singleCategory.name,
          type: 'SUBCATEGORY',
        },
      ];
      // setValue('subCategory', getValues()?.category?.label);
      setValue('categories', newObj);
      return;
    }
    if (multipleCategory.length > 0) {
      setValue('multipleCategories', multipleCategory);

      const categoriesChecked = multipleCategory.filter(
        (item: any) => item.checked === true,
      );

      const categoriesFormatted = categoriesChecked.map((category: any) => {
        return {
          categoryId: Number(getValues()?.category?.value),
          subCategory: category.name,
          type: 'SUBCATEGORY',
        };
      });
      setValue('categories', categoriesFormatted);
      setValue('subCategory', ' ');
    }
  }, [
    getValues,
    getValues()?.subCategory,
    multipleCategory,
    setValue,
    singleCategory,
  ]);
  console.log('errors category: ', getValues()?.category);
  console.log('errors content: ', getValues()?.content);

  return (
    <Container>
      <Content>
        <Title>Dados do Conteúdo</Title>
        <SubTitle>Tipo de conteúdo*</SubTitle>
        <CategoriesContent>
          {contentOptions.map(content => (
            <Category
              key={content.id}
              active={getValues().content?.id === content.id}
              onClick={() => {
                setContentType({
                  id: content.id,
                  name: content.name,
                  childType: content.childType as null | 'SINGLE' | 'MULTIPLE',
                });
                setSingleCategory({} as CategoryData);
                setMultipleCategory([]);
                setValue('subCategory', '');
                setValue('category', { label: '', value: '-1' });
                setValue('content', content);
                setError('content', { type: 'onBlur' });
                setValue('categories', []);
                setValue('multipleCategories', []);
              }}
            >
              {content.name}
            </Category>
          ))}
        </CategoriesContent>
        {errors?.content?.name && (
          <Message style={{ marginTop: 10 }} error={errors?.content?.name}>
            {errors?.content?.name?.message}
          </Message>
        )}
        <SubTitle>Categoria*</SubTitle>
        <Select
          {...register('category')}
          size="large"
          variant="secondary"
          options={CategoriesFormatted()}
          disabled={getValues().content === ''}
          value={{
            label: getValues()?.category?.label,
            value: getValues()?.category?.value,
          }}
          error={Boolean(errors?.category)}
          message={errors?.category?.label && errors?.category?.label.message}
          onChange={val => {
            setCurrentCategory(val);
            setCurrentListSubcategories([]);
            setError('category', { type: 'onBlur' });
            setValue('category', val);
          }}
        />
        <SubTitle>Subcategoria*</SubTitle>
        {watch('category')?.label !== '' &&
          watch('content') !== 'esportes' &&
          multipleCategory?.length && (
            <>
              <SubcategoryOptions
                getSingleSubcategories={setSingleCategory}
                getMultipleSubcategories={setMultipleCategory}
                variant={getVariant()}
                subcategories={multipleCategory}
              />
              {errors?.subcategory && (
                <Message style={{ marginTop: 10 }} error={errors?.subcategory}>
                  {errors?.subcategory.message}
                </Message>
              )}
            </>
          )}
        {console.log('contentType: ', contentType)}

        {!multipleCategory?.length && (
          <InputView>
            <Input
              {...register('subCategory')}
              disabled={getValues()?.content === ''}
              onChange={e => {
                register('subCategory').onChange(e);
                setValue('subCategory', e.target.value);
                setError('subCategory', { type: 'onBlur' });
              }}
              error={Boolean(errors?.subCategory)}
              message={errors?.subCategory && errors?.subCategory.message}
            />
          </InputView>
        )}
        <SubTitle>Palavras-chave</SubTitle>
        <MultipleInputValuesView>
          <CreatableSelect
            id="keywords"
            components={components}
            inputValue={keywords.inputValue}
            isClearable
            isMulti
            menuIsOpen={false}
            // onChange={(newValue, _) => handleChange(newValue)}
            onInputChange={handleInputChange}
            placeholder=""
            value={keywords.value}
            styles={colourStyles}
          />
          <p style={{ color: defaultTheme.colors.white1, fontSize: '1.2rem' }}>
            Insira palavras-chave separadas por vírgula.
          </p>
        </MultipleInputValuesView>
      </Content>
    </Container>
  );
};

export default ContentData;
