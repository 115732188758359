import styled, { css } from 'styled-components';

const padding = css`
  padding: 2.8rem 2.4rem;
`;

const sizes = {
  xl: '53.6rem',
  m: '36rem',
  s: '16.8rem',
  xs: '7.6rem',
};

interface DivProps {
  size?: keyof typeof sizes;
}

export const Div = styled.div<DivProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  max-width: ${({ size }) => (size ? sizes[size] : '100%')};
`;

export const SendDateTimeOptions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  .input-z-index {
    z-index: 2;
  }

  .nice-dates-popover {
    width: 280px;
    background-color: ${({ theme }) => theme.colors.black2};
    box-shadow: 16px;

    ::placeholder {
      span {
        color: ${({ theme }) => theme.colors.white1};
      }
    }
  }

  .nice-dates-navigation {
    align-items: center;
    justify-content: center;

    padding: 1rem 2.4rem;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.5rem;

      &:before {
        border-color: ${({ theme }) => theme.colors.white1};
        border-width: 0.2rem 0.2rem 0 0;
        width: 1rem;
        height: 1rem;
        margin: 0;

        transform: translate(-70%, -50%) rotate(45deg);
      }

      &:hover {
        background: ${({ theme }) => theme.colors.black3};
      }

      &:focus-visible {
        outline: 0.2rem solid ${({ theme }) => theme.colors.blue1};
        box-shadow: 0px 0px 10px ${({ theme }) => theme.colors.blue1};
        background: ${({ theme }) => theme.colors.black3};
      }

      &.-disabled {
        background: ${({ theme }) => theme.colors.black1};
        &:before {
          border-color: ${({ theme }) => theme.colors.white3};
        }
      }
    }
    .nice-dates-navigation_current {
      color: ${({ theme }) => theme.colors.white1};
      font-size: 1.6rem;
      font-weight: 700;
    }

    .nice-dates-navigation_previous {
      &:before {
        transform: translate(-30%, -50%) rotate(-135deg);
      }
    }
  }

  .nice-dates-week-header {
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    box-shadow: none;
    gap: 1rem;
    padding: 0 2.8rem;

    .nice-dates-week-header_day {
      width: 100%;
      color: ${({ theme }) => theme.colors.white6};
      text-transform: uppercase;

      font-weight: 600;
      font-size: 1rem;
      line-height: 1.4rem;

      margin-top: 1.6rem;
      margin-bottom: 1.2rem;
    }
  }

  .nice-dates-grid_container {
    width: 100%;

    padding: 0 2.4rem;
  }

  .nice-dates-day {
    height: 3.6rem !important;
    color: ${({ theme }) => theme.colors.white1};
    border: 0.1rem solid ${({ theme }) => theme.colors.black3};

    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.9rem;

    &:last-child {
      border-radius: 0 0 0.5rem 0;
    }

    &:nth-last-child(7) {
      border-radius: 0 0 0 0.5rem;
    }

    &:after {
      color: ${({ theme }) => theme.colors.white1};
      background-color: ${({ theme }) => theme.colors.blue1};
      border: none;
      padding: 1rem;
    }

    &:before,
    &:after {
      border-radius: 0;
    }

    .nice-dates-day_month {
      display: none;
      border: ${({ theme }) => theme.colors.blue1};
      color: ${({ theme }) => theme.colors.white1};
    }

    &.-wide {
      color: #686868;
    }

    &.-outside {
      color: ${({ theme }) => theme.colors.black3};
      background-color: ${({ theme }) => theme.colors.black1};
    }

    &:hover {
      color: ${({ theme }) => theme.colors.white1};
      background-color: ${({ theme }) => theme.colors.blue1};
      border: ${({ theme }) => theme.colors.blue1};
    }

    &.-selected {
      color: ${({ theme }) => theme.colors.white1};
      background-color: ${({ theme }) => theme.colors.blue1};
      border: 2px solid ${({ theme }) => theme.colors.white1};

      span {
        color: currentColor;
      }

      &:hover {
        &:after {
          background-color: ${({ theme }) => theme.colors.blue1};
        }
      }

      &:after {
        transform: scale(1);
      }

      &:before {
        /* background-color: ${({ theme }) => theme.colors.blue1}; */
      }

      &.-selected-middle {
        &:before {
          background-color: #72dec8;
        }
      }

      &.-selected-end {
        &:after {
          opacity: 1;
          background-color: ${({ theme }) => theme.colors.blue1};
          border-radius: 999px;
        }
        &:before {
          background-color: ${({ theme }) => theme.colors.blue1};
          border-color: #72dec8;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      &.-selected-start {
        &:after {
          opacity: 1;
          background-color: ${({ theme }) => theme.colors.blue1};
          border-radius: 999px;
        }
        &:before {
          background-color: ${({ theme }) => theme.colors.blue1};
          border-color: #72dec8;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }
`;

// export const Label = styled.div`
//   font-weight: bold;
//   font-size: 16px;
//   color: ${({ theme }) => theme.colors.white1};
// `;

interface ContainerProps {
  width?: string;
  maxWidth?: string;
  orientation?: 'column' | 'row';
  required?: boolean;
  size?: keyof typeof sizes;
}

export const Label = styled.span<ContainerProps>`
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: ${props => props.theme.colors.white2};

  margin-right: ${({ orientation }) =>
    orientation === 'row' ? '15px' : '0px'};
  font-weight: 600;

  margin-bottom: 1.2rem;

  :after {
    content: ${({ required }) => (required ? '"*"' : '""')};
    color: ${props => props.theme.colors.red1};
  }
`;

interface CalendarIconProps {
  isFocused: boolean;
}

export const CalendarIconDiv = styled.div<CalendarIconProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.6rem;
  min-width: 3.6rem;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.black3};
  border-radius: 0 0.5rem 0.5rem 0;

  z-index: 0;

  ${({ isFocused }) =>
    isFocused &&
    css`
      /* background-color: ${({ theme }) => theme.colors.blue1}; */
    `}
`;
