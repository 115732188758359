export function getSpatialization(
  layout: number,
  number: number,
  letter: number,
): number {
  switch (layout) {
    case 3:
      return number > 5 && number <= 11 && letter >= 0 && letter < 6
        ? number + 10
        : number;
    case 4:
      return number > 5 && number <= 11 && letter >= 2 && letter < 8
        ? number + 10
        : number;
    default:
      return number;
  }
}
