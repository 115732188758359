/* eslint-disable react/react-in-jsx-scope */
import { iStore } from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import { IconPlus } from 'presentation/base/icons';
import bannerBeta from 'presentation/base/images/bannerBeta.png';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { templateDefault } from 'services/mocks';
import { closeModal } from 'utils/modalFunctions';
import InfoEvent from '../InfoEvent';
import { NewSelect } from '../UI';
import ButtonToAddNewTemplate from './ButtonToAddNewTemplate';
import { EventContent } from './NewEventStyled';
import { BoxToPreviewTemplate } from './styles/PublicityStyled';
import { HeaderContent } from './styles/common';

// import { Container } from './styles';

const Publicity = () => {
  const [template, setTemplate] = useState(templateDefault);
  const { sponsor } = useSelector((store: iStore) => store);

  const {
    register,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext();

  const sponsorData = watch('sponsor');

  const optionsTemplate = useMemo(() => {
    return [
      ...(sponsor.sponsors.map(sponsorItem => ({
        label: sponsorItem.name,
        value: sponsorItem.id,
      })) ?? []),
    ];
  }, [sponsor.sponsors]);

  const handleSelectTemplateDefault = useCallback(() => {
    const { sponsors } = sponsor;

    const templateDefaultSelected = sponsors.find(
      templateItem =>
        templateItem.short === '2eb0ff34-29dc-4d1b-a181-d527a19a1ddd',
    );

    if (templateDefaultSelected) {
      setTemplate({
        ...templateDefaultSelected,
        logo: templateDefaultSelected.logo ?? bannerBeta,
      });
    }

    setValue('sponsor', template.id);
  }, [setValue, sponsor, template.id]);

  const openNewTemplateModal = useCallback(() => {
    makeReduxActiveMessage().active({
      active: MessageOptions.createTemplateToSponsor,
      actionCancel: closeModal,
    });
  }, []);

  const handleSelectTemplate = useCallback(
    (value: { label: string; value: number }) => {
      const templateSelected = sponsor.sponsors.find(
        sponsorItem => sponsorItem.id === value.value,
      );
      if (templateSelected) {
        setTemplate({
          ...templateSelected,
          logo: templateSelected.logo ?? bannerBeta,
        });
        setValue('sponsor', value.value);
      } else {
        setTemplate(templateDefault);
        setValue('sponsor', 1);
      }
    },
    [sponsor.sponsors, setValue],
  );

  useEffect(() => {
    if (sponsorData === undefined) {
      handleSelectTemplateDefault();
    }
  }, [
    handleSelectTemplate,
    handleSelectTemplateDefault,
    optionsTemplate,
    sponsorData,
  ]);

  return (
    <div>
      <HeaderContent>3. Anúncios</HeaderContent>
      <EventContent>
        <div className="form">
          <NewSelect
            {...register('sponsor')}
            label="Predefinição"
            placeholder="Escolha um anúncio"
            required
            buttonOnSelect={() => (
              <ButtonToAddNewTemplate
                label="Criar novo anúncio"
                onClick={openNewTemplateModal}
                icon={<IconPlus />}
              />
            )}
            onChange={value => {
              handleSelectTemplate(value);
              setValue('sponsor', value.value);
            }}
            error={errors.sponsor}
            message={errors.sponsor?.message}
            value={{
              ...template,
              value: template.id,
              label: template.name,
            }}
            options={optionsTemplate}
          />
          <BoxToPreviewTemplate>
            <InfoEvent template={template} />
          </BoxToPreviewTemplate>
        </div>
      </EventContent>
    </div>
  );
};

export default Publicity;
