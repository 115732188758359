import { Dispatch } from 'redux';
import store from 'data/store';

import { listRequest } from 'data/store/reducer/sponsor/actions/list';

import { iActionList } from 'domain/interfaces/redux/sponsor/list';

import { ListSponsors } from 'domain/usecases/sponsor/redux';

export class ReduxListSponsors implements ListSponsors {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  list = (params: ListSponsors.Params): iActionList =>
    this.send(listRequest(params));
}
