import React, { memo, useCallback, useContext, useMemo } from 'react';

import {
  IconAddFriend,
  IconBallonChat,
  IconClose,
  IconMicDisabled,
  IconMicEnabled,
  IconMoreVert,
  IconParticipantSide,
  IconParticipants,
  IconSearchNew,
} from 'presentation/base/icons';

import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import { SpotsInfo } from 'data/store/ducks/spotsInfo/types';
import { AudioContext } from 'presentation/contexts/AudioContext';
import MicButton from '../MicButton';
import Input from '../NewChat/Input';
import { RandomProfileUser } from '../randomProfileUser';

import MoreOptionsButton from './MoreOptionsButton';
import {
  AvatarContainer,
  Body,
  CloseButton,
  Container,
  HeaderToBottomSheet,
  Info,
  LeftSide,
  ListItem,
  Name,
  RightSide,
  Role,
  SearchBar,
  TitleContainer,
  TitleLabel,
} from './styles';
import IconButton from '../IconButton';

interface ParticipantsProps {
  participants?: SpotsInfo[];
  isMobile?: boolean;
  isOpen?: boolean;
  onClose?: () => void;
}

const ParticipantsList: React.FC<ParticipantsProps> = ({
  participants,
  isMobile,
  isOpen,
  onClose,
}) => {
  const [searchResults, setSearchResults] = React.useState<SpotsInfo[]>([]);
  const wrtcInfo = useSelector((store: iStore) => store.auth.wrtcInfo);
  const {
    isMicMuted,
    handleRemoteMute,
    getMuteState,
    isActualUser,
    localMute,
    isMuteRemotely,
  } = useContext(AudioContext);

  const handleMuteMicRemotely = useCallback(
    async (participant: SpotsInfo) => {
      const { peerId, spotCoordI, spotCoordJ } = participant;

      if (isActualUser(Number(peerId))) localMute();
      else {
        handleRemoteMute(
          Number(peerId),
          Number(spotCoordJ),
          Number(spotCoordI),
        );
      }
    },
    [handleRemoteMute, isActualUser, localMute],
  );

  if (participants) {
    const me = participants.find(
      participant => participant.peerId === wrtcInfo.peerId,
    );
    if (me) {
      participants = participants.filter(
        participant => participant.peerId !== wrtcInfo.peerId,
      );
      participants.unshift(me);
    }
  }

  const renderActionButtons = useCallback(
    (participant: SpotsInfo) => {
      const isMicEnabled = isActualUser(Number(participant?.peerId))
        ? isMicMuted
        : !getMuteState(Number(participant?.peerId));
      const isMe = participant?.peerId === wrtcInfo?.peerId;
      return (
        !isMe && (
          <RightSide>
            <IconButton
              disabled={isMuteRemotely(Number(participant?.peerId))}
              onClick={() => handleMuteMicRemotely(participant)}
              icon={
                isMicEnabled ? (
                  <IconMicEnabled />
                ) : (
                  <IconMicDisabled className="disabled" />
                )
              }
            />
            <IconButton icon={<IconMoreVert />} disabled />
          </RightSide>
        )
      );
    },
    [
      getMuteState,
      handleMuteMicRemotely,
      isActualUser,
      isMuteRemotely,
      isMicMuted,
      wrtcInfo?.peerId,
    ],
  );

  const handleSearchParticipants = useCallback(
    (searchTerm: string) => {
      if (searchTerm !== '') {
        const results = participants?.filter(participant =>
          participant.peerName
            .split(':')[0]
            .toLowerCase()
            .includes(searchTerm.toLowerCase()),
        );
        setSearchResults(results ?? []);
      } else {
        setSearchResults([]);
      }
    },
    [participants],
  );

  return (
    <Container
      tabIndex={isMobile ? 0 : undefined}
      isMobile={isMobile}
      isOpen={isOpen}
    >
      {isMobile && (
        <HeaderToBottomSheet>
          <TitleContainer>
            <div id="icon-participants">
              <IconParticipantSide />
            </div>
            <TitleLabel>Participantes</TitleLabel>
          </TitleContainer>
          <CloseButton onClick={onClose}>
            <IconClose />
          </CloseButton>
        </HeaderToBottomSheet>
      )}
      <SearchBar>
        <Input
          placeholder="Pesquisar participantes"
          withoutSticker
          IconCustom={IconSearchNew}
          onSend={text => handleSearchParticipants(text)}
          resetField={false}
        />
      </SearchBar>
      <Body>
        {(searchResults.length > 0 ? searchResults : participants)?.map(
          participant => (
            <ListItem
              key={participant.peerId}
              micDisabled={
                isActualUser(Number(participant.peerId))
                  ? !isMicMuted
                  : getMuteState(Number(participant.peerId))
              }
            >
              <LeftSide>
                <AvatarContainer>
                  <RandomProfileUser
                    peerId={Number(participant.peerId) ?? 0}
                    size="4rem"
                    style={{ borderRadius: '0.5rem' }}
                    micDisabled={
                      isActualUser(Number(participant.peerId))
                        ? !isMicMuted
                        : getMuteState(Number(participant.peerId))
                    }
                    containerStyle={{
                      width: '4rem',
                      height: '4rem',
                      overflow: 'hidden',
                      position: 'relative',
                    }}
                  />
                </AvatarContainer>

                <Info>
                  <Name>
                    {participant.peerName.split(':')[0]}
                    {participant.peerId === wrtcInfo.peerId && ' (Você)'}
                  </Name>
                  <Role>Participante</Role>
                </Info>
              </LeftSide>
              {renderActionButtons(participant)}
            </ListItem>
          ),
        )}
      </Body>
    </Container>
  );
};

export default ParticipantsList;

// function arePropsEqual(
//   prevProps: ParticipantsProps,
//   nextProps: ParticipantsProps,
// ) {
//   return prevProps.participants === nextProps.participants;
// }
