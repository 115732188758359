import { useTheme } from 'presentation/contexts/themeContext';
import React, { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import Checkbox from '../Checkbox';
import { Calendar } from '../Datepicker';
import LogoDropzone from '../Dropzone';
import { ContentInputRow } from '../EditEventForm/styles/GeneralDataStyled';
import TextArea from '../TextArea';
import { Input } from '../UI';
import { EventContent } from './NewEventStyled';
import {
  CheckBoxView,
  Container,
  Content,
  ContentInput,
  FormDiv,
  Message,
  ScheduleContainer,
} from './styles/GeneralDataStyled';
import { HeaderContent } from './styles/common';

const GeneralData: React.FC = () => {
  const {
    register,
    setValue,
    setError,
    getValues,
    watch,
    trigger,
    clearErrors,
    formState: { errors, touchedFields },
  } = useFormContext();

  const noForecast = watch('noForecast');

  const schedule = watch('schedule');
  const scheduleEnd = watch('scheduleEnd');

  const { defaultTheme } = useTheme();

  const dateErrorMessage: { message: string } = useMemo(() => {
    return (
      errors.schedule ||
      errors?.scheduleEnd ||
      errors?.scheduleEndHour ||
      errors?.scheduleStartHour
    );
  }, [
    errors.schedule,
    errors?.scheduleEnd,
    errors?.scheduleEndHour,
    errors?.scheduleStartHour,
  ]);

  const handleChangeDateStart = useCallback(
    (date: Date | null) => {
      setValue('schedule', date);
      setError('schedule', { type: 'onBlur' });

      if (touchedFields.scheduleEndHour) trigger('scheduleEndHour');
      if (scheduleEnd && date && scheduleEnd < date) {
        setValue('scheduleEnd', null);
      }
    },
    [scheduleEnd, setError, setValue, touchedFields.scheduleEndHour, trigger],
  );

  return (
    <div className="form-item">
      <HeaderContent>1. Informações gerais</HeaderContent>
      <EventContent>
        <div className="form">
          <Container>
            <Content>
              <FormDiv>
                <ContentInput>
                  <Input
                    {...register('name')}
                    id="name"
                    onChange={e => {
                      setError('name', {
                        type: 'onBlur',
                      });

                      register('name').onChange(e);
                    }}
                    label="Título da sessão"
                    required
                    onBlur={() => trigger('name')}
                    value={getValues()?.name}
                    name="name"
                    error={errors.name?.message}
                    message={errors?.name && errors.name?.message}
                    autoFocus
                    color="black4"
                  />
                </ContentInput>
                <ContentInput>
                  <Input
                    {...register('organization')}
                    onChange={e => {
                      setError('organization', { type: 'onBlur' });
                      register('organization').onChange(e);
                    }}
                    label="Responsável"
                    required
                    error={Boolean(errors.organization?.message)}
                    message={
                      errors.organization && errors?.organization?.message
                    }
                    color="black4"
                    onBlur={() => trigger('organization')}
                    id="organization"
                  />
                </ContentInput>
                <ContentInput error={Boolean(errors.descr)}>
                  <TextArea
                    {...register('descr')}
                    id="descr"
                    label="Descrição"
                    value={getValues()?.descr}
                    onChange={e => {
                      setError('descr', { type: 'onBlur' });
                      trigger('descr');
                      setValue('descr', e.currentTarget.value);
                    }}
                    onBlur={() => trigger('descr')}
                    color={defaultTheme.colors.black4}
                    error={Boolean(errors.descr)}
                  />
                </ContentInput>
                <ScheduleContainer>
                  <ContentInputRow>
                    <ContentInput direction="row">
                      <Calendar
                        {...register('schedule')}
                        date={schedule}
                        label="Inicia em"
                        error={Boolean(errors.schedule?.message)}
                        required
                        breakpointSize="s"
                        onDateChange={handleChangeDateStart}
                      />

                      <Input
                        {...register('scheduleStartHour')}
                        id="scheduleStartHour"
                        required
                        className="dateTime-input"
                        breakpointSize="ss"
                        error={Boolean(errors.scheduleStartHour)}
                        color="black4"
                        type="time"
                        defaultValue={getValues()?.scheduleStartHour ?? '00:00'}
                        onBlur={() => trigger('scheduleStartHour')}
                        onChange={e => {
                          setError('scheduleStartHour', { type: 'onChange' });
                          register('scheduleStartHour').onChange(e);
                          register('scheduleStartHour').onBlur(e);
                        }}
                      />
                    </ContentInput>
                    {!noForecast && (
                      <ContentInput direction="row">
                        <Calendar
                          {...register('scheduleEnd')}
                          date={scheduleEnd}
                          label="Encerra em"
                          required
                          breakpointSize="s"
                          error={Boolean(errors.scheduleEnd?.message)}
                          minimumDate={getValues()?.schedule}
                          onDateChange={date => {
                            setValue('scheduleEnd', date);
                            setError('scheduleEnd', { type: 'onBlur' });
                          }}
                        />
                        <Input
                          {...register('scheduleEndHour')}
                          id="scheduleEndHour"
                          required
                          className="dateTime-input"
                          breakpointSize="ss"
                          error={Boolean(errors.scheduleEndHour?.message)}
                          color="black4"
                          type="time"
                          defaultValue={getValues()?.scheduleEndHour ?? '00:00'}
                          onBlur={() => trigger('scheduleEndHour')}
                          onChange={e => {
                            setError('scheduleEndHour', { type: 'onChange' });
                            register('scheduleEndHour').onChange(e);
                          }}
                        />
                      </ContentInput>
                    )}
                  </ContentInputRow>

                  <Message
                    style={{ marginTop: '1rem' }}
                    error={!!dateErrorMessage}
                  >
                    {dateErrorMessage?.message}
                  </Message>
                </ScheduleContainer>
                <CheckBoxView>
                  <Checkbox
                    checked={Boolean(getValues()?.noForecast)}
                    onChange={() => {
                      setValue('noForecast', Boolean(!getValues()?.noForecast));
                      if (getValues()?.noForecast) {
                        clearErrors('scheduleEnd');
                        clearErrors('scheduleEndHour');
                      }
                    }}
                    label="Sem previsão de encerramento"
                  />
                </CheckBoxView>

                <ContentInput>
                  <LogoDropzone
                    {...register('thumbnail')}
                    id="dropzone"
                    className="logo-dropzone-create-event"
                    label="Miniatura"
                    required
                    color={defaultTheme.colors.black3}
                    src={getValues()?.thumbnail}
                    defaultImage={watch('thumbnail')}
                    handleFileUpload={e => {
                      setValue('thumbnail', e);
                      setError('thumbnail', { type: 'onChange' });
                    }}
                    error={errors?.thumbnail?.message}
                  />
                </ContentInput>
              </FormDiv>
            </Content>
          </Container>
        </div>
      </EventContent>
    </div>
  );
};

export default GeneralData;
