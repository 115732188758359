import { defaultTheme } from 'presentation/base/themes';

export const colourStyles: any = {
  container: (styles: any) => ({
    width: '100%',
    maxHeight: '10.2rem',
    border: 'none',
    outline: 0,
  }),
  valueContainer: (styles: any) => ({
    width: '100%',
    display: 'flex',
    height: '100%',
    flexWrap: 'wrap',
    overflowY: 'auto',
    gap: '1rem',
    maxHeight: '10.2rem',
    padding: '0.5rem',
    outline: 0,
  }),
  input: (styles: any) => ({
    marginLeft: '6px',
    display: 'flex',
    alignItems: 'center',
  }),
  control: (styles: any) => ({
    ...styles,
    width: '100%',
    minHeight: '10.2rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    color: defaultTheme.colors.white1,
    cursor: 'text',
    boxShadow: 'none',
    backgroundColor: defaultTheme.colors.black3,
    border: 'none',
    outline: '0',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    background: 'blue',
    paddingLeft: '2rem',
    fontSize: '1.8rem',
    lineHeight: '2.1rem',
    textAlign: 'left',
  }),
  multiValue: (styles: any) => ({
    backgroundColor: defaultTheme.colors.black5,
    color: defaultTheme.colors.white1,
    display: 'flex',
    gap: '1rem',
    justifyContent: 'space-between',
    textTransform: 'capitalize',
    fontFamily: 'Poppins',
    fontSize: '1.2rem',
    fontWeight: 'normal',
    alignItems: 'center',
    padding: '1.1rem 1rem',
    height: '3.4rem',
    borderRadius: '5px',
  }),
  multiValueRemove: (styles: any) => ({
    background: 'transparent',
    cursor: 'pointer',
  }),
  multiValueLabel: (styles: any) => ({
    color: defaultTheme.colors.white1,
    textOverflow: 'clip',
  }),
  clearIndicator: (styles: any) => ({
    display: 'none',
  }),
};
