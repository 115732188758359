/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-undef */
import React, { useCallback, useEffect, useMemo } from 'react';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { useSelector } from 'react-redux';
import { iMessage, iStore, insideMapProps } from 'domain/interfaces/models';
import { Container as GlobalContainer } from '../messages/Background';
import { Container, FeedBack, Header, InviteContent, Text } from './styles';

import LoungeLayout from '../Layouts/LoungeLayout';
import CineLayout from '../Layouts/CineLayout';
import TheaterLayout from '../Layouts/TheaterLayout';
import ArenaLayout from '../Layouts/ArenaLayout';

interface ownProps {
  message: iMessage;
}
const InsideMapModal: React.FC<ownProps> = ({ message }) => {
  const msgName = MessageOptions.insideMapModal;
  const spotsInfo = useSelector((state: iStore) => state.spotsInfo);

  const { active, actionOk, actionCancel, componentProps } = message;

  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const insideMapNavigationProps: insideMapProps = componentProps?.insideMap!;

  const layoutEnum = ['lounge', 'cine', 'theater', 'arena'];

  const gridContent = useMemo(() => {
    switch (layoutEnum[insideMapNavigationProps?.layout - 1]) {
      case 'lounge':
        return (
          <LoungeLayout
            roomName={insideMapNavigationProps?.roomName}
            selectedEvent={insideMapNavigationProps?.selectedEvent}
            layout={insideMapNavigationProps?.layout}
            rooms={insideMapNavigationProps?.rooms}
            size={insideMapNavigationProps?.size}
            handleClick={insideMapNavigationProps?.handleClick}
          />
        );

      case 'cine':
        return (
          <CineLayout
            roomName={insideMapNavigationProps?.roomName}
            selectedEvent={insideMapNavigationProps?.selectedEvent}
            layout={insideMapNavigationProps?.layout}
            rooms={insideMapNavigationProps?.rooms}
            size={insideMapNavigationProps?.size}
            handleClick={insideMapNavigationProps?.handleClick}
          />
        );

      case 'theater':
        return (
          <TheaterLayout
            roomName={insideMapNavigationProps?.roomName}
            selectedEvent={insideMapNavigationProps?.selectedEvent}
            layout={insideMapNavigationProps?.layout}
            rooms={insideMapNavigationProps?.rooms}
            size={insideMapNavigationProps?.size}
            handleClick={insideMapNavigationProps?.handleClick}
          />
        );

      case 'arena':
        return (
          <ArenaLayout
            roomName={insideMapNavigationProps?.roomName}
            selectedEvent={insideMapNavigationProps?.selectedEvent}
            layout={insideMapNavigationProps?.layout}
            rooms={insideMapNavigationProps?.rooms}
            size={insideMapNavigationProps?.size}
            handleClick={insideMapNavigationProps?.handleClick}
          />
        );
      default:
        return null;
    }
  }, [
    insideMapNavigationProps?.handleClick,
    insideMapNavigationProps?.layout,
    insideMapNavigationProps?.rooms,
    insideMapNavigationProps?.size,
  ]);

  const escFunction = useCallback(
    event => {
      if (event.key === 'Escape') {
        actionCancel?.();
      }
    },
    [actionCancel],
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction);

    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [actionCancel]);

  return (
    <>
      {msgName === active ? (
        <GlobalContainer style={{ background: 'rgba(18, 20, 30, 0.99)' }}>
          <Container>
            <Header>
              <InviteContent>
                <Text>Clique no mapa para trocar de lugar</Text>
              </InviteContent>
            </Header>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                gap: '4rem',
              }}
            >
              {gridContent}
              <FeedBack>
                Pressione Esc para sair deste modo de visualização
              </FeedBack>
            </div>
          </Container>
        </GlobalContainer>
      ) : undefined}
    </>
  );
};

export default InsideMapModal;
