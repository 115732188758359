import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { SendMiniatureEvent as UsecaseRemoteSendMiniatureEvent } from 'domain/usecases/event/remote';

import { RemoteSendMiniatureEvent } from 'data/repository/event';

/**
 * send request via API.
 */
export const makeRemoteSendMiniatureEvent =
  (): UsecaseRemoteSendMiniatureEvent =>
    new RemoteSendMiniatureEvent(makeApiUrl('/events/'), makeHttpClient());
