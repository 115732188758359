import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetByIdReaction as UsecaseRemoteGetByIdReaction } from 'domain/usecases/reaction/remote';
import { GetByIdReaction as UsecaseReduxGetByIdReaction } from 'domain/usecases/reaction/redux';

import { RemoteGetByIdReaction } from 'data/repository/reaction';
import { ReduxGetByIdReaction } from 'data/store/reducer/reaction/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetByIdReaction = (): UsecaseRemoteGetByIdReaction =>
  new RemoteGetByIdReaction(
    makeApiUrl('/reactions/{reactionId}'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
export const makeReduxGetByIdReaction = (): UsecaseReduxGetByIdReaction =>
  new ReduxGetByIdReaction();
