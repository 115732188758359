import styled, { css } from 'styled-components';
import { TextAreaProps } from './types';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const Label = styled.label<{ required?: boolean }>`
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 1.2rem;
  ${({ required, theme }) =>
    required
      ? css`
          &:after {
            content: '*';
            color: ${theme.colors.red1};
          }
        `
      : css`
          &:after {
            content: ' (opcional)';
          }
        `};
`;

type StTextAreaProps = {
  error?: boolean;
};

export const TextArea = styled.textarea<StTextAreaProps>`
  width: 100%;
  min-height: 16rem;
  height: 100%;
  padding: 0.85rem 1.6rem;

  outline: none;
  resize: none;

  color: ${({ theme }) => theme.colors.white1};
  font-size: 1.4rem;
  font-weight: 400;

  background: ${({ theme }) => theme.colors.black4};
  border: 0.1rem solid transparent;
  border-radius: 0.6rem;

  &:hover {
    background: ${({ theme }) => theme.colors.black3};
    border-color: ${({ theme }) => theme.colors.white3};
  }

  &:focus-visible {
    outline: 0.2rem solid
      ${({ theme, error }) => theme.colors[error ? 'red1' : 'blue1']};
    box-shadow: 0 0 1rem
      ${({ theme, error }) => theme.colors[error ? 'red1' : 'blue1']};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.white3};
  }

  ${({ error }) =>
    error &&
    css`
      outline: 0.2rem solid ${({ theme }) => theme.colors.red1};
    `}

  &::selection {
    background: ${({ theme }) => theme.colors.black5};
    color: ${({ theme }) => theme.colors.white1};
  }
`;

export const Error = styled.span`
  margin-top: 1.2rem;
  font-size: 1.4rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.red1};
`;
