import styled from 'styled-components';

export const InviteButtonsContainer = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const OptionItem = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  font-style: ${({ theme }) => theme.typography.h7};
  cursor: pointer;
  padding: 8px 16px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.black5};
  }

  &.Disable {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      background-color: transparent;
    }
  }
`;
