import { combineReducers } from 'redux';
import { persistReducer, createTransform } from 'redux-persist';
import omit from 'lodash/omit';
import storage from 'redux-persist/lib/storage';
import auth from './auth';
import message from './message';
import wrtc from './wrtcClient';
import spotsInfo from './spotsInfo';
import category from './category';
import event from './event';
import friendShip from './friendship';
import sponsor from './sponsor';

const reducer = combineReducers({
  auth,
  message,
  wrtc,
  spotsInfo,
  category,
  event,
  friendShip,
  sponsor,
});

// Blacklist specific property to not persist on LocalStorage
const blacklistProperty = (blacklistPaths: Array<string>) => {
  return createTransform((inboundState: any, key) => {
    const blacklistPathsForKey = blacklistPaths
      .filter(path => path.startsWith(`${String(key)}.`))
      .map(path => path.substr(String(key).length + 1));

    return omit(inboundState, ...blacklistPathsForKey);
  }, null);
};

const authPersistConfig = {
  key: '@netfans',
  storage,
  blacklist: ['message', 'spotsInfo'],
  transforms: [blacklistProperty(['auth.loading', 'auth.wrtcInfo'])],
};

export default persistReducer(authPersistConfig, reducer);
