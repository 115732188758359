import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import {
  BadRequestError,
  InvalidCredentialsError,
  UnexpectedError,
  UnProcessableEntity,
} from 'domain/errors';
import { UpdateEvent } from 'domain/usecases/event/remote';
import 'infra/global/variables';

export class RemoteUpdateEvent implements UpdateEvent {
  private readonly url: string;

  private readonly httpClient: HttpClient<UpdateEvent.Model>;

  constructor(url: string, httClient: HttpClient<UpdateEvent.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  update = async (params: UpdateEvent.Params): Promise<UpdateEvent.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url.replace('{eventId}', `${params.eventId}`),
      method: 'patch',
      body: params,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.unprocessableEntity:
        throw new UnProcessableEntity();
      default:
        throw new UnexpectedError();
    }
  };
}
