import styled from 'styled-components';
import media from 'utils/mediaqueries';

export const ReactionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 80%;
  overflow-y: auto;

  ${media.lessThan('sm')} {
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.colors.black2};
  }

  &::-webkit-scrollbar {
    margin-right: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.black2};
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.white1};
    border-radius: 5px;
  }
`;

export const ReactionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 2.4rem 0 2.4rem 2.4rem;
  width: 34rem;
  height: 100%;
  overflow-y: auto;
  flex-wrap: wrap;
  gap: 1.2rem;

  ${media.lessThan('sm')} {
    padding: 2.4rem 0 2.4rem 0;
    width: 34rem;
  }

  &::-webkit-scrollbar {
    margin-right: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.black2};
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.white1};
    border-radius: 5px;
  }
`;

export const ReactionItem = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 5px;
  background-color: ${props => props.theme.colors.black5};
  cursor: pointer;

  ${media.lessThan('sm')} {
    width: 5rem;
    height: 5rem;
  }
`;

export const ReactionItemDefault = styled.div`
  width: 4.8rem;
  height: 4.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.black2};
  border-radius: 6.4px;

  ${media.lessThan('sm')} {
    width: 4.8rem;
    height: 4.8rem;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 1.6rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);

  ${media.lessThan('sm')} {
    padding: 1.2rem 2rem 1.2rem 2rem;
  }
`;

export const TitleContent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Title = styled.h1`
  font-size: 1.8rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white1};
`;
