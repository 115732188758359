import { call, put, select } from 'redux-saga/effects';
import { iActionUpdate } from 'domain/interfaces/redux/reaction/update';
import { UpdateReaction } from 'domain/usecases/reaction/remote/UpdateReaction';
import { makeRemoteUpdateReaction } from 'main/factories/usecases/reaction/UpdateReactionFactory';
import {
  updateSuccess,
  updateFailed,
} from 'data/store/reducer/reaction/actions/update';

export function* onUpdate(action: iActionUpdate) {
  const remoteUpdateReaction = makeRemoteUpdateReaction();

  try {
    const response: UpdateReaction.Model = yield call(
      remoteUpdateReaction.update,
      action.payload,
    );
    yield put(
      updateSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(updateFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onUpdateSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onUpdateFailed() {}
