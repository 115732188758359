/* eslint-disable react/jsx-indent */
import React, { useCallback, useEffect, useState } from 'react';
import { CategoryData } from 'domain/interfaces/models/Category';
import Checkbox from '../Checkbox/Checkbox';
import RadioComponent from '../Radio';

import { Container, Content } from './styles/SubcategoryOptionsStyled';

interface CheckBoxProps extends CategoryData {
  checked?: boolean;
}
interface SubcategoryOptionsProps {
  subcategories?: any[];
  variant?: boolean;
  getMultipleSubcategories: Function;
  getSingleSubcategories: Function;
}

const SubcategoryOptions: React.FC<SubcategoryOptionsProps> = ({
  subcategories,
  variant,
  getMultipleSubcategories,
  getSingleSubcategories,
}) => {
  const [formattedCategory, setFormattedCategory] = useState<CheckBoxProps[]>(
    subcategories ?? [],
  );

  const updateSubcategoryChecked = (category: CheckBoxProps, type?: string) => {
    const newArr = [...formattedCategory];

    const findCheckedIndex = newArr.findIndex(item => item.checked === true);

    if (findCheckedIndex >= 0 && type === 'single') {
      newArr[findCheckedIndex].checked = false;
    }

    const findIndex = newArr.findIndex(item => item.name === category.name);

    if (findIndex >= 0) {
      newArr[findIndex].checked = !newArr[findIndex].checked;
      setFormattedCategory(newArr);
      getMultipleSubcategories(newArr);
    }
  };

  const addSubcategory = (category: CheckBoxProps) => {
    getMultipleSubcategories((prevState: any) => {
      const newArr = [...prevState];
      const findIndex = newArr.findIndex(item => item.id === category.id);

      if (findIndex >= 0) {
        newArr.slice(findIndex, 1);
        return newArr;
      }
      newArr.push(category);
      return newArr;
    });
  };

  return (
    <Container>
      {variant
        ? formattedCategory?.map(item => (
            <Content key={item.name}>
              <Checkbox
                onClick={() => {
                  updateSubcategoryChecked(item);
                  addSubcategory(item);
                }}
                checked={Boolean(item.checked)}
              />
              <span>{item.name}</span>
            </Content>
          ))
        : subcategories?.map(item => (
            <RadioComponent
              key={item.name}
              title={item.name}
              onClick={() => {
                getSingleSubcategories(item);
                updateSubcategoryChecked(item, 'single');
              }}
              checked={item.checked}
            />
          ))}
    </Container>
  );
};

export default SubcategoryOptions;
