import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { MixedSchema } from 'yup/lib/mixed';

// Validação referente ao primeiro passo do formulário de criação de evento
export const firstStep = {
  name: yup.string().required('O título da sessão é obrigatório').max(256),
  organization: yup.string().required('A organização é obrigatória').max(256),
  descr: yup
    .string()
    .max(256, 'A descrição deve conter no máximo 256 caracteres')
    .nullable(),
  scheduleStartHour: yup
    .string()
    .required('A hora de início é obrigatória')
    .when('schedule', (schedule: Date, schema: MixedSchema) => {
      const today = new Date();
      const date = schedule ?? new Date();
      today.setHours(0, 0, 0, 0);
      date.setHours(0, 0, 0, 0);
      if (date.getTime() === today.getTime()) {
        return schema.test(
          'scheduleStartHour',
          'A hora de início deve ser maior que o horário atual',
          value => {
            if (!value) return false;
            const [hour, minute] = value.split(':');
            const now = new Date();
            const newToday = new Date();
            now.setHours(hour, minute, 0, 0);
            return now > newToday;
          },
        );
      }
      return schema;
    }),
  noForecast: yup.boolean().optional(),

  schedule: yup
    .date()
    .required('A data de início é obrigatória')
    .test('schedule', 'Digite uma data válida.', value => {
      if (!value) return false;
      const date = value;
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date >= today;
    })
    .typeError('Digite uma data válida.'),
  scheduleEnd: yup
    .date()
    .required('A data de término é obrigatória')
    .min(8, 'Digite uma data válida.')
    .test(
      'finish date should be greater than start date',
      'A data de término deve ser maior que a data de início',
      (value, schema) => {
        if (schema.parent.noForecast) return true;
        if (!value) return false;

        const date = new Date(value ?? '');
        const dateStart = new Date(schema.parent.schedule ?? '');
        return date >= dateStart;
      },
    )
    .when('noForecast', (noForecast: boolean, schema: MixedSchema) => {
      if (noForecast) {
        return schema.optional();
      }
      return schema;
    })
    .typeError('Digite uma data válida.'),
  scheduleEndHour: yup
    .string()
    .required('A hora de término é obrigatória')
    .test(
      'should be greater than scheduleStartHour if scheduleEnd is equal to scheduleStart',
      'A hora de término deve ser maior que a hora de início',
      (value, schema) => {
        if (schema.parent.noForecast) return true;
        if (!value) return false;

        const [hour, minute] = value.split(':');
        const [hourStart, minuteStart] =
          schema.parent.scheduleStartHour.split(':');

        const date = new Date(schema.parent.scheduleEnd ?? '');
        const dateStart = new Date(schema.parent.schedule ?? '');

        date.setHours(Number(hour), Number(minute), 0, 0);
        dateStart.setHours(hourStart, minuteStart, 0, 0);

        return date > dateStart;
      },
    )
    .when('noForecast', (noForecast: boolean, schema: MixedSchema) => {
      if (noForecast) {
        return schema.optional();
      }
      return schema;
    }),

  thumbnail: yup.mixed().required('A miniatura é obrigatória'),
};

// Validação referente ao segundo passo do formulário de criação de evento
export const secondStep = {
  mediaType: yup.string().oneOf(['LIVE', 'VOD', 'EXTERNAL']).default('LIVE'),
  media: yup.mixed().when('mediaType', (value: string, schema: MixedSchema) => {
    if (value === 'EXTERNAL') {
      return schema
        .required('A URL do conteúdo externo é obrigatória')
        .not([null], 'A URL do conteúdo externo é obrigatória');
    }

    if (value === 'VOD') {
      return schema
        .required('O vídeo é obrigatório')
        .not([null], 'O vídeo é obrigatório');
    }

    return schema.nullable();
  }),

  category: yup.number().required('Escolha uma categoria'),
  subCategory: yup.number().required('Escolha uma subcategoria'),
  keywords: yup.array().of(yup.string().min(1).max(256)).nullable().optional(),
};

// Validação referente ao terceiro passo do formulário de criação de evento
export const thirdStep = {
  sponsor: yup.number().positive().required('Escolha um template').default(1),
};

export const schema = yup.object().shape({
  ...firstStep,
  ...secondStep,
  ...thirdStep,
});

export const schemaCreateEvent = yupResolver(schema);
