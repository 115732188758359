import React from 'react';
import { OptionProps } from 'react-select';
import { defaultTheme } from 'presentation/base/themes';
import { iOption } from '.';
import { StyledOption } from './styles';

const Option: React.FC = (props: any) => {
  const {
    children,
    className,
    cx,
    getStyles,
    isDisabled,
    color,
    innerRef,
    innerProps,
  } = props;
  return (
    <StyledOption
      ref={innerRef}
      isDisabled={isDisabled}
      className={cx(
        {
          option: true,
          'option--is-disabled': isDisabled,
        },
        className,
      )}
      {...innerProps}
    >
      {children}
    </StyledOption>
  );
};

export default Option;
