import { call, put, select } from 'redux-saga/effects';
import { iActionGetAll } from 'domain/interfaces/redux/category/getAll';
import { GetAllCategory } from 'domain/usecases/category/remote/GetAllCategory';
import { makeRemoteGetAllCategory } from 'main/factories/usecases/category/GetAllCategoryFactory';
import {
  getAllSuccess,
  getAllFailed,
} from 'data/store/reducer/category/actions/getAll';

export function* onGetAll(action: iActionGetAll) {
  const remoteGetAllCategory = makeRemoteGetAllCategory();

  try {
    const response: GetAllCategory.Model = yield call(
      remoteGetAllCategory.getAll,
      action.payload,
    );
    yield put(
      getAllSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(getAllFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetAllSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetAllFailed() {}
