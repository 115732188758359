import { Dispatch } from 'redux';
import store from 'data/store';
import { getAllRequest } from 'data/store/reducer/friendship/actions/getAll';

import { iActionGetAll } from 'domain/interfaces/redux/friendship/getAll';

import { GetAllFriendship } from 'domain/usecases/friendship/redux';

export class ReduxGetAllFriendship implements GetAllFriendship {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  getAll = (params: GetAllFriendship.Params): iActionGetAll =>
    this.send(getAllRequest(params));
}
