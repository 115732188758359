import { DeleteReaction } from 'domain/usecases/reaction/redux/DeleteReaction';
import {
  iActionDelete,
  iActionDeleteFailed,
  iActionDeleteSuccess,
  ReactionTypes,
} from 'domain/interfaces/redux/reaction/delete';

export const deleteRequest = (
  payload: DeleteReaction.Params,
): iActionDelete => ({
  type: ReactionTypes.DELETE,
  payload,
});

export const deleteSuccess = (
  params: DeleteReaction.Model,
): iActionDeleteSuccess => ({
  type: ReactionTypes.DELETE_SUCCESS,
  payload: params,
});

export const deleteFailed = (): iActionDeleteFailed => ({
  type: ReactionTypes.DELETE_FAILED,
});
