import styled from 'styled-components';

export const Container = styled.div`
  width: 82rem;
  height: 77.6rem;

  background: ${({ theme }) => theme.colors.black2};

  padding: 3rem 3rem 5.8rem;

  border-radius: 5px;
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ModalTitle = styled.h3`
  margin-left: 1.3rem;
  font-weight: 700;
  font-size: 2.4rem;
  color: ${({ theme }) => theme.colors.white1};
`;

export const BodyBox = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 76rem;
  height: 54.3rem;
  margin: 3rem 0rem;
  border-radius: 5px;
  padding: 3rem;
  background-color: ${({ theme }) => theme.colors.black1};
  color: white;
`;

export const InsideBodyBox = styled.div`
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    margin-right: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.black2};
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.white1};
    border-radius: 5px;
  }
  padding-right: 1rem;
`;

interface TextProps {
  bold?: boolean;
  marginTop?: boolean;
}

export const Text = styled.div<TextProps>`
  display: flex;
  flex-direction: column;

  font-family: Poppins;
  font-weight: ${props => (props.bold ? 700 : 500)};
  font-size: ${props => `${props.bold ? 1.6 : 1.4}rem`};
  line-height: ${props => `${props.bold ? 2.4 : 2.1}rem`};

  margin-top: ${props => (props.marginTop ? '1rem' : '0rem')};
`;

export const ButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
`;
