import { call, put } from 'redux-saga/effects';
import faker from 'faker-br';

import {
  loginSuccess,
  loginFailed,
} from 'data/store/reducer/auth/actions/login';

import { makeRemoteLogin } from 'main/factories/usecases/auth/LoginFactory';

import {
  iActionLogin,
  iActionLoginSuccess,
} from 'domain/interfaces/redux/auth/Login';

import { Login } from 'domain/usecases/auth/redux';
import { iAuth } from 'domain/interfaces/models';

import { animalsList } from 'data/repository/mockData/animals.json';

export function* onLogin(action: iActionLogin) {
  const user = makeRemoteLogin();

  try {
    if (action.payload.guest) {
      const nameSplited = action.payload.login.split(' ');
      const firstName = nameSplited[0];
      const lastName = nameSplited[nameSplited.length - 1];

      const guestUser: Login.Model['user'] = {
        birthdate: new Date().toLocaleDateString(),
        cpf: '412.854.854-12',
        email: 'teste@convidado.com',
        firstName,
        fullName: action.payload.login,
        lastName: nameSplited.length > 1 ? lastName : '',
        phone: '+55 (11) 99999-9999',
        username:
          nameSplited.length > 1 ? `${firstName} ${lastName}` : `${firstName}`,
      };

      const mapToRedux: Login.ModelToRedux = {
        accessToken: 'mock-token',
        accessTokenExpiration: 'mock-token-expiration',
        loading: false,
        refreshToken: 'mock-refresh-token',
        refreshTokenExpiration: 'mock-refresh-token-expiration',
        user: guestUser,
        wrtcInfo: {} as iAuth['wrtcInfo'],
      };

      yield put(loginSuccess(mapToRedux));
    } else if (action.payload.socialUser) {
      const { socialUser } = action.payload;

      const mapToRedux: Login.ModelToRedux = {
        accessToken: 'mock-token',
        accessTokenExpiration: 'mock-token-expiration',
        loading: false,
        refreshToken: 'mock-refresh-token',
        refreshTokenExpiration: 'mock-refresh-token-expiration',
        user: {
          birthdate: socialUser.metadata.creationTime,
          cpf: '412.854.854-12',
          email: socialUser.email ?? 'teste@social.com',
          firstName: socialUser?.displayName?.split(' ')?.[0] ?? 'Anônimo',
          fullName: socialUser?.displayName ?? 'Anônimo',
          lastName: socialUser?.displayName?.split(' ')?.[1] ?? 'Anônimo',
          phone: socialUser?.phoneNumber ?? '+55 (11) 99999-9999',
          username: socialUser?.displayName ?? 'Anônimo',
        },
        wrtcInfo: {} as iAuth['wrtcInfo'],
      };

      yield put(loginSuccess(mapToRedux));
    } else {
      const responseLogin: Login.Model = yield call(user.login, action.payload);

      const mapToRedux: Login.ModelToRedux = {
        accessToken: responseLogin.accessToken,
        accessTokenExpiration: responseLogin.accessTokenExpiration,
        loading: false,
        refreshToken: responseLogin.refreshToken,
        refreshTokenExpiration: responseLogin.refreshTokenExpiration,
        user: responseLogin.user,
        wrtcInfo: {} as iAuth['wrtcInfo'],
      };

      yield put(loginSuccess(mapToRedux));
    }
  } catch (e) {
    yield put(loginFailed());
  }
}

export function onLoginSuccess(action: iActionLoginSuccess) {
  // History.push('/appointment');
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onLoginFailed() {}
