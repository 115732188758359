import styled from 'styled-components';

interface propsCategory {
  active: boolean;
}

export const Container = styled.div`
  width: 100%;
  font-family: 'Poppins';
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 3rem;
  background: ${({ theme }) => theme.colors.black2};
`;

export const FormDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export const Form = styled.div`
  gap: 1rem;
  display: flex;
  padding: 1.5rem;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
`;

export const Title = styled.h1`
  color: white;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 3rem;
`;

export const DivFlex = styled.div`
  width: 100%;
  padding-left: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 3rem;
  color: ${props => props.theme.colors.white1};
`;

export const ContainerGridRoom = styled.div`
  width: 100%;
  height: 30rem;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
`;

export const Label = styled.div`
  white-space: nowrap;
  color: white;
  font-weight: bolder;
  font-size: 1.8rem;
`;

export const ContentInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ContentInputRow = styled.div`
  width: 75%;
  gap: 1.5rem;
  justify-content: space-between;
  padding-left: 3rem;
  display: flex;
`;

export const Category = styled.div<propsCategory>`
  display: flex;
  width: 100%;
  height: 3.4rem;
  text-align: center;
  border-radius: 5px;
  align-items: center;
  padding: 2rem;
  cursor: ${props => (props.active ? 'default' : 'pointer')};
  color: ${props =>
    props.active ? props.theme.colors.black1 : props.theme.colors.white1};
  background: ${props =>
    props.active ? props.theme.colors.white1 : props.theme.colors.black2};
  &:hover {
    background-color: ${props => !props.active && props.theme.colors.black5};
  }
`;

export const ContainerDisplayRoom = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ContentRadioButtons = styled.div`
  color: white;
  display: flex;
  align-items: center;
  gap: 3.8rem;

  .MuiIconButton-label {
    color: white;
  }
  .MuiTypography-root {
    font-size: 1.2rem;
    font-family: 'Poppins';
  }

  .MuiSvgIcon-root {
    width: 1.8rem;
    height: 1.8rem;
  }
`;

export const RadioButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;

  /* input[type='radio'] {
    width: 2rem;
    height: 2rem;
    background: black;
    :checked {
      color: white;
    }
  } */
`;

export const Message = styled.p`
  color: white;
  width: 70%;
  opacity: 0.65;
`;
