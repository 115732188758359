import React, { useMemo } from 'react';
import { iAnimation } from 'presentation/pages/Room/interface';
import { RoomGridContainer } from 'presentation/pages/Room/styles';
import useWindowDimensions from 'presentation/hooks/useWindowDimensions';
import GridRoom from '../GridRoom';
import {
  PlayerDiv,
  RoomContainerDiv,
  RoomContainer,
  RoomNameContainer,
  RoomName,
} from './styles';
import { layoutsProps } from './types';

const CineLayout: React.FC<layoutsProps> = ({
  roomName,
  rooms,
  selectedEvent,
  layout,
  size,
  handleClick,
  setTriggerAnimation,
  speakingPeerIds,
  triggerAnimation,
  Player = null,
  isChangingRoom = false,
  isHovering = false,
  isRoomsPage = false,
  isFullscreen = false,
  containerSize,
}) => {
  const { isMobile } = useWindowDimensions();

  const getPlayer = useMemo(() => {
    if (isMobile || isFullscreen) return <></>;

    if (Player) {
      return <Player />;
    }

    return (
      <PlayerDiv containerSize={containerSize}>
        {isHovering && (
          <RoomNameContainer>
            <RoomName>{`Sala ${roomName}`}</RoomName>
          </RoomNameContainer>
        )}
      </PlayerDiv>
    );
  }, [Player, containerSize, isHovering, isMobile, isFullscreen, roomName]);

  const getGrid = useMemo(() => {
    return (
      <RoomContainer containerSize={containerSize} isRoomsPage={isRoomsPage}>
        {/* {Player && <Player />} */}
        {getPlayer}
        {/* <EventPlayer playing url={selectedEvent?.midia} controls /> */}
        <RoomContainerDiv containerSize={containerSize}>
          <RoomGridContainer>
            <GridRoom
              isLayout
              numCol={20}
              rooms={rooms.slice(0, 20)}
              handleClick={handleClick}
              size={size}
              speakingPeerIds={speakingPeerIds}
              animation={triggerAnimation}
              isHovering={isHovering}
              isChangingRoom={isChangingRoom}
              handleAnimationEnd={() => setTriggerAnimation?.({} as iAnimation)}
            />
          </RoomGridContainer>
          <RoomGridContainer>
            <GridRoom
              isLayout
              numCol={20}
              rooms={rooms.slice(20, 40)}
              handleClick={handleClick}
              size={size}
              speakingPeerIds={speakingPeerIds}
              animation={triggerAnimation}
              isHovering={isHovering}
              isChangingRoom={isChangingRoom}
              handleAnimationEnd={() => setTriggerAnimation?.({} as iAnimation)}
            />
          </RoomGridContainer>
          <RoomGridContainer>
            <GridRoom
              isLayout
              numCol={20}
              rooms={rooms.slice(40, 60)}
              handleClick={handleClick}
              size={size}
              speakingPeerIds={speakingPeerIds}
              animation={triggerAnimation}
              isHovering={isHovering}
              isChangingRoom={isChangingRoom}
              handleAnimationEnd={() => setTriggerAnimation?.({} as iAnimation)}
            />
          </RoomGridContainer>
          <RoomGridContainer>
            <GridRoom
              isLayout
              numCol={20}
              rooms={rooms.slice(60, 80)}
              handleClick={handleClick}
              size={size}
              speakingPeerIds={speakingPeerIds}
              animation={triggerAnimation}
              isHovering={isHovering}
              isChangingRoom={isChangingRoom}
              handleAnimationEnd={() => setTriggerAnimation?.({} as iAnimation)}
            />
          </RoomGridContainer>
        </RoomContainerDiv>
      </RoomContainer>
    );
  }, [
    containerSize,
    getPlayer,
    handleClick,
    isChangingRoom,
    isHovering,
    isRoomsPage,
    rooms,
    setTriggerAnimation,
    size,
    speakingPeerIds,
    triggerAnimation,
  ]);

  return getGrid;
};

export default CineLayout;
