import { UpdateReaction } from 'domain/usecases/reaction/redux/UpdateReaction';
import {
  iActionUpdate,
  iActionUpdateFailed,
  iActionUpdateSuccess,
  ReactionTypes,
} from 'domain/interfaces/redux/reaction/update';

export const updateRequest = (
  payload: UpdateReaction.Params,
): iActionUpdate => ({
  type: ReactionTypes.UPDATE,
  payload,
});

export const updateSuccess = (
  params: UpdateReaction.Model,
): iActionUpdateSuccess => ({
  type: ReactionTypes.UPDATE_SUCCESS,
  payload: params,
});

export const updateFailed = (): iActionUpdateFailed => ({
  type: ReactionTypes.UPDATE_FAILED,
});
