import { createStore, Store, compose, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import createSaga from 'redux-saga';
import { iStore } from 'domain/interfaces/models';
import rootReducer from './reducer';
import rootSaga from './saga';

import { MessageMiddleware } from './middleware/message';

// Devtools.
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSaga();

// Middleware
const middleware = [MessageMiddleware(), sagaMiddleware, thunk];

// Store
const storeDev: Store<iStore, any> = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middleware)),
);

// Start Sagas.
sagaMiddleware.run(rootSaga);

export default storeDev;
