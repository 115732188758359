import { DeleteReaction } from 'domain/usecases/reaction/remote';
import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import {
  NotFound,
  InvalidCredentialsError,
  UnexpectedError,
} from '../../../domain/errors';
import 'infra/global/variables';

export class RemoteDeleteReaction implements DeleteReaction {
  private readonly url: string;

  private readonly httpClient: HttpClient<DeleteReaction.Model>;

  constructor(url: string, httClient: HttpClient<DeleteReaction.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  delete = async (
    params: DeleteReaction.Params,
  ): Promise<DeleteReaction.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url.replace('{reactionId}', `${params.id}`),
      method: 'delete',
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
