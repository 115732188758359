import styled, { css } from 'styled-components';

interface propsCategory {
  active: boolean;
  error?: boolean;
}

interface MessageProps {
  error: boolean;
}

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: transparent;
`;

export const FormDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Right = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Form = styled.div`
  gap: 1rem;
  display: flex;
  padding: 1.5rem;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
`;

export const DivFlex = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 3.2rem;
  color: ${({ theme }) => theme.colors.white1};
`;

export const HourEventDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  width: 100%;
  color: white;
`;

export const CheckBoxView = styled.div`
  margin-top: 2rem;
`;

export const SmallTitle = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  color: #ffffff;

  margin-bottom: 10px;
`;

export const CheckBoxText = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  margin-left: 10px;

  color: #fff;
`;

export const Title = styled.h1`
  color: white;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 1.75rem;
`;

export const Label = styled.div`
  white-space: nowrap;
  color: white;
  font-weight: bolder;
  font-size: 1.8rem;
`;

type ContentInputProps = {
  error?: boolean;
  direction?: 'column' | 'row';
};

export const ContentInput = styled.div.attrs({
  className: 'input-content',
})<ContentInputProps>`
  width: 100%;
  display: flex;
  flex-direction: ${({ direction }) => direction || 'column'};
  gap: 1.2rem;

  margin-top: 2rem;

  input[type='time']::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
  }

  input[type='time'] {
    font-size: 1.4rem;

    &::selection {
      background: ${({ theme }) => theme.colors.black5};
      color: ${({ theme }) => theme.colors.white1};
    }
  }

  input[type='time']::-webkit-datetime-selection {
    background: ${({ theme }) => theme.colors.black5};
    color: ${({ theme }) => theme.colors.white1};
  }

  @media (max-width: 425px) {
    & .logo-dropzone-create-event {
      height: 18rem;
    }
  }
`;

export const ContentHour = styled.div`
  width: 58rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3rem;
  white-space: nowrap;
`;

export const ContentInputRow = styled.div`
  width: 75%;
  gap: 1.5rem;
  justify-content: space-between;
  padding-left: 3rem;
  display: flex;
`;

export const Category = styled.div<propsCategory>`
  border: ${({ error }) => error && '1px solid red'};
  display: flex;
  width: 100%;
  justify-content: center;
  height: 3.4rem;
  border-radius: 5px;
  align-items: center;
  padding: 2rem;
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
  color: ${({ theme, active }) =>
    active ? theme.colors.black1 : theme.colors.white1};
  background: ${({ theme, active }) =>
    active ? theme.colors.white1 : theme.colors.black2};
  &:hover {
    background-color: ${({ theme, active }) => !active && theme.colors.black5};
  }
`;

export const FileUploadText = styled.p`
  width: 90%;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.white1};
  margin-top: 1rem;
`;

export const Message = styled.span<MessageProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  color: #7b8591;

  ${({ error }) =>
    error
      ? css`
          color: ${props => props.theme.colors.colorError}; ;
        `
      : css`
          color: ${props => props.theme.colors.green1}; ;
        `}
`;

export const ScheduleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
