import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { UpdateEvent as UsecaseRemoteUpdateEvent } from 'domain/usecases/event/remote';

import { RemoteUpdateEvent } from 'data/repository/event';

/**
 * send request via API.
 */
export const makeRemoteUpdateEvent = (): UsecaseRemoteUpdateEvent =>
  new RemoteUpdateEvent(makeApiUrl('/events/{eventId}'), makeHttpClient());
