import { GetAllReaction } from 'domain/usecases/reaction/redux/GetAllReaction';
import {
  iActionGetAll,
  iActionGetAllFailed,
  iActionGetAllSuccess,
  ReactionTypes,
} from 'domain/interfaces/redux/reaction/getAll';

export const getAllRequest = (
  payload: GetAllReaction.Params,
): iActionGetAll => ({
  type: ReactionTypes.GETALL,
  payload,
});

export const getAllSuccess = (
  params: GetAllReaction.Model,
): iActionGetAllSuccess => ({
  type: ReactionTypes.GETALL_SUCCESS,
  payload: params,
});

export const getAllFailed = (): iActionGetAllFailed => ({
  type: ReactionTypes.GETALL_FAILED,
});
