import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  padding: 30px 120px;
  filter: ${({ theme }) => `drop-shadow(0px 4px 20px ${theme.colors.black3})`};
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
`;
export const InviteContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
export const Text = styled.span`
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${({ theme }) => theme.colors.white1};
`;
export const Player = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50rem;
  height: 40%;

  position: relative;

  margin-bottom: 2px;
`;

export const FeedBack = styled.div`
  width: 100%;
  height: 5%;
  margin-top: 3rem;
  text-align: center;
  color: ${props => props.theme.colors.white1};
`;
