import styled, { css } from 'styled-components';

interface propsCategory {
  active: boolean;
  error?: boolean;
}

interface MessageProps {
  error: boolean;
}

export const Container = styled.div`
  width: 100%;
  font-family: 'Poppins';
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 3rem;
  background: ${({ theme }) => theme.colors.black2};
`;

export const FormDiv = styled.div`
  width: 100%;
  display: flex;
  gap: 3rem;
`;

export const Left = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;

export const Right = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* height: 65rem; */
`;

export const Form = styled.div`
  gap: 1rem;
  display: flex;
  padding: 1.5rem;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
`;

export const DivFlex = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 3.2rem;
  color: ${({ theme }) => theme.colors.white1};
`;

export const HourEventDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  width: 100%;
  color: white;
`;

export const Title = styled.h1`
  color: white;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 3rem;
`;

export const Label = styled.div`
  white-space: nowrap;
  color: white;
  font-weight: bolder;
  font-size: 1.8rem;
`;

export const ContentInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3rem;

  textarea {
    resize: none;
    width: 100%;
    padding: 1rem;
    height: 10.2rem;
    background: ${({ theme }) => theme.colors.black1};
    border: none;
    color: ${({ theme }) => theme.colors.white1};
    outline: 0;
    border-radius: 5px;
  }
`;

export const ContentHour = styled.div`
  width: 58rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3rem;
  white-space: nowrap;
`;

export const ContentInputRow = styled.div`
  width: 100%;
  gap: 1.5rem;
  justify-content: space-between;
  display: flex;

  @media (max-width: 425px) {
    flex-direction: column;
    div[orientation='column'] {
      max-width: 100%;
    }
  }
`;

export const Category = styled.div<propsCategory>`
  border: ${({ error }) => error && '0.1rem solid red'};
  display: flex;
  width: 100%;
  justify-content: center;
  height: 3.4rem;
  border-radius: 5px;
  align-items: center;
  padding: 2rem;
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
  color: ${({ theme, active }) =>
    active ? theme.colors.black1 : theme.colors.white1};
  background: ${({ theme, active }) =>
    active ? theme.colors.white1 : theme.colors.black2};
  &:hover {
    background-color: ${({ theme, active }) => !active && theme.colors.black5};
  }
`;

export const FileUploadText = styled.p`
  width: 90%;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.white1};
  margin-top: 1rem;
`;

export const Message = styled.span<MessageProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #7b8591;

  ${({ error }) =>
    error &&
    css`
      color: ${props => props.theme.colors.colorError}; ;
    `}
`;
