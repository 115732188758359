import { toast } from 'react-toastify';

export function requestFullscreen(
  document: Document,
  player?: HTMLVideoElement,
): void {
  try {
    if (document.body.requestFullscreen && !document.fullscreenElement) {
      document.body.requestFullscreen();
    } else if (
      (document as any).body.mozRequestFullScreen &&
      !(document as any).mozFullScreenElement
    ) {
      (document as any).body.mozRequestFullScreen();
    } else if (
      (document as any).body.webkitRequestFullscreen &&
      !(document as any).webkitFullscreenElement
    ) {
      (document as any).body.webkitRequestFullscreen(); /// work on ipad
    } else if (
      (document as any).body.msRequestFullscreen &&
      !(document as any).msFullscreenElement
    ) {
      (document as any).body.msRequestFullscreen();
      // } else if ( // iPhone start of Impl. Not working
      //   // iPhone test
      //   (player as any).webkitEnterFullscreen &&
      //   !(player as any).webkitDisplayingFullscreen
      //   // && !(document as any).webkitFullscreenElement
      // ) {
      //   (player as any).webkitEnterFullscreen();
      //   toast.success('DEV: webkit iphone fullscreen mode enabled!');
    }
  } catch (error) {
    console.error(error);
  }
}
export function exitFullscreen(
  document: Document,
  player?: HTMLVideoElement,
): void {
  try {
    if (document.exitFullscreen && document.fullscreenElement) {
      document.exitFullscreen();
    } else if (
      (document as any).mozCancelFullScreen &&
      (document as any).mozFullScreenElement
    ) {
      (document as any).mozCancelFullScreen();
    } else if (
      (document as any).webkitExitFullscreen &&
      (document as any).webkitFullscreenElement
    ) {
      (document as any).webkitExitFullscreen();
      // } else if ( // iPhone start of Impl. Not working
      //   (player as any).webkitExitFullscreen &&
      //   (player as any).webkitDisplayingFullscreen
      // ) {
      //   (player as any).webkitExitFullscreen();
      //   toast.success('DEV: webkit iPhone exit fullscreen');
    } else if (
      (document as any).msExitFullscreen &&
      (document as any).msFullscreenElement
    ) {
      (document as any).msExitFullscreen();
    }
  } catch (error) {
    console.error(error);
  }
}
