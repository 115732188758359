import React from 'react';
import { defaultTheme } from 'presentation/base/themes';
import { Container, Tab } from './styles';
import { TabsProps } from './types';

const Tabs: React.FC<TabsProps> = ({ options, ...rest }) => {
  return (
    <Container>
      {options.map(option => (
        <Tab key={option.label} value={option.label} {...rest} {...option}>
          {option.label}
        </Tab>
      ))}
    </Container>
  );
};

export default Tabs;
