import Skeleton from '@material-ui/lab/Skeleton';
import styled from 'styled-components';

export const StSkeleton = styled(Skeleton)`
  margin-bottom: 1rem;
  &.MuiSkeleton-rect,
  &.MuiSkeleton-text {
    background: ${({ theme }) => theme.colors.black2};
  }
`;
