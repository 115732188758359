import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { MixedSchema } from 'yup/lib/mixed';

export const schema = {
  name: yup
    .string()
    .min(3, 'O nome da predefinição deve ter no mínimo 3 caracteres')
    .required('O nome da predefinição é obrigatório'),
  descr: yup
    .string()
    .min(3, 'O nome do anunciante deve ter no mínimo 3 caracteres')
    .max(256, 'O nome do anunciante deve ter no máximo 256 caracteres')
    .required('A descrição do anuncio é obrigatória'),
  // slogan: yup
  //   .string()
  //   .min(3, 'O texto de chamada deve ter no mínimo 3 caracteres')
  //   .required('Texto de chamada é obrigatório'),
  url: yup.string().required('URL a ser acessada deve ser informada'),
  label: yup
    .string()
    .required('Titulo do botão deve ser informado')
    .default('Saiba mais'),
  color: yup.string().required('Por favor, selecione uma cor'),
  // level: yup.string().required('O nível do anunciante é obrigatório'),
  // logo: yup.mixed().required('A logo do anunciante é obrigatório'),
  logo: yup.mixed().optional(),
};

export const schemaCreateSponsor = yupResolver(yup.object().shape(schema));
