import styled, { css } from 'styled-components';
import { defaultTheme } from 'presentation/base/themes';
import { TextSpanProps } from './types';

interface CheckboxProps {
  color?: keyof typeof defaultTheme.colors;
  error?: boolean;
}

export const Check = styled.div`
  width: 2rem;
  height: 2rem;

  cursor: pointer;

  background: ${({ theme }) => theme.colors.black3};
  border-radius: 0.3rem;
`;

export const Container = styled.label<CheckboxProps>`
  display: flex;
  align-items: center;

  width: fit-content;

  &:hover input[type='checkbox']:not(:checked):not(:disabled) + ${Check} {
    background: ${({ theme }) => theme.colors.white5};
  }

  &:hover input[type='checkbox']:checked:not(:disabled) + ${Check} {
    background: ${({ theme }) => theme.colors.blue1};
  }

  transition: background 0.2s;

  input[type='checkbox'] {
    cursor: pointer;
    opacity: 0;
    outline: 0;
    width: 0;
    height: 0;

    &:checked + ${Check} {
      background: ${({ theme }) => theme.colors.blue2};
      border: 0.2rem solid transparent;

      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 0.3rem;
        left: 0.6rem;
        width: 0.45rem;
        height: 0.8rem;
        border: solid ${({ theme }) => theme.colors.white1};
        border-width: 0 0.2rem 0.2rem 0;
        transform: rotate(45deg);
      }
    }

    &:disabled + ${Check} {
      background: ${({ theme }) => theme.colors.black1};
      cursor: default;
    }

    &:focus-visible + ${Check} {
      outline: 0.2rem solid
        ${({ theme, error }) => theme.colors[error ? 'red1' : 'blue2']};
      box-shadow: 0 0 1rem
        ${({ theme, error }) => theme.colors[error ? 'red1' : 'blue2']};
    }

    &:checked:focus-visible + ${Check} {
      outline: 0.2rem solid
        ${({ theme, error }) => theme.colors[error ? 'red1' : 'white2']};
      box-shadow: 0 0 1rem
        ${({ theme, error }) => theme.colors[error ? 'red1' : 'blue2']};
    }
  }
`;

export const TextSpan = styled.span<TextSpanProps>`
  display: flex;

  user-select: none;

  margin-left: 1.2rem;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white2};
  cursor: pointer;

  ${({ required, theme }) =>
    required &&
    css`
      &:after {
        content: '*';
        color: ${theme.colors.red1};
      }
    `};
`;
