/* eslint-disable react/button-has-type */
/* eslint-disable react/require-default-props */
import React, { HTMLAttributes, useMemo, useState } from 'react';
import { IconArrowUp } from 'presentation/base/icons';
import useWindowDimensions from 'presentation/hooks/useWindowDimensions';
import {
  Container,
  ControlScrollViewContainer,
  Menu,
} from './styles/StyledTabNavigation';

interface propsTabNavigation extends HTMLAttributes<HTMLDivElement> {
  setState: (e: number) => void;
  options: Array<{ label: string; disabled?: boolean }>;
  active: number;
  isMenu?: boolean;
}

const TabNavigation: React.FC<propsTabNavigation> = ({
  setState,
  active,
  options,
  isMenu = false,
}): JSX.Element => {
  const handleSetActive = (elem: { label: string; disabled?: boolean }) => {
    if (!elem.disabled) setState(options.indexOf(elem));
  };

  const { isMobile } = useWindowDimensions();
  const [scrollLeftIsActive, setScrollLeftIsActive] = useState(false);

  const scrollContainer = document.getElementById('scroll-container');
  const handleScrollView = (move = 0) => {
    if (!scrollContainer) return;
    scrollContainer.scrollTo({
      behavior: 'auto',
      left: scrollContainer.scrollLeft + move,
    });
    setScrollLeftIsActive(move + scrollContainer.scrollLeft > 100);
  };

  return (
    <ControlScrollViewContainer>
      {!isMobile && scrollLeftIsActive && (
        <button
          className="button left"
          type="button"
          onClick={() => handleScrollView(-120)}
        >
          <IconArrowUp
            style={{
              transform: 'rotate(90deg)',
            }}
          />
        </button>
      )}
      <Container
        className="scroll-container"
        id="scroll-container"
        isMenu={isMenu}
        style={{
          padding: !isMobile
            ? `0 ${scrollLeftIsActive ? '3rem' : '0 0 1.5rem'}`
            : '0',
        }}
      >
        {options.map((elem, index) => {
          return (
            <Menu
              className="menu"
              index={index}
              key={`tab_${elem.label.toLocaleLowerCase()}`}
              // isActive={active === index}
              onClick={() => handleSetActive(elem)}
              disabled={elem.disabled}
            >
              {elem.label}
            </Menu>
          );
        })}
      </Container>
      {!isMobile && (
        <button
          className="button right"
          type="button"
          onClick={() => handleScrollView(120)}
        >
          <IconArrowUp
            style={{
              transform: 'rotate(90deg)',
            }}
          />
        </button>
      )}
    </ControlScrollViewContainer>
  );
};

export default TabNavigation;
