import styled, { keyframes } from 'styled-components';

export const Container = styled.label`
  position: relative;
  display: inline-block;
  width: 3.6rem;
  height: 1.8rem;

  margin: 0;

  cursor: pointer;

  &:focus-visible.container {
    outline: none;
    border-radius: 1.8rem;
    box-shadow: 0 0 0.2rem ${({ theme }) => theme.colors.blue2};
    border: 0.2rem solid ${({ theme }) => theme.colors.blue2};
  }
`;

export const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  user-select: none;

  &:hover + .track {
    background: ${({ theme }) => theme.colors.white5};

    & .thumb .icon .line-1,
    & .thumb .icon .line-2 {
      background: ${({ theme }) => theme.colors.white5};
    }
  }

  &:checked:hover + .track {
    background: ${({ theme }) => theme.colors.blue1};

    & .thumb .icon .line-1,
    & .thumb .icon .line-2 {
      background: ${({ theme }) => theme.colors.blue1};
    }
  }

  &:checked + .track {
    background: ${({ theme }) => theme.colors.blue2};
  }

  &:checked + .track .thumb {
    transform: translateX(0.8rem);

    &:focus-visible + .track {
      box-sizing: border-box;
      padding: 0.9rem;
      background: ${({ theme }) => theme.colors.black3};
      border: 0.2rem solid ${({ theme }) => theme.colors.blue1};
    }
  }

  &:checked + .track .thumb .icon {
    transform: rotate(45deg) translate(0.1rem, 0rem);
  }

  &:checked + .track .thumb .icon .line-1,
  &:checked + .track .thumb .icon .line-2 {
    background: ${({ theme }) => theme.colors.blue2};
  }

  &:checked + .track .thumb .icon .line-1 {
    width: 50%;
    transform: rotate(90deg) translate(-0.09rem, -0.035rem);
  }

  &:checked + .track .thumb .icon .line-2 {
    width: 40%;
    transform: rotate(0) translate(-0.13rem, 0.2rem);
  }

  &:focus-visible + .track {
    border-radius: 1.8rem;
    box-shadow: 0 0 1rem ${({ theme }) => theme.colors.blue1};
    outline: 0.2rem solid ${({ theme }) => theme.colors.blue1};
  }

  &:focus-visible:checked + .track {
    border-radius: 1.8rem;
    box-shadow: 0 0 1rem ${({ theme }) => theme.colors.blue1};
    background: ${({ theme }) => theme.colors.blue1};
    outline: 0.2rem solid ${({ theme }) => theme.colors.white2};
  }
  &:disabled + .track {
    cursor: default;
    background: ${({ theme }) => theme.colors.black1};

    & .thumb {
      background: ${({ theme }) => theme.colors.white3};

      & .icon .line-1,
      & .icon .line-2 {
        background: ${({ theme }) => theme.colors.black1};
      }
    }
  }
`;

export const Track = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 1.8rem;

  background: ${({ theme }) => theme.colors.black3};
`;

export const Thumb = styled.div`
  position: relative;
  /* top: 0.2rem; */
  width: 1.2rem;
  height: 1.2rem;

  display: flex;
  align-items: center;
  justify-content: center;

  transform: translateX(-0.8rem);

  border-radius: 50%;
  background: ${({ theme }) => theme.colors.white1};

  transition: 0.2s;

  & .icon {
    position: relative;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    transform: rotate(45deg);

    transition: 0.2s;
  }

  & .icon .line-1,
  & .icon .line-2 {
    position: absolute;

    border: 0;

    width: 0.8rem;
    height: 0.15rem;

    background: ${({ theme }) => theme.colors.black3};

    transition: 0.2s;
  }

  & .icon .line-1 {
    transform: rotate(90deg);
  }

  & .icon .line-2 {
    transform: rotate(0);
  }
`;
