import { call, put, select } from 'redux-saga/effects';
import {
  iActionCreate,
  iActionCreateSuccess,
} from 'domain/interfaces/redux/event/create';
import { CreateEvent } from 'domain/usecases/event/remote/CreateEvent';
import { makeRemoteCreateEvent } from 'main/factories/usecases/event/CreateEventFactory';
import {
  createSuccess,
  createFailed,
} from 'data/store/reducer/event/actions/create';
import { History } from 'main/routes';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import { MessageOptions } from 'domain/interfaces/redux/message';

export function* onCreate(action: iActionCreate) {
  const remoteCreateEvent = makeRemoteCreateEvent();

  try {
    const response: CreateEvent.Model = yield call(
      remoteCreateEvent.create,
      action.payload,
    );
    yield put(createSuccess(response));
  } catch (e) {
    yield put(createFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onCreateSuccess(action: iActionCreateSuccess) {
  makeReduxActiveMessage().active({
    active: MessageOptions.createEventSuccessModal,
  });
  // window.location.reload();
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onCreateFailed() {
  makeReduxActiveMessage().active({
    active: MessageOptions.none,
  });
}
