import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Entertainment from 'presentation/pages/Home/Entertainment';

interface iParamsTypes {
  language: string;
}

export const EntertainmentFactory: React.FC = () => {
  const { language } = useParams<iParamsTypes>();

  useEffect(() => {
    if (language && language.length === 5 && language.split('-').length === 2) {
      localStorage.setItem('@netfans/react-intl', language);
    }
  }, [language]);

  return <Entertainment />;
};
