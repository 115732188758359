/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useCallback, useEffect, useState } from 'react';
import { Popover } from '@material-ui/core';
import { IconMoreOptions } from 'presentation/base/icons';
import { iSpot, iStore, iFriendship } from 'domain/interfaces/models';
import { useSelector } from 'react-redux';
import IconBlockUser from 'presentation/base/icons/iconBlockUser.svg';
import IconDeleteFriend from 'presentation/base/icons/iconDeleteFriend.svg';
import IconDenounceUser from 'presentation/base/icons/iconDenounceUser.svg';
import IconViewProfile from 'presentation/base/icons/iconViewProfile.svg';
import AddFriend from 'presentation/base/images/AddFriend.png';
import { makeRemoteAcceptFriendship } from 'main/factories/usecases/friendship/AcceptFriendshipFactory';
import { makeRemoteRequestFriendship } from 'main/factories/usecases/friendship/RequestFriendshipFactory';
import { makeReduxGetAllFriendship } from 'main/factories/usecases/friendship/GetAllFriendshipFactory';
import { makeRemoteDeleteFriendship } from 'main/factories/usecases/friendship/DeleteFriendshipFactory';
import { toast } from 'react-toastify';
import {
  Provider,
  ContainerModal,
  RowContainer,
  Options,
  Content,
  ParticipantsList,
  ParticipantItem,
  ParticipantsItemRow,
  ParticipantsItemActions,
  ParticipantsMic,
  ScrollContainer,
  ParticipantsMoreOptions,
  ParticipantsMutedMic,
} from '../styles/StyledVirtualAudienceModal';
import VirtualAudienceHeader from './VirtualAudienceHeader';
import { EventRoomProps, UserProps } from './interface';
import { RandomProfileUser } from '../../randomProfileUser';

interface VirtualAudienceProps {
  user?: UserProps;
  participants?: iSpot[];
  event?: EventRoomProps;
  isOpen: boolean;
  onClose: () => void;
  localMuteState?: boolean;
  localMute?: () => void;
  remoteMute?: (data: any) => void;
  setIsOpen?: (isOpen: boolean) => void;
}

export interface iUsers {
  id?: number;
  peerName: string;
  peerId: number;
  userId?: number;
  x: number;
  y: number;
  type: 'R' | 'A' | undefined;
  iAmRequester?: boolean;
  remoteMuteState?: boolean;
}

const VirtualAudienceModal: React.FC<VirtualAudienceProps> = ({
  user,
  participants,
  event,
  isOpen,
  onClose,
  localMuteState,
  localMute,
  remoteMute,
  setIsOpen,
}) => {
  const [isMinimal, setIsMinimal] = useState(false);
  const [active, setActive] = useState(1);
  const [idFriend, setIdFriend] = useState(0);
  const [friends, setFriends] = useState<iFriendship['records']>([]);
  const [users, setUsers] = useState<iUsers[]>([]);

  const { wrtcInfo, user: userLogged } = useSelector(
    (store: iStore) => store.auth,
  );
  const { records } = useSelector((store: iStore) => store.friendShip);
  const wrtc = useSelector((store: iStore) => store.wrtc);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? 'options-popover' : undefined;

  const isActualUser = useCallback(
    (peerId: number) => {
      return Number(wrtcInfo?.peerId) === peerId;
    },
    [wrtcInfo?.peerId],
  );

  const getMuteState = useCallback(
    (peerId: number): boolean => {
      return wrtc?.getRemotePeerInputEdgeGain?.(peerId) === 0 ?? false;
    },
    [wrtc],
  );

  const handleRemoteMute = useCallback(
    (peerId: number, x: number, y: number) => {
      const isMuted = getMuteState(peerId);

      remoteMute?.({ x, y });

      if (wrtc.setRemotePeerInputEdgeGain) {
        isMuted
          ? wrtc.setRemotePeerInputEdgeGain(peerId, 1)
          : wrtc.setRemotePeerInputEdgeGain(peerId, 0);
      }

      // toast(isMuted ? 'Usuário não mais silenciado!' : 'Usuário silenciado!', {
      //   type: isMuted ? 'info' : 'error',
      // });
    },
    [getMuteState, remoteMute, wrtc],
  );

  const renderMic = useCallback(
    (item: iUsers) => {
      if (isActualUser(item?.peerId ?? -1)) {
        return localMuteState ? (
          <ParticipantsMic onClick={() => localMute?.()} />
        ) : (
          <ParticipantsMutedMic onClick={() => localMute?.()} />
        );
      }

      return getMuteState(item.peerId) ? (
        <ParticipantsMutedMic
          onClick={() => handleRemoteMute?.(item.peerId, item.x, item.y)}
        />
      ) : (
        <ParticipantsMic
          onClick={() => handleRemoteMute?.(item.peerId, item.x, item.y)}
        />
      );
    },
    [getMuteState, handleRemoteMute, isActualUser, localMute, localMuteState],
  );

  useEffect(() => {
    const auxFriends: iFriendship['records'] = [];
    const auxUsers: iUsers[] = participants
      ? participants?.map(el => {
          return {
            peerId: el.peerId,
            peerName: el.peerName.split(':')[0],
            userId: Number(el.peerName.split(':')[1]),
            type: undefined,
            x: el.spotCoordJ,
            y: el.spotCoordI,
          };
        })
      : [];

    records?.forEach(el => {
      auxUsers?.forEach(_el => {
        if (el.requester.id === Number(userLogged.id)) {
          if (el.addressee.id === _el.userId) {
            auxFriends.push(el);
            _el.id = el.id;

            if (el.status === 'ACCEPTED') {
              _el.type = 'A';
              _el.iAmRequester = true;
            } else if (el.status === 'REQUESTED') {
              _el.type = 'R';
              _el.iAmRequester = true;
            }
          }
        } else if (el.addressee.id === Number(userLogged.id)) {
          if (el.requester.id === _el.userId) {
            auxFriends.push(el);
            _el.id = el.id;

            if (el.status === 'ACCEPTED') {
              _el.type = 'A';
              _el.iAmRequester = false;
            } else if (el.status === 'REQUESTED') {
              _el.type = 'R';
              _el.iAmRequester = false;
            }
          }
        }
      });
    });

    setFriends(auxFriends);
    setUsers(auxUsers);
  }, [participants, records, userLogged.id]);

  return (
    <>
      <ContainerModal isOpen={isOpen} isMinimal={isMinimal}>
        <VirtualAudienceHeader
          handleClose={() => {
            setIsMinimal(false);
            onClose();
          }}
          handleMinimal={() => {
            setIsMinimal(!isMinimal);
          }}
          user={user}
          event={event}
        />
        <Content isMinimal={isMinimal} isOpen={isOpen}>
          <RowContainer>
            <Options
              id="tab_participants"
              isActive={active === 1}
              onClick={() => setActive(1)}
            >
              PARTICIPANTES
            </Options>
            <Options
              id="tab_friends"
              isActive={active === 2}
              onClick={() => {
                setActive(2);
                makeReduxGetAllFriendship().getAll({
                  user: Number(userLogged.id),
                });
              }}
            >
              AMIGOS
            </Options>
          </RowContainer>
          <ParticipantsList>
            {active === 1 && (
              <ScrollContainer>
                {users?.map((participant, idx) => (
                  <ParticipantItem key={idx}>
                    <ParticipantsItemRow>
                      <RandomProfileUser peerId={participant.peerId} />
                      <h4>{participant.peerName}</h4>
                    </ParticipantsItemRow>
                    <ParticipantsItemActions>
                      <ParticipantsItemRow>
                        {renderMic(participant)}
                      </ParticipantsItemRow>
                      <ParticipantsItemRow>
                        <button
                          type="button"
                          onClick={e => {
                            handleClick(e);
                            setIdFriend(participant.userId ?? -1);
                          }}
                        >
                          <IconMoreOptions />
                          {}
                        </button>
                      </ParticipantsItemRow>
                    </ParticipantsItemActions>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      style={{ background: 'transparent' }}
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                      }}
                    >
                      <ParticipantsMoreOptions>
                        <div className="opTop">
                          <img alt="" src={IconViewProfile} />
                          Ver perfil
                        </div>
                        {!Number.isNaN(idFriend) &&
                          userLogged.id &&
                          idFriend !== Number(userLogged.id) && (
                            <button
                              style={{
                                display: participant.iAmRequester
                                  ? 'none'
                                  : 'block',
                                marginTop: participant.iAmRequester
                                  ? '0px'
                                  : '15px',
                              }}
                              type="button"
                              className="opTop"
                              onClick={async () => {
                                if (participant.type === undefined)
                                  await makeRemoteRequestFriendship()
                                    .request({
                                      user: Number(userLogged.id),
                                      addressee: idFriend,
                                    })
                                    .then(() => {
                                      makeReduxGetAllFriendship().getAll({
                                        user: Number(userLogged.id),
                                      });
                                    });
                                else if (
                                  participant.type === 'A' &&
                                  participant.id
                                )
                                  await makeRemoteAcceptFriendship()
                                    .accept({
                                      friendship: participant.id,
                                      user: Number(userLogged.id),
                                    })
                                    .then(() => {
                                      makeReduxGetAllFriendship().getAll({
                                        user: Number(userLogged.id),
                                      });
                                    });

                                handleClose();
                              }}
                            >
                              <img alt="" src={AddFriend} />
                              Adicionar amigo
                            </button>
                          )}
                        <div className="line" style={{ display: 'none' }} />
                        <div className="opDown" style={{ display: 'none' }}>
                          <img alt="" src={IconBlockUser} />
                          Bloquear usuário
                        </div>
                        <div
                          className="opDown"
                          style={{ cursor: 'not-allowed', opacity: 0.7 }}
                        >
                          <img alt="" src={IconDenounceUser} />
                          Denunciar usuário
                        </div>
                      </ParticipantsMoreOptions>
                    </Popover>
                  </ParticipantItem>
                ))}
              </ScrollContainer>
            )}
            {active === 2 && (
              <ScrollContainer>
                {friends
                  ?.filter(el => {
                    return el.status === 'ACCEPTED';
                  })
                  ?.map(participant => {
                    const userFriend =
                      participant.addressee.id !== Number(userLogged.id)
                        ? {
                            id: participant.addressee.id,
                            name: `${participant.addressee.firstName} ${participant.addressee.lastName}`,
                          }
                        : {
                            id: participant.requester.id,
                            name: `${participant.requester.firstName} ${participant.requester.lastName}`,
                          };

                    return (
                      <ParticipantItem>
                        <ParticipantsItemRow>
                          <RandomProfileUser peerId={userFriend.id} />
                          <h4>{userFriend.name}</h4>
                        </ParticipantsItemRow>
                        <ParticipantsItemActions>
                          <ParticipantsItemRow />
                          <ParticipantsItemRow>
                            <button type="button" onClick={handleClick}>
                              <IconMoreOptions />
                            </button>
                          </ParticipantsItemRow>
                        </ParticipantsItemActions>
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          style={{ background: 'transparent' }}
                          anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                          }}
                        >
                          <ParticipantsMoreOptions>
                            <div className="opTop">
                              <img alt="" src={IconViewProfile} />
                              Ver perfil
                            </div>
                            <button
                              type="button"
                              className="opTop"
                              onClick={async () => {
                                await makeRemoteDeleteFriendship()
                                  .delete({
                                    friendship: participant.id,
                                    user: participant.addressee.id,
                                  })
                                  .then(() => {
                                    makeReduxGetAllFriendship().getAll({
                                      user: Number(userLogged.id),
                                    });
                                  });
                              }}
                              style={{ marginTop: '15px' }}
                            >
                              <img alt="" src={IconDeleteFriend} />
                              Remover amigo
                            </button>
                            <div className="line" />
                            <div
                              className="opDown"
                              style={{ cursor: 'not-allowed', opacity: 0.7 }}
                            >
                              <img alt="" src={IconBlockUser} />
                              Bloquear usuário
                            </div>
                            <div
                              className="opDown"
                              style={{ cursor: 'not-allowed', opacity: 0.7 }}
                            >
                              <img alt="" src={IconDenounceUser} />
                              Denunciar usuário
                            </div>
                          </ParticipantsMoreOptions>
                        </Popover>
                      </ParticipantItem>
                    );
                  })}
              </ScrollContainer>
            )}
          </ParticipantsList>
          {/* <Footer>
            <Button
              id="btn_invite"
              type="submit"
              title="+ Chamar para o evento"
              colorstyle="white1"
            />
          </Footer> */}
        </Content>
      </ContainerModal>
    </>
  );
};

export default VirtualAudienceModal;
