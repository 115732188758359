/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import { iEventItem } from 'domain/interfaces/models/Event';
import {
  CloseIcon,
  IconFacebook,
  IconGoogle,
  IconTwitter,
  IconWhatsapp,
  IconTelegram,
  IconTransferMinor,
} from 'presentation/base/icons';
import { useParams } from 'react-router';
import { makeStyles, Tooltip } from '@material-ui/core';
import { toast } from 'react-toastify';
import {
  formattedDate,
  incrementMinutesOnTimeString,
} from 'utils/formattedDate';
import Button from '../UI/Button';

import {
  MobileContentContainer,
  MobileContentHeader,
  MobileLeftHeaderContainer,
  EventText,
  MobileContentBody,
  InfoRoomContent,
  InviteButtonsContainer,
  MobileContentContainerSpaceFill,
} from './styles';
import { Input } from '../UI';

interface iParams {
  roomId: string;
  id: string;
}
interface MobileContentScreenProps {
  isDisplayed: boolean;
  onClose: () => void;
}

const MobileShareLink: React.FC<MobileContentScreenProps> = ({
  isDisplayed,
  onClose,
}) => {
  const { href } = window.location;

  return (
    <MobileContentContainer isDisplayed={isDisplayed}>
      {isDisplayed && (
        <MobileContentContainerSpaceFill>
          <MobileContentHeader>
            <MobileLeftHeaderContainer>
              <InfoRoomContent>
                <EventText bold>Compartilhar via</EventText>
              </InfoRoomContent>
            </MobileLeftHeaderContainer>

            <CloseIcon
              style={{
                cursor: 'pointer',
              }}
              onClick={onClose}
            />
          </MobileContentHeader>
          <MobileContentBody>
            <InviteButtonsContainer>
              <Button
                tooltipProps={{
                  title: 'Facebook',
                  placement: 'bottom',
                }}
                size="very small"
                onClick={e => {
                  e.preventDefault();
                  window.open(
                    `https://www.facebook.com/dialog/share?app_id=87741124305&href=${href}`,
                  );
                }}
                icon={IconFacebook}
                style={{
                  backgroundColor: '#1877F2',
                  width: '50px',
                  height: '40px',
                }}
                iconSize={20}
              />
              <Button
                tooltipProps={{
                  title: 'Gmail',
                  placement: 'bottom',
                }}
                size="very small"
                onClick={e => {
                  e.preventDefault();
                  window.open(
                    `mailto:email@example.com?subject=Subject&body=${href}`,
                  );
                }}
                icon={IconGoogle}
                colorstyle="white1"
                style={{
                  width: '50px',
                  height: '40px',
                }}
                iconSize={20}
              />
              <Button
                tooltipProps={{
                  title: 'Twitter',
                  placement: 'bottom',
                }}
                size="very small"
                onClick={e => {
                  e.preventDefault();
                  window.open(
                    `https://twitter.com/intent/tweet?url=${href}&text=Vem%20comigo%20no%20netfans`,
                  );
                }}
                icon={IconTwitter}
                style={{
                  width: '50px',
                  height: '40px',
                  backgroundColor: '#5899E3',
                }}
                iconSize={20}
              />
              <Button
                tooltipProps={{
                  title: 'Whatsapp',
                  placement: 'bottom',
                }}
                size="very small"
                onClick={e => {
                  e.preventDefault();
                  window.open(
                    `https://api.whatsapp.com/send/?phone&text=Vem%20comigo%20no%20netfans%20${href}`,
                  );
                }}
                style={{
                  backgroundColor: '#25D366',
                  width: '50px',
                  height: '40px',
                }}
                icon={IconWhatsapp}
                iconSize={20}
              />
              <Button
                tooltipProps={{
                  title: 'Telegram',
                  placement: 'bottom',
                }}
                size="very small"
                onClick={e => {
                  e.preventDefault();
                  window.open(
                    `http://t.me/share/url?url=${href}&text=Vem comigo no netfans`,
                  );
                }}
                icon={IconTelegram}
                style={{
                  width: '50px',
                  height: '40px',
                  backgroundColor: '#37AFE2',
                }}
                iconSize={20}
              />
            </InviteButtonsContainer>
            <div
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
              }}
            >
              <EventText bold>Copiar link</EventText>

              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  marginTop: '10px',
                }}
              >
                <Input disabled width="100%" value={`${href}`} />
                <Button
                  tooltipProps={{
                    title: 'Copiar link',
                    placement: 'bottom',
                  }}
                  size="very small"
                  onClick={e => {
                    e.preventDefault();
                    navigator.clipboard.writeText(`${href}`);
                    toast.info('Link copiado para a área de transferência');
                  }}
                  style={{
                    width: '50px',
                    height: '40px',
                    backgroundColor: '#3F4660',
                  }}
                  icon={IconTransferMinor}
                  iconSize={20}
                />
              </div>
            </div>
          </MobileContentBody>
        </MobileContentContainerSpaceFill>
      )}
    </MobileContentContainer>
  );
};

export default MobileShareLink;
