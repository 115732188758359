import styled from 'styled-components';

export const Container = styled.div`
  width: 41.6rem;
  height: 61rem;

  background: ${({ theme }) => theme.colors.black2};

  padding: 2rem 2rem 5.8rem;

  border-radius: 5px;

  font-family: Poppins;
  font-size: 1.4rem;
  line-height: 1.8rem;
`;

export const Content = styled.div`
  width: 100%;
`;

export const ContentHeader = styled.div`
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1.3rem;
  color: white;
  font-size: 2.4rem;
  font-weight: bold;
`;

export const CloseModalDiv = styled.div`
  display: flex;
  align-items: center;

  #button_close {
    cursor: pointer;
  }
`;

export const Message = styled.div`
  width: 100%;
  margin-top: 3rem;
  color: white;
`;

export const Form = styled.div`
  width: 100%;
  margin-top: 2.8rem;
`;

export const ButtonsDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 3rem;
`;
