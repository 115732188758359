import styled, { css } from 'styled-components';

interface GridProps {
  numCol: number;
  isLayout: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Grid = styled.div<GridProps>`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: ${props => `repeat(${props.numCol}, 0fr)`};

  ${props => {
    return props.isLayout
      ? css`
          overflow: visible;
        `
      : css`
          grid-template-rows: 1fr;
          max-height: 50px;
        `;
  }}
`;
