import { takeLatest } from 'redux-saga/effects';
import { ReactionTypes } from 'domain/interfaces/redux/reaction/types';
import { onGetAll, onGetAllSuccess, onGetAllFailed } from './GetAll';
import { onCreate, onCreateSuccess, onCreateFailed } from './Create';
import { onDelete, onDeleteSuccess, onDeleteFailed } from './Delete';
import { onGetById, onGetByIdSuccess, onGetByIdFailed } from './GetById';
import {
  onSendImage,
  onSendImageSuccess,
  onSendImageFailed,
} from './SendImage';
import { onUpdate, onUpdateSuccess, onUpdateFailed } from './Update';

const tasks = [
  takeLatest(ReactionTypes.GETALL, onGetAll),
  takeLatest(ReactionTypes.GETALL_SUCCESS, onGetAllSuccess),
  takeLatest(ReactionTypes.GETALL_FAILED, onGetAllFailed),
  takeLatest(ReactionTypes.CREATE, onCreate),
  takeLatest(ReactionTypes.CREATE_SUCCESS, onCreateSuccess),
  takeLatest(ReactionTypes.CREATE_FAILED, onCreateFailed),
  takeLatest(ReactionTypes.DELETE, onDelete),
  takeLatest(ReactionTypes.DELETE_SUCCESS, onDeleteSuccess),
  takeLatest(ReactionTypes.DELETE_FAILED, onDeleteFailed),
  takeLatest(ReactionTypes.GETBYID, onGetById),
  takeLatest(ReactionTypes.GETBYID_SUCCESS, onGetByIdSuccess),
  takeLatest(ReactionTypes.GETBYID_FAILED, onGetByIdFailed),
  takeLatest(ReactionTypes.SENDIMAGE, onSendImage),
  takeLatest(ReactionTypes.SENDIMAGE_SUCCESS, onSendImageSuccess),
  takeLatest(ReactionTypes.SENDIMAGE_FAILED, onSendImageFailed),
  takeLatest(ReactionTypes.UPDATE, onUpdate),
  takeLatest(ReactionTypes.UPDATE_SUCCESS, onUpdateSuccess),
  takeLatest(ReactionTypes.UPDATE_FAILED, onUpdateFailed),
];

export default tasks;
