import {
  iActionUpdateWRTCInfo,
  AuthTypes,
} from 'domain/interfaces/redux/auth/UpdateWRTCInfo';
import { UpdateWRTCInfo } from 'domain/usecases/auth/redux';

export const updateWRTCInfo = (
  payload: UpdateWRTCInfo.Params,
): iActionUpdateWRTCInfo => ({
  type: AuthTypes.UPDATE_WRTC_INFO,
  payload,
});
