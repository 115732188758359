import { Sponsor } from 'domain/interfaces/models/Sponsors';
import bannerBeta from 'presentation/base/images/bannerBeta.png';

export const templateDefault: Sponsor = {
  id: 1,
  short: 'Netfans',
  name: 'Netfans',
  descr: 'Netfans',
  logo: bannerBeta,
  color: '#5662CF',
  label: 'Netfans',
  url: 'https://netfans.com.br',
};
