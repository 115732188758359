import { GetAllCategory } from 'domain/usecases/category/redux/GetAllCategory';
import {
  iActionGetAll,
  iActionGetAllFailed,
  iActionGetAllSuccess,
  CategoryTypes,
} from 'domain/interfaces/redux/category/getAll';

export const getAllRequest = (
  payload: GetAllCategory.Params,
): iActionGetAll => ({
  type: CategoryTypes.GETALL,
  payload,
});

export const getAllSuccess = (
  params: GetAllCategory.Model,
): iActionGetAllSuccess => ({
  type: CategoryTypes.GETALL_SUCCESS,
  payload: params,
});

export const getAllFailed = (): iActionGetAllFailed => ({
  type: CategoryTypes.GETALL_FAILED,
});
