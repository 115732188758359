import { Dispatch } from 'redux';
import { Login } from 'domain/usecases/auth/redux';
import { iActionLogin } from 'domain/interfaces/redux/auth/Login';
import { login } from 'data/store/reducer/auth/actions/login';
import store from 'data/store';

export class ReduxLogin implements Login {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  login = (params: Login.Params): iActionLogin => this.send(login(params));
}
