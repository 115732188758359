import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { ListSponsors as UsecaseRemoteListSponsors } from 'domain/usecases/sponsor/remote';
import { ListSponsors as UsecaseReduxListSponsors } from 'domain/usecases/sponsor/redux';

import { RemoteListSponsors } from 'data/repository/sponsor';
import { ReduxListSponsors } from 'data/store/reducer/sponsor/usecases';

/**
 * send request via API.
 */
export const makeRemoteListSponsors = (): UsecaseRemoteListSponsors =>
  new RemoteListSponsors(makeApiUrl('/sponsors/'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxListSponsors = (): UsecaseReduxListSponsors =>
  new ReduxListSponsors();
