interface Positions {
  posScrollX: 'start' | 'half' | 'end';
  posScrollY: 'start' | 'half' | 'end';
}

export const getStartPosition = (roomId: number): Positions => {
  if (roomId === 1) {
    return {
      posScrollX: 'start',
      posScrollY: 'start',
    };
  }
  if (roomId === 15) {
    return {
      posScrollX: 'end',
      posScrollY: 'start',
    };
  }
  if (roomId === 136) {
    return {
      posScrollX: 'start',
      posScrollY: 'end',
    };
  }
  if (roomId === 150) {
    return {
      posScrollX: 'end',
      posScrollY: 'end',
    };
  }
  if (roomId > 1 && roomId < 15) {
    return {
      posScrollX: 'half',
      posScrollY: 'start',
    };
  }
  if (roomId > 136 && roomId < 150) {
    return {
      posScrollX: 'half',
      posScrollY: 'end',
    };
  }
  if ((roomId - 1) % 15 === 0) {
    return {
      posScrollX: 'start',
      posScrollY: 'half',
    };
  }
  if (roomId % 15 === 0) {
    return {
      posScrollX: 'end',
      posScrollY: 'half',
    };
  }

  return {
    posScrollX: 'half',
    posScrollY: 'half',
  };
};

export const getAdj = (
  roomId: number,
): {
  posX: number;
  posY: number;
  roomId: number;
  posScrollX?: string;
  posScrollY?: string;
}[] => {
  console.log('...roomId', roomId);

  if (roomId === 1) {
    return [
      {
        posX: 2,
        posY: 2,
        roomId,
        posScrollX: 'start',
        posScrollY: 'start',
      },
      { posX: 2, posY: 3, roomId: roomId + 1 },
      { posX: 2, posY: 4, roomId: roomId + 2 },
      { posX: 3, posY: 2, roomId: roomId + 15 },
      { posX: 3, posY: 3, roomId: roomId + (15 + 1) },
      { posX: 3, posY: 4, roomId: roomId + (15 + 2) },
      { posX: 4, posY: 2, roomId: roomId + 2 * 15 },
      { posX: 4, posY: 3, roomId: roomId + (2 * 15 + 1) },
      { posX: 4, posY: 4, roomId: roomId + (2 * 15 + 2) },
    ];
  }
  if (roomId === 15) {
    return [
      { posX: 2, posY: 2, roomId: roomId - 2 },
      { posX: 2, posY: 3, roomId: roomId - 1 },
      {
        posX: 2,
        posY: 4,
        roomId,
        posScrollX: 'end',
        posScrollY: 'start',
      },
      { posX: 3, posY: 2, roomId: roomId + (15 - 2) },
      { posX: 3, posY: 3, roomId: roomId + (15 - 1) },
      { posX: 3, posY: 4, roomId: roomId + 15 },
      { posX: 4, posY: 2, roomId: roomId + (2 * 15 - 2) },
      { posX: 4, posY: 3, roomId: roomId + (2 * 15 - 1) },
      { posX: 4, posY: 4, roomId: roomId + 2 * 15 },
    ];
  }
  if (roomId === 136) {
    return [
      { posX: 2, posY: 2, roomId: roomId - 2 * 15 },
      { posX: 2, posY: 3, roomId: roomId - (2 * 15 - 1) },
      { posX: 2, posY: 4, roomId: roomId - (2 * 15 - 2) },
      { posX: 3, posY: 2, roomId: roomId - 15 },
      { posX: 3, posY: 3, roomId: roomId - (15 - 1) },
      { posX: 3, posY: 4, roomId: roomId - (15 - 2) },
      {
        posX: 4,
        posY: 2,
        roomId,
        posScrollX: 'start',
        posScrollY: 'end',
      },
      { posX: 4, posY: 3, roomId: roomId + 1 },
      { posX: 4, posY: 4, roomId: roomId + 2 },
    ];
  }
  if (roomId === 150) {
    return [
      { posX: 2, posY: 2, roomId: roomId - (2 * 15 + 2) },
      { posX: 2, posY: 3, roomId: roomId - (2 * 15 + 1) },
      { posX: 2, posY: 4, roomId: roomId - 2 * 15 },
      { posX: 3, posY: 2, roomId: roomId - (15 + 2) },
      { posX: 3, posY: 3, roomId: roomId - (15 + 1) },
      { posX: 3, posY: 4, roomId: roomId - 15 },
      { posX: 4, posY: 2, roomId: roomId - 2 },
      { posX: 4, posY: 3, roomId: roomId - 1 },
      {
        posX: 4,
        posY: 4,
        roomId,
        posScrollX: 'end',
        posScrollY: 'end',
      },
    ];
  }
  if (roomId > 1 && roomId < 15) {
    return [
      { posX: 2, posY: 2, roomId: roomId - 1 },
      {
        posX: 2,
        posY: 3,
        roomId,
        posScrollX: 'half',
        posScrollY: 'start',
      },
      { posX: 2, posY: 4, roomId: roomId + 1 },
      { posX: 3, posY: 2, roomId: roomId + (15 - 1) },
      { posX: 3, posY: 3, roomId: roomId + 15 },
      { posX: 3, posY: 4, roomId: roomId + (15 + 1) },
      { posX: 4, posY: 2, roomId: roomId + (2 * 15 - 1) },
      { posX: 4, posY: 3, roomId: roomId + 2 * 15 },
      { posX: 4, posY: 4, roomId: roomId + (2 * 15 + 1) },
    ];
  }
  if (roomId > 136 && roomId < 150) {
    return [
      { posX: 2, posY: 2, roomId: roomId - (2 * 15 + 1) },
      { posX: 2, posY: 3, roomId: roomId - 2 * 15 },
      { posX: 2, posY: 4, roomId: roomId - (2 * 15 - 1) },
      { posX: 3, posY: 2, roomId: roomId - (15 + 1) },
      { posX: 3, posY: 3, roomId: roomId - 15 },
      { posX: 3, posY: 4, roomId: roomId - (15 - 1) },
      { posX: 4, posY: 2, roomId: roomId - 1 },
      {
        posX: 4,
        posY: 3,
        roomId,
        posScrollX: 'half',
        posScrollY: 'end',
      },
      { posX: 4, posY: 4, roomId: roomId + 1 },
    ];
  }
  if ((roomId - 1) % 15 === 0) {
    return [
      { posX: 2, posY: 2, roomId: roomId - 15 },
      { posX: 2, posY: 3, roomId: roomId - (15 - 1) },
      { posX: 2, posY: 4, roomId: roomId - (15 - 2) },
      {
        posX: 3,
        posY: 2,
        roomId,
        posScrollX: 'start',
        posScrollY: 'half',
      },
      { posX: 3, posY: 3, roomId: roomId + 1 },
      { posX: 3, posY: 4, roomId: roomId + 2 },
      { posX: 4, posY: 2, roomId: roomId + 15 },
      { posX: 4, posY: 3, roomId: roomId + (15 + 1) },
      { posX: 4, posY: 4, roomId: roomId + (15 + 2) },
    ];
  }
  if (roomId % 15 === 0) {
    return [
      { posX: 2, posY: 2, roomId: roomId - (15 + 2) },
      { posX: 2, posY: 3, roomId: roomId - (15 + 1) },
      { posX: 2, posY: 4, roomId: roomId - 15 },
      { posX: 3, posY: 2, roomId: roomId - 2 },
      { posX: 3, posY: 3, roomId: roomId - 1 },
      {
        posX: 3,
        posY: 4,
        roomId,
        posScrollX: 'end',
        posScrollY: 'half',
      },
      { posX: 4, posY: 2, roomId: roomId + (15 - 2) },
      { posX: 4, posY: 3, roomId: roomId + (15 - 1) },
      { posX: 4, posY: 4, roomId: roomId + 15 },
    ];
  }

  return [
    { posX: 2, posY: 2, roomId: roomId - (15 + 1) },
    { posX: 2, posY: 3, roomId: roomId - 15 },
    { posX: 2, posY: 4, roomId: roomId - (15 - 1) },
    { posX: 3, posY: 2, roomId: roomId - 1 },
    {
      posX: 3,
      posY: 3,
      roomId,
      posScrollX: 'half',
      posScrollY: 'half',
    },
    { posX: 3, posY: 4, roomId: roomId + 1 },
    { posX: 4, posY: 2, roomId: roomId + (15 - 1) },
    { posX: 4, posY: 3, roomId: roomId + 15 },
    { posX: 4, posY: 4, roomId: roomId + (15 + 1) },
  ];
};
