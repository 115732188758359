import React, { createContext, useCallback, useEffect, useState } from 'react';
import useWindowDimensions from '../hooks/useWindowDimensions';

type RoomContextProps = {
  isMobile: boolean;
  isFullScreen: boolean;
  setIsFullScreen: (isFullScreen: boolean) => void;
  rooms: Array<any>;
};

export const RoomContext = createContext<RoomContextProps>({
  isFullScreen: false,
} as RoomContextProps);

interface RoomProviderProps {
  rooms: Array<any>;
  setRooms: (rooms: Array<any>) => void;
}

const RoomProvider: React.FC<RoomProviderProps> = ({
  children,
  rooms,
  setRooms,
}) => {
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const { isMobile } = useWindowDimensions();

  return (
    <RoomContext.Provider
      value={{ isMobile, isFullScreen, setIsFullScreen, rooms }}
    >
      {children}
    </RoomContext.Provider>
  );
};

export default RoomProvider;
