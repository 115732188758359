import { takeLatest } from 'redux-saga/effects';
import { SponsorTypes } from 'domain/interfaces/redux/sponsor/types';
import { onCreate, onCreateSuccess, onCreateFailed } from './Create';
import { onList, onListSuccess, onListFailed } from './List';

const tasks = [
  takeLatest(SponsorTypes.CREATE, onCreate),
  takeLatest(SponsorTypes.CREATE_SUCCESS, onCreateSuccess),
  takeLatest(SponsorTypes.CREATE_FAILED, onCreateFailed),
  takeLatest(SponsorTypes.LIST, onList),
  takeLatest(SponsorTypes.LIST_SUCCESS, onListSuccess),
  takeLatest(SponsorTypes.LIST_FAILED, onListFailed),
];

export default tasks;
