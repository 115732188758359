import styled, { css } from 'styled-components';
import media from 'utils/mediaqueries';
import ScrollContainer from 'react-indiana-drag-scroll';

export const FooterContainer = styled.footer<{ isFullScreen?: boolean }>`
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: ${({ isFullScreen }) =>
    isFullScreen ? 'space-between' : 'center'};
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.5rem 0;
  max-height: 8rem;
  background-color: ${({ theme }) => theme.colors.black2};
  z-index: ${({ isFullScreen }) => (isFullScreen ? '10' : '1')};

  ${media.lessThan('1440px')} {
    max-height: 5.8rem;
  }
`;

export const ButtonExitFullScreen = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 5px;

  background: transparent;
  color: ${({ theme }) => theme.colors.white1};
  font: ${({ theme }) => theme.typography.h7};

  margin-right: 3rem;
  padding: 0.8rem 1rem;

  transition: background 0.5s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.black5};
  }

  p {
    margin: 0;
  }

  svg {
    width: 1.6rem;
    height: 1.6rem;
    margin-bottom: 0.3rem;
  }
`;

interface iFooterProps {
  isFullScreen?: boolean;
  embed?: boolean;
}

interface iActionsFooter {
  disabled?: boolean;
}

interface boxProps {
  isMic?: boolean;
  btnActive?: boolean;
  disabled?: boolean;
}

export const Footer = styled.div<iFooterProps>`
  display: flex;
  margin: ${({ isFullScreen }) => (isFullScreen ? '0 3rem' : '0 42rem 0 0')};

  ${({ embed }) =>
    embed &&
    css`
      margin: 0;
    `}
`;

export const ColumnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const VeticalDivider = styled.div`
  width: 1px;
  height: 5rem;
  margin: 0 1rem;
  background-color: ${({ theme }) => theme.colors.black5};
`;

export const ListUsersContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 0.25rem;
`;

export const InfoRoomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InfoRoomTitle = styled.div`
  margin: 0;
  /* max-width: 10rem; */

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  transition: all 0.5s ease-in-out;
`;

export const InfoRoomSubtitle = styled.div`
  gap: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;

  span {
    display: flex;
    font-weight: normal;
    align-items: center;
    font-size: 12px;
    font-family: 'Poppins';
    font-weight: normal;
    opacity: 0.75;
  }
`;

export const InfoRoomContent = styled.div.attrs({
  className: 'info-room-content',
})`
  color: white;
  font-weight: bold;
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 1.5rem;
`;

export const AvatarContainer = styled.img`
  border-radius: 0.8rem;
  overflow: hidden;
  margin-right: 1.5rem;
  width: 5rem;
  height: 5rem;
  object-fit: cover;
`;

// List Users Component

export const ListUsersContainerResponsive = styled.div`
  display: flex;
  position: relative;
`;

export const DisplayNumberUsers = styled.p`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  background: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  color: #fff;

  font-size: 2rem;
  font-weight: 700;
  line-height: 1.5;
  text-transform: uppercase;

  cursor: pointer;
  user-select: none;
`;

// Footer Responsive

export const FooterContainerResponsive = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: ${({ theme }) => theme.colors.white1};

  /* padding: 2rem; */
  /* gap: 1.5rem; */
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.1rem 2rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  color: white;

  border-top: 1px solid rgba(255, 255, 255, 0.08);
  padding: 1.2rem 0;
  gap: 1.2rem;
`;

interface iActionsFooter {
  disabled?: boolean;
}
export const ActionsFooter = styled.div<iActionsFooter>`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  width: 100%;
  white-space: nowrap;
  cursor: pointer;
  gap: 3px;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

export const ScrollContainerFooter = styled(ScrollContainer)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
`;

export const ContainerActions = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  color: white;

  gap: 2rem;
`;

export const Actions = styled.div<iActionsFooter>`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  min-width: 7rem;
  justify-content: center;
  white-space: nowrap;
  padding: 0.8rem 1rem;
  cursor: pointer;

  svg {
    width: 2rem;
    height: 2rem;
  }

  /* :hover {
    ${({ disabled }) =>
    !disabled &&
    css`
      border-radius: 5px;
      background-color: ${({ theme }) => theme.colors.black5};
      transition: opacity 0.2s ease-in-out;
    `}
  } */

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

export const AdContainer = styled.div`
  width: 100%;
  height: 5.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;

  background: linear-gradient(
    270deg,
    #12141e 100%,
    rgba(32, 36, 51, 0.3) 100%
  ); ;
`;

export const LeftAdContent = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1.3rem;
`;

export const LeftAdInfo = styled.div`
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Title = styled.h4`
  font: ${({ theme }) => theme.typography.h4};
  font-size: 13px;
  color: #f2f2f2;
`;

export const Subtitle = styled.h6`
  font: ${({ theme }) => theme.typography.h7};
  color: #d5d5e4;
`;

export const RightAdContent = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;
`;

export const Box = styled.div<boxProps>`
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  background: ${({ isMic, btnActive, theme }) =>
    isMic && !btnActive ? theme.colors.red1 : theme.colors.black3};
  border-radius: 5px;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.3s;

  &:hover {
    background: ${({ isMic, btnActive, theme }) =>
      isMic && !btnActive ? theme.colors.red2 : theme.colors.white3};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      background: ${({ theme }) => theme.colors.black1};
      svg path {
        fill: ${({ theme }) => theme.colors.white4};
        /* OBS: In figma the correct color is white5 */
      }

      &:hover {
        background: ${({ theme }) => theme.colors.black1};
      }
    `}
`;

export const ActionsMobile = styled.div<iActionsFooter>`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  min-width: 7rem;
  justify-content: center;
  white-space: nowrap;
  padding: 0.8rem 1rem;
  cursor: pointer;

  svg {
    width: 2rem;
    height: 2rem;
  }

  /* :hover {
    ${({ disabled }) =>
    !disabled &&
    css`
      border-radius: 5px;
      background-color: ${({ theme }) => theme.colors.black5};
      transition: opacity 0.2s ease-in-out;
    `}
  } */

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;
