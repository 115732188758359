import React, { ReactElement } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import ASlideLeft from 'presentation/base/images/ArrowSlideLeft.png';
import ASlideRight from 'presentation/base/images/ArrowSlideRight.png';
import { Container, ScrollSlide } from './styles/StyledScrollingCarousel';

interface SliderProps {
  children: any;
}

const ScrollingCarousel: React.FC<SliderProps> = ({
  children,
}: SliderProps) => {
  const SlickArrowLeft = (props: any) => (
    <ScrollSlide
      disable={props.className.includes('disable')}
      type="left"
      onClick={() => props.onClick && props.onClick()}
    >
      <img alt="" src={ASlideLeft} {...props} />
    </ScrollSlide>
  );

  const SlickArrowRight = (props: any) => (
    <ScrollSlide
      disable={props.className.includes('disable')}
      type="right"
      onClick={() => props.onClick && props.onClick()}
    >
      <img alt="" src={ASlideRight} {...props} />
    </ScrollSlide>
  );

  const settings = {
    slidesToShow: 2,
    slidesToScroll: 2,
    infinite: false,
    draggable: false,
    adaptiveHeight: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 3088,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 7,
        },
      },
      {
        breakpoint: 2702,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 2316,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1930,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1544,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1158,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 772,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
  };
  return (
    <Container>
      <Slider {...settings}>{children}</Slider>
    </Container>
  );
};

export default ScrollingCarousel;
