import { CreateLayout } from 'domain/usecases/layout/redux/CreateLayout';
import {
  iActionCreate,
  iActionCreateFailed,
  iActionCreateSuccess,
  LayoutTypes,
} from 'domain/interfaces/redux/layout/create';

export const createRequest = (payload: CreateLayout.Params): iActionCreate => ({
  type: LayoutTypes.CREATE,
  payload,
});

export const createSuccess = (
  params: CreateLayout.Model,
): iActionCreateSuccess => ({
  type: LayoutTypes.CREATE_SUCCESS,
  payload: params,
});

export const createFailed = (): iActionCreateFailed => ({
  type: LayoutTypes.CREATE_FAILED,
});
