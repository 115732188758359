/*eslint-disable spaced-comment*/
import styled, { css } from 'styled-components';
import {
  IconParticipant,
  HeadphoneIcon,
  MicIcon2 as MicrophoneIcon,
  MicMutedIcon as MutedMicrophoneIcon,
} from 'presentation/base/icons';

interface VirtualAudienceProps {
  isOpen?: boolean;
  isMinimal?: boolean;
  isActive?: boolean;
}

export const Provider = styled.div`
  display: flex;
  /* width: 100%;
  height: 100vh; */
  padding: 0 3rem;
  color: ${({ theme }) => theme.colors.white1};
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 2;

  .chats {
    margin-right: 11rem;
    display: flex;
    position: fixed;
    bottom: 0;
    right: 3rem;
    transition: all 2s ease-in-out;
  }
`;

export const ContainerModal = styled.div<VirtualAudienceProps>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 41.6rem;
  height: ${({ isOpen, isMinimal }) =>
    isOpen && !isMinimal ? '60.2rem' : '8rem'};
  background: ${({ theme, isOpen }) => !isOpen && theme.colors.black2};
  overflow: hidden;
  border-radius: 0.5rem 0.5rem 0 0;

  position: relative;
  bottom: 0;
  bottom: ${({ isOpen, isMinimal }) =>
    isOpen && !isMinimal ? '52.2rem' : 'auto'};

  /* right: 3rem; */
  transition: translate 2s ease-in-out;

  margin-bottom: 8rem;

  &.content {
    display: ${({ isOpen }) => (!isOpen ? 'block' : 'none')};
    height: auto;
  }

  ${({ isMinimal, isOpen }) =>
    isMinimal &&
    isOpen &&
    css`
      & .content {
        display: none;
        height: 0;
      }
      .icon-minus {
        background: transparent;
        border: 2px solid ${({ theme }) => theme.colors.white1};
        width: 14px;
        height: 14px;
      }
    `}
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Options = styled.div<VirtualAudienceProps>`
  display: flex;
  align-items: center;
  margin: 0 1rem 0 0;
  padding-bottom: 0.3rem;
  border-bottom: 3px solid
    ${({ isActive, theme }) =>
      isActive ? theme.colors.blue1 : theme.colors.black5};
  cursor: ${props => (props.isActive ? 'default' : 'pointer')};
  color: ${props =>
    props.isActive ? props.theme.colors.white1 : props.theme.colors.black5};
  &:hover {
    color: ${({ theme }) => theme.colors.white1};
    border-bottom: 3px solid ${({ theme }) => theme.colors.blue1};
  }
  &:active,
  :focus {
    color: ${({ theme }) => theme.colors.white1};
    border-bottom: 3px solid ${({ theme }) => theme.colors.blue1};
  }
`;

export const Content = styled.div.attrs({
  className: 'content',
})<VirtualAudienceProps>`
  display: flex;
  width: 41.6rem;
  height: 60.2rem;
  max-height: 60.2rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  background: ${({ theme }) => theme.colors.black2};
  padding: 2rem 1.5rem;
  padding-bottom: 0;
`;

export const ParticipantsList = styled.div`
  display: flex;
  width: 100%;
  height: 33.8rem;
  margin: 15px 0 25px 0;
  /* background: ${({ theme }) => theme.colors.blue1}; */
`;

export const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.5rem;
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    margin-right: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.black2};
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.white1};
    border-radius: 5px;
  }
  padding-right: 1rem;
`;

export const ParticipantItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px 0 10px;
`;

export const ParticipantsItemRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 11px;
`;

export const ParticipantsItemActions = styled.div`
  width: 50px;
  display: flex;
  justify-content: space-between;

  svg {
    cursor: pointer;
  }

  button {
    background: transparent;
    border: none;
  }
`;

export const ParticipantsProfile = styled(IconParticipant)`
  width: 3.4rem;
  height: 3.4rem;
`;

export const ParticipantsMic = styled(MicrophoneIcon)`
  width: 1.8rem;
  height: 1.8rem;
`;

export const ParticipantsMutedMic = styled(MutedMicrophoneIcon)`
  width: 1.8rem;
  height: 1.8rem;
`;

export const ParticipantsHeadphone = styled(HeadphoneIcon)`
  width: 1.8rem;
  height: 1.8rem;
`;

export const ParticipantsMoreOptions = styled.div`
  width: 153px;
  padding: 15px 10px;
  background: #05060c;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  border-radius: 5px;

  img {
    margin-right: 5px;
  }

  button {
    background: transparent;
    color: white;
    border: none;
  }

  .opTop {
    cursor: pointer;
  }

  .line {
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.08);
  }
  .opDown {
    margin-top: 15px;
    cursor: pointer;
  }
`;

export const Footer = styled.div`
  bottom: 0;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.black5};
  color: white;
  align-items: center;
  justify-content: center;
`;

export const RowButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 1.4rem;
`;

//VIRTUAL AUDIENCE HEADER

export const Header = styled.div`
  width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  background: ${({ theme }) => theme.colors.black2};
  border-radius: 5px 5px 0 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black5};
`;

export const InfoContainer = styled.div`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
`;

export const HeaderTitle = styled.h4`
  font: ${({ theme }) => theme.typography.h4};
  text-align: left;
  color: ${({ theme }) => theme.colors.white1};
`;

export const RoomName = styled.p`
  font: ${({ theme }) => theme.typography.h7};
  color: ${({ theme }) => theme.colors.white1};
  text-align: left;
  margin: 0;
  width: 100%;
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 20%;

  svg path {
    fill: ${({ theme }) => theme.colors.white1};
    cursor: pointer;
  }
`;

export const IconMinus = styled.div.attrs({
  className: 'icon-minus',
})`
  height: 2px;
  background: ${({ theme }) => theme.colors.white1};
  width: 1.2rem;
  cursor: pointer;
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  svg {
    margin-right: 1.5rem;
  }
`;
