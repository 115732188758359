import { call, put, select } from 'redux-saga/effects';
import { iActionDelete } from 'domain/interfaces/redux/reaction/delete';
import { DeleteReaction } from 'domain/usecases/reaction/remote/DeleteReaction';
import { makeRemoteDeleteReaction } from 'main/factories/usecases/reaction/DeleteReactionFactory';
import {
  deleteSuccess,
  deleteFailed,
} from 'data/store/reducer/reaction/actions/delete';

export function* onDelete(action: iActionDelete) {
  const remoteDeleteReaction = makeRemoteDeleteReaction();

  try {
    const response: DeleteReaction.Model = yield call(
      remoteDeleteReaction.delete,
      action.payload,
    );
    yield put(
      deleteSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(deleteFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onDeleteSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onDeleteFailed() {}
