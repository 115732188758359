/* eslint-disable @typescript-eslint/no-empty-function */
import { iStore } from 'domain/interfaces/models';
import { iEventItem } from 'domain/interfaces/models/Event';
import bannerBeta from 'presentation/base/images/bannerBeta.png';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { formattedDate } from 'utils/formattedDate';
import { RoomContext } from 'presentation/contexts/RoomContext';
import { SpotsInfo } from 'data/store/ducks/spotsInfo/types';
import PublicChat from '../Chat/PublicChat';
import TabNavigation from '../TabNavigation';
import About from './about';
import { Participants } from './participants';
import { Banner, Container } from './styles';

interface SideBarInfoEventProps {
  publicChat: any;
  newMessage: any;
  rooms: any[];
  renderLayouts?: any;
  handleNewMessageAdded: () => void;
  participants?: SpotsInfo[];
}

export interface iParams {
  id: string;
  roomId: string;
}

export type Event = {
  id: number;
  name: string;
  descr: string;
  date: string;
  tags: string[];
  type: string;
  category: string;
  subCategory: string;
  organization: string;
};

const mockEvent: iEventItem = {
  id: 9999,
  created: new Date().toLocaleDateString(),
  updated: new Date().toLocaleDateString(),
  short: 'Short Demo',
  name: 'Demo',
  organization: 'Org Demo',
  external: 'https://www.google.com',
  media: 'Midia Demo',
  descr: 'Descr Demo',
  keywords: ['keyword1', 'keyword2'],
  competitive: false,
  thumbnail: 'https://i.imgur.com/J5LVg76.png',
  duration: 90,
  invitationLink: 'https://www.google.com',
  joinPolicy: 'PUBLIC',
  listPolicy: 'PUBLIC',
  paymentPolicy: 'FREE',
  schedule: new Date().toLocaleDateString(),
  category: {
    id: 1,
    descr: 'Sport',
    name: 'Sport',
  },
  contentType: {
    id: 1,
    descr: 'Sport',
    name: 'Sport',
  },
  layout: {
    id: 1,
    name: 'Layout 1',
    descr: 'Layout 1',
    competitive: false,
    seats: 50,
  },
  mediaType: 'Test',
  rooms: 120,
};

export const DemoSideBarInfoEvent: React.FC<SideBarInfoEventProps> = ({
  publicChat,
  newMessage,
  rooms,
  renderLayouts,
  handleNewMessageAdded,
  participants,
}) => {
  const [active, setActive] = useState<number>(0);

  const { isFullScreen, isMobile } = useContext(RoomContext);

  const options = useMemo(
    () => [
      { label: 'Mapa Interativo' },
      { label: 'Sobre o evento' },
      { label: 'Bate-papo ao vivo' },
      { label: 'Participantes' },
      { label: 'Linha do tempo', disabled: true },
    ],
    [],
  );

  const [selectedEvent, setSelectedEvent] = useState<iEventItem>(mockEvent);

  const { id }: iParams = useParams();

  const { results: events } = useSelector((store: iStore) => store.event);
  const { results: categories } = useSelector(
    (store: iStore) => store.category,
  );

  const eventType = useMemo(() => {
    return selectedEvent?.contentType?.name;
  }, [selectedEvent]);

  const eventDate = useMemo(() => {
    return selectedEvent?.schedule
      ? formattedDate(
          new Date(selectedEvent?.schedule),
          selectedEvent?.duration,
        )
      : 'Jan 1,2022 - 00h00';
  }, [selectedEvent]);

  const eventTags = useMemo(() => {
    return selectedEvent?.keywords?.map(item => {
      return item;
    });
  }, [selectedEvent]);

  const tabs = [
    <ScrollContainer
      className="scroll-container"
      horizontal
      style={{ margin: '0 2rem' }}
    >
      {renderLayouts}
    </ScrollContainer>,
    <About
      event={{
        ...selectedEvent,
        tags: eventTags ?? [],
        category: selectedEvent?.category?.name || '--',
        subCategory: selectedEvent?.subCategories?.[0]?.subCategory ?? '--',
        date: eventDate,
        type: eventType ?? '',
      }}
    />,
    <PublicChat
      chat={publicChat}
      key={publicChat?.id}
      chatId={publicChat?.id}
      handleNewMessageAdded={handleNewMessageAdded}
      newMessage={newMessage}
    />,
    <Participants participants={participants} />,
    <div style={{ color: '#fff' }}>Linha do tempo</div>,
  ];

  return !isMobile ? (
    <Container isFullScreen={isFullScreen}>
      <Banner
        src={selectedEvent?.banner ?? bannerBeta}
        alt={`Banner do evento: ${selectedEvent?.name}`}
      />
      <TabNavigation
        options={isFullScreen ? options : options.slice(1, options.length)}
        active={active}
        setState={setActive}
      />

      {isFullScreen ? tabs[active] : tabs.slice(1, tabs.length)[active]}
    </Container>
  ) : null;
};

export default DemoSideBarInfoEvent;
