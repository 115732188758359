import { defaultTheme } from 'presentation/base/themes';
import { iOption } from '../../UI/Select';

export const labels: iOption[] = [
  {
    label: 'ACESSE',
    value: 'ACESSE',
  },
  {
    label: 'SAIBA MAIS',
    value: 'SAIBA MAIS',
  },
  {
    label: 'APOSTAR',
    value: 'APOSTAR',
  },
  {
    label: 'COMPRAR',
    value: 'COMPRAR',
  },
];

export const colors: Array<keyof typeof defaultTheme.colors> = [
  'white2',
  'white5',
  'blue2',
  'green2',
  'pink1',
  'red1',
  'purple1',
];
