import React, { useState, useCallback, useEffect } from 'react';
import { IconChangeRoom, CloseIcon } from 'presentation/base/icons';
import { useHistory } from 'react-router-dom';

import { iMessage, iStore } from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';

import Layout from 'presentation/pages/Room/layouts';

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getSpatialization } from 'utils/getSpatialization';
import useWindowDimensions from 'presentation/hooks/useWindowDimensions';
import { iEventItem } from 'domain/interfaces/models/Event';
import GridRoom from '../GridRoom';
import { Container as GlobalContainer } from '../messages/Background';
import {
  Container,
  Content,
  Header,
  Title,
  CloseButton,
  Rooms,
  Room,
  FeedBack,
  RoomsContainer,
  RoomDiv,
} from './styles';

interface propsChangeRoomModal {
  message: iMessage;
}

interface iAdjOccupation {
  id: number;
  occupation: number;
}

const ChangeRoomModal: React.FC<propsChangeRoomModal> = ({ message }) => {
  const msgName = MessageOptions.changeRoomModal;

  const history = useHistory();
  const { wrtc, spotsInfo } = useSelector((state: iStore) => state);

  const splitRoomName = window.location.pathname.split('/');

  const roomName = splitRoomName[splitRoomName.length - 1];
  const eventId = splitRoomName[2];

  const { active, actionOk, actionCancel } = message;

  const [rooms, setRooms] = useState<Array<any>>([]);
  const [roomsAdj, setRoomsAdj] = useState<Array<any>>([]);
  const [layoutId, setLayoutId] = useState<number>(1);
  const [layoutSize, setLayoutSize] = useState<string>('');
  const [adjOccupation, setAdjOccupation] = useState<Array<iAdjOccupation>>([]);

  const between = useCallback((min, max) => {
    return Math.floor(Math.random() * (max - min) + min);
  }, []);

  const shuffle = useCallback((array: any[]) => {
    let m = array.length;
    let t;
    let i;

    while (m > 0) {
      // eslint-disable-next-line no-plusplus
      i = Math.floor(Math.random() * m--);
      t = array[m];
      array[m] = array[i];
      array[i] = t;
    }

    return array;
  }, []);

  const getRandomPeerId = useCallback((): number => {
    const random = Math.floor(Math.random() * 2);

    // Do not render
    if (random === 0) {
      return -1;
    }

    // Render any
    return between(0, 120);
  }, [between]);

  // eslint-disable-next-line consistent-return
  const getRandomPositionArray = useCallback(
    (targetArray: Array<any>, qty: number): Array<any> => {
      let randomlyAdded = 0;

      const updatedArray = targetArray.map(item => {
        if (randomlyAdded >= qty) return item;

        const updatedPeerId = getRandomPeerId();

        // eslint-disable-next-line no-plusplus
        if (updatedPeerId !== -1) randomlyAdded++;

        return {
          ...item,
          peerId: updatedPeerId,
        };
      });

      return qty <= 20 ? shuffle(updatedArray) : updatedArray;
    },
    [getRandomPeerId, shuffle],
  );

  const renderHexagons = useCallback(async () => {
    const array = [];
    const flag = true;

    // eslint-disable-next-line no-plusplus
    for (let letter = 0; letter < 10; letter++) {
      // eslint-disable-next-line no-plusplus
      for (let number = 0; number < 22; number++) {
        const object = {
          // eslint-disable-next-line prefer-template
          id: 10 * letter + number,
          peerId: -1,
          peerName: '',
          x: getSpatialization(layoutId, number, letter),
          y: letter,
          label: '',
          state: 'normal',
          type: 'normal',
          disabled: false,
          actualUser: false,
          muted: false,
        };

        array.push(object);
      }
    }

    setRooms(array);
  }, [layoutId]);

  const renderRooms = useCallback(() => {
    const array: any = [];
    const idRoom = Number(roomName);

    const adj = {
      topLeft: idRoom - 16,
      top: idRoom - 15,
      topRight: idRoom - 14,
      left: idRoom - 1,
      center: idRoom,
      right: idRoom + 1,
      bottomLeft: idRoom + 14,
      bottom: idRoom + 15,
      bottomRight: idRoom + 16,
    };

    Object.values(adj).forEach(item => {
      const occupancy =
        adjOccupation?.find(occupancyItem => occupancyItem.id === item)
          ?.occupation ?? 0;

      const object = {
        id: item,
        peerId: -1,
        state: 'normal',
        label: item <= 0 || item > 150 ? 'Indisponível' : item,
        type: 'normal',
        disabled: item > 150 || item <= 0, // TODO: check where is rooms max id (150)
        actualUser: false,
        muted: false,
        isCenter: item === idRoom,
        rooms: getRandomPositionArray(rooms, occupancy),
      };

      array.push(object);
    });

    setRoomsAdj(array);
  }, [roomName, adjOccupation, getRandomPositionArray, rooms]);

  useEffect(() => {
    renderHexagons();
  }, [renderHexagons]);

  useEffect(() => {
    renderRooms();
  }, [renderRooms]);

  useEffect(() => {
    setLayoutId(message.componentProps?.changeRoom?.layout || 1);
  }, [message]);

  const { widthBreakPointSquareModal } = useWindowDimensions();
  useEffect(() => {
    return setLayoutSize(widthBreakPointSquareModal as string);
  }, [widthBreakPointSquareModal]);

  useEffect(() => {
    if (!eventId || msgName !== active) return;

    wrtc?.requestRoomsOccupancies?.(
      `floor_${eventId}`,
      0,
      res => {
        const updatedAdjOccupation = res?.rooms?.map(
          (item: { name: string; occup: number }) => ({
            id: Number(item.name.split('_')[1]) + 1,
            occupation: item.occup,
          }),
        );

        setAdjOccupation(updatedAdjOccupation);
      },
      err => {
        console.log('Request occupancies err: ', err);
      },
    );
  }, [eventId, wrtc, active, msgName]);

  return (
    <>
      {msgName === active ? (
        <GlobalContainer style={{ background: 'rgba(18, 20, 30, 0.99)' }}>
          <Container>
            <Content>
              <Header>
                <Title>Trocar de sala</Title>
                <CloseButton>
                  <CloseIcon
                    style={{ cursor: 'pointer' }}
                    onClick={actionCancel}
                  />
                </CloseButton>
              </Header>
              <RoomsContainer>
                <Rooms containerSize={layoutSize}>
                  {/* {TODO: Verificar a chamada do actionOk e actionCancel pois está fazendo o modal não abrir} */}
                  {/* <div> */}
                  {/* <GridRoom
                    size="5rem"
                    rooms={rooms}
                    numCol={3}
                    handleClick={item => {
                      if (actionOk) actionOk();

                      if (!item.disabled && String(item.label) !== roomName) {
                        history.push(`/rooms/${eventId}/room/${item.label}`);
                        window.location.reload();
                      }
                    }}
                  /> */}
                  {/* <RoomLayout rooms={rooms} layout={2} /> */}

                  {roomsAdj?.map(room => (
                    <RoomDiv key={room.label} isCentered={room.isCenter}>
                      <Room
                        // eslint-disable-next-line consistent-return
                        onClick={() => {
                          if (room.isCenter) return actionCancel?.();
                          if (
                            !room.disabled &&
                            String(room.label) !== roomName
                          ) {
                            history.push(`/rooms/${eventId}/room/${room.id}`);
                            window.location.reload();
                          } else {
                            toast.error('Sala indisponível');
                          }
                        }}
                      >
                        <Layout
                          handleClick={() => {
                            return 0;
                          }}
                          rooms={room.isCenter ? rooms : room?.rooms}
                          speakingPeerIds={[]}
                          selectedEvent={{} as iEventItem}
                          reactionsButtons={[]}
                          displayedLabelsByMap={[]}
                          spotsInfo={spotsInfo}
                          displayedUsersByMap={[]}
                          roomName={room.label}
                          layout={layoutId}
                          hasPlayer
                          layoutSize={layoutSize}
                          // eslint-disable-next-line react/jsx-no-bind
                          handleChangeRoomModal={(): void => {
                            throw new Error('Function not implemented.');
                          }}
                          isCentered={room.isCenter}
                          isChangingRoom
                          isRoomsPage={false}
                        />
                      </Room>
                    </RoomDiv>
                  ))}
                  {/* </div> */}
                </Rooms>
              </RoomsContainer>
              <FeedBack>
                Pressione Esc para sair deste modo de visualização
              </FeedBack>
            </Content>
          </Container>
        </GlobalContainer>
      ) : undefined}
    </>
  );
};

export default ChangeRoomModal;
