import { GetAllEvent } from 'domain/usecases/event/redux/GetAllEvent';
import {
  iActionGetAll,
  iActionGetAllFailed,
  iActionGetAllSuccess,
  EventTypes,
} from 'domain/interfaces/redux/event/getAll';

export const getAllRequest = (payload: GetAllEvent.Params): iActionGetAll => ({
  type: EventTypes.GETALL,
  payload,
});

export const getAllSuccess = (
  params: GetAllEvent.Model,
): iActionGetAllSuccess => ({
  type: EventTypes.GETALL_SUCCESS,
  payload: params,
});

export const getAllFailed = (): iActionGetAllFailed => ({
  type: EventTypes.GETALL_FAILED,
});
