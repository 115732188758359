import styled from 'styled-components';
import media from 'utils/mediaqueries';

interface iMobileContentProps {
  isDisplayed: boolean;
}
export const Container = styled.div``;

export const MobileContentContainer = styled.div<iMobileContentProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ isDisplayed }) => (isDisplayed ? '25%' : '0')};
  background-color: ${({ theme }) => theme.colors.black2};
  max-height: 366px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  transition: ${({ isDisplayed }) =>
    isDisplayed ? 'height 0.400s ease-in' : 'height 0.400s ease-out'};

  ${media.lessThan('sm')} {
    height: ${({ isDisplayed }) => (isDisplayed ? '366px' : '0')};
  }
`;
