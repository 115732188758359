import React, { cloneElement, ReactElement } from 'react';
import { Container } from './styles';

interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: React.ReactElement;
  // create a prop conditionally required

  onClick?: () => void;
}

const IconButton: React.FC<IconButtonProps> = ({ icon, onClick, ...props }) => {
  const classNameIcon = icon.props.className;

  return (
    <Container onClick={onClick} {...props}>
      {cloneElement(icon as ReactElement, {
        className: `icon ${classNameIcon}`,
      })}
    </Container>
  );
};

export default IconButton;
