// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import {
  signInWithPopup,
  signInWithRedirect,
  signOut,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  getAuth,
  User,
} from 'firebase/auth';
import { toast } from 'react-toastify';
import { makeRemoteLoginWithGoogle } from 'main/factories/usecases/auth/LoginWithGoogleFactory';
import { AlertMessage } from 'presentation/components/messages/AlertMessage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth();
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const twitterProvider = new TwitterAuthProvider();

const getProvider = (type: 'google' | 'facebook' | 'twitter') => {
  switch (type) {
    case 'google':
      return GoogleAuthProvider;
    case 'facebook':
      return FacebookAuthProvider;
    case 'twitter':
      return TwitterAuthProvider;
    default:
      return GoogleAuthProvider;
  }
};

const getProviderInstance = (
  type: 'google' | 'facebook' | 'twitter',
): GoogleAuthProvider | FacebookAuthProvider | TwitterAuthProvider => {
  switch (type) {
    case 'google':
      return googleProvider;
    case 'facebook':
      return facebookProvider;
    case 'twitter':
      return twitterProvider;
    default:
      return googleProvider;
  }
};

type LoginSocialResponse = {
  user: User;
  token?: string;
};

export async function LoginSocial(
  type: 'google' | 'facebook' | 'twitter',
): Promise<any> {
  const provider = getProvider(type);
  const providerInstance = getProviderInstance(type);

  console.log('estou no loginSocial', provider, providerInstance);

  try {
    const { loginWithGoogle } = makeRemoteLoginWithGoogle();

    const result = await signInWithPopup(auth, providerInstance);
    const { user } = result;

    const credential = provider.credentialFromResult(result);
    const token = credential?.idToken;

    // const loginSocialResponse: LoginSocialResponse = {
    //   user,
    //   token,
    // };

    if (token) {
      const userResponse = await loginWithGoogle({
        token,
        type: type.toLocaleUpperCase(),
      });

      console.log('userResponse', userResponse);
      return userResponse;
    }
  } catch {
    AlertMessage({ message: 'Erro ao realizar login', type: 'danger' });
  }
  return {} as User;
}

export async function Logout(): Promise<void> {
  await signOut(auth).then(response => toast.success('Deslogado'));
}

export default app;
