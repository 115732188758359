import styled, { css } from 'styled-components';
import media from 'utils/mediaqueries';

import Slider from '@mui/material/Slider';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1.2rem 1.6rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
`;

export const TitleContent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Title = styled.h1`
  font-size: 1.8rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white1};
`;

interface boxProps {
  disabled?: boolean;
}

export const Box = styled.div<boxProps>`
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  background: ${({ theme }) => theme.colors.black3};
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.3s;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      background: ${({ theme }) => theme.colors.black1};

      svg path {
        fill: ${({ theme }) => theme.colors.white4};
      }
    `}
`;

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 80%;
  padding: 24px;
`;

export const VolumeTitle = styled.h1`
  font-size: 1.4rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white2};

  margin-bottom: 8px;
`;

export const VolumeContentText = styled.h1`
  font-size: 1.4rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white2};
`;

export const VolumeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
`;

export const TransmissionVolumeSlider = styled(Slider)`
  .MuiSlider-track {
    background-color: #5cdfbf;
  }

  .MuiSlider-thumb {
    height: 19px;
    width: 19px;
    background-color: #fff;
  }
`;

export const PublicVolumeSlider = styled(Slider)`
  .MuiSlider-track {
    background-color: #5667f6;
  }

  .MuiSlider-thumb {
    height: 19px;
    width: 19px;
    background-color: #fff;
  }
`;
