import { CreateCategory } from 'domain/usecases/category/redux/CreateCategory';
import {
  iActionCreate,
  iActionCreateFailed,
  iActionCreateSuccess,
  CategoryTypes,
} from 'domain/interfaces/redux/category/create';

export const createRequest = (
  payload: CreateCategory.Params,
): iActionCreate => ({
  type: CategoryTypes.CREATE,
  payload,
});

export const createSuccess = (
  params: CreateCategory.Model,
): iActionCreateSuccess => ({
  type: CategoryTypes.CREATE_SUCCESS,
  payload: params,
});

export const createFailed = (): iActionCreateFailed => ({
  type: CategoryTypes.CREATE_FAILED,
});
