import { iAuth } from 'domain/interfaces/models';
import { AuthTypes } from 'domain/interfaces/redux/auth/types';
import { AuthActions } from './actions';

export const initialState: iAuth = {
  loading: false,
  user: {},
  refreshTokenExpiration: '',
  refreshToken: '',
  accessTokenExpiration: '',
  accessToken: '',
  wrtcInfo: {},
};

const reducer = (state = initialState, action: AuthActions): iAuth => {
  switch (action.type) {
    case AuthTypes.LOGIN:
      return { ...state, loading: true };
    case AuthTypes.LOGIN_SUCCESS:
      return { ...state, ...action.payload, loading: false };
    case AuthTypes.LOGIN_FAILED:
      return { ...state, loading: false };
    case AuthTypes.UPDATE_INFO:
      return { ...state, user: { ...state.user, ...action.payload } };
    case AuthTypes.UPDATE_WRTC_INFO:
      return { ...state, wrtcInfo: { ...state.wrtcInfo, ...action.payload } };
    case AuthTypes.LOGOUT:
      if (window.location.pathname.includes('/room/')) {
        setTimeout(() => {
          window.location.href = '/';
        }, 500);
      }

      return initialState;
    default:
      return state;
  }
};

export default reducer;
