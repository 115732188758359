/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { iStore } from 'domain/interfaces/models';
import { iEventItem } from 'domain/interfaces/models/Event';
import { makeReduxUpdateWRTCInfo } from 'main/factories/usecases/auth/UpdateWRTCInfoFactory';
import { makeReduxGetAllCategory } from 'main/factories/usecases/category/GetAllCategoryFactory';
import { makeReduxGetAllEvent } from 'main/factories/usecases/event/GetAllEventFactory';
import { makeReduxUpdateWrtc } from 'main/factories/usecases/wrtcClient/UpdateFactory';
import imgSports from 'presentation/base/images/imgSports.png';
import EventSmallPreview from 'presentation/components/EventSmallPreview';
import { WRTCClient } from 'services/wrtccli/netfans-wrtc-wrapper.js';
import ScrollingCarousel from 'presentation/components/ScrollingCarousel';
import _ from 'lodash';
import CategoryBackground from '../CategoryBackground';
import Header from '../Header';
import { translator } from '../i18n';
import {
  CarouselExample2,
  Category,
  CategoryBar,
  CategoryTitle,
  Container,
} from './styles';

const SPORTS_CATEGORY_ID = 1;

const SportsPage: React.FC = () => {
  const [hover, setHover] = useState(false);

  const wrtc = useSelector((store: iStore) => store.wrtc);
  const { wrtcInfo, user } = useSelector((store: iStore) => store.auth);
  const { results, loading } = useSelector((store: iStore) => store.event);
  const { results: categoryResults } = useSelector(
    (store: iStore) => store.category,
  );

  const [events, setEvents] = useState<iEventItem[]>([]);

  const history = useHistory();

  useEffect(() => {
    makeReduxGetAllEvent().getAll({
      limit: 9999,
    });
    makeReduxGetAllCategory().getAll({});
    makeReduxUpdateWRTCInfo().update({ insideRoom: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!results) return;

    const sportsEvents = results?.filter(
      event => event.contentType.id === SPORTS_CATEGORY_ID,
    );

    setEvents(sportsEvents);
  }, [results]);

  useEffect(() => {
    const notConnectedToWRTC =
      (!wrtcInfo?.peerId || wrtcInfo?.peerId === -1) && user?.email;

    if (!wrtc.connect) {
      // Se o usuário estiver autenticado e não houver WRTC, instanciar WRTC e conectar
      const client = new WRTCClient(window.config.clientConfig);

      const loginSuccess = () => {
        const peerId = client.getLocalPeersId();
        if (peerId) makeReduxUpdateWRTCInfo().update({ peerId });
      };

      const loginFailed = (cause?: any) => {
        toast(
          'Não foi possível estabelecer comunicação com o servidor, tente novamente mais tarde',
          { type: 'error' },
        );
        client.disconnect();
      };

      client.onconnect = (name: string) => {
        client.loginPeer(name, loginSuccess, loginFailed);
      };

      if (notConnectedToWRTC) {
        makeReduxUpdateWrtc().update(client);
        try {
          client.connect(`${user.firstName} ${user.lastName}:${user.id}`);
        } catch (e) {
          console.log(e);
        }
      } else {
        makeReduxUpdateWrtc().update(client);
      }
    }

    if (wrtc.connect && notConnectedToWRTC) {
      try {
        wrtc.connect(`${user.firstName} ${user.lastName}:${user.id}`);
      } catch (e) {
        console.log(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrtc, user, wrtcInfo, history]);

  useEffect(() => {
    // Desconectar o usuário de qualquer sala quando o mesmo estiver na seleção de páginas
    if (wrtc.leaveRoom && wrtc.leaveFloor) {
      makeReduxUpdateWRTCInfo().update({ insideRoom: false });
      wrtc.leaveRoom();
      wrtc.leaveFloor();
    }
  }, [wrtc]);

  const groupedEvents = useMemo(
    () =>
      _.groupBy(events, item => {
        return item?.subCategories?.[0]?.name ?? 'Esportes';
      }),
    [events],
  );

  const selectedCategory = useMemo(
    () => categoryResults?.find(category => category.id === SPORTS_CATEGORY_ID),
    [categoryResults],
  );

  return (
    <Container>
      <Header />
      <CategoryBackground
        image={imgSports}
        title={selectedCategory?.name.toLocaleUpperCase() ?? 'ESPORTES'}
        description={`${
          // selectedCategory?.descr ??
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer porta malesuada luctus. Curabitur placerat odio sit amet arcu ultrices luctus. Nam volutpat sit amet sapien in aliquam. Donec pulvinar ornare felis, eget eleifend dolor fermentum sed. Mauris venenatis diam odio, ut dignissim tortor vulputate ac. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer porta malesuada luctus.'
        }`}
      />
      {Object.entries(groupedEvents)?.map(item => (
        <>
          <Category>
            <CategoryBar type="Esportes" />
            <CategoryTitle>{item?.[0]}</CategoryTitle>
          </Category>
          <CarouselExample2 isHovering={hover}>
            <ScrollingCarousel>
              {item?.[1]?.map(event => {
                return (
                  <EventSmallPreview
                    skeleton={loading}
                    key={event.id}
                    className="item"
                    handleHover={setHover}
                    image={event.thumbnail}
                    event={event}
                  />
                );
              })}
            </ScrollingCarousel>
          </CarouselExample2>
        </>
      ))}
    </Container>
  );
};
export default SportsPage;
