import styled, { css } from 'styled-components';
import media from 'utils/mediaqueries';

export const Container = styled.div`
  width: 280px;
  height: 100%;
  /* height: 83rem; */

  background-color: ${({ theme }) => theme.colors.black2};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 0px;

  ${media.lessThan('sm')} {
    width: 250px;
    padding: 20px 0px;
    height: 100%;
  }
`;

export const Avatar = styled.button`
  border: none;
  outline: none;
  background-color: transparent;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 500;
  font-family: 'Poppins';
  gap: 1rem;

  .info {
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    display: flex;
    align-items: start;
    text-align: start;

    /* white */

    color: #ffffff;

    .name {
      display: flex;
      justify-content: start;
    }

    .values {
      width: 150px;
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .value {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: #ffffff;
      opacity: 0.55;
      margin-right: 5px;
    }
  }
`;

export const UserName = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.1rem;

  color: ${({ theme }) => theme.colors.white1};

  margin-left: 1rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 30px;

  ${media.lessThan('sm')} {
    width: 250px;
    padding: 0px 20px;
    display: flex;
  }
`;

export const Main = styled.div`
  display: flex;
  padding: 0px 30px;
  margin-bottom: 30px;

  ${media.lessThan('sm')} {
    padding: 0px 20px;
    margin-bottom: 0px;
    margin-top: 20px;
  }
`;

export const RightSpace = styled.div`
  display: flex;
  flex-direction: column;

  gap: 0.7rem;

  margin-right: 1.5rem;
`;

export const LeftSpace = styled.div`
  display: flex;
  flex-direction: column;

  gap: 0.7rem;

  margin-left: 1.5rem;
`;

export const AvatarStyle = styled.div`
  width: 220px;
  height: 220px;

  background-color: ${({ theme }) => theme.colors.black5};

  border-radius: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  ${media.lessThan('sm')} {
    width: 210px;
    height: 210px;
  }
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: none;
  align-items: center;
  color: #ffffff;
  opacity: 0.5;
  margin: 20px 0px 25px 0px;

  ${media.lessThan('sm')} {
    padding: 0px 20px;
    display: flex;
  }
`;

export const Border = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.08);

  margin: 15px 30px 20px 30px;

  ${media.lessThan('sm')} {
    margin: 15px 20px 20px 20px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 1.5rem; */
`;

export const Type = styled.div`
  width: 3.4rem;
  height: 3.4rem;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.black5};
  border-radius: 5px;
`;

interface iOptions {
  disabled?: boolean;
}

export const Options = styled.div<iOptions>`
  display: flex;
  align-items: center;

  cursor: pointer;
  padding: 10px 30px;

  &:hover {
    background: ${({ theme }) => theme.colors.black3};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.3;

      &:hover {
        background: transparent;
      }
    `}

  ${media.lessThan('sm')} {
    padding: 10px 20px;
  }
`;

export const Text = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 21px;

  color: ${({ theme }) => theme.colors.white1};

  margin-left: 1rem;
`;

export const Footer = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white1};
  opacity: 0.25;
  margin-top: 25px;
  padding: 0px 30px;

  ${media.lessThan('sm')} {
    padding: 0px 20px;
  }
`;
