import styled from 'styled-components';
import media from 'utils/mediaqueries';

const timeToAnimate = '0.5s';

export const Container = styled.button`
  position: fixed;
  bottom: 2.4rem;
  right: 2.4rem;
  z-index: 10;
  width: fit-content;
  height: 4.8rem;
  padding: 1.6rem;
  border-radius: 5rem;
  background: ${({ theme }) => theme.colors.black2};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  border: none;

  cursor: pointer;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  transition: all ${timeToAnimate};

  ${media.lessThan('xs')} {
    overflow: visible;
    width: 4.8rem;
  }

  & p.label-float-button {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2.2rem;
    color: ${({ theme }) => theme.colors.white2};
    margin: 0;
    /* display: none; */
    white-space: nowrap;

    margin-left: 1.2rem;
    transition: all ${timeToAnimate};

    ${media.lessThan('xs')} {
      display: block;
      transform: translateX(0);
    }
  }

  & svg.icon-float-button {
    min-width: 1.6rem;
    min-height: 1.6rem;

    transition: all ${timeToAnimate};
  }
  &:hover {
    width: fit-content;
    transform: scale(1.1);
    & svg.icon-float-button {
      transform: rotate(180deg);
    }

    & p.label-float-button {
      transform: translateX(0);
    }
  }

  ${media.lessThan('xs')} {
    & p.label-float-button {
      display: none;
    }
    &:hover {
      & svg.icon-float-button {
        margin-right: 0;
      }
    }
  }
`;
