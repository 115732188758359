import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: safe center;
  color: ${({ theme }) => theme.colors.white1};
  background: ${({ theme }) => theme.colors.black1};
  justify-content: center;
`;

export const Header = styled.header`
  width: 100%;
  /* height: 100%; */
  background: ${({ theme }) => theme.colors.black2};
  border-radius: 1rem 1rem 0 0;
  padding: 0 1.6rem;
  display: flex;
  align-items: flex-end;
  justify-content: start;

  /* max-height: 5.4rem; */
`;
