import React, {
  memo,
  TextareaHTMLAttributes,
  useEffect,
  useState,
} from 'react';
import { IconSend, IconSticker } from 'presentation/base/icons';
import { InputButton, InputContainer, InputStyled } from './styles';

interface InputChatProps
  extends TextareaHTMLAttributes<Omit<HTMLTextAreaElement, 'className'>> {
  onSend: (text: string) => void;
  withoutSticker?: boolean;
  IconCustom?: any;
  resetField?: boolean;
}

const InputChat: React.FC<InputChatProps> = ({
  onSend,
  withoutSticker = false,
  IconCustom,
  resetField = true,
  ...rest
}) => {
  const [text, setText] = useState('');
  const [isMultipleLine, setIsMultipleLine] = useState<boolean>(false);

  const textAreaRef = React.createRef<HTMLTextAreaElement>();

  const handleSend = () => {
    if (text.trim() === '' && resetField) return;
    onSend?.(text);
    if (resetField) setText('');
    textAreaRef?.current?.focus();
  };

  useEffect(() => {
    if (textAreaRef?.current) {
      const maxHeight = 90;
      const height = textAreaRef.current.scrollHeight;

      if (height > maxHeight) {
        textAreaRef.current.style.minHeight = `${maxHeight}px`;
        textAreaRef.current.style.overflowY = 'scroll';
        setIsMultipleLine(true);
      } else {
        textAreaRef.current.style.minHeight = `${height}px`;
        if (height > 36) setIsMultipleLine(true);
        else {
          textAreaRef.current.style.overflowY = 'hidden';
          setIsMultipleLine(false);
        }
      }
      if (text === '' && height > 36) {
        textAreaRef.current.style.minHeight = '36px';
        textAreaRef.current.style.overflowY = 'hidden';
        setIsMultipleLine(false);
      }
    }
  }, [text, textAreaRef]);

  return (
    <InputContainer
      isMultipleLine={isMultipleLine}
      withoutSticker={withoutSticker}
    >
      {!withoutSticker && (
        <InputButton type="button" tabIndex={0} disabled={rest.disabled}>
          <IconSticker width={15} fontSize={15} />
        </InputButton>
      )}
      <InputStyled
        className="text-area"
        ref={textAreaRef}
        rows={1}
        onChange={e => setText(e.target.value)}
        value={text}
        onKeyDownCapture={e => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend?.();
          }
        }}
        {...rest}
      />
      <InputButton
        type="reset"
        onClick={handleSend}
        placement="right"
        disabled={rest.disabled || (text === '' && resetField)}
      >
        {IconCustom ? <IconCustom /> : <IconSend />}
      </InputButton>
    </InputContainer>
  );
};

export default memo(InputChat);
