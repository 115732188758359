import { call, put, select } from 'redux-saga/effects';
import { iActionCreate } from 'domain/interfaces/redux/reaction/create';
import { CreateReaction } from 'domain/usecases/reaction/remote/CreateReaction';
import { makeRemoteCreateReaction } from 'main/factories/usecases/reaction/CreateReactionFactory';
import {
  createSuccess,
  createFailed,
} from 'data/store/reducer/reaction/actions/create';

export function* onCreate(action: iActionCreate) {
  const remoteCreateReaction = makeRemoteCreateReaction();

  try {
    const response: CreateReaction.Model = yield call(
      remoteCreateReaction.create,
      action.payload,
    );
    yield put(
      createSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(createFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onCreateSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onCreateFailed() {}
