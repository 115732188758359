import { call, put, select } from 'redux-saga/effects';
import { iActionGetById } from 'domain/interfaces/redux/reaction/getById';
import { GetByIdReaction } from 'domain/usecases/reaction/remote/GetByIdReaction';
import { makeRemoteGetByIdReaction } from 'main/factories/usecases/reaction/GetByIdReactionFactory';
import {
  getByIdSuccess,
  getByIdFailed,
} from 'data/store/reducer/reaction/actions/getById';

export function* onGetById(action: iActionGetById) {
  const remoteGetByIdReaction = makeRemoteGetByIdReaction();

  try {
    const response: GetByIdReaction.Model = yield call(
      remoteGetByIdReaction.getById,
      action.payload,
    );
    yield put(
      getByIdSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(getByIdFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetByIdSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetByIdFailed() {}
