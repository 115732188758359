import React, { useEffect } from 'react';
import { iStore } from 'domain/interfaces/models';
import { iEventItem } from 'domain/interfaces/models/Event';
import { useSelector } from 'react-redux';
import useWindowDimensions from 'presentation/hooks/useWindowDimensions';
import VirtualAudienceModal from '../Modals/VirtualAudience/VirtualAudienceModal';
import Chat from './Chat';
import { ChatInfoProps, MessageProps } from './interfaces';
import { Provider } from './styles';

interface iChatProvider {
  chats: any[];
  updateChats: (chats: any[]) => void;
  newMessage?: MessageProps;
  isListParticipantsOpen: boolean;
  handleNewMessageAdded?: () => void;
  selectedEvent: iEventItem;
  setIsVirtualRoomOpen: (isOpen: boolean) => void;
  isMicMuted: boolean;
  handleMuteMicrophone: () => void;
  localMute: (data: any) => void;
}

const ChatProvider: React.FC<iChatProvider> = ({
  chats: chatsA,
  updateChats,
  newMessage,
  handleNewMessageAdded,
  isListParticipantsOpen,
  selectedEvent,
  setIsVirtualRoomOpen,
  isMicMuted,
  handleMuteMicrophone,
  localMute,
}) => {
  const [chats, setChats] = React.useState<ChatInfoProps[]>([]);
  const { user: userLog } = useSelector((store: iStore) => store.auth);
  const { spotsInfo } = useSelector((state: iStore) => state);

  const handleDeleteChat = (chatId: number) => {
    const newChats = chats.map(item => {
      if (item?.id === chatId)
        return { ...item, isDisplayed: !item?.isDisplayed };

      return item;
    });

    updateChats(newChats);
    setChats(newChats);
  };

  const { isMobile } = useWindowDimensions();

  useEffect(() => {
    setChats(chatsA);
  }, [chatsA, isListParticipantsOpen, chats]);

  return !isMobile ? (
    <Provider>
      <div className="chats">
        {chats.map(chat => (
          <Chat
            chat={chat}
            handleDeleteChat={handleDeleteChat}
            key={chat?.id}
            chatId={chat?.id}
            newMessage={newMessage}
            handleNewMessageAdded={handleNewMessageAdded}
          />
        ))}
        <VirtualAudienceModal
          user={{ ...userLog, id: 4, name: 'Lista de participantes' }}
          participants={spotsInfo?.results}
          event={selectedEvent}
          isOpen={isListParticipantsOpen}
          onClose={() => setIsVirtualRoomOpen(false)}
          localMuteState={isMicMuted}
          setIsOpen={setIsVirtualRoomOpen}
          localMute={handleMuteMicrophone}
          remoteMute={localMute}
        />
      </div>
    </Provider>
  ) : null;
};

export default ChatProvider;
