import styled, { css } from 'styled-components';
import { Pagination } from 'react-bootstrap';
import ReactPlayer from 'react-player/lazy';
import {
  IconReactions,
  IconArrowUp,
  IconBackArrow,
  IconMutedMic,
  IconUnmutedMic,
  IconUser,
  IconSettingsMobile,
  IconParticipant as Participant,
  HeadphoneIcon,
  MicIcon2 as MicrophoneIcon,
  MicMutedIcon as MutedMicrophoneIcon,
} from 'presentation/base/icons';

import media from 'utils/mediaqueries';
import bannerImage from 'presentation/base/images/bannerEvent.png';
import { stubTrue } from 'lodash';

interface ContainerProps {
  embed?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  /* position: relative; */
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.black1};
`;

export const Header = styled.div`
  width: 100%;
  height: 60px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${props => props.theme.colors.black2};
`;

interface LayoutsContainerProps {
  isRoomsPage?: boolean;
  embed?: boolean;
}

export const LayoutsContainer = styled.div<LayoutsContainerProps>`
  display: flex;
  width: ${({ isRoomsPage }) => isRoomsPage && '100%'};
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
  /*  align-items: ${({ isRoomsPage }) => isRoomsPage && 'center'};
  justify-content: ${({ isRoomsPage }) => isRoomsPage && 'center'};
  padding: ${({ isRoomsPage }) => isRoomsPage && '0 7.4rem'};
  margin-right: ${({ isRoomsPage, embed }) =>
    !embed && isRoomsPage && '42rem'}; */

  ${media.lessThan('md')} {
    padding: ${({ isRoomsPage }) => isRoomsPage && '0 1.6rem'};
  }

  ${media.lessThan('sm')} {
    display: ${({ isRoomsPage, embed }) => isRoomsPage && !embed && 'none'};
  }
`;

interface boxProps {
  isMic?: boolean;
  btnActive?: boolean;
  disabled?: boolean;
}

export const Box = styled.div<boxProps>`
  width: 48px;
  height: 48px;
  background: ${({ isMic, btnActive, theme }) =>
    isMic && !btnActive ? theme.colors.red1 : theme.colors.black3};
  border-radius: 5px;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.3s;

  &:hover {
    background: ${({ isMic, btnActive, theme }) =>
      isMic && !btnActive ? theme.colors.red2 : theme.colors.white3};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      background: ${({ theme }) => theme.colors.black1};
      svg path {
        fill: ${({ theme }) => theme.colors.white4};
        /* OBS: In figma the correct color is white5 */
      }

      &:hover {
        background: ${({ theme }) => theme.colors.black1};
      }
    `}
`;

export const VideoContainer = styled.div<LayoutsContainerProps>`
  display: flex;
  width: 100%;
  height: ${({ embed }) => (embed ? 'calc(100% - 80px)' : '100%')};
`;

export const IconsContainer = styled.div`
  display: flex;
  height: 80px;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  gap: 12px;
  background-color: ${({ theme }) => theme.colors.black2};
`;

interface RoomContainerProps {
  layout?: 'lounge' | 'cine' | 'theater' | 'arena';
}

export const RoomContainer = styled.div<RoomContainerProps>`
  max-width: 1920px;
  /* max-width: 1080px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 75%;
  margin-top: 6rem;
  padding: 0 7.4rem;
  justify-content: center;

  ${({ layout }) => {
    return layout === 'theater' || layout === 'arena'
      ? css`
          margin: 0;
          .room-grid-container {
            margin: 0;
          }

          .player {
            /* max-width: 575px; */
            max-width: 30rem;
            /* height: 345px; */
            padding: 5rem;
            /* padding-bottom: 3rem; */
            background: none;
          }
        `
      : null;
  }}
`;

export const ReactionIcon = styled(IconReactions)`
  margin-top: 5px;
`;

export const EventPlayer = styled(ReactPlayer).attrs({
  className: 'player',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40rem;
  height: 40%;

  position: relative;
  margin-bottom: 2px;
  background-color: #000000;
`;

interface iEventPlayerVideoJsProps {
  width: string;
  height: string;
}

export const EventPlayerVideoJs = styled.video.attrs({
  id: 'my-video',
  className: 'video-js',
  preload: 'auto',
  'data-setup': '{}',
  src: '//cdn-cf-east.streamable.com/video/mp4/kpask.mp4?Expires=1655415840&Signature=WrD0klGwHDjayDt8cs6U7~dYjrDLo4qW4-kxHAgFUnYQwqzYipHTIYqfO0BLKxr74N~KWbPKSqf848XWje6vrJnzEmpAX8-NsRyT8ekAI~Zrv5w9DW6ZOmGOmGBeObfboHbQ3DFxZODS47vDJlklL~FRfkyu6fXMpn8i6VF~HXJOUbOUchlZPb5zcRmeocX7~Ytrmoz1xS7-4ZePN9b5O5Ex64hPsg1kq~RMeoQijS2uENvMa0WlqYitWoAsjqaIAHrnfs3qMdJPMZJzMNbK8E5JwrNmWDLoZFjeJtgzBXtAaqbd5NB41rjcVClKd8xOn8Z98RqDmcMyux-e62LwtA__&Key-Pair-Id=APKAIEYUVEN4EVB2OKEQ',
})<iEventPlayerVideoJsProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width || '40rem'};
  height: ${({ height }) => height || '40%'};

  position: relative;
  margin-bottom: 2px;
  background-color: #000000;

  .video-js {
    width: ${({ width }) => width || '40rem'};
    height: ${({ height }) => height || '40%'};
  }
`;

export const RoomGridContainer = styled.div.attrs({
  className: 'room-grid-container',
})`
  /* width: 53.6rem; */
  /* height: 30%; */
  /* margin-top: 30px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const EventHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  margin-top: 20px;
`;

interface IEventText {
  bold?: boolean;
  marginRight?: boolean;
}

export const EventText = styled.h5<IEventText>`
  font-family: Poppins;
  font-weight: ${props => (props.bold ? 'bold' : 400)};
  font-size: 14px;
  line-height: 18px;

  margin-right: ${props => (props.marginRight ? '4px' : 0)};
  color: #ffffff;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RoomHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 6rem;

  padding: 30px 48px 0 48px;

  ${media.lessThan('md')} {
    padding: 30px 21px 0 21px;
  }

  ${media.lessThan('sm')} {
    display: none;
  }
`;

export const RowFullScreenContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  left: 50px;
  top: 30px;
  z-index: 10;
`;

export const SpacedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 8px;
`;

export const BackArrow = styled(IconBackArrow)`
  width: 2rem;
  height: 2rem;

  cursor: pointer;
`;

interface CategoryProps {
  type: 'Esportes' | 'Música' | 'Entretenimento';
}

export const CategoryBar = styled.div<CategoryProps>`
  width: 3px;
  height: 36px;
  margin-left: 0.8rem;
  margin-right: 1.4rem;
  background-color: ${props => {
    switch (props.type) {
      case 'Esportes':
        return props.theme.colors.blue2;
      case 'Música':
        return props.theme.colors.green1;
      case 'Entretenimento':
        return props.theme.colors.pink1;
      default:
        return props.theme.colors.black2;
    }
  }};
`;

export const CategoryTitle = styled.h5`
  font-family: Poppins;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;

  color: #ffffff;
`;

export const Button = styled.button`
  width: 13.6rem;
  height: 3.4rem;
  border-radius: 0.5rem;
  border: 0;
  background-color: ${props => props.theme.colors.black5};
  color: ${props => props.theme.colors.white1};
`;

export const ReactionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 8rem;
  height: 60.1rem;
  padding: 15px;
  background-color: ${props => props.theme.colors.black2};
`;
export const FullScreenReactionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: -7rem;
  bottom: calc((100% - 60.1rem) / 2);
  width: 8rem;
  height: 60.1rem;
  padding: 15px;
  background-color: ${props => props.theme.colors.black2};
  transition: ease-in-out 0.3s;

  &:hover {
    left: 0;
  }
`;

export const StyledPagination = styled(Pagination)`
  flex-direction: column;
  gap: 14px;
`;

export const StyledPaginationItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${props => props.theme.colors.black5};
`;

export const StyledPaginationReaction = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  border-radius: 5px;
  background-color: ${props => props.theme.colors.black5};
`;

export const NavigationArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 5rem;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${props => props.theme.colors.black5};

  svg {
    height: 1.1rem;
  }
`;

export const ArrowUp = styled(IconArrowUp)`
  width: 11px;
  height: 11px;
`;

export const ArrowDown = styled(IconArrowUp)`
  width: 11px;
  height: 11px;
  transform: rotate(180deg);
`;

export const ChatSidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 8rem;
  height: 60.1rem;
  padding: 15px;
  background-color: ${props => props.theme.colors.black2};
`;

export const FullScreenChatSidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: -7rem;
  bottom: calc((100% - 60.1rem) / 2);
  width: 8rem;
  height: 60.1rem;
  padding: 15px;
  background-color: ${props => props.theme.colors.black2};
  transition: ease-in-out 0.3s;
  &:hover {
    right: 0;
  }
`;

export const IconParticipant = styled(IconUser)`
  width: 5rem;
  height: 5rem;

  cursor: pointer;
`;

export const MicIcon = styled(IconUnmutedMic)`
  width: 5rem;
  height: 5rem;

  cursor: pointer;
`;
interface iconProps {
  disabled?: boolean;
}

export const MobileSettingsicon = styled(IconSettingsMobile)<iconProps>`
  filter: ${props => (props.disabled ? 'opacity(50%)' : 'none')};

  cursor: pointer;
`;

export const MutedMicIcon = styled(IconMutedMic)`
  width: 5rem;
  height: 5rem;

  cursor: pointer;
`;

export const RowButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1.4rem;
`;

export const FooterContainer = styled.footer`
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 1.5rem 7.5rem;
  background-color: ${({ theme }) => theme.colors.black2};
`;

export const ColumnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const VerticalDivider = styled.div`
  width: 1px;
  height: 5rem;
  margin: 0 3rem;
  z-index: 999;
  background-color: ${({ theme }) => theme.colors.black5};
`;

export const ListUsersContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 1.5rem;
`;

export const InfoRoomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const InfoRoomTitle = styled.div`
  margin: 0;
  max-width: 30rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.5rem 0;

  transition: all 0.5s ease-in-out;
`;

export const InfoRoomSubtitle = styled.div`
  gap: 1rem;
  display: flex;
  align-items: center;
  font-size: 1.2rem;

  span {
    font-weight: normal;
  }

  span + span {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: normal;
  }

  span + span::before {
    content: '•';
  }
`;

export const InfoRoomContent = styled.div`
  color: white;
  font-weight: bold;
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 1.5rem;
`;

export const AvatarContainer = styled.img`
  border-radius: 0.8rem;
  overflow: hidden;
  margin-right: 1.5rem;
  width: 5rem;
  height: 5rem;
  object-fit: cover;
`;

interface RoomsHeaderProps {
  banner?: string;
}

export const RoomsHeader = styled.div<RoomsHeaderProps>`
  width: 100%;
  height: 9.4rem;

  padding: 20px 188px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-image: url(${({ banner }) => banner ?? bannerImage});
  background-size: cover;

  ${media.lessThan('sm')} {
    display: none;
  }

  ${media.lessThan('xxl')} {
    padding: 20px 80px;
  }

  ${media.lessThan('xl')} {
    padding: 20px 75px;
  }

  ${media.lessThan('lg')} {
    padding: 20px 60px;
  }

  ${media.lessThan('md')} {
    padding: 20px 21px;
  }
`;

interface iFullscreenDivProps {
  isFullScreen?: boolean;
  isMobile?: boolean;
  isIphone?: boolean;
  orientation?: string | 'landscape' | 'portrait';
}
export const FullscreenDiv = styled.div<iFullscreenDivProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  width: 100vw;
  display: flex;
  height: ${props =>
    props.orientation === 'landscape' ? '100vh' : 'calc(100vh - 8.2rem)'};

  ${({ isFullScreen, isMobile, orientation, isIphone }) =>
    isFullScreen &&
    isMobile &&
    !isIphone &&
    orientation !== 'landscape' &&
    css`
      transform: rotate(-90deg);
      transform-origin: left top;
      width: 100vh;
      height: 100vw;
      overflow-x: hidden;
      position: absolute;
      top: 100%;
      left: 0;

      -webkit-animation: spin 0.35s ease;
      -moz-animation: spin 0.35s ease;
      animation: spin 0.35s ease;
    `}

  @-moz-keyframes spin {
    0% {
      top: 0;
      -moz-transform: rotate(0deg);
    }
    100% {
      top: 100%;
      -moz-transform: rotate(-90deg);
    }
  }
  @-webkit-keyframes spin {
    0% {
      top: 0;
      -webkit-transform: rotate(0deg);
    }
    100% {
      top: 100%;
      -webkit-transform: rotate(-90deg);
    }
  }
  @keyframes spin {
    0% {
      top: 0;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      top: 100%;
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }
  }
`;
export const MobileScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
  justify-content: flex-end;
`;

export const MobilePlayerContainer = styled.div<iMobileContentProps>`
  width: 100%;
  position: relative;
  z-index: 0;

  ${({ isDescriptionDisplaying }) =>
    isDescriptionDisplaying &&
    css`
      filter: blur(3px);
    `}
`;

export const MobileFooterPlaceholder = styled.div`
  display: flex;
  width: 100%;
  /* height: 128px; */
  background-color: ${({ theme }) => theme.colors.black2};
  /* opacity: 0.7; */
`;

export const MobileContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.black2};
  position: relative;
  overflow: hidden;
  color: #fff;
`;

export const MobileContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 42px;
  padding: 0 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
`;

export const MobileLeftHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

interface iMobileContentProps {
  isDescriptionDisplaying?: boolean;
}

export const MobileContentLayout = styled.div<iMobileContentProps>`
  display: flex;
  width: 100%;
  height: 100%;
  /* height: calc(100% - 42px); */
  /* max-height: calc(100vh - 50rem); */
  background: ${({ theme }) => theme.colors.black1};
  /* padding: 0 10px; */
  align-items: center;
  overflow: hidden;

  color: #fff;

  position: relative;

  .scroll-container {
    max-height: 100%;
  }

  ${({ isDescriptionDisplaying }) =>
    isDescriptionDisplaying &&
    css`
      filter: blur(3px);
    `}
`;

export const RowOptionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1.5rem;
`;

export const ParticipantContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
`;

export const ParticipantItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* padding: 0 20px 0 10px; */
`;

export const ParticipantsItemRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 11px;

  h4 {
    color: white;
  }
`;

export const ParticipantsItemActions = styled.div`
  width: 78px;
  display: flex;
  gap: 10px;

  svg {
    cursor: pointer;
  }

  button {
    background: transparent;
    border: none;
  }
`;

export const ParticipantsActionButton = styled.button`
  width: 34px;
  height: 34px;

  :disabled {
    opacity: 0.3;
  }
`;

export const ParticipantsProfile = styled(Participant)`
  width: 3.4rem;
  height: 3.4rem;
`;

export const ParticipantsMic = styled(MicrophoneIcon)`
  width: 1.8rem;
  height: 1.8rem;
`;

export const ParticipantsMutedMic = styled(MutedMicrophoneIcon)`
  width: 1.8rem;
  height: 1.8rem;
`;

export const ParticipantsHeadphone = styled(HeadphoneIcon)`
  width: 1.8rem;
  height: 1.8rem;
`;

export const ParticipantsMoreOptions = styled.div`
  width: 153px;
  padding: 15px 10px;
  background: #05060c;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  border-radius: 5px;

  img {
    margin-right: 5px;
  }

  button {
    background: transparent;
    color: white;
    border: none;
  }

  .opTop {
    cursor: pointer;
  }

  .line {
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.08);
  }
  .opDown {
    margin-top: 15px;
    cursor: pointer;
  }
`;

interface iFullScreenLandscapedProps {
  isMenu?: boolean;
}
export const FullScreenLandscaped = styled.div<iFullScreenLandscapedProps>`
  display: flex;
  flex-direction: column;
  width: ${({ isMenu }) => (isMenu ? '42rem' : '0')};
  background-color: #12141e;
`;

interface GridLayoutProps {
  showDescription?: boolean;
}

export const GridLayout = styled.div<GridLayoutProps>`
  width: 100%;
  height: 100%;
  display: grid;
  /* flex-direction: column; */
  margin: 2.5rem 0;
  grid-template-columns: 64% auto;
  padding: 0 4.8rem;

  /* grid-auto-rows: minmax(800px, auto); */
  grid-auto-rows: minmax(59.5rem, auto);
  grid-auto-rows: ${({ showDescription }) =>
    `${showDescription ? '800px' : '59.5rem'} 59.5rem 59.5rem 59.5rem`};
  grid-gap: 2.4rem;

  &:focus-visible {
    outline: none;
  }

  ${media.lessThan('md')} {
    padding: 0 2.1rem;
  }
`;

export const PlayerAndActions = styled.div`
  height: 100%;
  border: 1px solid blue;
  border-radius: 10px;
`;

export const ChatAndParticipants = styled.div`
  display: inline-block;
  border-radius: 1rem;
  max-height: 90vh;
  /* max-width: 60rem; */
`;

export const MapInteractive = styled.div`
  border: 1px solid yellow;
  border-radius: 10px;
  height: 100%;
`;

export const InfoEventBanner = styled.div``;

export const EventTitleDetails = styled.div`
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;

  color: #fbfbfb;
`;

export const EventDateDetails = styled.div`
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 19px;

  color: #cccccc;
`;
