import { GetAllReaction } from '../../../domain/usecases/reaction/remote';
import {
  NotFound,
  InvalidCredentialsError,
  UnexpectedError,
} from '../../../domain/errors';
import { HttpClient, HttpStatusCode } from '../../protocols/http';
import '../../../infra/global/variables';

export class RemoteGetAllReaction implements GetAllReaction {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetAllReaction.Model>;

  constructor(url: string, httClient: HttpClient<GetAllReaction.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  getAll = async (
    params: GetAllReaction.Params,
  ): Promise<GetAllReaction.Model> => {
    let query = '';

    Object.entries(params).forEach(([key, value]) => {
      query += `${key}=${value}&`;
    });

    const httpResponse = await this.httpClient.request({
      url: `${this.url}/?${query}`,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
