import { Dispatch } from 'redux';
import { iActionUpdateSpotsInfo } from 'domain/interfaces/redux/spotsInfo/update';
import { UpdateSpotsInfo } from 'domain/usecases/spotsInfo/redux';
import store from '../../..';
import { updateSpotsInfo } from '../actions/update';

export class ReduxUpdateSpotsInfo implements UpdateSpotsInfo {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  update = (params: UpdateSpotsInfo.Params): iActionUpdateSpotsInfo =>
    this.send(updateSpotsInfo(params));
}
