import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetAllReaction as UsecaseRemoteGetAllReaction } from 'domain/usecases/reaction/remote';
import { GetAllReaction as UsecaseReduxGetAllReaction } from 'domain/usecases/reaction/redux';

import { RemoteGetAllReaction } from 'data/repository/reaction';
import { ReduxGetAllReaction } from 'data/store/reducer/reaction/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetAllReaction = (): UsecaseRemoteGetAllReaction =>
  new RemoteGetAllReaction(makeApiUrl('/reactions/'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxGetAllReaction = (): UsecaseReduxGetAllReaction =>
  new ReduxGetAllReaction();
