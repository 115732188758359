export enum ReactionTypes {
  GETALL = '@reaction/GETALL',
  GETALL_SUCCESS = '@reaction/GETALL_SUCCESS',
  GETALL_FAILED = '@reaction/GETALL_FAILED',
  CREATE = '@reaction/CREATE',
  CREATE_SUCCESS = '@reaction/CREATE_SUCCESS',
  CREATE_FAILED = '@reaction/CREATE_FAILED',
  DELETE = '@reaction/DELETE',
  DELETE_SUCCESS = '@reaction/DELETE_SUCCESS',
  DELETE_FAILED = '@reaction/DELETE_FAILED',
  GETBYID = '@reaction/GETBYID',
  GETBYID_SUCCESS = '@reaction/GETBYID_SUCCESS',
  GETBYID_FAILED = '@reaction/GETBYID_FAILED',
  SENDIMAGE = '@reaction/SENDIMAGE',
  SENDIMAGE_SUCCESS = '@reaction/SENDIMAGE_SUCCESS',
  SENDIMAGE_FAILED = '@reaction/SENDIMAGE_FAILED',
  UPDATE = '@reaction/UPDATE',
  UPDATE_SUCCESS = '@reaction/UPDATE_SUCCESS',
  UPDATE_FAILED = '@reaction/UPDATE_FAILED',
}
