/* eslint-disable consistent-return */
import { iStore } from 'domain/interfaces/models';
import { defaultTheme } from 'presentation/base/themes';
import React, { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import LogoDropzone from '../Dropzone';
import TagArea from '../TagArea';
import { Input, NewSelect } from '../UI';
import { EventContent } from './NewEventStyled';
import {
  Tabs,
  TabsText,
  TabsView,
  TransmitionText,
} from './styles/ContentDataStyled';
import { ContentInput } from './styles/GeneralDataStyled';
import { HeaderContent } from './styles/common';

interface CurrentContentData {
  name: string;
  id: number;
  childType: null | 'SINGLE' | 'MULTIPLE';
}

interface SelectValue {
  label: string;
  value: string;
}

const EventsForm: React.FC = () => {
  const [tags, setTags] = useState<string[]>([]);
  const categories = useSelector((store: iStore) => store.category.results);

  const {
    register,
    setValue,
    setError,
    getValues,
    watch,
    trigger,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const currentMediaType = watch('mediaType');

  const category = watch('category');
  const subCategory = watch('subCategory');

  const options = categories.map(categoryItem => ({
    label: categoryItem.name,
    value: String(categoryItem.id),
  }));

  const tabs: Record<string, string> = {
    LIVE: 'Ao vivo',
    VOD: 'VOD',
    EXTERNAL: 'Externa',
  };

  const subCategoriesOptions = useMemo(() => {
    const currentCategory = categories.find(
      categoryItem => categoryItem.id === Number(category),
    );

    if (!currentCategory) return [];

    return currentCategory.subCategory?.map(categoryItem => ({
      label: categoryItem.name,
      value: String(categoryItem.id),
    }));
  }, [categories, category]);

  const handleSelect = useCallback(
    (field: string, option: { label: string; value: any }) => {
      const { value, label } = option;

      setValue(field, value);

      const currentCategory = categories.find(
        categoryItem => categoryItem.name === label,
      );

      trigger(field);
    },
    [categories, setValue, trigger],
  );

  const handleAddAndRemoveTag = useCallback(
    (newTags: string[]) => {
      setTags(newTags);
      setValue('keywords', newTags);
    },
    [setValue],
  );

  return (
    <div>
      <HeaderContent>2. Conteúdo</HeaderContent>
      <EventContent>
        <div className="form">
          <div style={{ width: '100%' }}>
            <TransmitionText required>Fonte da transmissão</TransmitionText>
            <TabsView>
              {Object.keys(tabs).map(tab => (
                <Tabs
                  key={tab}
                  onClick={() => {
                    setValue('mediaType', tab);
                    clearErrors('media');
                    setValue('media', null);
                  }}
                  type="button"
                  active={currentMediaType === tab}
                  {...register('mediaType')}
                >
                  <TabsText>{tabs[tab]}</TabsText>
                </Tabs>
              ))}
            </TabsView>
            {currentMediaType === 'VOD' && (
              <ContentInput>
                <LogoDropzone
                  {...register('media')}
                  id="media"
                  className="logo-dropzone-create-event"
                  label="Arquivo de mídia"
                  required
                  src={getValues()?.media}
                  color={defaultTheme.colors.black3}
                  accept="video/*"
                  defaultImage={watch('media')}
                  handleFileUpload={e => {
                    setValue('media', e);
                    setError('media', { type: 'onBlur' });
                  }}
                  error={errors?.media?.message}
                />
              </ContentInput>
            )}
            {currentMediaType === 'EXTERNAL' && (
              <ContentInput>
                <Input
                  {...register('media')}
                  color="black4"
                  label="URL da mídia"
                  required
                  error={errors?.media}
                  message={errors?.media?.message}
                  placeholder="https://"
                />
              </ContentInput>
            )}

            <ContentInput>
              <NewSelect
                {...register('category')}
                id="category"
                options={options}
                ref={register('category').ref}
                label="Categoria"
                required
                placeholder="Selecione uma categoria"
                color="black3"
                error={errors?.category}
                message={errors?.category?.message}
                onChange={e => {
                  handleSelect('category', {
                    label: e.label,
                    value: Number(e.value),
                  });
                  setError('category', { type: 'onBlur' });
                }}
              />
            </ContentInput>
            <ContentInput>
              <NewSelect
                {...register('subCategory')}
                id="subCategory"
                options={subCategoriesOptions}
                label="Subcategoria"
                required
                placeholder="Selecione uma subcategoria"
                color="black3"
                error={errors?.subCategory}
                message={errors?.subCategory?.message}
                onChange={e => {
                  handleSelect('subCategory', {
                    label: e.label,
                    value: Number(e.value),
                  });
                  setError('subCategory', { type: 'onBlur' });
                }}
              />
            </ContentInput>
            <ContentInput>
              <TagArea
                {...register('keywords')}
                label="Palavras-chave"
                error={Boolean(errors?.keywords)}
                message={errors?.keywords?.message}
                setTags={handleAddAndRemoveTag}
                tags={tags}
              />
            </ContentInput>
          </div>
        </div>
      </EventContent>
    </div>
  );
};

export default EventsForm;
