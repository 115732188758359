import { Dispatch } from 'redux';
import store from 'data/store';
import { getAllRequest } from 'data/store/reducer/category/actions/getAll';

import { iActionGetAll } from 'domain/interfaces/redux/category/getAll';

import { GetAllCategory } from 'domain/usecases/category/redux';

export class ReduxGetAllCategory implements GetAllCategory {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  getAll = (params: GetAllCategory.Params): iActionGetAll =>
    this.send(getAllRequest(params));
}
