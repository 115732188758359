import styled, { css } from 'styled-components';

type InputContainerProps = {
  isMultipleLine?: boolean;
  withoutSticker?: boolean;
};

export const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  position: relative;
  background: ${({ theme }) => theme.colors.black4};

  ${({ isMultipleLine }) =>
    isMultipleLine
      ? css`
          .input-button {
            align-items: flex-start;
          }
        `
      : null}

  ${({ withoutSticker }) =>
    withoutSticker
      ? css`
          .text-area {
            border-radius: 0.5rem 0 0 0.5rem;
          }
        `
      : null}
  width: 100%;
  border-radius: 0.5rem;
`;

const inputFocusStyle = css`
  outline: none;
  padding: 0.8rem;
  border: 0.2rem solid ${({ theme }) => theme.colors.blue1};
  box-shadow: 0px 0px 10px #4754bc;
`;

export const InputStyled = styled.textarea`
  width: 100%;
  background: ${({ theme }) => theme.colors.black4};
  border: 0;
  outline: none;
  padding: 0.85rem 1.2rem;

  flex-flow: column nowrap;

  display: flex;
  align-items: flex-start;
  justify-content: end;

  resize: none;
  transform-origin: bottom;

  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.white1};

  &::placeholder {
    color: ${({ theme }) => theme.colors.white3};
  }

  &:disabled {
    cursor: default;
    background: ${({ theme }) => theme.colors.black3};
    color: ${({ theme }) => theme.colors.white3};
    opacity: 0.3;
  }

  &:focus-visible,
  &:active {
    ${({ disabled }) =>
      disabled
        ? ''
        : css`
            ${inputFocusStyle}
            padding: 0.65rem 1rem;
          `}
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

type InputButtonProps = {
  placement?: 'left' | 'right';
};

export const InputButton = styled.button.attrs({
  className: 'input-button',
})<InputButtonProps>`
  background: ${({ theme }) => theme.colors.black3};
  color: ${({ theme }) => theme.colors.white1};
  padding: 1rem;
  outline: none;
  border: 0;
  border-radius: ${({ placement }) =>
    placement === 'right' ? '0 0.5rem 0.5rem 0' : '0.5rem 0px 0px 0.5rem'};
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  & svg path {
    width: 1.5rem;
    fill: ${({ theme }) => theme.colors.white1};
  }

  &:focus-visible {
    ${inputFocusStyle}
  }

  &:hover {
    border: 0;
    padding: 1rem;
    box-shadow: initial;
    background: ${({ theme }) => theme.colors.white3};
    svg path {
      fill: ${({ theme }) => theme.colors.white2};
    }
  }

  &:active {
    outline: none;
    padding: 1rem;
    border: 0;
    box-shadow: initial;
    background: ${({ theme }) => theme.colors.black3};
    svg path {
      fill: ${({ theme }) => theme.colors.blue1};
    }

    transition: background 0s ease-in;
  }

  &:disabled {
    cursor: default;
    background: ${({ theme }) => theme.colors.black3};
    svg path {
      fill: ${({ theme }) => theme.colors.white3};
    }
    opacity: 0.3;
  }

  transition: background 0.2s ease-in-out;
`;
