import styled, { css } from 'styled-components';

/* interface LayoutGridRoomProps {
  size: string;
  preview?: boolean;
}

export const Container = styled.div<LayoutGridRoomProps>`
  border-radius: ${({ preview }) => (preview ? '2px' : '5px')};
  color: ${({ theme }) => theme.colors.white1};
  margin: ${({ preview }) => (preview ? 0 : '0.2rem')};
  transition: all 0.2s ease-in-out;
  cursor: pointer;
`; */
interface GridProps {
  numCol: number;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Grid = styled.div<GridProps>`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: ${props => `repeat(${props.numCol}, 1fr)`};
  overflow: visible;
`;

interface AnimationProps {
  fadeOut: boolean;
}

export const AnimationContainer = styled.div<AnimationProps>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 6rem;
  top: -4rem;
  right: -2.5rem;
  z-index: 1;

  visibility: ${({ fadeOut }) => (fadeOut ? 'hidden' : 'visible')};
  opacity: ${({ fadeOut }) => (fadeOut ? 0 : 1)};
  transition: ${({ fadeOut }) =>
    fadeOut ? 'visibility 0s 2s, opacity 2s linear' : 'opacity 2s linear'};

  img {
    width: 6rem;
    height: 6rem;
    object-fit: cover;
  }
`;

interface SquareProps {
  size: string;
  selected: boolean;
  state?: 'full' | 'busy' | 'normal';
  disabled?: boolean;
  hasBorder?: boolean;
  isSpeaking?: boolean;
  isCenter?: boolean;
  preview?: boolean;
  isChangingRoom?: boolean;
  isHovering?: boolean;
  isActualUser?: boolean;
  type?: 'vip' | 'normal';
}

export const Square = styled.div<SquareProps>`
  border-radius: ${({ preview }) => (preview ? '2px' : '5px')};
  color: ${({ theme }) => theme.colors.white1};
  margin: ${({ preview }) => (preview ? 0 : '0.2rem')};
  transition: all 0.2s ease-in-out;
  border: 2px solid;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.black4 : theme.colors.black2};
  border-color: ${({ theme, selected }) =>
    selected ? theme.colors.white5 : theme.colors.black3};

  position: relative;
  overflow: hidden;

  width: ${({ size }) => size};
  height: ${({ size }) => size};
`;
