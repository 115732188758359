/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import { iReactions } from 'domain/interfaces/models';

import {
  ReactionsDefaultIcon,
  NewCloseIcon,
  NewIconReactions,
} from 'presentation/base/icons';
import {
  ReactionsContainer,
  ReactionsWrapper,
  ReactionItem,
  ReactionItemDefault,
  Header,
  Title,
  TitleContent,
} from './styles/StyledReactionsContent';

import { TooltipComponent } from '../TooltipComponent';
import { Button } from '../UI';

interface ownProps {
  reactions: iReactions[];
  handleReactionsClick?: (reaction: string) => void;
  setOpenReactions: () => void;
  openReactions?: boolean;
}

const Reactions: React.FC<ownProps> = ({
  reactions,
  handleReactionsClick,
  openReactions,
  setOpenReactions,
}) => {
  const renderPaginationItems = useMemo(() => {
    const minimumReactionsDisplayed = 20;

    let renderedReactions: Array<iReactions | string> = reactions;

    if (renderedReactions?.length < minimumReactionsDisplayed) {
      renderedReactions = [
        ...renderedReactions,
        ...Array(minimumReactionsDisplayed - renderedReactions.length).fill(
          'default',
        ),
      ];
    }

    return renderedReactions.map(reaction => {
      if (typeof reaction === 'string') {
        return (
          <ReactionItemDefault>
            <ReactionsDefaultIcon />
          </ReactionItemDefault>
        );
      }

      return (
        <TooltipComponent
          title={reaction.name}
          key={reaction.id}
          placement="top"
        >
          <ReactionItem
            src={reaction.thumbnail || reaction.url}
            alt={reaction.name}
            key={reaction.id}
            onClick={() => {
              handleReactionsClick?.(reaction.name);
              setOpenReactions();
            }}
          />
        </TooltipComponent>
      );
    });
  }, [reactions, handleReactionsClick, openReactions, setOpenReactions]);

  return (
    <div>
      <Header>
        <TitleContent>
          <NewIconReactions />
          <Title>Reações</Title>
        </TitleContent>
        <div>
          <div>
            <Button
              id="btn_close"
              onClick={setOpenReactions}
              colorstyle="black2"
              size="very small"
              icon={NewCloseIcon}
            />
          </div>
        </div>
      </Header>
      <ReactionsContainer>
        <ReactionsWrapper>{renderPaginationItems}</ReactionsWrapper>
      </ReactionsContainer>
    </div>
  );
};

export default Reactions;
