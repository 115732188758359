import styled, { css } from 'styled-components';
import ReactPlayer from 'react-player/lazy';

interface boxProps {
  isMic?: boolean;
  btnActive?: boolean;
  disabled?: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.black2};
  /* background: red; */
  border-radius: 10px;
`;

export const VideoContainer = styled.div`
  width: 100%;
  height: calc(100% - 80px);
`;

export const IconsContainer = styled.div`
  /* display: flex; */
  /* height: 80px; */
  /* align-items: center; */
  /* justify-content: space-between; */
  /* padding: 16px 24px; */
  /* gap: 12px; */
`;

export const Box = styled.div<boxProps>`
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  background: ${({ isMic, btnActive, theme }) =>
    isMic && !btnActive ? theme.colors.red1 : theme.colors.black3};
  border-radius: 5px;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.3s;

  &:hover {
    background: ${({ isMic, btnActive, theme }) =>
      isMic && !btnActive ? theme.colors.red2 : theme.colors.white3};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      background: ${({ theme }) => theme.colors.black1};
      svg path {
        fill: ${({ theme }) => theme.colors.white4};
        /* OBS: In figma the correct color is white5 */
      }

      &:hover {
        background: ${({ theme }) => theme.colors.black1};
      }
    `}
`;

interface EventPlayerProps {
  rounded?: boolean;
}

// TODO: check this style copypaste from src/presentation/pages/Room/styles.ts
export const EventPlayer = styled(ReactPlayer).attrs({
  className: 'player',
})<EventPlayerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40rem;
  height: 40%;

  position: relative;
  margin-bottom: 2px;
  background-color: ${({ theme }) => theme.colors.colorBlack};

  ${({ rounded }) =>
    rounded &&
    css`
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      div,
      iframe {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    `}
`;

interface AboutContainerProps {
  hasPlayerControls?: boolean;
}

export const AboutContainer = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: ${({ hasPlayerControls }: AboutContainerProps) =>
    hasPlayerControls ? '0' : '1.8rem 2.4rem'};

  overflow-y: auto;

  color: ${({ theme }) => theme.colors.white2};

  .content {
    width: 100%;
  }
`;

export const DescriptionContent = styled.div`
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  color: #e1e1e1;

  margin-top: 24px;
`;

export const Description = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  /* Dark Theme/White-2 */

  color: #fbfbfb;
`;
