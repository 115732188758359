import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { RoomGridContainer } from 'presentation/pages/Room/styles';
import { shuffle } from 'lodash';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import { makeReduxUpdateWRTCInfo } from 'main/factories/usecases/auth/UpdateWRTCInfoFactory';
import { makeReduxUpdateSpotsInfo } from 'main/factories/usecases/spotsInfo/UpdateFactory';
import {
  convertToWRTCRoomName,
  getSpotIndexFromWRTCRoomName,
  onJoinRoomFailed,
} from 'utils';
import ArrowScroll from '../ArrowScroll/ArrowScroll';
import LoungeLayout from '../Layouts/LoungeLayout';
import { getAdj, getStartPosition } from './functions';
import {
  ChooseRoomsContainer,
  MapBodyContainer,
  MapContainer,
  MapTabContainer,
  RoomContainer,
} from './styles';
import { layoutsProps } from './types';
import NewGridRoom, { RoomProps } from '../newGridRoom';

interface iParams {
  id: string;
  roomId: string;
}

interface iRoom {
  name: string;
  occup: number;
}

interface ownProps extends layoutsProps {
  id?: string;
}

const InteractiveMap: React.FC<ownProps> = ({
  id = '',
  rooms,
  containerSize,
  isRoomsPage,
  embed,
  ...rest
}) => {
  const [roomInfo, setRoomInfo] = useState<iRoom[]>();
  const [positionX, setPositionX] = useState<'start' | 'half' | 'end'>('start');
  const [positionY, setPositionY] = useState<'start' | 'half' | 'end'>('start');

  const { wrtc } = useSelector((state: iStore) => state);
  const params: iParams = useParams();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const { posScrollX, posScrollY } = getStartPosition(Number(params.roomId));
    setPositionX(posScrollX);
    setPositionY(posScrollY);
  }, [params.roomId]);

  const onRoomClick = useCallback(
    (room: any) => {
      console.log('...click: ', room);
      /* console.log('...wrtc: ', wrtc.disconnect); */
      /* makeReduxUpdateSpotsInfo().update([]); */
      if (wrtc.leaveRoom) {
        makeReduxUpdateWRTCInfo().update({ insideRoom: false });
        makeReduxUpdateSpotsInfo().update([]);
        console.log('...wrtc: leave', wrtc.leaveRoom());
      }

      const WRTCRoomName = convertToWRTCRoomName(room.label);
      const spotIndex = getSpotIndexFromWRTCRoomName(WRTCRoomName);

      const onJoinRoomSuccess = () => {
        const query = new URLSearchParams(location.search);
        console.debug('...Join room success');
        history.push({
          pathname: embed
            ? `/embed/rooms/${params.id}/room/${room.label}${
                query.values.length ? `?${query}` : ''
              }`
            : `/rooms/${params.id}/room/${room.label}`,
        });
      };

      // console.log('...wrtc: ', wrtc.joinRoom);

      if (wrtc.joinRoom) {
        // console.log('...wrtc: join', wrtc.joinRoom);
        console.log('... params joinRoom', WRTCRoomName, spotIndex);
        wrtc.joinRoom(
          WRTCRoomName,
          spotIndex.toString(),
          onJoinRoomSuccess,
          onJoinRoomFailed,
        );
      }
    },
    [wrtc, location.search, history, embed, params.id],
  );

  const [ready, setReady] = useState(false);

  const emptyRoomsGenerator: RoomProps['rooms'] = useMemo(() => {
    const emptyRooms: RoomProps['rooms'] = Array.from(
      { length: 24 },
      (_, i) => ({
        id: i,
        peerId: -1,
        peerName: '',
        x: i % 6,
        y: Math.floor(i / 6),
        actualUser: false,
        disable: false,
        state: 'normal',
        type: 'normal',
        label: 'T:',
      }),
    );

    if (emptyRooms.length) setReady(true);

    return emptyRooms;
  }, [params.roomId]);

  const between = useCallback((min, max) => {
    return Math.floor(Math.random() * (max - min) + min);
  }, []);

  const getRandomPeerId = useCallback((): number => {
    const random = Math.floor(Math.random() * 2);

    // Do not render
    if (random === 0) {
      return -1;
    }

    // Render any
    return between(0, 24);
  }, [between]);

  const getRandomPositionArray = useCallback(
    (targetArray: any[], qty: number): any[] => {
      let randomlyAdded = 0;

      const updatedArray = targetArray.map(item => {
        if (randomlyAdded >= qty) return item;

        const updatedPeerId = getRandomPeerId();

        // eslint-disable-next-line no-plusplus
        if (updatedPeerId !== -1) randomlyAdded++;

        return {
          ...item,
          peerId: updatedPeerId,
        };
      });
      return qty <= 20 ? shuffle(updatedArray) : updatedArray;
    },
    [getRandomPeerId],
  );

  const getRoomOcup = useCallback(
    (roomId: number) => {
      const roomName1 = `room_${roomId - 1}`;
      const room = roomInfo?.find(
        searchedRoom => searchedRoom.name === roomName1,
      );
      const occup = room ? room?.occup : 0;
      return occup;
    },
    [roomInfo],
  );

  const randomRooms: { [key: number]: RoomProps['rooms'] } = useMemo(() => {
    const roomId = Number(params.roomId);
    const adjacentRooms = getAdj(roomId);
    return {
      0: getRandomPositionArray(
        emptyRoomsGenerator,
        getRoomOcup(adjacentRooms[0].roomId),
      ) as RoomProps['rooms'],
      1: getRandomPositionArray(
        emptyRoomsGenerator,
        getRoomOcup(adjacentRooms[1].roomId),
      ) as RoomProps['rooms'],
      2: getRandomPositionArray(
        emptyRoomsGenerator,
        getRoomOcup(adjacentRooms[2].roomId),
      ) as RoomProps['rooms'],
      3: getRandomPositionArray(
        emptyRoomsGenerator,
        getRoomOcup(adjacentRooms[3].roomId),
      ) as RoomProps['rooms'],
      4: getRandomPositionArray(
        emptyRoomsGenerator,
        getRoomOcup(adjacentRooms[4].roomId),
      ) as RoomProps['rooms'],
      5: getRandomPositionArray(
        emptyRoomsGenerator,
        getRoomOcup(adjacentRooms[5].roomId),
      ) as RoomProps['rooms'],
      6: getRandomPositionArray(
        emptyRoomsGenerator,
        getRoomOcup(adjacentRooms[6].roomId),
      ) as RoomProps['rooms'],
      7: getRandomPositionArray(
        emptyRoomsGenerator,
        getRoomOcup(adjacentRooms[7].roomId),
      ) as RoomProps['rooms'],
      8: getRandomPositionArray(
        emptyRoomsGenerator,
        getRoomOcup(adjacentRooms[8].roomId),
      ) as RoomProps['rooms'],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRandomPositionArray, ready, roomInfo, params.roomId]);

  useEffect(() => {
    const floorToSearch = `floor_${params.id}`;

    setTimeout(() => {
      const onRequestAllFloorsInfosSuccess = (data: any) => {
        setRoomInfo(data?.rooms);
      };

      if (wrtc.requestRoomsOccupancies)
        wrtc.requestRoomsOccupancies(
          floorToSearch,
          0,
          onRequestAllFloorsInfosSuccess,
        );
    }, 10000);
  }, [params.id, wrtc]);

  return (
    <MapContainer
      containerSize={containerSize}
      isRoomsPage={isRoomsPage}
      layout="cine"
      embed={embed}
    >
      <MapBodyContainer>
        <ArrowScroll
          isLayout={false}
          disableArrow={false}
          maxWidth="100%"
          maxHeight="100%"
          positionX={positionX}
          positionY={positionY}
          /* style={{ margin: '30px' }} */
        >
          <ChooseRoomsContainer containerSize={containerSize}>
            {getAdj(Number(params.roomId)).map((item, index) => {
              return (
                <RoomContainer
                  style={{
                    gridColumnStart: item.posY,
                    gridRowStart: item.posX,
                  }}
                  isSelected={Number(params.roomId) === item.roomId}
                  onClick={() => {
                    if (Number(params.roomId) !== item.roomId)
                      onRoomClick({ label: item.roomId });
                  }}
                >
                  <span className="roomname">{`Sala ${item.roomId}`}</span>
                  {Number(params.roomId) === item.roomId ? (
                    <div>
                      <LoungeLayout
                        rooms={rooms}
                        containerSize={containerSize}
                        isRoomsPage={isRoomsPage}
                        embed={embed}
                        {...rest}
                      />
                    </div>
                  ) : (
                    <div>
                      <RoomGridContainer
                        onClick={() => {
                          onRoomClick({ label: item.roomId });
                        }}
                      >
                        <NewGridRoom
                          isLayout
                          numCol={6}
                          rooms={randomRooms[index]}
                          {...rest}
                        />
                      </RoomGridContainer>
                    </div>
                  )}
                </RoomContainer>
              );
            })}
            <div
              style={{
                gridColumnStart: '5',
                gridRowStart: '5',
              }}
            />
          </ChooseRoomsContainer>
        </ArrowScroll>
      </MapBodyContainer>
    </MapContainer>
  );
};

export default InteractiveMap;
