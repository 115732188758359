import { Check } from '@material-ui/icons';
import { IconCopyToClipboard } from 'presentation/base/icons';
import React, { cloneElement, useState } from 'react';
import { toast } from 'react-toastify';
import { Container, LeftIcon } from './styles';

type InputCopyValueProps = {
  value: string;
  leftIcon?: React.ReactElement;
};

const InputCopyValue: React.FC<InputCopyValueProps> = ({ value, leftIcon }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    setCopied(true);
    toast.success('Copiado com sucesso!', {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      icon: <Check />,
    });
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <Container>
      {leftIcon && (
        <LeftIcon>{cloneElement(leftIcon, { className: 'icon' })}</LeftIcon>
      )}
      <input value={value} readOnly className="input_copy" />
      <button className="button_copy" type="button" onClick={handleCopy}>
        <IconCopyToClipboard />
      </button>
    </Container>
  );
};

export default InputCopyValue;
