/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Container as GlobalContainer } from 'presentation/components/messages/Background';
import { Button } from 'presentation/components/UI';
import { iMessage } from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { CloseIcon, IconModal } from 'presentation/base/icons';
import {
  BodyBox,
  ButtonBox,
  Container,
  Header,
  InsideBodyBox,
  ModalTitle,
  Title,
  Text,
} from './styles/StyledEnterRoomPolicyModal';
import { translator } from '../i18n';

interface propsEnterRoomPolicyModal {
  message: iMessage;
}

const EnterRoomPolicyModal: React.FC<propsEnterRoomPolicyModal> = ({
  message,
}) => {
  const msgName = MessageOptions.enterRoomPolicyModal;

  const { active, actionCancel, actionAccept } = message;

  return (
    <>
      {msgName === active ? (
        <GlobalContainer>
          <Container>
            <Header>
              <Title>
                <ModalTitle>{translator('Política de Privacidade')}</ModalTitle>
              </Title>
            </Header>

            <BodyBox>
              <InsideBodyBox>
                <Text bold small>
                  A Netfans é uma plataforma de uso público.
                  <Text marginTop>
                    Obrigado por usar a Netfans. Nosso compromisso é proteger a
                    privacidade e a segurança dos dados pessoais envolvidos na
                    utilização da Plataforma Netfans.
                  </Text>
                  <Text marginTop>
                    Esta política de privacidade (“Política“) descreve como
                    coletamos e utilizamos suas informações pessoais e abrange o
                    seu uso e acesso ao Serviço, aqui denominado Netfans, e
                    disponibilizados à você.
                  </Text>
                  <Text marginTop>
                    Esta política se aplica especificamente à Netfans, a ser
                    fornecido e baseia-se nas seguintes premissas gerais:
                  </Text>
                  <Text bold small marginTop>
                    Confidencialidade
                  </Text>
                  <Text marginTop>
                    Permitir o acesso aos dados e aos nossos sistemas somente a
                    pessoas autorizadas, conforme o princípio do privilégio
                    mínimo. Integridade Preservar a confiabilidade dos dados e
                    das informações contra qualquer tipo de alteração, seja de
                    forma acidental ou fraudulenta.
                  </Text>
                  <Text bold small marginTop>
                    Integridade
                  </Text>
                  <Text marginTop>
                    Preservar a confiabilidade dos dados e das informações
                    contra qualquer tipo de alteração, seja de forma acidental
                    ou fraudulenta.
                  </Text>
                </Text>
              </InsideBodyBox>
            </BodyBox>
            <ButtonBox>
              <Button
                id="btn_cancel"
                title={translator('Rejeitar')}
                size="medium"
                colorstyle="black5"
                onClick={() => actionCancel?.()}
              />
              <Button
                id="btn_ok"
                title={translator('Aceitar')}
                size="medium"
                onClick={() => actionAccept?.()}
              />
            </ButtonBox>
          </Container>
        </GlobalContainer>
      ) : undefined}
    </>
  );
};

export default EnterRoomPolicyModal;
