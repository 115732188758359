import { defaultTheme } from 'presentation/base/themes';
import { SpotsInfo } from 'presentation/pages/Room/interface';
import React, { useState } from 'react';
import { useTheme } from 'presentation/contexts/themeContext';
import Chat from '../NewChat';
import ParticipantsList from '../ParticipantsList';
import Tabs from '../Tabs';
import { Container, Header } from './styles';

interface ActionWindowProps {
  publicChat: any;
  newMessage: any;
  handleNewMessageAdded: () => void;
  participants?: SpotsInfo[];
}

const ActionsWindow: React.FC<ActionWindowProps> = ({
  publicChat,
  newMessage,
  handleNewMessageAdded,
  participants,
}) => {
  const [tabs, setTabs] = useState<
    {
      label: string;
      color: keyof typeof defaultTheme.colors;
      disabled?: boolean;
      isActive?: boolean;
      type: 'chat' | 'participants' | 'polls';
    }[]
  >([
    {
      label: 'Bate-papo ao vivo',
      color: 'blue1',
      type: 'chat',
      isActive: true,
    },
    { label: 'Participantes', color: 'pink1', type: 'participants' },
    { label: 'Enquetes', color: 'green1', type: 'polls', disabled: true },
  ]);

  const handleChangeActive = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { value } = event.target as HTMLButtonElement;
    const newTabs = tabs.map(tab => ({
      ...tab,
      isActive: tab.label === value,
    }));
    setTabs(newTabs);
  };

  return (
    <Container>
      <Header>
        <Tabs options={tabs} onClick={handleChangeActive} />
      </Header>
      <div
        style={{
          maxHeight: 'calc(100% - 5.4rem)',
          height: '100%',
          width: '100%',
        }}
      >
        {tabs.find(tab => tab.isActive)?.type === 'chat' && (
          <Chat
            chat={publicChat}
            key={publicChat?.id}
            chatId={publicChat?.id}
            handleNewMessageAdded={handleNewMessageAdded}
            newMessage={newMessage}
          />
        )}
        {tabs.find(tab => tab.isActive)?.type === 'participants' && (
          <ParticipantsList participants={participants} />
        )}
      </div>
    </Container>
  );
};

export default ActionsWindow;
