import styled from 'styled-components';

export const Container = styled.div`
  color: white;
  display: flex;
  align-items: center;
  gap: 3.8rem;

  .MuiIconButton-label {
    color: #3f4660;
  }
  .MuiTypography-root {
    font-size: 1.2rem;
    font-family: 'Poppins';
  }

  .MuiSvgIcon-root {
    width: 2.5rem;
    height: 2.5rem;

    color: white;
  }
`;
