import { Dispatch } from 'redux';
import store from 'data/store';
import { deleteRequest } from 'data/store/reducer/event/actions/delete';

import { iActionDelete } from 'domain/interfaces/redux/event/delete';

import { DeleteEvent } from 'domain/usecases/event/redux';

export class ReduxDeleteEvent implements DeleteEvent {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  delete = (params: DeleteEvent.Params): iActionDelete =>
    this.send(deleteRequest(params));
}
