/* eslint-disable no-useless-return */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { Container as GlobalContainer } from 'presentation/components/messages/Background';
import { Button, Input } from 'presentation/components/UI';
import Checkbox from 'presentation/components/Checkbox';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { iMessage, iStore } from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { NewCloseIcon, IconModalLogin } from 'presentation/base/icons';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import { closeModal } from 'utils/modalFunctions';
import { LoginSocial } from 'services/firebase';
import { makeReduxLogin } from 'main/factories/usecases/auth/LoginFactory';
import { schemaLogin } from 'validation/validators/Login/LoginValidator';
import {
  Container,
  ContentInputs,
  Header,
  Title,
  ChangeMode,
  Content,
  OptionsMode,
  Agreements,
  LinkToAgreements,
  TitleContent,
  Body,
  ContainerInputs,
  ContainerLoginProblem,
  RememberMe,
  ActionsLogin,
  ContainerSocialButtons,
  TitleSocialMedia,
  SocialMedia,
  SocialMediaButtons,
  PaddingContainer,
  CheckboxTitle,
} from './styles/StyledLoginModal';

interface propsRegisterModal {
  message: iMessage;
}

interface IFormLogin {
  email: string;
  password: string;
  rememberMe: boolean;
}

const LoginModal: React.FC<propsRegisterModal> = ({ message }) => {
  const msgName = MessageOptions.loginModal;

  const { active, actionOk, actionCancel } = message;

  const [inputValues, setInputValues] = useState({
    email: '',
    password: '',
    rememberMe: false,
  });

  const [selectedOption, setSelectedOption] = useState<number>(1);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [guestName, setGuestName] = useState<string>('');

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IFormLogin>({ resolver: schemaLogin, shouldFocusError: true });

  const wrtc = useSelector((store: iStore) => store.wrtc);
  const { accessToken } = useSelector((store: iStore) => store.auth);

  const options = ['Convidado', 'Tenho uma conta'];

  const updateInputValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };

  const handleCancel = useCallback(() => {
    makeReduxActiveMessage().active({
      active: MessageOptions.loginModal,
      actionOk: () => closeModal(),
      actionCancel: () => closeModal(),
    });
  }, []);

  const handleRegister = useCallback(() => {
    makeReduxActiveMessage().active({
      active: MessageOptions.registerModal,
      actionOk: () => handleCancel(),
      actionCancel: () => handleCancel(),
    });
  }, [handleCancel]);

  const handleRecoveryPassword = useCallback(() => {
    makeReduxActiveMessage().active({
      active: MessageOptions.recoveryPassword,
      actionOk: () => handleCancel(),
      actionCancel: () => handleCancel(),
    });
  }, [handleCancel]);

  const handleLogin = useCallback(() => {
    makeReduxLogin().login({
      login: getValues().email.trim().toLowerCase(),
      password: getValues().password.trim(),
    });
  }, [getValues]);

  const handlePolicy = useCallback(() => {
    makeReduxActiveMessage().active({
      active: MessageOptions.enterRoomPolicyModal,
      actionAccept: () => {
        setAcceptedTerms(true);
        handleCancel();
      },
      actionCancel: () => {
        setAcceptedTerms(false);
        handleCancel();
      },
    });
  }, []);

  const handleGuest = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      makeReduxLogin().login({
        login: guestName,
        password: 'convidado',
        guest: true,
      });
    },
    [guestName],
  );

  const onGoogleLogin = useCallback(async () => {
    const user = await LoginSocial('google');
    if (user === undefined) return;

    makeReduxLogin().login({
      login: String(user.displayName?.split(' ')[0]),
      password: String(
        user.displayName?.split(' ')[user.displayName?.split(' ').length - 1],
      ),
      socialUser: user,
    });

    if (actionOk) actionOk();
  }, [actionOk]);

  const onFacebookLogin = useCallback(async () => {
    const user = await LoginSocial('facebook');
    if (user === undefined) return;

    makeReduxLogin().login({
      login: String(user.displayName?.split(' ')[0]),
      password: String(
        user.displayName?.split(' ')[user.displayName?.split(' ').length - 1],
      ),
      socialUser: user,
    });

    if (actionOk) actionOk();
  }, [actionOk]);

  const onTwitterLogin = useCallback(async () => {
    const user = await LoginSocial('twitter');
    if (user === undefined) return;

    makeReduxLogin().login({
      login: String(user.displayName?.split(' ')[0]),
      password: String(
        user.displayName?.split(' ')[user.displayName?.split(' ').length - 1],
      ),
      socialUser: user,
    });

    if (actionOk) actionOk();
  }, [actionOk]);

  useEffect(() => {
    if (actionOk && accessToken && accessToken !== '') actionOk();
  }, [accessToken, actionOk]);

  return (
    <>
      {msgName === active ? (
        <GlobalContainer>
          <Container onSubmit={handleLogin}>
            <Content>
              <Header>
                <TitleContent>
                  <IconModalLogin />
                  <Title>Login</Title>
                </TitleContent>
                <div>
                  <Button
                    id="btn_close"
                    onClick={actionCancel}
                    colorstyle="black2"
                    size="very small"
                    icon={NewCloseIcon}
                  />
                </div>
              </Header>
              <Body>
                <PaddingContainer>
                  <ChangeMode>
                    {options?.map((option, index) => (
                      <OptionsMode
                        active={selectedOption === index}
                        onClick={() => setSelectedOption(index)}
                        index={index}
                      >
                        {option}
                      </OptionsMode>
                    ))}
                  </ChangeMode>
                </PaddingContainer>
                {selectedOption === 1 ? (
                  <>
                    <div
                      style={{
                        borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
                      }}
                    >
                      <ContainerInputs>
                        <ContentInputs>
                          <div className="input-email">
                            <Input
                              {...register('email')}
                              error={Boolean(errors.email)}
                              message={errors?.email && errors.email.message}
                              label="E-mail ou Nome de usuário"
                              name="email"
                              required
                            />
                          </div>
                          <div className="input-password">
                            <Input
                              {...register('password')}
                              error={Boolean(errors.password)}
                              message={
                                errors?.password && errors.password.message
                              }
                              label="Senha"
                              required
                              name="password"
                              type="password"
                            />
                          </div>
                          <ContainerLoginProblem>
                            <RememberMe>
                              <Checkbox
                                label="Lembrar-me"
                                checked={watch('rememberMe')}
                                onClick={() =>
                                  setValue('rememberMe', !watch('rememberMe'))
                                }
                                color="black3"
                              />
                            </RememberMe>
                            <Button
                              id="btn_modal_account"
                              title="Esqueceu sua senha?"
                              onClick={handleRecoveryPassword}
                              colorstyle="black2"
                              size="medium"
                            />
                          </ContainerLoginProblem>
                          <ActionsLogin>
                            <Button
                              id="btn_modal_login"
                              title="Entrar"
                              type="submit"
                              onClick={handleSubmit(handleLogin)}
                            />

                            <Button
                              id="btn_modal_account"
                              title="Criar conta"
                              onClick={handleRegister}
                              colorstyle="black3"
                            />
                          </ActionsLogin>
                        </ContentInputs>
                      </ContainerInputs>
                    </div>
                    <ContainerSocialButtons>
                      <TitleSocialMedia>
                        Acesse o Netfans por suas redes sociais:
                      </TitleSocialMedia>
                      <SocialMedia>
                        <SocialMediaButtons>
                          <Button
                            size="very small"
                            onClick={e => {
                              e.preventDefault();
                              onFacebookLogin();
                            }}
                            style={{
                              width: '48px',
                              height: '48px',
                            }}
                            social="facebook"
                          />
                          <p>Facebook</p>
                        </SocialMediaButtons>
                        <SocialMediaButtons>
                          <Button
                            size="very small"
                            onClick={e => {
                              e.preventDefault();
                              onTwitterLogin();
                            }}
                            style={{
                              width: '48px',
                              height: '48px',
                            }}
                            social="twitter"
                          />
                          <p>Twitter</p>
                        </SocialMediaButtons>
                        <SocialMediaButtons>
                          <Button
                            size="very small"
                            onClick={e => {
                              e.preventDefault();
                              onGoogleLogin();
                            }}
                            style={{
                              width: '48px',
                              height: '48px',
                            }}
                            social="google"
                          />
                          <p>Gmail</p>
                        </SocialMediaButtons>
                        <SocialMediaButtons>
                          <Button
                            size="very small"
                            style={{
                              width: '48px',
                              height: '48px',
                            }}
                            social="linkedin"
                            disabled
                          />
                          <p>LinkedIn</p>
                        </SocialMediaButtons>
                      </SocialMedia>
                    </ContainerSocialButtons>
                  </>
                ) : (
                  <PaddingContainer>
                    <ContentInputs>
                      <div className="input-email">
                        <Input
                          onChange={e => setGuestName(e.target.value)}
                          value={guestName}
                          label="Apelido"
                          name="email"
                          placeholder="Diga como quer ser chamado"
                        />
                      </div>
                    </ContentInputs>
                    <Agreements>
                      <Checkbox
                        id="checkbox_termsOfService"
                        checked={acceptedTerms}
                        onClick={() => setAcceptedTerms(!acceptedTerms)}
                      />
                      <span>
                        Li e concordo com os
                        <LinkToAgreements
                          id="termsOfService"
                          to="/"
                          onClick={handlePolicy}
                        >
                          {' '}
                          Termos de Serviço e Política de Privacidade
                        </LinkToAgreements>{' '}
                        da plataforma Netfans.
                      </span>
                    </Agreements>
                    <Button
                      id="btn_modal_guest"
                      title="Entrar como convidado"
                      onClick={handleGuest}
                      style={{ marginBottom: '26.9rem' }}
                      disabled={!acceptedTerms || guestName === ''}
                    />
                  </PaddingContainer>
                )}
              </Body>
            </Content>
          </Container>
        </GlobalContainer>
      ) : undefined}
    </>
  );
};

export default LoginModal;
