import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { iStore } from 'domain/interfaces/models';
import { makeReduxUpdateWRTCInfo } from 'main/factories/usecases/auth/UpdateWRTCInfoFactory';
import { makeReduxGetAllCategory } from 'main/factories/usecases/category/GetAllCategoryFactory';
import { makeReduxGetAllEvent } from 'main/factories/usecases/event/GetAllEventFactory';
import { makeReduxUpdateWrtc } from 'main/factories/usecases/wrtcClient/UpdateFactory';
import MainPage from 'presentation/components/MainPage';
import { WRTCClient } from 'services/wrtccli/netfans-wrtc-wrapper.js';

const Home: React.FC = () => {
  const wrtc = useSelector((store: iStore) => store.wrtc);
  const { wrtcInfo, user } = useSelector((store: iStore) => store.auth);

  const history = useHistory();

  useEffect(() => {
    const notConnectedToWRTC =
      (!wrtcInfo?.peerId || wrtcInfo?.peerId === -1) && user?.email;

    if (!wrtc.connect) {
      // Se o usuário estiver autenticado e não houver WRTC, instanciar WRTC e conectar
      const client = new WRTCClient(window.config.clientConfig);

      const loginSuccess = () => {
        const peerId = client.getLocalPeersId();
        if (peerId) makeReduxUpdateWRTCInfo().update({ peerId });
      };

      const loginFailed = (cause?: any) => {
        toast(
          'Não foi possível estabelecer comunicação com o servidor, tente novamente mais tarde',
          { type: 'error' },
        );
        client.disconnect();
      };

      client.onconnect = (name: string) => {
        client.loginPeer(name, loginSuccess, loginFailed);
      };

      if (notConnectedToWRTC) {
        makeReduxUpdateWrtc().update(client);
        try {
          client.connect(`${user.firstName} ${user.lastName}:${user.id}`);
        } catch (e) {
          console.log(e);
        }
      } else {
        makeReduxUpdateWrtc().update(client);
      }
    }

    if (wrtc.connect && notConnectedToWRTC) {
      try {
        wrtc.connect(`${user.firstName} ${user.lastName}:${user.id}`);
      } catch (e) {
        console.log(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrtc, user, wrtcInfo, history]);

  useEffect(() => {
    // Desconectar o usuário de qualquer sala quando o mesmo estiver na seleção de páginas
    if (wrtc.leaveRoom && wrtc.leaveFloor) {
      makeReduxUpdateWRTCInfo().update({ insideRoom: false });
      wrtc.leaveRoom();
      wrtc.leaveFloor();
    }
  }, [wrtc]);

  useEffect(() => {
    makeReduxGetAllEvent().getAll({
      limit: 9999,
    });
    makeReduxGetAllCategory().getAll({});
  }, []);

  return <MainPage />;
};

export default Home;
