import { iReduxSponsor } from 'domain/interfaces/models/Sponsors';
import { SponsorTypes } from 'domain/interfaces/redux/sponsor/types';
import { SponsorActions } from './actions';

export const initialState: iReduxSponsor = {
  loading: false,
  sponsors: [],
};

const reducer = (
  state = initialState,
  action: SponsorActions,
): iReduxSponsor => {
  switch (action.type) {
    case SponsorTypes.CREATE:
      return { ...state, loading: true };
    case SponsorTypes.CREATE_FAILED:
      return { ...state, loading: false };
    case SponsorTypes.CREATE_SUCCESS:
      return { ...state, loading: false };

    case SponsorTypes.LIST:
      return { ...state, loading: true };
    case SponsorTypes.LIST_FAILED:
      return { ...state, loading: false };
    case SponsorTypes.LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        sponsors: action.payload.records,
      };
    }
    default:
      return state;
  }
};

export default reducer;
