import { CreateEvent } from 'domain/usecases/event/remote';
import { UnexpectedError, BadRequestError, NotFound } from 'domain/errors';
import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import 'infra/global/variables';

export class RemoteCreateEvent implements CreateEvent {
  private readonly url: string;

  private readonly httpClient: HttpClient<CreateEvent.Model>;

  constructor(url: string, httClient: HttpClient<CreateEvent.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  create = async (params: CreateEvent.Params): Promise<CreateEvent.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      default:
        throw new UnexpectedError();
    }
  };
}
