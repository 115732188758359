/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-wrap-multilines */
import { MessageOptions } from 'domain/interfaces/redux/message';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import {
  IconChangeRoomFooter,
  IconConfigRoomFooter,
  IconExitFullScreen,
  IconMicDisabledFooter,
  IconMicNewFooter,
  IconReactionsFooter,
  IconShareFooter,
  IconSoundFooter,
} from 'presentation/base/icons';
import ReactionsContent from 'presentation/components/ReactionsContent';
import { RoomContext } from 'presentation/contexts/RoomContext';
import React, { useCallback, useContext, useMemo } from 'react';
import { closeModal } from 'utils/modalFunctions';
import { TooltipComponent } from '../TooltipComponent';
import {
  Actions,
  ButtonExitFullScreen,
  ContainerActions,
  Footer,
  FooterContainer,
} from './styles';
import { FooterInsideRoomProps } from './types';

const FooterInsideRoom: React.FC<FooterInsideRoomProps> = ({
  handleMuteMicrophone,
  handleReactionClick,
  isMicMuted,
  openReactions,
  reactionButtons,
  setOpenReactions,
  handleChangeRoomModal,
  openVolumeHandler,
  setOpenVolumeHandler,
  handleVolume,
  embed,
}) => {
  const handleInviteModal = useCallback(() => {
    makeReduxActiveMessage().active({
      active: MessageOptions.inviteModal,
      actionCancel: () => closeModal(),
    });
  }, []);

  const params = useMemo(
    () => ({
      reaction:
        new URLSearchParams(window.location.search).get('reaction') ?? true,
      muted: new URLSearchParams(window.location.search).get('muted') ?? false,
    }),
    [],
  );

  const { isMobile, isFullScreen, setIsFullScreen } = useContext(RoomContext);
  const renderFooter = useMemo(() => {
    return embed || !isMobile;
  }, [isMobile, embed]);

  return renderFooter ? (
    <FooterContainer isFullScreen={isFullScreen}>
      <Footer isFullScreen={isFullScreen} embed={embed}>
        <ContainerActions>
          <Actions onClick={handleMuteMicrophone} id="btn_mic">
            {isMicMuted ? <IconMicNewFooter /> : <IconMicDisabledFooter />}
            {isMicMuted ? 'Silenciar' : 'Habilitar'}
          </Actions>

          {!embed && (
            <Actions
              onClick={() => setOpenVolumeHandler?.(!openVolumeHandler)}
              id="btn_volume"
              disabled
            >
              <IconSoundFooter />
              Ajustar Volume
            </Actions>
          )}

          {params.reaction && params.reaction !== 'false' && (
            <TooltipComponent
              title="teste"
              placement="bottom"
              tooltipType="reactions"
              open={openReactions}
              titleContent={
                <ReactionsContent
                  reactions={reactionButtons}
                  handleReactionsClick={handleReactionClick}
                  openReactions={openReactions}
                  setOpenReactions={() => setOpenReactions?.(!openReactions)}
                />
              }
            >
              <Actions
                onClick={() => setOpenReactions?.(!openReactions)}
                id="btn_reaction"
              >
                <IconReactionsFooter />
                Reações
              </Actions>
            </TooltipComponent>
          )}

          {!embed && (
            <Actions id="btn_share" onClick={handleInviteModal}>
              <IconShareFooter />
              Compartilhar
            </Actions>
          )}

          {!embed && (
            <Actions id="btn_change_room" onClick={handleChangeRoomModal}>
              <IconChangeRoomFooter />
              Trocar de sala
            </Actions>
          )}

          {!embed && (
            <Actions disabled id="btn_config">
              <IconConfigRoomFooter />
              Configurações
            </Actions>
          )}
        </ContainerActions>
      </Footer>
      {isFullScreen && (
        <ButtonExitFullScreen
          style={{ color: '#fff' }}
          onClick={() => setIsFullScreen(!isFullScreen)}
        >
          <IconExitFullScreen />
          <p>Sair da tela cheia</p>
        </ButtonExitFullScreen>
      )}
    </FooterContainer>
  ) : (
    <></>
  );
};

export default FooterInsideRoom;
