/* eslint-disable react/react-in-jsx-scope */
import { iMessage, iStore } from 'domain/interfaces/models';
import { IconClose, IconConfiguration } from 'presentation/base/icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useAudioDeviceConfigure } from 'presentation/contexts/AudioDeviceConfigureContext';
import Switch from '../../Switch';
import Tabs from '../../Tabs';
import { NewSelect } from '../../UI';
import { ModalContainer } from '../styles';
import {
  Body,
  CloseButton,
  Container,
  Divider,
  Header,
  IconHeader,
  Label,
  OptionToggle,
  OptionToggleLabel,
  Title,
} from './styles';

interface PropsRoomsConfigureModal {
  message: iMessage;
}

interface SelectOption {
  label: string;
  value: string;
}
interface MediaDeviceInfo {
  deviceId: string;
  groupId: string;
  kind: 'audiooutput' | 'audioinput' | 'videoinput';
  label: string;
}

const RoomsConfigureModal: React.FC<PropsRoomsConfigureModal> = ({
  message,
}) => {
  const { active, actionCancel } = message;
  const isActive = active === 'roomsConfigure';

  const {
    handleSelectInputDevice,
    handleSelectOutputDevice,
    inputDevices,
    outputDevices,
    selectedInputDevice,
    selectedOutputDevice,
  } = useAudioDeviceConfigure();

  return isActive ? (
    <ModalContainer tabIndex={0}>
      <Container>
        <Header>
          <Title>
            <IconHeader>
              <IconConfiguration />
            </IconHeader>
            <Label>Configurações</Label>
          </Title>
          <CloseButton type="button" onClick={actionCancel}>
            <IconClose />
          </CloseButton>
        </Header>
        <Divider />
        <Tabs
          options={[
            {
              label: 'Áudio',
              color: 'blue1',
              isActive: true,
            },
            {
              label: 'Vídeo',
              color: 'green1',
              disabled: true,
            },
            {
              label: 'Acessibilidade',
              color: 'pink1',
              disabled: true,
            },
          ]}
        />
        <Body>
          <NewSelect
            label="Entrada de áudio"
            color="black4"
            value={selectedInputDevice}
            options={inputDevices}
            onChange={handleSelectInputDevice}
          />
          <NewSelect
            label="Saída de áudio"
            color="black4"
            value={selectedOutputDevice}
            options={outputDevices}
            onChange={handleSelectOutputDevice}
          />
          <Divider />
          <OptionToggle>
            <OptionToggleLabel>Alertas de som no bate-papo</OptionToggleLabel>
            <Switch id="chatSoundAlert" />
          </OptionToggle>
          <OptionToggle>
            <OptionToggleLabel>Alertas de som em reações</OptionToggleLabel>
            <Switch id="reactionSoundAlert" />
          </OptionToggle>
          <OptionToggle>
            <OptionToggleLabel>
              Alertas de som ao entrar na sala
            </OptionToggleLabel>
            <Switch id="userInSoundAlert" />
          </OptionToggle>
        </Body>
      </Container>
    </ModalContainer>
  ) : (
    <></>
  );
};

export default RoomsConfigureModal;
