import React, { useMemo } from 'react';
import { iAnimation } from 'presentation/pages/Room/interface';
import { RoomGridContainer } from 'presentation/pages/Room/styles';
import useWindowDimensions from 'presentation/hooks/useWindowDimensions';
import GridRoom from '../GridRoom';
import {
  PlayerDiv,
  RoomContainer,
  RoomNameContainer,
  RoomName,
} from './styles'; // check diff between presentation/pages/Room/styles
import { layoutsProps } from './types';

const TheaterLayout: React.FC<layoutsProps> = ({
  roomName,
  rooms,
  selectedEvent,
  layout,
  size,
  handleClick,
  setTriggerAnimation,
  speakingPeerIds,
  triggerAnimation,
  Player = null,
  isChangingRoom = false,
  isHovering = false,
  isRoomsPage = false,
  isFullscreen = false,
  containerSize,
}) => {
  const { isMobile } = useWindowDimensions();

  const getPlayer = useMemo(() => {
    if (isMobile || isFullscreen) return <PlayerDiv />;

    if (Player) {
      return <Player />;
    }

    return (
      <PlayerDiv containerSize={containerSize}>
        {isHovering && (
          <RoomNameContainer>
            <RoomName>{`Sala ${roomName}`}</RoomName>
          </RoomNameContainer>
        )}
      </PlayerDiv>
    );
  }, [Player, containerSize, isHovering, isMobile, isFullscreen, roomName]);

  const getGrid = useMemo(() => {
    return (
      <RoomContainer
        layout="theater"
        containerSize={containerSize}
        isRoomsPage={isRoomsPage}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            gap: '2rem',
            // justifyContent: 'space-between',
          }}
        >
          <div>
            <RoomGridContainer>
              <GridRoom
                isLayout
                numCol={6}
                rooms={rooms.slice(0, 6)}
                handleClick={handleClick}
                size={size}
                speakingPeerIds={speakingPeerIds}
                animation={triggerAnimation}
                isHovering={isHovering}
                isChangingRoom={isChangingRoom}
                handleAnimationEnd={() =>
                  setTriggerAnimation?.({} as iAnimation)
                }
              />
            </RoomGridContainer>

            <RoomGridContainer>
              <GridRoom
                isLayout
                numCol={6}
                rooms={rooms.slice(22, 28)}
                handleClick={handleClick}
                size={size}
                speakingPeerIds={speakingPeerIds}
                animation={triggerAnimation}
                isHovering={isHovering}
                isChangingRoom={isChangingRoom}
                handleAnimationEnd={() =>
                  setTriggerAnimation?.({} as iAnimation)
                }
              />
            </RoomGridContainer>
            <RoomGridContainer>
              <GridRoom
                isLayout
                numCol={6}
                rooms={rooms.slice(44, 50)}
                handleClick={handleClick}
                size={size}
                speakingPeerIds={speakingPeerIds}
                animation={triggerAnimation}
                isHovering={isHovering}
                isChangingRoom={isChangingRoom}
                handleAnimationEnd={() =>
                  setTriggerAnimation?.({} as iAnimation)
                }
              />
            </RoomGridContainer>
            <RoomGridContainer>
              <GridRoom
                isLayout
                numCol={6}
                rooms={rooms.slice(66, 72)}
                handleClick={handleClick}
                size={size}
                speakingPeerIds={speakingPeerIds}
                animation={triggerAnimation}
                isHovering={isHovering}
                isChangingRoom={isChangingRoom}
                handleAnimationEnd={() =>
                  setTriggerAnimation?.({} as iAnimation)
                }
              />
            </RoomGridContainer>
            <RoomGridContainer>
              <GridRoom
                isLayout
                numCol={6}
                rooms={rooms.slice(88, 94)}
                handleClick={handleClick}
                size={size}
                speakingPeerIds={speakingPeerIds}
                animation={triggerAnimation}
                isHovering={isHovering}
                isChangingRoom={isChangingRoom}
                handleAnimationEnd={() =>
                  setTriggerAnimation?.({} as iAnimation)
                }
              />
            </RoomGridContainer>
            <RoomGridContainer>
              <GridRoom
                isLayout
                numCol={6}
                rooms={rooms.slice(110, 116)}
                handleClick={handleClick}
                size={size}
                speakingPeerIds={speakingPeerIds}
                animation={triggerAnimation}
                isHovering={isHovering}
                isChangingRoom={isChangingRoom}
                handleAnimationEnd={() =>
                  setTriggerAnimation?.({} as iAnimation)
                }
              />
            </RoomGridContainer>
          </div>
          {getPlayer}

          {/* <EventPlayer playing url={selectedEvent?.midia} controls /> */}
          <div>
            <RoomGridContainer>
              <GridRoom
                isLayout
                numCol={6}
                rooms={rooms.slice(6, 12)}
                handleClick={handleClick}
                size={size}
                speakingPeerIds={speakingPeerIds}
                animation={triggerAnimation}
                isHovering={isHovering}
                isChangingRoom={isChangingRoom}
                handleAnimationEnd={() =>
                  setTriggerAnimation?.({} as iAnimation)
                }
              />
            </RoomGridContainer>
            <RoomGridContainer>
              <GridRoom
                isLayout
                numCol={6}
                rooms={rooms.slice(28, 34)}
                handleClick={handleClick}
                size={size}
                speakingPeerIds={speakingPeerIds}
                animation={triggerAnimation}
                isHovering={isHovering}
                isChangingRoom={isChangingRoom}
                handleAnimationEnd={() =>
                  setTriggerAnimation?.({} as iAnimation)
                }
              />
            </RoomGridContainer>
            <RoomGridContainer>
              <GridRoom
                isLayout
                numCol={6}
                rooms={rooms.slice(50, 56)}
                handleClick={handleClick}
                size={size}
                speakingPeerIds={speakingPeerIds}
                animation={triggerAnimation}
                isHovering={isHovering}
                isChangingRoom={isChangingRoom}
                handleAnimationEnd={() =>
                  setTriggerAnimation?.({} as iAnimation)
                }
              />
            </RoomGridContainer>
            <RoomGridContainer>
              <GridRoom
                isLayout
                numCol={6}
                rooms={rooms.slice(72, 78)}
                handleClick={handleClick}
                size={size}
                speakingPeerIds={speakingPeerIds}
                animation={triggerAnimation}
                isHovering={isHovering}
                isChangingRoom={isChangingRoom}
                handleAnimationEnd={() =>
                  setTriggerAnimation?.({} as iAnimation)
                }
              />
            </RoomGridContainer>
            <RoomGridContainer>
              <GridRoom
                isLayout
                numCol={6}
                rooms={rooms.slice(94, 100)}
                handleClick={handleClick}
                size={size}
                speakingPeerIds={speakingPeerIds}
                animation={triggerAnimation}
                isHovering={isHovering}
                isChangingRoom={isChangingRoom}
                handleAnimationEnd={() =>
                  setTriggerAnimation?.({} as iAnimation)
                }
              />
            </RoomGridContainer>
            <RoomGridContainer>
              <GridRoom
                isLayout
                numCol={6}
                rooms={rooms.slice(116, 122)}
                handleClick={handleClick}
                size={size}
                speakingPeerIds={speakingPeerIds}
                animation={triggerAnimation}
                isHovering={isHovering}
                isChangingRoom={isChangingRoom}
                handleAnimationEnd={() =>
                  setTriggerAnimation?.({} as iAnimation)
                }
              />
            </RoomGridContainer>
          </div>
        </div>
        <RoomGridContainer>
          <GridRoom
            isLayout
            numCol={22}
            rooms={rooms.slice(132, 220)}
            handleClick={handleClick}
            size={size}
            speakingPeerIds={speakingPeerIds}
            animation={triggerAnimation}
            isHovering={isHovering}
            isChangingRoom={isChangingRoom}
            handleAnimationEnd={() => setTriggerAnimation?.({} as iAnimation)}
          />
        </RoomGridContainer>
      </RoomContainer>
    );
  }, [
    containerSize,
    getPlayer,
    handleClick,
    isChangingRoom,
    isHovering,
    isRoomsPage,
    rooms,
    setTriggerAnimation,
    size,
    speakingPeerIds,
    triggerAnimation,
  ]);

  return getGrid;
};

export default TheaterLayout;
