import React, { useMemo, useState } from 'react';
import { CloseIcon, IconDeleteTag } from 'presentation/base/icons';
import { Label } from '../TextArea/styles';
import {
  CloseIconButton,
  Container,
  ContainerTagArea,
  InputTagArea,
  Tag,
} from './styles';
import { TagAreaProps } from './types';
import { Message } from '../UI/Input/styles';

const TagArea: React.FC<TagAreaProps> = ({
  label,
  required,
  error,
  message,
  tags,
  setTags,
}) => {
  const [inputTagValue, setInputTagValue] = useState<string>('');

  function handleAddTag(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === ',' && inputTagValue !== '') {
      event.preventDefault();
      setTags([...tags, inputTagValue]);
      setInputTagValue('');
      document.getElementById('bottom')?.scroll();
    } else if (event.key === 'Backspace' && !inputTagValue) {
      setTags(tags.slice(0, -1));
    }
  }

  const tagsToRender = useMemo(() => {
    return tags;
  }, [tags]);

  return (
    <Container>
      <Label required={required}>{label}</Label>
      <ContainerTagArea error={error} htmlFor="input-tag-area">
        {tagsToRender.map((tag, idx) => (
          <Tag>
            {tag}
            <CloseIconButton
              onClick={() => {
                setTags(tags.filter((_, i) => i !== idx));
              }}
            >
              <IconDeleteTag />
            </CloseIconButton>
          </Tag>
        ))}
        <InputTagArea
          id="input-tag-area"
          type="text"
          autoComplete="off"
          onKeyDown={e => handleAddTag(e)}
          value={inputTagValue}
          onChange={e => setInputTagValue(e.target.value)}
        />
      </ContainerTagArea>
      <Message error={error}>{message}</Message>
    </Container>
  );
};

export default TagArea;
