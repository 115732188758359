import { url } from 'inspector';
import styled, { css } from 'styled-components';
import media from 'utils/mediaqueries';
import { withAccess } from 'presentation/hooks/access';
import { Button } from '../UI';
// import Skeleton from '@material-ui/lab/Skeleton';

interface HoverProps {
  isHovering?: boolean;
  skeleton?: boolean;
  imageUrl?: string;
}

export const Container = styled.div<HoverProps>`
  max-width: 32.4rem;
  width: 100%;
  height: fit-content;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 2.4rem;
  margin-bottom: 3.2rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: all 0.2s ease-in;
  transition-delay: 0.5s 0s;

  ${media.lessThan('sm')} {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }

  ${({ skeleton }) =>
    !skeleton &&
    css`
      &:hover {
        /* z-index: 3; */
        height: fit-content;
        transform: scale(1.1);

        /* padding-bottom: 3rem; */
        background-color: ${({ theme }) => theme.colors.white2};
        border-radius: 5px 5px 5px 5px;
      }
    `}
`;

export const Image = styled.div<HoverProps>`
  width: 100%;
  height: 194px;
  display: flex;
  background-image: ${({ imageUrl }) =>
    imageUrl ? `url(${imageUrl})` : 'url(https://via.placeholder.com/356x200)'};
  background-size: cover;
  background-repeat: no-repeat;
  flex-direction: column;
  border-radius: 5px 5px 0px 0px;
  opacity: 0.7;
  align-items: center;
  z-index: 0;
  position: relative;

  cursor: pointer;

  ${media.lessThan('sm')} {
    border-radius: 0;
  }

  .icon {
    width: 50px;
    height: 50px;
    ${props =>
      props.isHovering &&
      css`
        display: none;
      `}
  }

  .shade {
    width: 100%;
    height: 100%;
    /* background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.9)
    ); */
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px 5px 0px 0px;
    z-index: -1;
    opacity: 0.7;
  }
`;

export const Buttons = styled.div<HoverProps>`
  display: flex;
  width: 100%;
  height: 185px;
  align-items: flex-end;
  justify-content: right;
  margin-right: ${({ isHovering }) => isHovering && '1.5rem'};

  z-index: 4;

  button + button {
    margin-left: 1.5rem;
  }
`;

export const Header = styled.div<HoverProps>`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  width: 100%;
  font-family: 'Poppins';
  margin-bottom: 42px;
  position: relative;

  ${props =>
    props.isHovering &&
    css`
      margin-top: 42px;
    `}
`;

export const Live = styled.div<HoverProps>`
  background: #05060c;
  border-radius: 0px 5px;
  padding: 1rem;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  gap: 7px;
`;

export const Footer = styled.div<HoverProps>`
  background-color: ${({ theme }) => theme.colors.black1};
  width: 100%;
  /* height: ${props => (props.isHovering ? '7rem' : '7.4rem')}; */
  padding: ${props => (props.isHovering ? '0 16px 12px 16px' : '0')};

  ${media.lessThan('sm')} {
    padding: 8px 1.2rem;
  }

  .sub-content {
    font-size: 15px;
    color: ${({ theme }) => theme.colors.white6};
  }

  ${({ isHovering }) =>
    isHovering &&
    css`
      background: ${({ theme }) => theme.colors.black2};
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    `}
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  font: ${({ theme }) => theme.typography.h4};
  margin-top: 1.2rem;

  ${media.lessThan('sm')} {
    margin: 0;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 1rem;
  }

  .live {
    color: #fe3980;
    border: 2px solid #fe3980;
    padding: 3px 6px;
    border-radius: 5px;
    font: ${({ theme }) => theme.typography.h7};
    text-align: center;
  }
`;

export const Entity = styled.div`
  display: flex;
  margin-top: 4px;

  .name {
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
  }

  .time {
    font-weight: normal;
    font-size: 12px;
  }
`;

export const Avatar = styled.div`
  background: #c4c4c4;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
`;

export const Info = styled.div<HoverProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid red;

  font: ${({ theme }) => theme.typography.h7};

  ${props =>
    !props.isHovering &&
    css`
      display: none;
    `}
`;

export const Description = styled.div`
  display: inline-block;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 2.5rem;
`;

export const Tags = styled.div`
  display: flex;
  height: fit-content;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-top: 3.6rem;
`;

export const Tag = styled.div`
  background: ${({ theme }) => theme.colors.black5};
  padding: 0.6rem 2rem;
  border-radius: 0.5rem;
  font: ${({ theme }) => theme.typography.h5};
`;

export const DescriptionCards = styled.p`
  margin-top: 1rem;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 16px;
  font-family: 'Poppins';
`;

export const SubDescription = styled.p`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const CounterPerson = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  font-family: 'Poppins';
`;

export const DateEvent = styled.div`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-family: 'Poppins';
`;

export const AuthorizedButton = withAccess(styled(Button)``);
