/* eslint-disable react/require-default-props */
import {
  IconChatDisabled,
  IconCoin,
  IconGreyUser,
  IconMenu,
  IconSearch,
  IconStore,
  LogoNetfans,
  NotLoggedUser,
} from 'presentation/base/icons';
import ProfileMenu from 'presentation/components/ProfileMenu/ProfileMenu';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Drawer } from '@material-ui/core';
import { iStore } from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { makeReduxLogout } from 'main/factories/usecases/auth/LogoutFactory';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import { Input, Popover } from 'presentation/components/UI';
import Button from 'presentation/components/UI/Button';
import { ArrowDown } from 'presentation/pages/Room/styles';
import { Link, useHistory } from 'react-router-dom';
import { closeModal } from 'utils/modalFunctions';
import CategoriesMenu from '../CategoriesMenu';
import { translator } from '../i18n';
import { Notifications } from '../Notifications';
import {
  Actions,
  Avatar,
  AvatarResponsive,
  ButtonGroup,
  Container,
  LinkContent,
  LinkGroup,
  LoggedActions,
  LogoContent,
  MiniMenu,
  MiniSearch,
  MobileLogoContent,
  RowContainer,
  SearchInput,
  StyledButton,
  Title,
} from './styles';
import { RandomProfileUser } from '../randomProfileUser';
import { Body } from '../EventPreview/styles';
import { HeaderProps } from './types';
import DrawerMenu from '../DrawerMenu/DrawerMenu';

/* const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        width: theme.spacing(4),
        height: theme.spacing(4),
      },
    },
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: '3f4660',
    },
  }),
); */

const Header: React.FC<HeaderProps> = ({ notFixed }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openUserMenu, setOpenUserMenu] = useState(false);
  const [openCategories, setOpenCategories] = useState(false);
  const [search, setSearch] = useState<string>('');

  const onlyDemo = window.config?.featureToggles?.onlyDemo || false;

  const info = useSelector((store: iStore) => store.auth.user);
  const { wrtcInfo } = useSelector((store: iStore) => store.auth);

  /* const classes = useStyles(); */
  const history = useHistory();

  const handleSubmit = () => {
    makeReduxActiveMessage().active({
      active: MessageOptions.loginModal,
      actionOk: () => closeModal(),
      actionCancel: () => closeModal(),
    });
  };

  const handleSubmitRegister = () => {
    makeReduxActiveMessage().active({
      active: MessageOptions.registerModal,
      actionOk: () => {
        closeModal();
      },
      actionCancel: () => {
        closeModal();
      },
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenUserMenu(false);
  };

  const handleSearch = () => {
    if (search.length) {
      // history.push(`/search?term=${search}`);
    }
  };

  return (
    <Container notFixed={notFixed}>
      <RowContainer>
        <MiniMenu>
          <button
            type="button"
            onClick={() => {
              setOpenCategories(true);
            }}
          >
            <IconMenu />
          </button>
        </MiniMenu>
        <LogoContent>
          {onlyDemo ? (
            <a
              href="https://netfans.tv"
              target="_blank"
              data-testid="logo"
              rel="noreferrer"
            >
              <LogoNetfans />
              <Title className="sports">Netfans</Title>
            </a>
          ) : (
            <Link to="/" data-testid="logo">
              <LogoNetfans />
              <Title className="sports">Netfans</Title>
            </Link>
          )}
        </LogoContent>
        {/* <MiniMenu>
          <button
            type="button"
            onClick={() => {
              setOpenCategories(true);
            }}
          >
            <IconMenu />
          </button>
        </MiniMenu> */}

        {/* <MenuContainer>
          <Menu>
            <MenuItem disableRipple>Edit</MenuItem>
            <MenuItem disableRipple>Edit</MenuItem>
            <MenuItem disableRipple>Edit</MenuItem>
            <MenuItem disableRipple>Edit</MenuItem>
            <MenuItem disableRipple>Edit</MenuItem>
            <MenuItem disableRipple>Edit</MenuItem>
          </Menu>
        </MenuContainer> */}

        {/* <LinkGroup>
          {onlyDemo ? (
            <a
              href="https://netfans.tv"
              target="_blank"
              data-testid="button-sports"
              rel="noreferrer"
            >
              <LinkContent className="sports">
                {translator('Esportes')}
              </LinkContent>
            </a>
          ) : (
            <Link to="/sports" data-testid="button-sports">
              <LinkContent className="sports">
                {translator('Esportes')}
              </LinkContent>
            </Link>
          )}

          {onlyDemo ? (
            <a href="https://netfans.tv" target="_blank" rel="noreferrer">
              <LinkContent className="music">
                {translator('Música')}
              </LinkContent>
            </a>
          ) : (
            <Link to="/music">
              <LinkContent className="music">
                {translator('Música')}
              </LinkContent>
            </Link>
          )}

          {onlyDemo ? (
            <a href="https://netfans.tv" target="_blank" rel="noreferrer">
              <LinkContent className="entertainment">
                {translator('Entretenimento')}
              </LinkContent>
            </a>
          ) : (
            <Link to="/entertainment">
              <LinkContent className="entertainment">
                {translator('Entretenimento')}
              </LinkContent>
            </Link>
          )}
        </LinkGroup> */}
        <SearchInput>
          <Input
            id="filter"
            placeholder="Pesquisar no Netfans"
            isSearch
            onChange={e => setSearch?.(e.target.value)}
            value={search}
          >
            <StyledButton
              iconSize={14}
              size="very small"
              colorstyle="black4"
              icon={IconSearch}
              data-testid="button-test"
              onClick={handleSearch}
            />
          </Input>
        </SearchInput>
      </RowContainer>
      <ButtonGroup>
        <MiniSearch>
          <button type="button">
            <IconSearch />
          </button>
        </MiniSearch>
        {/* <Button
            title="Loja"
            colorstyle="black5"
            size="small"
            style={{ marginLeft: '21.1rem' }}
            onClick={handleSubmit}
            icon={StoreIcon}
          /> */}
        {!info?.email ? (
          <Actions>
            <Button
              id="btn_login"
              title="Entrar"
              colorstyle="black3"
              className="button"
              style={{ marginLeft: '3px' }}
              onClick={handleSubmit}
            />
            <Button
              id="btn_login"
              title="Cadastrar-se"
              className="button"
              onClick={handleSubmitRegister}
              style={{ marginLeft: '10px' }}
            />

            <Button
              iconSize={20}
              icon={NotLoggedUser}
              className="teste"
              onClick={handleSubmit}
              size="very small"
              colorstyle="black2"
              style={{ marginLeft: '10px' }}
            />
          </Actions>
        ) : (
          <>
            {/* <LoggedActions>
              <Notifications />
              <button disabled type="button" style={{ paddingLeft: '2px' }}>
                <IconChatDisabled />
              </button>
              <button disabled type="button">
                <IconStore />
              </button>
            </LoggedActions> */}
            <AvatarResponsive onClick={() => setOpenUserMenu(true)}>
              {wrtcInfo?.peerId ? (
                <RandomProfileUser
                  size="34px"
                  containerStyle={{ width: 'initial' }}
                  peerId={wrtcInfo?.peerId}
                />
              ) : (
                <IconGreyUser width={34} height={34} />
              )}
            </AvatarResponsive>
            <Avatar onClick={() => setOpenUserMenu(true)}>
              {wrtcInfo?.peerId ? (
                <RandomProfileUser
                  size="34px"
                  containerStyle={{ width: 'initial' }}
                  peerId={wrtcInfo?.peerId}
                />
              ) : (
                <IconGreyUser width={34} height={34} />
              )}
              <div className="info">
                <div className="name">{`${info.username}`}</div>
                <div>
                  <IconCoin />
                  <div className="value">1.000.000</div>
                </div>
              </div>
              <div className="icon">
                <ArrowDown />
              </div>
            </Avatar>
            <Drawer
              open={openUserMenu}
              anchor="right"
              onClose={() => setOpenUserMenu(false)}
            >
              <DrawerMenu
                userName={info.fullName ?? ''}
                handleLogout={() => {
                  makeReduxLogout().logout();
                  handleClose();
                }}
              />
            </Drawer>
            <Drawer
              anchor="right"
              open={openUserMenu}
              onClose={() => setOpenUserMenu(false)}
            >
              <DrawerMenu
                userName={info.fullName ?? ''}
                handleLogout={() => {
                  makeReduxLogout().logout();
                  handleClose();
                }}
              />
            </Drawer>
          </>
        )}
        <Drawer
          anchor="left"
          open={openCategories}
          onClose={() => setOpenCategories(false)}
        >
          <CategoriesMenu />
        </Drawer>
      </ButtonGroup>
    </Container>
  );
};
export default Header;
