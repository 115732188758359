import { call, put, select } from 'redux-saga/effects';
import { iActionCreate } from 'domain/interfaces/redux/layout/create';
import { CreateLayout } from 'domain/usecases/layout/remote/CreateLayout';
import { makeRemoteCreateLayout } from 'main/factories/usecases/layout/CreateLayoutFactory';
import {
  createSuccess,
  createFailed,
} from 'data/store/reducer/layout/actions/create';

export function* onCreate(action: iActionCreate) {
  const remoteCreateLayout = makeRemoteCreateLayout();

  try {
    const response: CreateLayout.Model = yield call(
      remoteCreateLayout.create,
      action.payload,
    );
    yield put(
      createSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(createFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onCreateSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onCreateFailed() {}
