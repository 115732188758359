import { CreateSponsor } from 'domain/usecases/sponsor/redux/CreateSponsor';
import {
  iActionCreate,
  iActionCreateFailed,
  iActionCreateSuccess,
  SponsorTypes,
} from 'domain/interfaces/redux/sponsor/create';

export const createRequest = (
  payload: CreateSponsor.Params,
): iActionCreate => ({
  type: SponsorTypes.CREATE,
  payload,
});

export const createSuccess = (
  params: CreateSponsor.Model,
): iActionCreateSuccess => ({
  type: SponsorTypes.CREATE_SUCCESS,
  payload: params,
});

export const createFailed = (): iActionCreateFailed => ({
  type: SponsorTypes.CREATE_FAILED,
});
