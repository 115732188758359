import styled from 'styled-components';

export const AnimationContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 6rem;
  top: -4rem;
  right: -2.5rem;
  z-index: 1;

  img {
    width: 6rem;
    height: 6rem;
    object-fit: cover;
  }
`;
