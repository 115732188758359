import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import Demo from '../../../../presentation/pages/Demo';
import { makeReduxGetAllFriendship } from '../../usecases/friendship/GetAllFriendshipFactory';

export const DemoFactory: React.FC = () => {
  const { id } = useSelector((store: iStore) => store.auth.user);

  useEffect(() => {
    if (id) makeReduxGetAllFriendship().getAll({ user: Number(id) });
  }, []);

  return <Demo eventId="9999" roomName="1" />;
};
