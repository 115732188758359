import styled, { css } from 'styled-components';
import media from 'utils/mediaqueries';

interface RoomContainerProps {
  layout?: 'lounge' | 'cine' | 'theater' | 'arena';
  containerSize?: string; // 'small' | 'medium' | 'large' | 'default'; // | String;
  isRoomsPage?: boolean;
  embed?: boolean;
}

export const RoomContainer = styled.div<RoomContainerProps>`
  width: ${({ containerSize, isRoomsPage }) => {
    if (isRoomsPage) return 'inherit';

    switch (containerSize) {
      case 'smaller':
        return 'none';
      case 'small':
        return '300px';
      case 'medium':
        return '100%';
      case 'large':
        return '720px';
      default:
        return '1080px';
    }
  }};

  /* max-width: 1920px; */
  /* max-width: 1080px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: ${({ isRoomsPage }) => (isRoomsPage ? '732px' : '100%')}; */
  width: 100%;
  height: ${({ containerSize }) => {
    switch (containerSize) {
      case 'small':
        return '100%';
      case 'medium':
        return '100%';
      case 'large':
        return '100%';
      default:
        return '100%';
    }
  }};
  /* margin-top: 6rem; */
  margin-top: ${({ containerSize }) => {
    switch (containerSize) {
      case 'smaller':
        return '0px';
      case 'small':
        return '0px';
      case 'medium':
        return '0px';
      case 'large':
        return '0px';
      default:
        return '0rem';
    }
  }};
  padding: 0
    ${({ containerSize, isRoomsPage }) => {
      if (isRoomsPage) return '0';

      switch (containerSize) {
        case 'smaller':
          return '0rem';
        case 'small':
          return '0rem';
        case 'medium':
          return '1.4rem';
        case 'large':
          return '1.4rem';
        default:
          return '7.4rem';
      }
    }};
  justify-content: start;
  overflow: ${({ isRoomsPage }) => (isRoomsPage ? 'auto' : 'hidden')};

  ${({ layout }) => {
    return layout === 'theater' || layout === 'arena'
      ? css`
          margin: 0;
          .room-grid-container {
            margin: 0;
          }

          .player {
            /* max-width: 575px; */
            max-width: 50rem;
            height: 345px;
            /* padding: 3.3rem; */
            /* padding-bottom: 3rem; */
            background: none;
          }
        `
      : null;
  }}

  /* Alinha a esquerda para scroll horizontal funcionar no layout ARENA, considerar outros layouts */
  ${media.lessThan('1120px')} {
    align-items: ${({ isRoomsPage }) => isRoomsPage && 'flex-start'};
    width: ${({ isRoomsPage, embed }) =>
      isRoomsPage && !embed ? '614px' : '100%'};
    height: 100%;
    overflow: auto;
  }

  /* Alinha a esquerda para scroll vertical funcionar no layout ARENA, considerar outros layouts */
  ${media.lessThan('830px')} {
    justify-content: ${({ isRoomsPage }) => isRoomsPage && 'flex-start'};
  }

  ${media.lessThan('sm')} {
    align-items: ${({ layout }) => layout === 'cine' && 'center'};
    justify-content: ${({ layout }) => layout === 'cine' && 'center'};
    margin-top: 0;
  }

  /* Alinha a esquerda para scroll horizontal funcionar no layout CINE, considerar outros layouts */
  ${media.lessThan('xs')} {
    align-items: ${({ isRoomsPage }) => isRoomsPage && 'flex-start'};
  }
`;

export const RoomTabContainer = styled.div<RoomContainerProps>`
  background: #252424;
  color: #fbfbfb;
  border-radius: 10px 10px 0px 0px;
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  /* margin-top: ${({ containerSize }) => {
    // eslint-disable-next-line no-console
    switch (containerSize) {
      case 'smaller':
        return '1rem';
      case 'small':
        return '1rem';
      default:
        return '3rem';
    }
  }}; */
`;

export const RoomBodyContainer = styled.div`
  background: #1e1e1e;
  padding: 0px 32px 32px 32px;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

export const RoomContainerDiv = styled.div<RoomContainerProps>`
  display: grid;
  grid-template-columns: 162px 1fr 1fr 1fr 162px;
  grid-template-rows: 108px 1fr 1fr 1fr 108px;
  /* height: 100%; */
  max-height: 300px;
  height: 100%;
  width: 100%;
  gap: 10px;
  /* padding: 108px 162px; */
  /* align-items: center;
  justify-content: center;
  background: #1e1e1e;
  border-radius: 0px 0px 10px 10px;
  padding: 10px 32px 32px 32px; */
  /* margin-top: 3rem;
  margin-bottom: 4rem; */

  margin-bottom: ${({ containerSize }) => {
    switch (containerSize) {
      case 'smaller':
        return '0rem';
      case 'small':
        return '0rem';
      default:
        return '4rem';
    }
  }};
`;

export const RoomGridContainer = styled.div.attrs({
  className: 'room-grid-container',
})`
  /* width: 53.6rem; */
  /* height: 30%; */
  /* margin-top: 30px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const PlayerDiv = styled.div<RoomContainerProps>`
  width: ${({ containerSize }) => {
    switch (containerSize) {
      case 'smaller':
        return '15rem';
      case 'small':
        return '20rem';
      case 'medium':
        return '20rem';
      case 'large':
        return '720px';
      default:
        return '50rem';
    }
  }};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40%;
  min-height: 85px;
  align-self: center;
  position: relative;
  margin-bottom: 2px;
  background: ${({ theme }) => `linear-gradient(
    180deg,
    ${theme.colors.black4} 0%,
    ${theme.colors.black3} 50%,
    ${theme.colors.black4} 100%
    )`};

  ${media.lessThan('sm')} {
    width: 40rem;
    height: 80%;
    background: none;
    /* display: none; */
  }
`;

export const ArenaLayoutDiv = styled.div<RoomContainerProps>`
  max-height: ${({ containerSize }) => {
    switch (containerSize) {
      case 'smaller':
        return '130px';
      case 'small':
        return '100px';
      case 'medium':
        return '20rem';
      case 'large':
        return '720px';
      default:
        return '323px';
    }
  }};
  display: flex;
  align-items: center;
  // height: '323px;
  //height: 100px; // small
  gap: 2rem;
`;

export const RoomNameContainer = styled.div`
  display: flex;
  padding: 5px 10px;

  background: ${({ theme }) => theme.colors.black4};
  border-radius: 5px;
`;

export const RoomName = styled.h3`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
`;
