import { call, put, select } from 'redux-saga/effects';
import { iActionGetAll } from 'domain/interfaces/redux/reaction/getAll';
import { GetAllReaction } from 'domain/usecases/reaction/remote/GetAllReaction';
import { makeRemoteGetAllReaction } from 'main/factories/usecases/reaction/GetAllReactionFactory';
import {
  getAllSuccess,
  getAllFailed,
} from 'data/store/reducer/reaction/actions/getAll';

export function* onGetAll(action: iActionGetAll) {
  const remoteGetAllReaction = makeRemoteGetAllReaction();

  try {
    const response: GetAllReaction.Model = yield call(
      remoteGetAllReaction.getAll,
      action.payload,
    );
    yield put(
      getAllSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(getAllFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetAllSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetAllFailed() {}
