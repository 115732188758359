import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import { makeReduxUpdateWRTCInfo } from 'main/factories/usecases/auth/UpdateWRTCInfoFactory';
import { makeReduxGetAllCategory } from 'main/factories/usecases/category/GetAllCategoryFactory';
import { makeReduxGetAllEvent } from 'main/factories/usecases/event/GetAllEventFactory';
import EntertainmentPage from 'presentation/components/MainPage/EntertainmentPage';

const Entertainment: React.FC = () => {
  const wrtc = useSelector((store: iStore) => store.wrtc);

  useEffect(() => {
    // Desconectar o usuário de qualquer sala quando o mesmo estiver na seleção de páginas
    if (wrtc.leaveRoom && wrtc.leaveFloor) {
      makeReduxUpdateWRTCInfo().update({ insideRoom: false });
      wrtc.leaveRoom();
      wrtc.leaveFloor();
    }
  }, [wrtc]);

  useEffect(() => {
    makeReduxGetAllEvent().getAll({
      limit: 9999,
    });
    makeReduxGetAllCategory().getAll({});
  }, []);

  return <EntertainmentPage />;
};

export default Entertainment;
