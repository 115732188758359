import {
  Controls,
  Player as PlayerLottie,
} from '@lottiefiles/react-lottie-player';
import { iMessage } from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import { IconCalendarCircle, IconKey, IconLink } from 'presentation/base/icons';
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import InputCopyValue from '../../InputCopyValue';
import { Button } from '../../UI';
import { Container as ModalContainer } from '../../messages/Background';
import {
  Body,
  ConfettiContainer,
  Content,
  Divider,
  Footer,
  InfoTitle,
  Inputs,
  ModalContent,
  TitleMessage,
  ValueContainer,
} from './styles';

interface props {
  message: iMessage;
}

const SuccessOnCreateEvent: React.FC<props> = ({ message }) => {
  const { active, content, actionOk } = message;
  const isActive = active === MessageOptions.successOnCreateEvent;

  const history = useHistory();

  const data: {
    name: string;
    date: string;
    url: string;
    accessKey: string;
    mediaType: 'LIVE' | 'VOD' | 'EXTERNAL';
  } = JSON.parse(content ?? '{}');

  const handleCreateNewEvent = () => {
    makeReduxActiveMessage().active({
      active: MessageOptions.none,
    });
    actionOk?.();
  };

  const dateToFormat = useMemo(() => {
    return data && data?.date ? new Date(data.date) : new Date();
  }, [data]);

  const dateFormatted = Intl.DateTimeFormat('pt-BR', {
    dateStyle: 'long',
  }).format(dateToFormat);

  const hourFormatted = new Date(data?.date).toLocaleTimeString('pt-BR', {
    hour: '2-digit',
    minute: '2-digit',
  });

  const handleBackToHome = useCallback(() => {
    makeReduxActiveMessage().active({
      active: MessageOptions.none,
    });

    setTimeout(() => {
      window.location.pathname = '/';
      window.location.reload();
    }, 500);
  }, []);

  return isActive ? (
    <ModalContainer tabIndex={0}>
      <ConfettiContainer>
        <PlayerLottie
          autoplay
          loop
          src="https://assets3.lottiefiles.com/packages/lf20_h7q0t9pm.json"
        >
          <Controls visible={false} />
        </PlayerLottie>
      </ConfettiContainer>
      <ModalContent>
        <Body className="body">
          <Content className="content">
            <TitleMessage variant="primary">
              <IconCalendarCircle className="icon-calendar-success-create-event" />
              <p className="message">Evento agendado!</p>
              <span className="subtitle">
                {`O evento "${data.name}" foi agendado para o dia ${dateFormatted}, às ${hourFormatted}`}
              </span>
            </TitleMessage>
          </Content>
          {data.mediaType === 'LIVE' && (
            <>
              <Divider />
              <ValueContainer>
                <InfoTitle>Aqui estão a URL e a chave de acesso:</InfoTitle>
                <Inputs>
                  <InputCopyValue value={data.url} leftIcon={<IconLink />} />
                  <InputCopyValue
                    value={data.accessKey}
                    leftIcon={<IconKey />}
                  />
                </Inputs>
              </ValueContainer>
            </>
          )}
        </Body>
        <Footer>
          <Button
            size="dynamic"
            className="btn-template create"
            title="Agendar outro evento"
            colorstyle="blue2"
            type="button"
            onClick={handleCreateNewEvent}
          />
          <Button
            size="dynamic"
            className="btn-template cancel"
            title="Voltar para o início"
            colorstyle="black3"
            type="button"
            onClick={handleBackToHome}
          />
        </Footer>
      </ModalContent>
    </ModalContainer>
  ) : null;
};

export default SuccessOnCreateEvent;
