import { SendMiniatureEvent } from 'domain/usecases/event/remote';
import { UnexpectedError, BadRequestError, NotFound } from 'domain/errors';
import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import 'infra/global/variables';

export class RemoteSendMiniatureEvent implements SendMiniatureEvent {
  private readonly url: string;

  private readonly httpClient: HttpClient<SendMiniatureEvent.Model>;

  constructor(url: string, httClient: HttpClient<SendMiniatureEvent.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  sendMiniature = async (
    params: SendMiniatureEvent.Params,
  ): Promise<SendMiniatureEvent.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}upload`,
      method: 'post',
      body: params.body,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      default:
        throw new UnexpectedError();
    }
  };
}
