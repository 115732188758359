import { iMessage } from 'domain/interfaces/models/Message';
import { MessageTypes } from 'domain/interfaces/redux/message/types';
import { MessageActions } from './actions';

export const initialState: iMessage = {
  active: 'none',
  actionOk: () => '',
  actionCancel: () => '',
};

const reducer = (state = initialState, action: MessageActions): iMessage => {
  switch (action.type) {
    case MessageTypes.MESSAGE_ACTIVE: {
      return { ...state, ...action.payload };
    }
    case MessageTypes.SET_DATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default reducer;
