import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { CreateUser as UsecaseRemoteCreateUser } from 'domain/usecases/user/remote';

import { RemoteCreateUser } from 'data/repository/user';

/**
 * send request via API.
 */
export const makeRemoteCreateUser = (): UsecaseRemoteCreateUser =>
  new RemoteCreateUser(makeApiUrl('/users/'), makeHttpClient());
