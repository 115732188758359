import styled from 'styled-components';

export const BoxToPreviewTemplate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* height: 26.3rem; */
  background: ${({ theme }) => theme.colors.black5};
  border-radius: 0.5rem;
  border: 0.2rem dashed ${({ theme }) => theme.colors.white5};
  margin-top: 2rem;
  padding: 3rem 6rem;

  @media (max-width: 425px) {
    padding: 2rem;
  }
`;

export const StButtonToAddNewTemplate = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.85rem 1.2rem;

  border: 0;
  outline: 0;
  background: ${({ theme }) => theme.colors.black4};

  border-top: 0.1rem solid ${({ theme }) => theme.colors.black3};

  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.white1};

  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;

  p {
    margin: 0;
    margin-left: 0.8rem;
  }

  cursor: pointer;

  transition: background 0.2s;

  &:hover {
    background: ${({ theme }) => theme.colors.blue2};
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.black1};
    color: ${({ theme }) => theme.colors.white5};
    cursor: not-allowed;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;

  margin-top: 20px;

  gap: 15px;
`;

interface CheckBoxStyledProps {
  active?: boolean;
}

export const CheckBoxStyled = styled.div<CheckBoxStyledProps>`
  padding: 3px;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #0d1222;

  border: ${({ active }) =>
    active ? '2px solid #ffffff' : '2px solid #0d1222'};

  border-radius: 50%;
`;

interface CircleFillProps {
  color: string;
}

export const CircleFill = styled.div<CircleFillProps>`
  width: 10px;
  height: 10px;

  background-color: ${({ color }) => color};

  border-radius: 5px;
`;
