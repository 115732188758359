import { LoginWithGoogle } from 'domain/usecases/auth/remote';
import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import { UnexpectedError, BadRequestError, NotFound } from 'domain/errors';
import { AlertMessage } from 'presentation/components/messages/AlertMessage';

export class RemoteAuthLoginWithGoogle implements LoginWithGoogle {
  private readonly url: string;

  private readonly httpClient: HttpClient<LoginWithGoogle.Model>;

  constructor(url: string, httpClient: HttpClient<LoginWithGoogle.Model>) {
    this.url = url;
    this.httpClient = httpClient;
  }

  loginWithGoogle = async (
    params: LoginWithGoogle.Params,
  ): Promise<LoginWithGoogle.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
      case HttpStatusCode.unauthorized:
      case HttpStatusCode.forbidden:
        AlertMessage({ message: 'Credenciais inválidas!', type: 'danger' });
        throw new NotFound();
      default:
        AlertMessage({ message: 'Falha ao realizar login!', type: 'danger' });
        throw new UnexpectedError();
    }
  };
}
