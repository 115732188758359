/* eslint-disable react/require-default-props */
import React, { HTMLAttributes } from 'react';
import { IconVirtualAudience, CloseIcon } from 'presentation/base/icons';
import { EventRoomProps, UserProps } from './interface';
import {
  Header,
  RoomName,
  HeaderTitle,
  InfoContainer,
  ActionContainer,
  IconMinus,
  LeftSide,
} from '../styles/StyledVirtualAudienceModal';

interface VirtualAudienceProps extends HTMLAttributes<HTMLDivElement> {
  handleClose: () => void;
  handleMinimal: () => void;
  user?: UserProps;
  event?: EventRoomProps;
}

const VirtualAudienceHeader: React.FC<VirtualAudienceProps> = ({
  user,
  event,
  handleClose,
  handleMinimal,
}) => {
  return (
    <Header>
      <LeftSide>
        <IconVirtualAudience width="45px" height="45px" />
        <InfoContainer>
          <HeaderTitle>{user ? `${user.name}` : 'BladeRunner2048'}</HeaderTitle>
          <RoomName>
            {event ? `${event.name} | Sala 1` : 'Título da sessão | Sala 1'}
          </RoomName>
        </InfoContainer>
      </LeftSide>
      <ActionContainer>
        <IconMinus id="icon_minus" onClick={handleMinimal} />
        <CloseIcon id="icon_close" onClick={handleClose} />
      </ActionContainer>
    </Header>
  );
};

export default VirtualAudienceHeader;
