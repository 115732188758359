import { takeLatest } from 'redux-saga/effects';
import { FriendshipTypes } from 'domain/interfaces/redux/friendship/types';
import { onGetAll, onGetAllSuccess, onGetAllFailed } from './GetAll';

const tasks = [
  takeLatest(FriendshipTypes.GETALL, onGetAll),
  takeLatest(FriendshipTypes.GETALL_SUCCESS, onGetAllSuccess),
  takeLatest(FriendshipTypes.GETALL_FAILED, onGetAllFailed),
];

export default tasks;
