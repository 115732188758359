import styled from 'styled-components';

import { IconBackArrow } from 'presentation/base/icons';

interface CarouselProps {
  isHovering?: boolean;
  last?: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100%;
  flex-direction: column;
  background-color: ${props => props.theme.colors.black1};

  padding-bottom: 30rem;
`;

export const SearchContainer = styled.div`
  width: 100%;
  margin-top: 3rem;
  padding: 0 8.5rem 0 8.5rem;
`;

export const SearchHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SearchTitle = styled.div`
  display: flex;
  align-items: center;
  color: white;
  font-size: 2.4rem;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  gap: 1rem;
`;

export const BackArrow = styled(IconBackArrow)`
  cursor: pointer;
  width: 2rem;
  height: 2rem;
`;

export const Divider = styled.div`
  width: 4px;
  height: 3.4rem;
  background: #3f4660;
`;

export const FilterButton = styled.div`
  width: 10.2rem;
  height: 3.2rem;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-family: 'Poppins', sans-serif;
  color: white;
  border-radius: 5px;
  border: 2px solid #3f4660;
  cursor: pointer;
`;

export const NotFoundSearch = styled.div`
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 17.2rem;
`;

export const ContentIcon = styled.div`
  border-radius: 1rem;
  padding: 5rem 5.1rem 5rem 5.1rem;
  background: #202433;
  opacity: 0.3;
`;

export const NotFoundResultSearch = styled.p`
  margin-top: 3rem;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: white;
  text-align: center;
`;

export const Suggestion = styled.p`
  width: 30rem;
  margin-top: 1.5rem;
  font-family: 'Poppins', sans-serif;
  color: white;
  text-align: center;
`;

export const ContainerFilteredEvents = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
`;

export const CarouselExample2 = styled.div<CarouselProps>`
  width: 41.5rem;
  height: ${props => (props.isHovering ? '30rem' : '30rem')};

  display: flex;
  gap: 3rem;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 60px;
  margin-top: 30px;
  margin-bottom: ${props => (props.last ? '60px' : '0')};

  overflow: hidden;
  position: relative;

  &:hover {
    overflow: ${props => (props.isHovering ? 'visible' : 'hidden')};
    z-index: 5;
  }
`;
