import { iWrtcClient } from 'domain/interfaces/models/WrtcClient';
import { WrtcClientTypes } from 'domain/interfaces/redux/wrtcClient/types';
import { WrtcClientActions } from './actions';

export const initialState: iWrtcClient = {} as iWrtcClient;

const reducer = (
  state = initialState,
  action: WrtcClientActions,
): iWrtcClient => {
  switch (action.type) {
    case WrtcClientTypes.UPDATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default reducer;
