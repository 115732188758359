import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export const schema = Yup.object()
  .shape({
    name: Yup.string().required('O Nome é obrigatório'),
    date: Yup.date().required('A data de nascimento é obrigatória'),
    email: Yup.string().email().required('O e-mail é obrigatório'),
    userName: Yup.string().required('O nome de usuário é obrigatório'),
    password: Yup.string()
      .required('A senha é obrigatória')
      .min(6, 'A senha precisa ter no mínimo 6 caracteres.'),
  })
  .required();

export const schemaRegisterUser = yupResolver(schema);
