export const MessageMiddleware = () => {
  // Socket param is the client. We'll show how to set this up later.
  return ({ dispatch, getState }: any) =>
    (next: any) =>
    (action: any) => {
      if (typeof action === 'function') {
        return action(dispatch, getState);
      }

      return next(action);
    };
};
