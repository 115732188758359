import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { DeleteFriendship as UsecaseRemoteDeleteFriendship } from 'domain/usecases/friendship/remote';
// import { DeleteFriendship as UsecaseReduxDeleteFriendship } from 'domain/usecases/friendship/redux';

import { RemoteDeleteFriendship } from 'data/repository/friendship';
// import { ReduxDeleteFriendship } from 'data/store/reducer/friendship/usecases';

/**
 * send request via API.
 */
export const makeRemoteDeleteFriendship = (): UsecaseRemoteDeleteFriendship =>
  new RemoteDeleteFriendship(
    makeApiUrl('/users/{user}/friendships/{friendship}'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
/* export const makeReduxDeleteFriendship =
  (): UsecaseReduxDeleteFriendship =>
    new ReduxDeleteFriendship(); */
