import { takeLatest } from 'redux-saga/effects';
import { AuthTypes } from 'domain/interfaces/redux/auth';
import * as Login from './Login';
import * as Refresh from './Refresh';

const tasks = [
  takeLatest(AuthTypes.LOGIN, Login.onLogin),
  takeLatest(AuthTypes.LOGIN_SUCCESS, Login.onLoginSuccess),
  takeLatest(AuthTypes.LOGIN_FAILED, Login.onLoginFailed),
  takeLatest(AuthTypes.REFRESH, Refresh.onRefresh),
  takeLatest(AuthTypes.REFRESH_SUCCESS, Refresh.onRefreshSuccess),
  takeLatest(AuthTypes.REFRESH_FAILED, Refresh.onRefreshFailed),
];

export default tasks;
