import styled from 'styled-components';

interface iMobileContentProps {
  isDisplayed: boolean;
}
export const Container = styled.div``;

export const MobileContentContainer = styled.div<iMobileContentProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ isDisplayed }) => (isDisplayed ? '70%' : '0')};
  background-color: ${({ theme }) => theme.colors.black2};
  position: absolute;
  z-index: 2;
  transition: ${({ isDisplayed }) =>
    isDisplayed ? 'height 0.400s ease-in' : 'height 0.400s ease-out'};
`;

export const MobileContentHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.1rem 2rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
`;

export const InfoRoomContent = styled.div`
  color: white;
  font-weight: bold;
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 1.5rem;
`;

export const InfoRoomSubtitle = styled.div`
  gap: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;

  span {
    display: flex;
    font-weight: normal;
    align-items: center;
    font-size: 12px;
    font-family: 'Poppins';
    font-weight: normal;
    opacity: 0.75;
  }
`;

export const MobileLeftHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

interface IEventText {
  bold?: boolean;
  marginRight?: boolean;
}

export const EventText = styled.h5<IEventText>`
  font-family: Poppins;
  font-weight: ${props => (props.bold ? 'bold' : 400)};
  font-size: 14px;
  line-height: 21px;

  margin-right: ${props => (props.marginRight ? '4px' : 0)};
  color: #ffffff;
`;

export const MobileContentBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  color: #fff;
  gap: 10px;
  padding: 10px 20px;
`;

export const Tags = styled.div`
  display: flex;
  height: fit-content;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding-top: 2.8rem;
  color: #fff;
`;

export const Tag = styled.div`
  background: transparent;
  padding: 0.6rem 2rem;
  border-radius: 0.5rem;
  font: ${({ theme }) => theme.typography.h5};
  overflow-x: auto;
  border: 1px solid rgba(255, 255, 255, 0.08);
`;

export const MobileContentBodyText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: '#fff';
  font-size: '12px';
  width: 100%;
  &:nth-child(2) {
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    padding-top: 11px;
  }
`;

export const Text = styled.span``;
