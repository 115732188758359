/* eslint-disable prefer-promise-reject-errors */
import { makeReduxGetAllCategory } from 'main/factories/usecases/category/GetAllCategoryFactory';
import { makeRemoteUpdateEvent } from 'main/factories/usecases/event/UpdateEventFactory';
import { CalendarHexIcon } from 'presentation/base/icons';
import GeneralData from 'presentation/components/EventsForm/GeneralData';
import { Button } from 'presentation/components/UI';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getDuration } from 'services/getHours';
import { schemaCreateEvent } from 'validation/validators/CreateEvent/CreateEventValidator';
import { makeRemoteSendMiniatureEvent } from 'main/factories/usecases/event/SendMiniatureEventFactory';
import { formatToIso } from 'utils/formattedDate';
import ContentData from './ContentData';
import EventRoom from './EventRoom';
import {
  Body,
  Container,
  Content,
  ContentPageNewEvent,
  Options,
  StepContent,
  SubTitle,
} from './NewEventStyled';

export interface iForm {
  categories: {
    categoryId: number;
    subCategory: string;
    type: 'TYPE' | 'CATEGORY' | 'SUBCATEGORY';
    childType: null | 'SINGLE' | 'MULTIPLE';
  };
  short: string;
  eventName: string;
  organization: string;
  media: string;
  description: string;
  layoutId: number;
  categoryId: number;
  subCategory: string;
  schedulingDay: string;
  schedulingMonth: string;
  schedulingYear: string;
  schedulingHourStart: string;
  keywords: string[];
  competitive: boolean;
  image: string;
  file: File;
  banner: File;
  joinPolicy: 'PUBLIC' | 'RESTRICTED' | 'AUTHENTICATED';
  paymentPolicy: 'FREE' | 'REQUIRED';
}

interface IFormCreateNewEvent {
  categories: {
    categoryId: number;
    subCategory: string;
    type: 'TYPE' | 'CATEGORY' | 'SUBCATEGORY';
    childType: null | 'SINGLE' | 'MULTIPLE';
  };
  short: string;
  eventName: string;
  organization: string;
  media: string;
  description: string;
  layoutId: {
    value: string;
    label: string;
  };
  categoryId: number;
  subCategory: string;
  schedulingDay: string;
  schedulingMonth: {
    value: string;
  };
  schedulingYear: string;
  schedulingHourStart: {
    value: string;
    label: string;
  };
  schedulingHourEnd: {
    value: string;
    label: string;
  };
  keywords: string[];
  competitive: boolean;
  image: string;
  miniature: File;
  banner: File;
  joinPolicy: 'PUBLIC' | 'RESTRICTED' | 'AUTHENTICATED';
  paymentPolicy: 'FREE' | 'REQUIRED';
  currentStep: number;
  multipleCategories: any;
  noForecast: boolean;
}

interface NewEventProps {
  event?: IFormCreateNewEvent;
}

const NewEditEvent: React.FC<NewEventProps> = ({ event }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [form, setForm] = useState<iForm>({} as iForm);

  const params = useParams<{ id: string }>();
  const history = useHistory();

  const formHook = useForm<IFormCreateNewEvent>({
    resolver: schemaCreateEvent,
    shouldFocusError: true,
    defaultValues: event,
  });

  const currentForm = useMemo(() => {
    const stepIndex: Record<number, JSX.Element> = {
      0: <GeneralData />,
      1: <ContentData eventForm={form} updateForm={setForm} />,
      2: <EventRoom />,
    };

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#202433',
            // height: '50px',
            padding: '3rem 3rem 1rem 3rem',
            gap: '30px',
          }}
        >
          <Options
            id="tab_participants"
            isActive={currentIndex === 0}
            onClick={() => setCurrentIndex(0)}
          >
            Transmissões
          </Options>
          <Options
            id="tab_friends"
            isActive={currentIndex === 1}
            onClick={() => setCurrentIndex(1)}
          >
            Conteúdo
          </Options>
          <Options
            id="tab_friends"
            isActive={currentIndex === 2}
            onClick={() => setCurrentIndex(2)}
          >
            Sala do evento
          </Options>
        </div>

        {stepIndex[currentIndex] || <div />}
      </div>
    );
  }, [form, currentIndex]);

  const createEvent = () => {
    if (form.layoutId >= 5) {
      toast.error(
        'O layout selecionado ainda não está disponível. Por favor, selecione outro.',
      );
      return;
    }

    const objectToSend = {
      eventId: Number(params?.id),
      descr: formHook.getValues().description,
      keywords: form.keywords,
      layoutId: formHook.getValues()?.layoutId?.value
        ? Number(formHook.getValues()?.layoutId?.value)
        : 1,
      media: formHook.getValues().media,
      name: formHook.getValues().eventName,
      organization: formHook.getValues().organization,
      schedule: formatToIso(
        formHook.getValues().schedulingYear,
        formHook.getValues().schedulingMonth?.value,
        formHook.getValues().schedulingDay,
        formHook.getValues()?.schedulingHourStart.label,
      ),
      // short: form.short,
      joinPolicy: formHook.getValues().joinPolicy,
      paymentPolicy: formHook.getValues().paymentPolicy,
      categories: formHook.getValues().categories,
      duration: formHook.getValues()?.noForecast
        ? 0
        : getDuration(
            formHook.getValues()?.schedulingHourStart.label,
            formHook.getValues()?.schedulingHourEnd.label,
          ),
    };

    makeRemoteUpdateEvent()
      .update(objectToSend)
      .then(() => {
        const formData = new FormData();
        const formDataBanner = new FormData();
        formData.append('file', formHook.getValues().miniature);
        formData.append('type', 'THUMBNAIL');

        formDataBanner.append('file', formHook.getValues().banner);
        formDataBanner.append('type', 'BANNER');

        const promiseSendMiniature = new Promise((resolve, reject) => {
          if (event?.miniature !== formHook.getValues().miniature) {
            makeRemoteSendMiniatureEvent()
              .sendMiniature({
                // id: Number(params?.id),
                // body: {
                //   // thumbnail: formHook.getValues().miniature,
                //   // media: formHook.getValues().media,
                //   // banner: formHook.getValues().banner,
                // },
              })
              .then(response => {
                resolve(response);
              })
              .catch(() => {
                reject('MINIATURE_ERROR');
              });
          }
        });

        const promiseSendBanner = new Promise((resolve, reject) => {
          if (event?.banner !== formHook.getValues().banner) {
            makeRemoteSendMiniatureEvent()
              .sendMiniature({
                // id: Number(params?.id),
              })
              .then(response => {
                resolve(response);
              })
              .catch(() => {
                reject('BANNER_ERROR');
              });
          }
        });

        Promise.all([promiseSendMiniature, promiseSendBanner]).catch(err => {
          if (err === 'MINIATURE_ERROR') {
            toast.error('Erro ao atualizar miniatura');
          }

          if (err === 'BANNER_ERROR') {
            toast.error('Erro ao atualizar banner');
          }
        });

        toast.success('Evento atualizado com sucesso!');
      })
      .catch(() => {
        toast.error('Não foi possível atualizar o evento');
      });
  };

  useEffect(() => {
    makeReduxGetAllCategory().getAll({});
  }, []);

  return (
    <FormProvider {...formHook}>
      <Container>
        <ContentPageNewEvent>
          <Content>
            <SubTitle>
              <CalendarHexIcon width="60px" height="60px" />
              Editar informações do evento
            </SubTitle>
            <form onSubmit={formHook.handleSubmit(() => createEvent())}>
              <StepContent>
                <div>
                  <Button
                    title="Voltar"
                    type="button"
                    size="small"
                    colorstyle="black5"
                    onClick={() => history.push('/')}
                  />
                </div>
                <div>
                  <Button
                    title="Salvar alterações"
                    size="medium"
                    type="submit"
                    colorstyle="blue2"
                  />
                </div>
              </StepContent>
              <Body>{currentForm}</Body>
            </form>
          </Content>
        </ContentPageNewEvent>
      </Container>
    </FormProvider>
  );
};

export default NewEditEvent;
