import React, { useContext, useMemo, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { darkTheme, lightTheme } from '../base/themes';

type ThemeContextProps = {
  theme: 'light' | 'dark';
  toggleTheme: () => void;
  defaultTheme: typeof lightTheme | typeof darkTheme;
};

export const ThemeContextNetfans = React.createContext<ThemeContextProps>(
  {} as ThemeContextProps,
);

export const ThemeProviderNetfans: React.FC = ({ children }) => {
  const [theme, setTheme] = useState<'light' | 'dark'>(() => {
    const storageValue = localStorage.getItem('@netfans/theme-mode');

    if (storageValue) {
      return storageValue as 'light' | 'dark';
    }

    return 'dark';
  });

  const defaultTheme = useMemo(() => {
    return theme === 'light' ? lightTheme : darkTheme;
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
    localStorage.setItem(
      '@netfans/theme-mode',
      theme === 'light' ? 'dark' : 'light',
    );
  };

  return (
    <ThemeContextNetfans.Provider value={{ theme, toggleTheme, defaultTheme }}>
      <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
        {children}
      </ThemeProvider>
    </ThemeContextNetfans.Provider>
  );
};

export const useTheme = (): ThemeContextProps => {
  const context = useContext(ThemeContextNetfans);

  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }

  return context;
};
