/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-undef */
import React, { useMemo, useState } from 'react';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { iMessage, iStore } from 'domain/interfaces/models';
import {
  IconFillCheck,
  IconFillIcon,
  IconFillText,
  NewShareIcon,
  NewCloseIcon,
  IconCopy,
  IconClose,
  IconCopyToClipboard,
} from 'presentation/base/icons';
import {
  Container,
  CloseButton,
  Header,
  InviteButtonsContainer,
  ParticipantItem,
  ParticipantsItemRow,
  SearchInput,
  Text,
  Content,
  Title,
  TitleContent,
  InviteContainer,
  ContainerSocialButtons,
  SocialMedia,
  SocialMediaButtons,
} from './styles';
import { Container as GlobalContainer } from '../messages/Background';

import { Button, Input } from '../UI';
import { RandomProfileUser } from '../randomProfileUser';
import Checkbox from '../Checkbox';

interface inviteModalProps {
  message: iMessage;
}
const InviteModal: React.FC<inviteModalProps> = ({ message }) => {
  const msgName = MessageOptions.inviteModal;
  const spotsInfo = useSelector((state: iStore) => state.spotsInfo);
  const userLogged = useSelector((store: iStore) => store.auth.user);
  const [invite, setInvite] = useState<string[]>([]);
  const [isChecked, setIsChecked] = useState<{
    [key: string]: boolean;
  }>({});
  const { active, actionOk, actionCancel } = message;
  const participants = spotsInfo?.results;
  const { records } = useSelector((store: iStore) => store.friendShip);
  const { pathname } = window.location;
  const { href } = window.location;
  console.log('location', window.location.href);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function handleClick(id: string) {
    if (invite.includes(id)) {
      setInvite(invite.filter(item => item !== id));
      setIsChecked({ ...isChecked, [id]: false });
    } else {
      setInvite([...invite, id]);
    }
  }

  const renderUserList = useMemo(() => {
    let userList =
      records?.map(participant => {
        const add = participant.addressee;
        const req = participant.requester;
        const user =
          add.id === Number(userLogged)
            ? { name: `${req.firstName} ${req.lastName}`, id: req.id }
            : { name: `${add.firstName} ${add.lastName}`, id: add.id };

        return (
          <ParticipantItem key={user.id}>
            <ParticipantsItemRow>
              <RandomProfileUser peerId={user.id} size="34px" />
              <h4>{user.name}</h4>
            </ParticipantsItemRow>
            <ParticipantsItemRow>
              <Checkbox
                checked={isChecked[user.name]}
                onClick={() => {
                  setIsChecked({ ...isChecked, [user.name]: true });
                  handleClick(user.name);
                }}
              />
            </ParticipantsItemRow>
          </ParticipantItem>
        );
      }) ?? [];
    if (userList?.length <= 6) {
      userList = [
        ...userList,
        ...Array(6 - userList.length).fill(
          <ParticipantItem>
            <ParticipantsItemRow>
              <IconFillIcon />
              <IconFillText />
            </ParticipantsItemRow>
            <ParticipantsItemRow>
              <IconFillCheck />
            </ParticipantsItemRow>
          </ParticipantItem>,
        ),
      ];
    }
    return userList;
  }, [records, userLogged, isChecked, handleClick]);

  return (
    <>
      {msgName === active ? (
        <GlobalContainer>
          <Container>
            <Content>
              <Header>
                <TitleContent>
                  <NewShareIcon />
                  <Title>Compartilhar</Title>
                </TitleContent>
                <div>
                  <CloseButton type="button" onClick={actionCancel}>
                    <IconClose />
                  </CloseButton>
                </div>
              </Header>
              <InviteContainer>
                <ContainerSocialButtons>
                  <SocialMedia>
                    <SocialMediaButtons>
                      <Button
                        size="very small"
                        onClick={e => {
                          e.preventDefault();
                          window.open(
                            `https://www.facebook.com/dialog/share?app_id=87741124305&href=${href}`,
                          );
                        }}
                        style={{
                          width: '48px',
                          height: '48px',
                        }}
                        social="facebook"
                      />
                      <p>Facebook</p>
                    </SocialMediaButtons>
                    <SocialMediaButtons>
                      <Button
                        size="very small"
                        onClick={e => {
                          e.preventDefault();
                          window.open(
                            `mailto:email@example.com?subject=Subject&body=${href}`,
                          );
                        }}
                        style={{
                          width: '48px',
                          height: '48px',
                        }}
                        social="google"
                      />
                      <p>Gmail</p>
                    </SocialMediaButtons>
                    <SocialMediaButtons>
                      <Button
                        size="very small"
                        onClick={e => {
                          e.preventDefault();
                          window.open(
                            `https://twitter.com/intent/tweet?url=${href}&text=Vem%20comigo%20no%20netfans`,
                          );
                        }}
                        style={{
                          width: '48px',
                          height: '48px',
                        }}
                        iconSize={48}
                        social="twitter"
                      />
                      <p>Twitter</p>
                    </SocialMediaButtons>
                    <SocialMediaButtons>
                      <Button
                        size="very small"
                        onClick={e => {
                          e.preventDefault();
                          window.open(
                            `https://api.whatsapp.com/send/?phone&text=Vem%20comigo%20no%20netfans%20${href}`,
                          );
                        }}
                        style={{
                          width: '48px',
                          height: '48px',
                        }}
                        social="whatsapp"
                        iconSize={48}
                      />
                      <p>WhatsApp</p>
                    </SocialMediaButtons>
                    <SocialMediaButtons>
                      <Button
                        size="very small"
                        onClick={e => {
                          e.preventDefault();
                          window.open(
                            `http://t.me/share/url?url=${href}&text=Vem comigo no netfans`,
                          );
                        }}
                        style={{
                          width: '48px',
                          height: '48px',
                        }}
                        iconSize={48}
                        social="telegram"
                      />
                      <p>Telegram</p>
                    </SocialMediaButtons>
                  </SocialMedia>
                </ContainerSocialButtons>
              </InviteContainer>
              <div
                style={{
                  borderTop: '1px solid rgba(255, 255, 255, 0.08)',
                  marginTop: '1rem',
                }}
              >
                <InviteButtonsContainer>
                  <Text>Link associado</Text>
                  <SearchInput>
                    <Input value={`${href}`} color="black4" isSearch disabled>
                      <Button
                        tooltipProps={{
                          title: 'Copiar link',
                          placement: 'bottom',
                        }}
                        colorstyle="black3"
                        size="very small"
                        onClick={e => {
                          e.preventDefault();

                          navigator.clipboard.writeText(`${href}`);
                          toast.info(
                            'Link copiado para a área de transferência',
                          );
                        }}
                        icon={IconCopy}
                        style={{
                          width: '36px',
                          height: '36px',
                          border: 'none',
                        }}
                        iconSize={34}
                      />
                    </Input>
                  </SearchInput>
                </InviteButtonsContainer>
              </div>
            </Content>
          </Container>
        </GlobalContainer>
      ) : undefined}
    </>
  );
};

export default InviteModal;
