import React from 'react';

import { Container, Input, Thumb, Track } from './styles';

type Props = React.InputHTMLAttributes<HTMLInputElement>;

const Switch: React.FC<Props> = ({ id }) => {
  return (
    <Container className="container" htmlFor={`switch-${id}`} key={id}>
      <Input type="checkbox" id={`switch-${id}`} />
      <Track className="track">
        <Thumb className="thumb">
          <div className="icon">
            <div className="line-1" />
            <div className="line-2" />
          </div>
        </Thumb>
      </Track>
    </Container>
  );
};

export default Switch;
