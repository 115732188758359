import React, { useMemo } from 'react';
import { iAnimation } from 'presentation/pages/Room/interface';
import { RoomGridContainer } from 'presentation/pages/Room/styles';
import useWindowDimensions from 'presentation/hooks/useWindowDimensions';
import GridRoom from '../GridRoom';
import {
  PlayerDiv,
  RoomContainer,
  ArenaLayoutDiv,
  RoomNameContainer,
  RoomName,
} from './styles';
import { layoutsProps } from './types';

const ArenaLayout: React.FC<layoutsProps> = ({
  roomName,
  rooms,
  selectedEvent,
  layout,
  size,
  handleClick,
  setTriggerAnimation,
  speakingPeerIds,
  triggerAnimation,
  Player = null,
  isChangingRoom = false,
  isHovering = false,
  isRoomsPage = false,
  isFullscreen = false,
  containerSize,
}) => {
  const { isMobile } = useWindowDimensions();

  const getPlayer = useMemo(() => {
    if (isMobile || isFullscreen) return <PlayerDiv />;

    if (Player) {
      return <Player />;
    }

    return (
      <PlayerDiv containerSize={containerSize}>
        {isHovering && (
          <RoomNameContainer>
            <RoomName>{`Sala ${roomName}`}</RoomName>
          </RoomNameContainer>
        )}
      </PlayerDiv>
    );
  }, [Player, containerSize, isHovering, isMobile, isFullscreen, roomName]);

  const getGrid = useMemo(() => {
    return (
      <RoomContainer
        layout="arena"
        containerSize={containerSize}
        isRoomsPage={isRoomsPage}
      >
        <RoomGridContainer>
          <GridRoom
            isLayout
            numCol={22}
            rooms={rooms.slice(0, 44)}
            handleClick={handleClick}
            size={size}
            speakingPeerIds={speakingPeerIds}
            animation={triggerAnimation}
            isHovering={isHovering}
            isChangingRoom={isChangingRoom}
            handleAnimationEnd={() => setTriggerAnimation?.({} as iAnimation)}
          />
        </RoomGridContainer>
        {/* <div
          style={{
            display: 'flex',
            alignItems: 'center',
            // height: '323px',
            height: '100px', // small
            gap: '2rem',
          }}
        > */}
        <ArenaLayoutDiv containerSize={containerSize}>
          <div style={{ height: 'fit-content' }}>
            <RoomGridContainer>
              <GridRoom
                isLayout
                numCol={6}
                rooms={rooms.slice(44, 50)}
                handleClick={handleClick}
                size={size}
                speakingPeerIds={speakingPeerIds}
                animation={triggerAnimation}
                isHovering={isHovering}
                isChangingRoom={isChangingRoom}
                handleAnimationEnd={() =>
                  setTriggerAnimation?.({} as iAnimation)
                }
              />
            </RoomGridContainer>
            <RoomGridContainer>
              <GridRoom
                isLayout
                numCol={6}
                rooms={rooms.slice(66, 72)}
                handleClick={handleClick}
                size={size}
                speakingPeerIds={speakingPeerIds}
                animation={triggerAnimation}
                isHovering={isHovering}
                isChangingRoom={isChangingRoom}
                handleAnimationEnd={() =>
                  setTriggerAnimation?.({} as iAnimation)
                }
              />
            </RoomGridContainer>
            <RoomGridContainer>
              <GridRoom
                isLayout
                numCol={6}
                rooms={rooms.slice(88, 94)}
                handleClick={handleClick}
                size={size}
                speakingPeerIds={speakingPeerIds}
                animation={triggerAnimation}
                isHovering={isHovering}
                isChangingRoom={isChangingRoom}
                handleAnimationEnd={() =>
                  setTriggerAnimation?.({} as iAnimation)
                }
              />
            </RoomGridContainer>
            <RoomGridContainer>
              <GridRoom
                isLayout
                numCol={6}
                rooms={rooms.slice(110, 116)}
                handleClick={handleClick}
                size={size}
                speakingPeerIds={speakingPeerIds}
                animation={triggerAnimation}
                isHovering={isHovering}
                isChangingRoom={isChangingRoom}
                handleAnimationEnd={() =>
                  setTriggerAnimation?.({} as iAnimation)
                }
              />
            </RoomGridContainer>
            <RoomGridContainer>
              <GridRoom
                isLayout
                numCol={6}
                rooms={rooms.slice(132, 138)}
                handleClick={handleClick}
                size={size}
                speakingPeerIds={speakingPeerIds}
                animation={triggerAnimation}
                isHovering={isHovering}
                isChangingRoom={isChangingRoom}
                handleAnimationEnd={() =>
                  setTriggerAnimation?.({} as iAnimation)
                }
              />
            </RoomGridContainer>
            <RoomGridContainer>
              <GridRoom
                isLayout
                numCol={6}
                rooms={rooms.slice(154, 160)}
                handleClick={handleClick}
                size={size}
                speakingPeerIds={speakingPeerIds}
                animation={triggerAnimation}
                isHovering={isHovering}
                isChangingRoom={isChangingRoom}
                handleAnimationEnd={() =>
                  setTriggerAnimation?.({} as iAnimation)
                }
              />
            </RoomGridContainer>
          </div>
          {getPlayer}

          <div style={{ height: 'fit-content' }}>
            <RoomGridContainer>
              <GridRoom
                isLayout
                numCol={6}
                rooms={rooms.slice(50, 56)}
                handleClick={handleClick}
                size={size}
                speakingPeerIds={speakingPeerIds}
                animation={triggerAnimation}
                isHovering={isHovering}
                isChangingRoom={isChangingRoom}
                handleAnimationEnd={() =>
                  setTriggerAnimation?.({} as iAnimation)
                }
              />
            </RoomGridContainer>
            <RoomGridContainer>
              <GridRoom
                isLayout
                numCol={6}
                rooms={rooms.slice(72, 78)}
                handleClick={handleClick}
                size={size}
                speakingPeerIds={speakingPeerIds}
                animation={triggerAnimation}
                isHovering={isHovering}
                isChangingRoom={isChangingRoom}
                handleAnimationEnd={() =>
                  setTriggerAnimation?.({} as iAnimation)
                }
              />
            </RoomGridContainer>
            <RoomGridContainer>
              <GridRoom
                isLayout
                numCol={6}
                rooms={rooms.slice(94, 100)}
                handleClick={handleClick}
                size={size}
                speakingPeerIds={speakingPeerIds}
                animation={triggerAnimation}
                isHovering={isHovering}
                isChangingRoom={isChangingRoom}
                handleAnimationEnd={() =>
                  setTriggerAnimation?.({} as iAnimation)
                }
              />
            </RoomGridContainer>
            <RoomGridContainer>
              <GridRoom
                isLayout
                numCol={6}
                rooms={rooms.slice(116, 122)}
                handleClick={handleClick}
                size={size}
                speakingPeerIds={speakingPeerIds}
                animation={triggerAnimation}
                isHovering={isHovering}
                isChangingRoom={isChangingRoom}
                handleAnimationEnd={() =>
                  setTriggerAnimation?.({} as iAnimation)
                }
              />
            </RoomGridContainer>
            <RoomGridContainer>
              <GridRoom
                isLayout
                numCol={6}
                rooms={rooms.slice(138, 144)}
                handleClick={handleClick}
                size={size}
                speakingPeerIds={speakingPeerIds}
                animation={triggerAnimation}
                isHovering={isHovering}
                isChangingRoom={isChangingRoom}
                handleAnimationEnd={() =>
                  setTriggerAnimation?.({} as iAnimation)
                }
              />
            </RoomGridContainer>
            <RoomGridContainer>
              <GridRoom
                isLayout
                numCol={6}
                rooms={rooms.slice(160, 166)}
                handleClick={handleClick}
                size={size}
                speakingPeerIds={speakingPeerIds}
                animation={triggerAnimation}
                isHovering={isHovering}
                isChangingRoom={isChangingRoom}
                handleAnimationEnd={() =>
                  setTriggerAnimation?.({} as iAnimation)
                }
              />
            </RoomGridContainer>
          </div>
        </ArenaLayoutDiv>
        <RoomGridContainer>
          <GridRoom
            isLayout
            numCol={22}
            rooms={rooms.slice(176, 220)}
            handleClick={handleClick}
            size={size}
            speakingPeerIds={speakingPeerIds}
            animation={triggerAnimation}
            isHovering={isHovering}
            isChangingRoom={isChangingRoom}
            handleAnimationEnd={() => setTriggerAnimation?.({} as iAnimation)}
          />
        </RoomGridContainer>
      </RoomContainer>
    );
  }, [
    containerSize,
    getPlayer,
    handleClick,
    isChangingRoom,
    isHovering,
    isRoomsPage,
    rooms,
    setTriggerAnimation,
    size,
    speakingPeerIds,
    triggerAnimation,
  ]);

  return getGrid;
};

export default ArenaLayout;
