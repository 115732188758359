import styled, { css } from 'styled-components';

interface ChatContainerProps {
  isMobile?: boolean;
  isChatEmpty?: boolean;
}

export const Container = styled.div<ChatContainerProps>`
  display: flex;
  height: calc(100vh - 31.6rem);
  flex-direction: column;
  padding: 1rem;

  position: relative;

  color: #fff;

  .input-container {
    width: 100%;
    margin-top: initial;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    flex-direction: column;
    padding: 1rem;

    span {
      width: 100%;
    }
    /* margin-top: 5rem; */
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      & {
        width: 100%;
        height: 100%;
        background: ${({ theme }) => theme.colors.black1};
      }
      height: 100%;
      & .messages {
        color: #fff;
      }
      & .input-container {
        width: 100%;
        padding: 1rem;

        input {
          background: ${({ theme }) => theme.colors.black2};
          color: ${({ theme }) => theme.colors.white1};
          ::placeholder {
            color: ${({ theme }) => theme.colors.blue1};
          }
          border-radius: 0.5rem 0 0 0.5rem;
        }
      }

      .content {
        padding: 0;
      }
    `};
`;

export const ContentPublicChat = styled.div<ChatContainerProps>`
  display: flex;
  align-items: ${({ isChatEmpty }) => (isChatEmpty ? 'center' : 'flex-start')};
  height: 90%;
  overflow-y: auto;

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 100%;
      height: 100%;
      margin: 0;
      background: ${({ theme }) => theme.colors.black1};
    `}
`;
