import styled, { css } from 'styled-components';

interface ContainerProps {
  rounded?: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 100%;
  position: relative;
  color: #fff;
  overflow: hidden;

  /* #media-control {
    transform: translateY(100%);
    position: absolute;
    bottom: 0;
    transition: transform 0.3s ease-in-out;
  } */

  /* &:hover #media-control {
    transform: translateY(0);
    opacity: 1;
  } */

  /* & p#player div[data-player]:hover .media-control,
  .media-control {
    display: none !important;
  } */

  & .bar-background {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  & .bar-hover,
  .bar-scrubber {
    display: none !important;
  }

  /* & .bar-scrubber {
    display: none !important;
  } */

  & .bar-background .bar-fill-1 {
    width: 100% !important;
  }

  & .bar-background .bar-fill-1,
  .bar-fill-2 {
    height: 0.4rem !important;
    border-radius: 0.5rem !important;
    background-color: ${({ theme }) => theme.colors.black5} !important;
  }

  & .bar-background .bar-fill-2 {
    background-color: ${({ theme }) => theme.colors.white1} !important;
  }

  ${({ rounded }) =>
    rounded &&
    css`
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    `}
`;

interface iVideoContainerProps {
  rounded?: boolean;
}

export const VideoContainer = styled.div<iVideoContainerProps>`
  display: flex;
  width: 100%;
  height: 100%;
  border-top-left-radius: ${({ rounded }) => (rounded ? '10px' : '0px')};
  border-top-right-radius: ${({ rounded }) => (rounded ? '10px' : '0px')};

  div:first-of-type {
    width: 100% !important;
    height: 100% !important;
  }
`;

// https:/ / customer -
// jhwbx8ghw193ocso.cloudflarestream.com /
//   d8d83e35d8a6dae26ca8cfcf2969db17 /
//   manifest /
//   video.mpd;
