export default {
  colors: {
    colorTextGoogle: '#757575',
    colorButtonTwitter: '#5899e3',
    colorButtonFacebook: '#1877f2',
    colorButtonTelegram: '#169ce3',
    colorButtonLinkedin: '#2064a8',
    colorButtonWhats: '#20bf5b',
    colorButtonFacebookHover: '#3e92ff',
    colorButtonTwitterHover: '#75b5ff',
    colorButtonGoogleHover: '#cdcfd8',
    colorButtonWhatsHover: '#51e287',
    colorButtonTelegramHover: '#5ec7ff',
    colorButtonLinkedinHover: '#4092e4',
    colorError: '#ea3e4f',
    colorBlack: '#000000',

    // New colors

    white1: '#e1e1e1',
    white2: '#fbfbfb',
    white3: '#828282',
    white4: '#575555',
    white5: '#575555',
    white6: '#CCCCCC',
    black1: '#121212',
    black2: '#252424',
    black3: '#3d3b3b',
    black4: '#333333',
    black5: '#1e1e1e',
    black6: '#040404',
    black7: '#222222',
    black8: '#363636',
    blue1: '#758afa',
    blue2: '#5667f6',
    yellow1: '#cd8803',
    pink1: '#ff75a6',
    pink2: '#fe3980',
    red1: '#d04545',
    red2: '#ff6666',
    green1: '#5cdfbf',
    green2: '#1ad1a5',

    purple1: '#8951ff',
  },
  typography: {
    h1: `700 6rem 'Poppins'`,
    h2: `700 3.2rem 'Poppins'`,
    h3: `700 2.4rem 'Poppins'`,
    h4: `700 1.6rem 'Poppins'`,
    h5: `500 1.4rem 'Poppins'`,
    h6: `700 1.2rem 'Poppins'`,
    h7: `400 1.2rem 'Poppins'`,
    h8: `400 1.1rem 'Poppins'`,
  },
  breakpoints: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1536px',
  },
};
