import { Dispatch } from 'redux';
import store from 'data/store';
import { getAllRequest } from 'data/store/reducer/layout/actions/getAll';

import { iActionGetAll } from 'domain/interfaces/redux/layout/getAll';

import { GetAllLayout } from 'domain/usecases/layout/redux';

export class ReduxGetAllLayout implements GetAllLayout {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  getAll = (params: GetAllLayout.Params): iActionGetAll =>
    this.send(getAllRequest(params));
}
