import { WRTCClient, LoopbackPeerConnection } from 'services/wrtccli/netfans-wrtc-wrapper.js';

var AudioContext = window.AudioContext || window.webkitAudioContext;

var audioContext = new AudioContext();

export function AudioOutputRedirector() {
  var self = this;

  var loopback = null;

  var capStream = null;

  var streamSourceNode = null;
  var gainNode = audioContext.createGain();
  var destinationStreamNode = audioContext.createMediaStreamDestination();

  this.redirectAudio = function(sourceTag, destTag) {

    if (sourceTag.captureStream) {
      capStream = sourceTag.captureStream();
    } else if (sourceTag.mozCaptureStream) {
      console.warn('Mozilla browser');
      capStream = sourceTag.mozCaptureStream();
    } else {
      console.warn('Capture failed');
      return;
    }

    if (capStream) {
      console.log('Capture stream ok');
      loopback = new LoopbackPeerConnection(
        capStream,
        (stream) => {
          if (destTag.srcObject != null) {
            return;
          }
          if (stream.getAudioTracks().length == 0) {
            return;
          }
          console.log('Loopback ok');
          if (sourceTag.mozCaptureStream == undefined) {
            sourceTag.volume = 0.001;
          }
          destTag.srcObject = stream;
        }
      );
    } else {
      console.warn('Capture stream == null');
    }

  }

};
