import React, { useCallback } from 'react';
import { iMessage } from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { History } from 'main/routes';
import { closeModal } from 'utils/modalFunctions';
import { CloseIcon, IconLogoJockey } from 'presentation/base/icons';
import { Button } from '../../UI';
import {
  ModalContainer,
  ModalContent,
  Header,
  Content,
  SubTitleModal,
  DescriptionModal,
  TitleModal,
} from './styles';

interface props {
  message: iMessage;
}

export const InfoEvent: React.FC<props> = ({ message }) => {
  const { active, actionOk, componentProps } = message;

  const isActive = active === MessageOptions.infoEvent;

  const event = componentProps?.infoEvent?.event;

  const openNewTab = useCallback((url?: string) => {
    if (!url) return;

    const withHttp = !/^https?:\/\//i.test(url) ? `http://${url}` : url;
    window.open(withHttp, '_blank', 'noopener,noreferrer');
  }, []);

  return isActive ? (
    <ModalContainer>
      <ModalContent>
        <Header>
          <CloseIcon style={{ cursor: 'pointer' }} onClick={closeModal} />
        </Header>
        <Content>
          <IconLogoJockey
            style={{ width: 54, height: 54, marginBottom: 10, maxWidth: 300 }}
          />
          <SubTitleModal style={{ marginBottom: 4, maxWidth: 300 }}>
            {event?.sponsor?.name}
          </SubTitleModal>
          <DescriptionModal style={{ marginBottom: 45, maxWidth: 300 }}>
            {event?.sponsor?.url}
          </DescriptionModal>
          {event?.sponsor?.slogan && (
            <TitleModal style={{ marginBottom: 15, maxWidth: 300 }}>
              {event?.sponsor?.slogan}
            </TitleModal>
          )}
          {event?.sponsor?.descr && (
            <DescriptionModal style={{ marginBottom: 30, maxWidth: 300 }}>
              {event?.sponsor?.descr}
            </DescriptionModal>
          )}
          <Button
            size="large"
            title={event?.sponsor?.label2 || event?.sponsor?.label}
            color={event?.sponsor?.color}
            onClick={() => {
              openNewTab(event?.sponsor?.url);
              closeModal();
            }}
          />
        </Content>
      </ModalContent>
    </ModalContainer>
  ) : null;
};

export default InfoEvent;
