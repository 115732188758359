import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export const schema = Yup.object()
  .shape({
    email: Yup.string().required('O login é obrigatório'),
    password: Yup.string()
      .required('A senha é obrigatória')
      .min(6, 'A senha precisa ter no mínimo 6 caracteres.'),
    rememberMe: Yup.boolean().optional(),
  })
  .required();

export const schemaLogin = yupResolver(schema);
