/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Container as GlobalContainer } from 'presentation/components/messages/Background';
import { Button } from 'presentation/components/UI';
import { iMessage } from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { CloseIcon, IconModal } from 'presentation/base/icons';
import {
  BodyBox,
  ButtonBox,
  Container,
  Header,
  InsideBodyBox,
  ModalTitle,
  Title,
  Text,
} from './styles/StyledPolicyModal';
import { translator } from '../i18n';

interface propsRegisterModal {
  message: iMessage;
}

const PolicyModal: React.FC<propsRegisterModal> = ({ message }) => {
  const msgName = MessageOptions.policyModal;

  const { active, actionOk, actionCancel } = message;

  return (
    <>
      {msgName === active ? (
        <GlobalContainer>
          <Container>
            <Header>
              <Title>
                <IconModal />
                <ModalTitle>
                  {translator('Termos de Serviço e Política de Privacidade')}
                </ModalTitle>
              </Title>

              <div>
                <CloseIcon
                  id="btn_close"
                  style={{ cursor: 'pointer' }}
                  onClick={actionCancel}
                />
              </div>
            </Header>

            <BodyBox>
              <InsideBodyBox>
                <Text>
                  01 de janeiro de 2021
                  <Text marginTop>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse sed turpis a justo dignissim tempus. Duis
                    maximus interdum augue, et euismod lectus finibus sit amet.
                    Pellentesque cursus eu magna eget laoreet. Fusce lacinia
                    augue eu elementum tempor. Aliquam fringilla lacus sapien,
                    ac imperdiet velit efficitur sed. Morbi maximus lectus ac
                    diam scelerisque, eu venenatis diam laoreet. Donec sit amet
                    sapien lacus. Duis sollicitudin imperdiet posuere. Sed
                    rhoncus est leo, sit amet auctor nisi convallis vitae.
                    Vestibulum rutrum sem ut tristique scelerisque. Vivamus non
                    tempus massa.
                  </Text>
                  <Text bold marginTop>
                    1.Título da seção
                  </Text>
                  <Text marginTop>
                    Aliquam nisi diam, aliquet sed lectus semper, ultrices
                    lobortis nibh. Vestibulum et dignissim justo, eu congue
                    tortor. Donec lacinia diam vel porta tincidunt. Vivamus
                    condimentum erat at malesuada maximus. Donec in tincidunt
                    felis. Phasellus semper nibh vitae posuere vulputate. Lorem
                    ipsum dolor sit amet, consectetur adipiscing elit. Nunc non
                    lorem libero. Suspendisse ut ante nec ipsum ultrices
                    bibendum. Maecenas vulputate semper justo, eget tempor
                    turpis auctor nec. Vivamus tristique purus sit amet euismod
                    imperdiet. Suspendisse potenti. Orci varius natoque
                    penatibus et magnis dis parturient montes, nascetur
                    ridiculus mus. Sed pulvinar felis quis feugiat tempor. Duis
                    nec lobortis ante. Nam et ligula eros. Curabitur imperdiet
                    aliquet sagittis. Suspendisse a lectus sapien. In pulvinar,
                    eros vitae convallis sagittis, ipsum libero dictum massa,
                    quis volutpat tellus mauris ultrices erat. Sed bibendum
                    vestibulum 01 de janeiro de 2021 Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Suspendisse sed turpis a justo
                    dignissim tempus. Duis maximus interdum augue, et euismod
                    lectus finibus sit amet. Pellentesque cursus eu magna eget
                    laoreet. Fusce lacinia augue eu elementum tempor. Aliquam
                    fringilla lacus sapien, ac imperdiet velit efficitur sed.
                    Morbi maximus lectus ac diam scelerisque, eu venenatis diam
                    laoreet. Donec sit amet sapien lacus. Duis sollicitudin
                    imperdiet posuere. Sed rhoncus est leo, sit amet auctor nisi
                    convallis vitae. Vestibulum rutrum sem ut tristique
                    scelerisque. Vivamus non tempus massa. 1. Título da seção
                    Aliquam nisi diam, aliquet sed lectus semper, ultrices
                    lobortis nibh. Vestibulum et dignissim justo, eu congue
                    tortor. Donec lacinia diam vel porta tincidunt. Vivamus
                    condimentum erat at malesuada maximus. Donec in tincidunt
                    felis. Phasellus semper nibh vitae posuere vulputate. Lorem
                    ipsum dolor sit amet, consectetur adipiscing elit. Nunc non
                    lorem libero. Suspendisse ut ante nec ipsum ultrices
                    bibendum. Maecenas vulputate semper justo, eget tempor
                    turpis auctor nec. Vivamus tristique purus sit amet euismod
                    imperdiet. Suspendisse potenti. Orci varius natoque
                    penatibus et magnis dis parturient montes, nascetur
                    ridiculus mus. Sed pulvinar felis quis feugiat tempor. Duis
                    nec lobortis ante. Nam et ligula eros. Curabitur imperdiet
                    aliquet sagittis. Suspendisse a lectus sapien. In pulvinar,
                    eros vitae convallis sagittis, ipsum libero dictum massa,
                    quis volutpat tellus mauris
                  </Text>
                </Text>
              </InsideBodyBox>
            </BodyBox>
            <ButtonBox>
              <Button
                id="btn_cancel"
                title={translator('Cancelar')}
                size="medium"
                colorstyle="black5"
                onClick={actionCancel}
              />
              <Button
                id="btn_ok"
                title={translator('Aceitar')}
                size="medium"
                onClick={actionOk}
              />
            </ButtonBox>
          </Container>
        </GlobalContainer>
      ) : undefined}
    </>
  );
};

export default PolicyModal;
