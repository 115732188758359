import { Dispatch } from 'redux';
import store from 'data/store';

import { createRequest } from 'data/store/reducer/sponsor/actions/create';

import { iActionCreate } from 'domain/interfaces/redux/sponsor/create';

import { CreateSponsor } from 'domain/usecases/sponsor/redux';

export class ReduxCreateSponsor implements CreateSponsor {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  create = (params: CreateSponsor.Params): iActionCreate =>
    this.send(createRequest(params));
}
