import styled, { css } from 'styled-components';

import {
  IconReactions,
  IconArrowUp,
  IconBackArrow,
  IconMutedMic,
  IconUnmutedMic,
  IconUser,
  IconSettingsMobile,
  IconParticipant as Participant,
  HeadphoneIcon,
  MicIcon2 as MicrophoneIcon,
  MicMutedIcon as MutedMicrophoneIcon,
  IconMicDisabledFooter,
} from 'presentation/base/icons';

type ContainerProps = {
  isFullScreen?: boolean;
};

export const Container = styled.aside<ContainerProps>`
  max-width: 42rem;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  z-index: 2;

  position: fixed;
  right: 0;

  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  background-color: ${({ theme }) => theme.colors.black2};

  transition: transform 0.2s ease-in;

  /* ${({ isFullScreen }) =>
    isFullScreen &&
    css`
      transform: translateX(35rem);
      &:hover {
        transform: translateX(0);
      }
    `} */

  & .content {
    width: 100%;
    max-height: calc(100vh - 31.6rem);
    overflow-y: auto;
    padding-top: 1.5rem;
  }

  & .event-banner {
    margin-top: ${({ isFullScreen }) => (isFullScreen ? 0 : '6rem')};
  }
`;

export const Banner = styled.img.attrs({
  className: 'event-banner',
})`
  width: 100%;
  max-height: 15rem;
  display: flex;

  object-fit: cover;
`;

export const AdContainer = styled.div`
  width: 100%;
  height: 5.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;

  background: linear-gradient(
    270deg,
    #12141e 100%,
    rgba(32, 36, 51, 0.3) 100%
  ); ;
`;

export const LeftAdContent = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1.3rem;
`;

export const LeftAdInfo = styled.div`
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Title = styled.h4`
  font: ${({ theme }) => theme.typography.h4};
  font-size: 13px;
  color: #f2f2f2;
`;

export const Subtitle = styled.h6`
  font: ${({ theme }) => theme.typography.h7};
  color: #d5d5e4;
`;

export const RightAdContent = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;
`;
// About

export const AboutContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;

  overflow-y: auto;

  color: #fff;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.white1};
  opacity: 0.2;
`;

export const AboutInfoContainer = styled.div`
  padding: 1.5rem 3rem;
`;

export const AboutTitle = styled.h4`
  font: ${({ theme }) => theme.typography.h5};
  font-weight: 700;

  margin-bottom: 5px;
`;

interface AboutDescriptionProps {
  capitalize?: boolean;
}

export const AboutDescription = styled.p<AboutDescriptionProps>`
  font: ${({ theme }) => theme.typography.h7};
  margin: 0;

  ${({ capitalize }) =>
    capitalize &&
    `
    text-transform: capitalize;
  `}
`;

// Tag

export const TagContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & .tag:last-child {
    margin-right: 0;
  }
`;

export const Tag = styled.span.attrs({
  className: 'tag',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.1rem 2.5rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.black5};
  border-radius: 0.5rem;
  margin: 0 1.5rem 1.3rem 0;

  font-size: 1.2rem;
`;

// Participants

export const SearchContainer = styled.div`
  display: flex;
  padding: 1.5rem;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);

  .input-search-participant {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.black4};
    border-radius: 0.5rem;
  }
`;

export const ParticipantContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  padding: 1.5rem 3rem;

  max-height: calc(100vh - 33rem);
  overflow-y: auto;
`;

export const ParticipantItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* padding: 0 20px 0 10px; */
`;

export const ParticipantsItemRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 11px;

  h4 {
    color: white;
  }
`;

export const ParticipantsItemActions = styled.div`
  width: 78px;
  display: flex;
  gap: 10px;

  svg {
    cursor: pointer;
  }

  button {
    background: transparent;
    border: none;
  }
`;

export const ParticipantsActionButton = styled.button`
  width: 34px;
  height: 34px;

  :disabled {
    opacity: 0.3;
  }
`;

export const ParticipantsProfile = styled(Participant)`
  width: 3.4rem;
  height: 3.4rem;
`;

export const ParticipantsMic = styled(MicrophoneIcon)`
  width: 1.8rem;
  height: 1.8rem;
`;

export const ParticipantsMutedMic = styled(IconMicDisabledFooter)`
  width: 1.8rem;
  height: 1.8rem;
`;

export const ParticipantsHeadphone = styled(HeadphoneIcon)`
  width: 1.8rem;
  height: 1.8rem;
`;

export const ParticipantsMoreOptions = styled.div`
  width: 153px;
  padding: 15px 10px;
  background: #05060c;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  border-radius: 5px;

  img {
    margin-right: 5px;
  }

  button {
    background: transparent;
    color: white;
    border: none;
  }

  .opTop {
    cursor: pointer;
  }

  .line {
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.08);
  }
  .opDown {
    margin-top: 15px;
    cursor: pointer;
  }
`;
