import { Dispatch } from 'redux';
import { Logout } from 'domain/usecases/auth/redux';
import { iActionLogout } from 'domain/interfaces/redux/auth/Logout';
import { logout } from 'data/store/reducer/auth/actions/logout';
import store from 'data/store';

export class ReduxLogout implements Logout {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  logout = (): iActionLogout => this.send(logout());
}
