/* eslint-disable no-plusplus */
import * as dateFns from 'date-fns';

const hours = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
];
const minutes = ['00', '15', '30', '45'];

export const times: string[] = [];

for (let i = 0; i < hours.length; i++) {
  for (let j = 0; j < minutes.length; j++) {
    times.push(`${hours[i]}:${minutes[j]}`);
  }
}

export const getDuration = (timeStart: string, timeEnd: string): number => {
  const auxTimeStart = timeStart.split(':');
  const auxTimeEnd = timeEnd.split(':');
  const startSchedule = new Date();
  startSchedule.setHours(Number(auxTimeStart[0]));
  startSchedule.setMinutes(Number(auxTimeStart[1]));
  const endSchedule = new Date();
  endSchedule.setHours(Number(auxTimeEnd[0]));
  endSchedule.setMinutes(Number(auxTimeEnd[1]));

  return dateFns.differenceInMinutes(endSchedule, startSchedule);
};
