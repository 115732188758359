import { useEffect, useState } from 'react';

type Breakpoint = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

const useBreakpoint = (): Breakpoint => {
  const convertBreakpoints = (breakpointValue: number): Breakpoint => {
    if (breakpointValue <= 360) {
      return 'xxs';
    }
    if (breakpointValue <= 480) {
      return 'xs';
    }
    if (breakpointValue <= 768) {
      return 'sm';
    }
    if (breakpointValue <= 1024) {
      return 'md';
    }
    if (breakpointValue <= 1366) {
      return 'lg';
    }
    if (breakpointValue <= 1440) {
      return 'xl';
    }
    return 'xxl';
  };

  const [breakpoint, setBreakpoint] = useState(
    convertBreakpoints(window.innerWidth),
  );

  useEffect(() => {
    const resize = () => {
      setBreakpoint(convertBreakpoints(window.innerWidth));
    };
    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  return breakpoint;
};

export default useBreakpoint;
