/* eslint-disable @typescript-eslint/no-empty-function */
import { iStore } from 'domain/interfaces/models';
import { MessagesPublicChatContext } from 'presentation/contexts/MessagesPublicChatContext';
import React, { HTMLAttributes, memo, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IconBallonChat, IconClose } from 'presentation/base/icons';
import { ChatInfoProps, MessageProps } from '../Chat/interfaces';
import InputChat from './Input';
import MessageBallon from './Message';
import {
  Body,
  CloseButton,
  Container,
  Footer,
  HeaderToBottomSheet,
  TitleContainer,
  TitleLabel,
} from './styles';

interface ChatProps extends HTMLAttributes<HTMLDivElement> {
  chatId: number;
  chat: ChatInfoProps;
  newMessage?: MessageProps;
  isMobile?: boolean;
  isOpen?: boolean;
  closeChat?: () => void;
  handleNewMessageAdded?: () => void;
}

const Chat: React.FC<ChatProps> = ({
  chatId,
  chat,
  newMessage,
  isMobile,
  isOpen = true,
  closeChat = () => {},
  handleNewMessageAdded,
}) => {
  const wrtc = useSelector((store: iStore) => store.wrtc);
  const user = useSelector((store: iStore) => store.auth.user);

  const { handleAddNewMessage, messages, isChatEmpty } = useContext(
    MessagesPublicChatContext,
  );

  useEffect(() => {
    document.getElementById('scroll-div')?.scrollTo({
      top: document.getElementById('scroll-div')?.scrollHeight,
      left: 0,
      behavior: 'auto',
    });
  }, [messages]);

  const sendChatMessage = (data: any) => {
    if (wrtc?.sendChatMessage) {
      if (chat?.isPublic) {
        wrtc?.sendChatMessage(data, -1);
      } else {
        wrtc?.sendChatMessage(data, chat?.to?.id);
      }
    }
  };

  const handleSendMessage = (text: string) => {
    const hour = new Date().getHours();
    const minute = new Date().getMinutes();

    if (text !== '' && chat?.from?.id && chat?.to?.id) {
      const message: MessageProps = {
        chat: {
          ...chat,
          id: chat?.isPublic ? -1 : chat?.id,
          to: {
            ...chat?.to,
            name: chat?.isPublic ? String(user.fullName) : chat?.to?.name,
          },
        },
        message: text,
        type: 'sent',
        timestamp: `${hour < 10 ? `0${hour}` : hour}:${
          minute < 10 ? `0${minute}` : minute
        }`,
        userId: chat?.from.id,
        messageType: 'chat',
      };

      handleAddNewMessage?.(message);
      sendChatMessage?.({
        ...message,
        type: 'received',
      });
      // handleAddNewMessage({ ...message, type: 'received' });
    }
  };

  useEffect(() => {
    if (!newMessage) return;

    if (!newMessage?.isAdded) {
      handleNewMessageAdded?.();

      if (newMessage?.chat?.isPublic && chat?.isPublic) {
        // Tratando mensagens para o chat público
        if (newMessage?.chat?.id === chat?.id) {
          handleAddNewMessage(newMessage);
        }
      } else if (
        !chat?.isPublic &&
        !newMessage?.chat?.isPublic &&
        (newMessage?.userId === chat?.from?.id ||
          newMessage?.userId === chat?.to?.id)
      )
        // Tratando mensagens para o chat privado
        handleAddNewMessage(newMessage);
    }
  }, [chat, handleNewMessageAdded, newMessage]);

  console.log('isOpened', isOpen);

  return (
    <Container
      tabIndex={isMobile ? 0 : undefined}
      isMobile={isMobile}
      isOpen={isOpen}
    >
      {isMobile && (
        <HeaderToBottomSheet>
          <TitleContainer>
            <div id="icon-ballon-chat">
              <IconBallonChat />
            </div>
            <TitleLabel>Bate-papo ao vivo</TitleLabel>
          </TitleContainer>
          <CloseButton onClick={closeChat}>
            <IconClose />
          </CloseButton>
        </HeaderToBottomSheet>
      )}
      <Body id="scroll-div" isMobile={isMobile}>
        {messages?.map((message, idx) => (
          <MessageBallon
            id={`chat-message#${message.userId + idx}`}
            key={`chat-message#${message.userId + idx}`}
            message={message}
            isMe={message.type === 'sent'}
          />
        ))}
      </Body>
      <Footer>
        <InputChat
          tabIndex={0}
          onSend={text => {
            handleSendMessage?.(text);
          }}
          placeholder="Digite uma mensagem..."
        />
      </Footer>
    </Container>
  );
};

export default memo(Chat, arePropsEqual);

function arePropsEqual(prevProps: ChatProps, nextProps: ChatProps) {
  return (
    prevProps.chat?.messages === nextProps.chat?.messages &&
    prevProps?.newMessage === nextProps?.newMessage &&
    prevProps.isOpen === nextProps.isOpen
  );
}
