import { makeReduxGetAllCategory } from 'main/factories/usecases/category/GetAllCategoryFactory';
import GeneralData from 'presentation/components/EventsForm/GeneralData';
import { Button } from 'presentation/components/UI';
import { NewEventContext } from 'presentation/contexts/NewEventContext';
import React, { useContext, useEffect } from 'react';

import { makeReduxListSponsors } from 'main/factories/usecases/sponsor/ListSponsorsFactory';
import { IconBackArrow, IconHelp } from 'presentation/base/icons';
import { defaultTheme } from 'presentation/base/themes';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router';
import ContentData from './ContentData';
import { Content, ContentPageNewEvent } from './NewEventStyled';
import Publicity from './Publicity';
import {
  FormContainer,
  SecondaryHeader,
  SecondaryHeaderActions,
  SecondaryHeaderTitle,
} from './styles/common';

const NewEvent: React.FC = () => {
  const { nextStep } = useContext(NewEventContext);

  const {
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    makeReduxGetAllCategory().getAll({});
    makeReduxListSponsors().list({
      limit: 9999,
    });
  }, []);

  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  useEffect(() => {
    const hasErrorOnlyInFirstField = Object.keys(errors).length === 1;
    if (hasErrorOnlyInFirstField) return;

    const firstElementWithError = Object.entries(errors)?.[0];
    const element = document.getElementById(firstElementWithError?.[0]);

    document.scrollingElement?.scrollTo({
      top: (element?.offsetTop ?? 0) - 100,
      behavior: 'smooth',
    });
  }, [errors]);

  return (
    <FormContainer>
      <ContentPageNewEvent>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <SecondaryHeader>
            <SecondaryHeaderTitle>
              <IconBackArrow className="arrowBack" onClick={handleBack} />
              Novo evento
            </SecondaryHeaderTitle>
            <SecondaryHeaderActions>
              <Button
                title="Concluir"
                color={defaultTheme.colors.blue2}
                size="medium"
                onClick={nextStep}
              />
              <Button
                iconSize={20}
                icon={IconHelp}
                size="very small"
                style={{
                  padding: '0.8rem',
                }}
                color={defaultTheme.colors.black3}
              />
            </SecondaryHeaderActions>
          </SecondaryHeader>
          <Content>
            <GeneralData />
            <ContentData />
            <Publicity />
          </Content>
        </div>
      </ContentPageNewEvent>
    </FormContainer>
  );
};

export default NewEvent;
