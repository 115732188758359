/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import { iEventItem } from 'domain/interfaces/models/Event';
import { CloseIcon } from 'presentation/base/icons';
import { useParams } from 'react-router';
import {
  formattedDate,
  incrementMinutesOnTimeString,
} from 'utils/formattedDate';

import {
  MobileContentContainer,
  MobileContentHeader,
  MobileLeftHeaderContainer,
  EventText,
  MobileContentBody,
  Tag,
  Text,
  Tags,
  MobileContentBodyText,
  InfoRoomSubtitle,
  InfoRoomContent,
} from './styles';

interface iParams {
  roomId: string;
  id: string;
}
interface MobileContentScreenProps {
  selectedEvent: iEventItem;
  isDisplayed: boolean;
  onClose: () => void;
}

const MobileContentScreen: React.FC<MobileContentScreenProps> = ({
  selectedEvent,
  isDisplayed,
  onClose,
}) => {
  const { results } = useSelector((store: iStore) => store.category);
  const params: iParams = useParams();

  return (
    <MobileContentContainer isDisplayed={isDisplayed}>
      {isDisplayed && (
        <div>
          <MobileContentHeader>
            <MobileLeftHeaderContainer>
              <InfoRoomContent>
                <EventText bold>{selectedEvent.name}</EventText>

                <InfoRoomSubtitle>
                  <span>
                    {`Sala ${params?.roomId}`} {' - '}
                    {selectedEvent?.schedule
                      ? formattedDate(
                          new Date(selectedEvent?.schedule),
                          selectedEvent?.duration,
                        )
                      : 'Jan 1,2022 - 00h00'}
                  </span>
                </InfoRoomSubtitle>
              </InfoRoomContent>
            </MobileLeftHeaderContainer>

            <CloseIcon
              style={{
                cursor: 'pointer',
              }}
              onClick={onClose}
            />
          </MobileContentHeader>
          <MobileContentBody>
            <MobileContentBodyText>
              <div
                style={{
                  display: 'flex',
                  marginBottom: '10px',
                }}
              >
                <EventText bold>Dados Gerais</EventText>
              </div>
              <Text>
                {'Tipo de conteudo: '}

                {selectedEvent?.contentType?.name}
              </Text>
              <Text>
                Categoria:
                {selectedEvent?.category?.name}
              </Text>
              <Text>
                {'Subcategoria: '}

                {selectedEvent?.subCategories?.[0]?.subCategory}
              </Text>
              <Text>
                {'Responsavel: '}
                {selectedEvent.organization}
              </Text>
            </MobileContentBodyText>

            <MobileContentBodyText>
              <div
                style={{
                  display: 'flex',
                  marginBottom: '10px',
                }}
              >
                <EventText bold>Descrição</EventText>
              </div>
              {selectedEvent.descr}
            </MobileContentBodyText>

            <Tags>
              {selectedEvent?.keywords?.map(item => (
                <Tag>{item}</Tag>
              ))}
            </Tags>
          </MobileContentBody>
        </div>
      )}
    </MobileContentContainer>
  );
};

export default MobileContentScreen;
