import React from 'react';
import ContentLeft from 'presentation/base/images/ContentEsq.png';
import ContentRight from 'presentation/base/images/ContentDir.png';
import ShadowLeft from 'presentation/base/images/ShadowLeft.png';
import ShadowRight from 'presentation/base/images/ShadowRight.png';
import {
  ContainerImage,
  LeftContent,
  RightContent,
  Title,
  Paragraph,
  ImageShadowLeft,
  ContainerLeft,
  TextContainer,
} from './styles';

const HomeBanner = () => {
  return (
    <ContainerImage>
      <LeftContent>
        <ImageShadowLeft src={ShadowLeft} alt="Sombra Esquerda" />
        <ContainerLeft>
          <img src={ContentLeft} alt="Left" style={{ height: '100%' }} />
        </ContainerLeft>
        <TextContainer>
          <Title>Streaming de vídeo ao vivo com áudio espacializado</Title>
          <br />
          <Paragraph>
            Nossa plataforma oferece um ambiente interativo único para assistir
            a transmissões de vídeo. Aqui, você poderá se comunicar por áudio,
            reações e mensagens de texto e ter a sensação de que está em um
            evento do mundo real.
            <br />
            <br />
            Convide seus amigos e venha fazer parte do Netfans!
          </Paragraph>
        </TextContainer>
      </LeftContent>
      <RightContent>
        <img
          src={ShadowRight}
          alt="Sombra"
          style={{ height: '100%', position: 'absolute', right: '0' }}
        />
        <img
          src={ContentRight}
          alt="Direita"
          style={{ height: '100%', position: 'absolute', right: '0' }}
        />
      </RightContent>
    </ContainerImage>
  );
};

export default HomeBanner;
