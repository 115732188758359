/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-plusplus */
import React from 'react';
import {
  IconLayoutArena,
  IconLayoutCinema,
  IconLayoutLounge,
  IconLayoutLoungeEvent,
  IconLayoutTheatre,
} from 'presentation/base/icons';

import {
  Container,
  Content,
  Message,
  Contentlounge,
  CapacityText,
} from './styles';

interface EventsLayoutProps {
  layout: string;
}

const EventsLayout: React.FC<EventsLayoutProps> = ({ layout = 'lounge' }) => {
  return (
    <>
      {layout === 'lounge' && (
        <Container>
          <Contentlounge>
            <IconLayoutLoungeEvent />
          </Contentlounge>
        </Container>
      )}
      {layout === 'cinema' && (
        <Container>
          <Content>
            <IconLayoutCinema width="100%" height="150px" />
          </Content>
        </Container>
      )}
      {layout === 'teatro' && (
        <Container>
          <Content>
            <IconLayoutTheatre width="100%" height="150px" />
          </Content>
        </Container>
      )}
      {layout === 'arena' && (
        <Container>
          <Content>
            <IconLayoutArena width="100%" height="150px" />
          </Content>
        </Container>
      )}
      {layout === 'large-theatre' && (
        <Container>
          <Content>
            <Message>Formato ainda não disponível para visualização.</Message>
          </Content>
        </Container>
      )}
    </>
  );
};

export default EventsLayout;
