export enum MessageTypes {
  SET_DATA = '@message/SET_DATA',
  MESSAGE_ACTIVE = '@message/MESSAGE_ACTIVE',
}

export enum MessageOptions {
  registerModal = 'registerModal',
  loginModal = 'loginModal',
  recoveryPassword = 'recoveryPassword',
  policyModal = 'policyModal',
  enterRoomPolicyModal = 'enterRoomPolicyModal',
  changeRoomModal = 'changeRoomModal',
  inviteModal = 'inviteModal',
  insideMapModal = 'insideMapModal',
  createEventSuccessModal = 'createEventSuccessModal',
  infoEvent = 'infoEvent',
  creatingEvent = 'creatingEvent',
  roomsConfigure = 'roomsConfigure',
  createTemplateToSponsor = 'createTemplateToSponsor',
  successOnCreateEvent = 'successOnCreateEvent',
  none = 'none',
}

export type iMessageOptions = keyof typeof MessageOptions;
