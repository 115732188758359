import styled from 'styled-components';
import ReactPlayer from 'react-player/lazy';
import { IconBackArrow } from 'presentation/base/icons';
import media from 'utils/mediaqueries';

interface iMobile {
  isMobile: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: ${props => props.theme.colors.black1};
  overflow: hidden;
`;

export const Header = styled.div`
  width: 100%;
  height: 60px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${props => props.theme.colors.black2};
`;

export const Box = styled.div<iMobile>`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 2.4rem;
  column-gap: 2.4rem;

  padding: ${({ isMobile }) => (!isMobile ? '0 7.4rem' : '0 0 0 2rem')};
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  height: 100%;
  max-width: 86.2rem;
`;

export const RoomsHeader = styled.div`
  width: 100%;
  height: 3.6rem;

  margin-top: 3rem;
  padding: 0 7.4rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const RoomsHeaderMobile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  padding: 0 2rem;
`;

export const HexagonContainer = styled.div<iMobile>`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  max-height: 58rem;
  max-width: 82rem;
  /* margin-top: 3rem; */
  /* padding: 2.9rem;
  border-radius: 1rem;
  background-color: ${props => props.theme.colors.black5}; */
  /* padding: ${({ isMobile }) => (!isMobile ? '0 7.4rem' : '0 0 0 2rem')}; */
`;

export const RoomsContainer = styled.div`
  display: flex;
  width: 100%;
  /* width: calc(100% - 574px); */
  /* padding: 4rem 3rem; */
  border-radius: 1rem;
  background-color: ${props => props.theme.colors.black5};
  height: 100%;

  ${media.lessThan('sm')} {
    width: 100%;
  }
`;

export const EventContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* width: 640px; */
  width: 35%;
  height: 100%;
  max-height: 58rem;
  min-width: 420px;
  gap: 2.4rem;

  /* box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25); */
  /* background-color: ${({ theme }) => theme.colors.black2}; */
  /* position: fixed; */
  /* right: 0; */
  /* max-height: 100vh; */
`;

export const EventPlayer = styled(ReactPlayer)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40%;
  background-color: #000000;
`;

export const EventPlayerClappr = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50%;
`;

export const EventInfo = styled.div`
  padding: 22px 30px;
`;

export const EventTitle = styled.div`
  font: ${({ theme }) => theme.typography.h5};
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
`;

export const EventHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  margin-top: 20px;
`;

interface IEventText {
  bold?: boolean;
  marginRight?: boolean;
}

export const EventText = styled.h5<IEventText>`
  font-family: Poppins;
  font-weight: ${props => (props.bold ? 'bold' : 400)};
  font-size: 14px;
  line-height: 18px;

  margin-right: ${props => (props.marginRight ? '4px' : 0)};
  color: #ffffff;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SpacedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 8px;
`;

export const BackArrow = styled(IconBackArrow)`
  width: 2rem;
  height: 2rem;

  margin-right: 2.2rem;

  cursor: pointer;
`;

interface CategoryProps {
  type?: 'Esportes' | 'Música' | 'Entretenimento';
}

export const CategoryBar = styled.div<CategoryProps>`
  width: 3px;
  height: 36px;
  margin-left: 0.8rem;
  margin-right: 1.4rem;
  background-color: ${props => {
    switch (props.type) {
      case 'Esportes':
        return props.theme.colors.blue2;
      case 'Música':
        return props.theme.colors.green1;
      case 'Entretenimento':
        return props.theme.colors.pink1;
      default:
        return props.theme.colors.black2;
    }
  }};
`;

export const CategoryTitle = styled.h5`
  font-family: Poppins;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;

  color: #ffffff;
`;

export const Button = styled.button`
  width: 13.6rem;
  height: 3.4rem;
  border-radius: 0.5rem;
  border: 0;
  background-color: ${props => props.theme.colors.black5};
  color: ${props => props.theme.colors.white1};
`;

export const AboutContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;

  overflow-y: auto;

  color: #fff;

  .content {
    width: 100%;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.white1};
  opacity: 0.2;
`;

export const AboutInfoContainer = styled.div`
  padding: 1.5rem 3rem;
`;

export const AboutTitle = styled.h4`
  font: ${({ theme }) => theme.typography.h4};
  font-weight: 700;

  margin-bottom: 5px;
`;

interface AboutDescriptionProps {
  capitalize?: boolean;
}

export const AboutDescription = styled.p<AboutDescriptionProps>`
  font: ${({ theme }) => theme.typography.h7};
  margin: 0;

  ${({ capitalize }) =>
    capitalize &&
    `
    text-transform: capitalize;
  `}

  span {
    margin-left: 1.8rem;
  }
`;

// Tag

export const TagContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & .tag:last-child {
    margin-right: 0;
  }
`;

export const Tag = styled.span.attrs({
  className: 'tag',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.1rem 2.5rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.black5};
  border-radius: 0.5rem;
  margin: 0 1.5rem 1.3rem 0;

  font-size: 1.2rem;
`;

// Participants

export const SearchContainer = styled.div`
  display: flex;
  padding: 1.5rem;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);

  .input-search-participant {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.black4};
    border-radius: 0.5rem;
  }
`;

export const ParticipantContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  padding: 1.5rem 3rem;

  max-height: calc(100vh - 33rem);
  overflow-y: auto;
`;

export const ParticipantItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* padding: 0 20px 0 10px; */
`;

export const ParticipantsItemRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 11px;

  h4 {
    color: white;
  }
`;

export const ParticipantsItemActions = styled.div`
  width: 78px;
  display: flex;
  gap: 10px;

  svg {
    cursor: pointer;
  }

  button {
    background: transparent;
    border: none;
  }
`;

export const ParticipantsActionButton = styled.button`
  width: 34px;
  height: 34px;

  :disabled {
    opacity: 0.3;
  }
`;

export const ParticipantsMoreOptions = styled.div`
  width: 153px;
  padding: 15px 10px;
  background: #05060c;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  border-radius: 5px;

  img {
    margin-right: 5px;
  }

  button {
    background: transparent;
    color: white;
    border: none;
  }

  .opTop {
    cursor: pointer;
  }

  .line {
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.08);
  }
  .opDown {
    margin-top: 15px;
    cursor: pointer;
  }
`;
