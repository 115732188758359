/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  Fragment,
} from 'react';
import { iAnimation } from 'presentation/pages/Room/interface';

import SquareRoom from '../SquareRoom';
import { AnimationContainer } from './styles/LayoutGridRoomStyled';

// styles
import { Container, Grid } from './styles/styles';
import ArrowScroll from '../ArrowScroll/ArrowScroll';
import BaseAnimation from '../BaseAnimation';

interface RoomProps {
  isLayout: boolean;
  rooms: {
    peerId?: number;
    peerName?: string;
    label: string;
    oldLabel?: string;
    state?: 'busy' | 'full' | 'normal';
    type?: 'vip' | 'normal';
    disabled?: boolean;
    muted?: boolean;
    isCenter?: boolean;
    actualUser?: boolean;
  }[];
  numCol: number;
  preview?: boolean;
  isChangingRoom?: boolean;
  isHovering?: boolean;
  hasBorder?: boolean;
  handleClick: (room: any) => void;
  size?: string;
  speakingPeerIds?: any;
  occupancyIds?: any;
  animation?: iAnimation;
  handleAnimationEnd?: () => void;
  roomMax?: number;
}

const GridRoom: React.FC<RoomProps> = ({
  isLayout,
  rooms = [],
  numCol,
  handleClick,
  size,
  preview,
  hasBorder,
  speakingPeerIds,
  occupancyIds,
  animation,
  handleAnimationEnd,
  roomMax,
  isHovering = false,
  isChangingRoom = false,
}) => {
  const [tempAnimation, setTempAnimation] = useState<iAnimation[]>([]);

  const isSpeaking = useCallback(
    peerId => {
      if (!peerId || peerId === -1 || !speakingPeerIds) return false;

      return Boolean(speakingPeerIds[peerId]);
    },
    [speakingPeerIds],
  );

  const isOccupiedOrFull = useCallback(
    (label?: string): 'busy' | 'full' | 'normal' => {
      let returnType: 'busy' | 'full' | 'normal' = 'normal';

      // Utilizar Occupancy para atualizar lotação dinamica das salas
      occupancyIds?.forEach((data: any) => {
        if (!roomMax) return;
        if (Number(data.roomname) + 1 !== Number(label)) return;

        returnType = data.amount >= roomMax ? 'full' : 'busy';
      });

      return returnType;
    },
    [occupancyIds, roomMax],
  );

  const updateReaction = useCallback(
    (peerId: number, animationUrl: string) => {
      const animationFound = tempAnimation.find(item => item.peerId === peerId);

      if (animationFound) {
        const newTempAnimation = tempAnimation.map(item => {
          if (item.peerId === peerId) {
            return {
              peerId: item.peerId,
              animationUrl: String(animationUrl),
              active: true,
              triggerFadeout: false,
            };
          }

          return item;
        });

        setTempAnimation(newTempAnimation);
        return newTempAnimation;
      }
      const newTempAnimation = [
        ...tempAnimation,
        {
          peerId,
          animationUrl,
          active: true,
          triggerFadeout: false,
        },
      ];

      setTempAnimation(newTempAnimation);
      return newTempAnimation;
    },
    [tempAnimation],
  );

  const renderAnimation = useCallback(
    (room: RoomProps['rooms'][0]) => {
      let renderedAnimation = null;

      tempAnimation.forEach(item => {
        if (item.peerId === room.peerId && item.active) {
          renderedAnimation = (
            <BaseAnimation>
              {item.animationUrl ? (
                <img src={item.animationUrl} alt="reaction" />
              ) : null}
            </BaseAnimation>
          );
        }
      });

      return renderedAnimation;
    },
    [tempAnimation],
  );

  useEffect(() => {
    if (!animation || !Object.keys(animation).length) return;

    updateReaction(animation.peerId, animation.animationUrl);

    setTimeout(() => {
      setTempAnimation(prevState =>
        prevState.map(item => {
          if (item.peerId === animation.peerId) {
            return {
              peerId: item.peerId,
              animationUrl: item.animationUrl,
              active: false,
              triggerFadeout: item.triggerFadeout,
            };
          }

          return item;
        }),
      );

      handleAnimationEnd?.();
    }, 2000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animation]);

  return (
    <Container>
      <ArrowScroll
        isLayout={isLayout}
        maxHeight="100%"
        style={{ padding: '3rem 2rem' }}
      >
        <Grid numCol={numCol} isLayout={isLayout}>
          {rooms.map((room, index) => (
            <Fragment key={index}>
              {animation && handleAnimationEnd ? (
                <div style={{ position: 'relative' }}>
                  <SquareRoom
                    key={index}
                    label={room.label}
                    state={
                      room.label ? isOccupiedOrFull(room.label) : room.state
                    }
                    type={room.type}
                    size={size}
                    preview={preview}
                    peerId={room.peerId}
                    peerName={room.peerName}
                    hasBorder={hasBorder}
                    isSpeaking={isSpeaking(room.peerId)}
                    isCenter={room.isCenter}
                    isChangingRoom={isChangingRoom}
                    isHovering={isHovering}
                    isActualUser={room.actualUser}
                    onClick={() => handleClick(room)}
                    disabled={room.muted || room.disabled}
                    isLayout={isLayout}
                  />
                  {renderAnimation(room)}
                </div>
              ) : (
                <SquareRoom
                  key={index}
                  label={room.label}
                  state={room.label ? isOccupiedOrFull(room.label) : room.state}
                  type={room.type}
                  size={size}
                  preview={preview}
                  isCenter={room.isCenter}
                  hasBorder={hasBorder}
                  peerId={room.peerId}
                  peerName={room.peerName}
                  isSpeaking={isSpeaking(room.peerId)}
                  isChangingRoom={isChangingRoom}
                  isHovering={isHovering}
                  isActualUser={room.actualUser}
                  onClick={() => handleClick(room)}
                  disabled={room.muted || room.disabled}
                  isLayout={isLayout}
                />
              )}
            </Fragment>
          ))}
        </Grid>
      </ArrowScroll>
    </Container>
  );
};

export default GridRoom;
