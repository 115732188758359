import {
  deleteFailed,
  deleteSuccess,
} from 'data/store/reducer/event/actions/delete';
import { iActionDelete } from 'domain/interfaces/redux/event/delete';
import { DeleteEvent } from 'domain/usecases/event/remote/DeleteEvent';
import { makeRemoteDeleteEvent } from 'main/factories/usecases/event/DeleteEventFactory';
import { call, put } from 'redux-saga/effects';
import { makeReduxGetAllEvent } from 'main/factories/usecases/event/GetAllEventFactory';
import { getAllRequest } from '../../reducer/event/actions/getAll';

export function* onDelete(action: iActionDelete) {
  const remoteDeleteEvent = makeRemoteDeleteEvent();

  try {
    const response: DeleteEvent.Model = yield call(
      remoteDeleteEvent.delete,
      action.payload,
    );

    yield put(
      deleteSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(deleteFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function* onDeleteSuccess() {
  yield put(
    getAllRequest({
      limit: 9999,
    }),
  );
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onDeleteFailed() {}
