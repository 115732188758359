import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: ${props => props.theme.colors.black1};
`;

export const EventContent = styled.div`
  display: flex;
  width: 100%;

  background: ${({ theme }) => theme.colors.black2};
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;

  /* margin-top: 2.4rem; */
  padding: 2.4rem;

  .form {
    width: 100%;
    color: #fff;
  }

  @media (max-width: 425px) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom: 0.1rem solid ${({ theme }) => theme.colors.black3};
  }
`;

export const EventForm = styled.div`
  display: flex;
`;

export const Divider = styled.div`
  width: 4px;
  height: 34px;

  background: ${({ theme }) => theme.colors.black5};

  /* &:hover {
    background: ${({ theme }) => theme.colors.pink1};
  } */

  @media (max-width: 769px) {
    display: none;
  }
`;

export const StepContent = styled.div`
  width: 100%;
  /* margin-top: 3rem; */
  display: flex;
  align-items: center;
  padding: 15px 30px;
  justify-content: space-between;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  background: ${props => props.theme.colors.black2};

  margin-bottom: 6rem;

  @media (max-width: 769px) {
    margin-bottom: 0;
    border-radius: 0;
    padding: 15px 20px;
  }
`;

export const Content = styled.form`
  width: 100%;
  max-width: 58.4rem;

  @media (min-width: 769px) {
    .mobile {
      display: none;
    }
  }

  @media (max-width: 769px) {
    width: 100%;
    .web {
      display: none;
    }
  }
`;

export const ContentPageNewEvent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 2.4rem;
`;

export const SubTitle = styled.div`
  display: flex;
  width: 100%;
  gap: 1.3rem;
  align-items: center;
  color: ${props => props.theme.colors.white1};
  background: transparent;
  margin-top: 3rem;
  font-size: 2.4rem;
  border-radius: 5px;
  font-family: 'Poppins';
  font-weight: bold;

  &.arrow-back:hover {
    fill: ${props => props.theme.colors.pink2};
  }

  @media (max-width: 769px) {
    font-size: 1.8rem;
    padding: 20px;
    margin-top: 0;
    background: ${props => props.theme.colors.black2};
    border-radius: 0;

    position: relative;
    .arrow-back,
    path {
      fill: ${props => props.theme.colors.white1};
    }

    &:before {
      content: '';
      width: 100%;
      height: 1px;
      background: ${props => props.theme.colors.white1};
      opacity: 0.2;
      display: block;
      margin: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;

export const Body = styled.div`
  width: 100%;
  margin-top: 3rem;
`;

export const ContainerButton = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 3rem;
  /* align-items: flex-end;
  justify-content: flex-end; */
  padding-right: 1rem;
`;

export const ContentButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 20px;
`;

export const BigDivider = styled.div`
  height: 100%;
  width: 1px;

  background-color: rgba(255, 255, 255, 0.08);
  /* background-color: red; */
  /* box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25); */

  margin: 0 30px;

  @media (max-width: 769px) {
    display: none;
  }
`;

export const HorizontalDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.08);
  margin: 20px 0;
`;

// todo: Remove this style to a component

export const StepComponent = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-start;

  @media (max-width: 769px) {
    display: none;
  }
`;

export const StepDivider = styled.div`
  width: 35px;
  height: 2px;

  background: #3f4660;
  opacity: 0.5;
  transform: rotate(90deg);
`;

export interface CircleProps {
  active?: boolean;
}

export const Circle = styled.div<CircleProps>`
  width: 35px;
  height: 35px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ active }) => (active ? '#3f4660' : '#3c46a0')};
  border-radius: 33.6px;

  margin-right: 10px;
`;

export const StepText = styled.div``;

export const StepTitle = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;

  color: #ffffff;
`;

export const StepSubtitle = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;

  color: #ffffff;
`;
