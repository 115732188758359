import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100%;
  max-height: 100%;
  height: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 0;
  margin-top: 6rem;

  .shade {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.9)
    );
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
`;

export const Image = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: 100vw 500px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  position: absolute;
  bottom: 6rem;
  gap: 8px;
`;

export const Title = styled.div`
  color: #efefef;
  margin-left: 45px;
  text-align: initial;
  font-weight: bold;
  font-size: 32px;
`;

export const Description = styled.div`
  width: 40%;
  margin-left: 45px;

  span {
    color: #efefef;
    display: block;
    text-align: justify;
    font-weight: 500;
    font-size: 14px;
  }

  @media (max-width: 390px) {
    span {
      color: #efefef;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-align: justify;
      text-overflow: ellipsis;
      font-weight: 500;
      font-size: 14px;
    }
  }

  @media (max-width: 768px) {
    span {
      color: #efefef;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-align: justify;
      text-overflow: ellipsis;
      font-weight: 500;
      font-size: 14px;
    }
  }
`;
