/* eslint-disable react/require-default-props */
import { iStore } from 'domain/interfaces/models';
import { IconChatBallon, SendIcon } from 'presentation/base/icons';
import { MessagesPublicChatContext } from 'presentation/contexts/MessagesPublicChatContext';
import React, { HTMLAttributes, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Input } from '../UI';
import HeaderChat from './HeaderChat';
import Message from './Message';
import { ChatInfoProps, MessageProps } from './interfaces';
import {
  Container,
  Content,
  InputContainer,
  Messages,
  NoMessageContent,
} from './styles';

interface ChatProps extends HTMLAttributes<HTMLDivElement> {
  handleDeleteChat: (id: number) => void;
  chatId: number;
  chat: ChatInfoProps;
  newMessage?: MessageProps;
  isMobile?: boolean;
  isActive?: boolean;
  handleNewMessageAdded?: () => void;
}

const Chat: React.FC<ChatProps> = ({
  handleDeleteChat,
  chat,
  chatId,
  newMessage,
  isMobile,
  isActive = true,
  handleNewMessageAdded,
}) => {
  const wrtc = useSelector((store: iStore) => store.wrtc);
  const wrtcInfo = useSelector((store: iStore) => store.auth.wrtcInfo);
  const user = useSelector((store: iStore) => store.auth.user);

  const [chatMessages, setChatMessages] = React.useState<MessageProps[]>(
    chat?.messages,
  );

  const [inputValue, setInputValue] = React.useState('');
  const [isOpen, setIsOpen] = React.useState(chat?.isOpen);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const { handleAddNewMessage, messages, isChatEmpty } = useContext(
    MessagesPublicChatContext,
  );

  const sendChatMessage = (data: any) => {
    if (wrtc.sendChatMessage) {
      if (chat?.isPublic) {
        wrtc.sendChatMessage(data, -1);
      } else {
        wrtc.sendChatMessage(data, chat?.to?.id);
      }
    }
  };

  const handleSubmit = () => {
    const hour = new Date().getHours();
    const minute = new Date().getMinutes();

    if (inputValue !== '' && chat?.from?.id && chat?.to?.id) {
      const message: MessageProps = {
        chat: {
          ...chat,
          id: chat?.isPublic ? -1 : chat?.id,
          to: {
            ...chat?.to,
            name: chat?.isPublic ? String(user.fullName) : chat?.to?.name,
          },
        },
        message: inputValue,
        type: 'sent',
        timestamp: `${hour < 10 ? `0${hour}` : hour}:${
          minute < 10 ? `0${minute}` : minute
        }`,
        userId: chat?.from.id,
        messageType: 'chat',
      };
      setChatMessages([...chatMessages, message]);
      sendChatMessage({
        ...message,
        type: 'received',
      });
      handleAddNewMessage({ ...message, type: 'received' });
      setInputValue('');
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleOpen = () => setIsOpen(!isOpen);

  useEffect(() => {
    document.getElementById(`bottom`)?.scrollIntoView({
      behavior: 'auto',
    });
  }, [chatMessages, newMessage, messages, chatId]);

  useEffect(() => {
    if (!newMessage) return;

    if (!newMessage?.isAdded) {
      handleNewMessageAdded?.();

      if (newMessage?.chat?.isPublic && chat?.isPublic) {
        // Tratando mensagens para o chat público
        if (newMessage?.chat?.id === chat?.id) {
          setChatMessages([...chatMessages, newMessage]);
          handleAddNewMessage(newMessage);
        }
      } else if (
        !chat?.isPublic &&
        !newMessage?.chat?.isPublic &&
        (newMessage?.userId === chat?.from?.id ||
          newMessage?.userId === chat?.to?.id)
      )
        // Tratando mensagens para o chat privado
        setChatMessages([...chatMessages, newMessage]);
    }
  }, [chat, chatMessages, handleNewMessageAdded, newMessage]);

  return (
    <>
      {chat?.isDisplayed && isActive && (
        <Container
          isOpen={isOpen}
          isMobile={isMobile}
          onKeyPress={handleKeyPress}
        >
          {!isMobile && (
            <HeaderChat
              onClick={handleOpen}
              handleDeleteChat={() => handleDeleteChat(chatId)}
              user={!chat?.isPublic ? chat?.to : undefined}
            />
          )}
          <Content id="chat1" isChatEmpty={isChatEmpty}>
            {isChatEmpty && (
              <NoMessageContent>
                <IconChatBallon />
                <h3>Não há novas mensagens</h3>
                <span>
                  Até agora, nenhum usuário enviou uma mensagem neste bate-papo.
                </span>
              </NoMessageContent>
            )}
            <Messages>
              {messages?.map((messageMap, index) => (
                <Message
                  message={messageMap}
                  user={
                    messageMap?.userId === chat?.to?.id
                      ? chat?.to
                      : messageMap?.chat?.from
                  }
                  id={
                    index + 1 >= chatMessages?.length
                      ? `last${chatId}`
                      : `${index}`
                  }
                />
              ))}
              <div id="bottom" />
            </Messages>
          </Content>
          <InputContainer>
            <Input
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Envie uma mensagem"
              isSearch
              onClickBtn={handleSubmit}
            >
              <Button
                style={{ height: '3.8rem' }}
                iconSize={20}
                withInput
                size="very small"
                icon={SendIcon}
                onClick={handleSubmit}
              />
            </Input>
          </InputContainer>
        </Container>
      )}
    </>
  );
};

export default Chat;
