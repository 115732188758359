import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetAllEvent as UsecaseRemoteGetAllEvent } from 'domain/usecases/event/remote';
import { GetAllEvent as UsecaseReduxGetAllEvent } from 'domain/usecases/event/redux';

import { RemoteGetAllEvent } from 'data/repository/event';
import { ReduxGetAllEvent } from 'data/store/reducer/event/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetAllEvent = (): UsecaseRemoteGetAllEvent =>
  new RemoteGetAllEvent(makeApiUrl('/events/'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxGetAllEvent = (): UsecaseReduxGetAllEvent =>
  new ReduxGetAllEvent();
