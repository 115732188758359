import { DeleteEvent } from 'domain/usecases/event/redux/DeleteEvent';
import {
  iActionDelete,
  iActionDeleteFailed,
  iActionDeleteSuccess,
  EventTypes,
} from 'domain/interfaces/redux/event/delete';

export const deleteRequest = (payload: DeleteEvent.Params): iActionDelete => ({
  type: EventTypes.DELETE,
  payload,
});

export const deleteSuccess = (
  params: DeleteEvent.Model,
): iActionDeleteSuccess => ({
  type: EventTypes.DELETE_SUCCESS,
  payload: params,
});

export const deleteFailed = (): iActionDeleteFailed => ({
  type: EventTypes.DELETE_FAILED,
});
