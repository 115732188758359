import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { CreateReaction as UsecaseRemoteCreateReaction } from 'domain/usecases/reaction/remote';
import { CreateReaction as UsecaseReduxCreateReaction } from 'domain/usecases/reaction/redux';

import { RemoteCreateReaction } from 'data/repository/reaction';
import { ReduxCreateReaction } from 'data/store/reducer/reaction/usecases';

/**
 * send request via API.
 */
export const makeRemoteCreateReaction = (): UsecaseRemoteCreateReaction =>
  new RemoteCreateReaction(makeApiUrl('/reactions/'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxCreateReaction = (): UsecaseReduxCreateReaction =>
  new ReduxCreateReaction();
