/* eslint-disable react/require-default-props */
import { CircularProgress } from '@mui/material';
import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import { defaultTheme } from 'presentation/base/themes';
import {
  IconFacebook,
  NewIconGmail,
  IconTwitter,
  IconWhatsapp,
  IconLinkedin,
  IconTelegram,
} from '../../../base/icons';
import { TooltipComponent } from '../../TooltipComponent';
import { ButtonSocial, ButtonStyle } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title?: string;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  iconSize?: number;
  size?: 'very small' | 'small' | 'medium' | 'large' | 'dynamic';
  social?:
    | 'google'
    | 'facebook'
    | 'twitter'
    | 'telegram'
    | 'whatsapp'
    | 'linkedin';
  colorstyle?: keyof typeof defaultTheme.colors;

  children?: ReactNode;
  tooltipProps?: {
    title?: string;
    placement: 'top' | 'bottom' | 'left' | 'right';
    pathName?: string;
  };
  withInput?: boolean;
  isLoading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  title,
  icon: Icon,
  iconSize,
  size,
  social = undefined,
  colorstyle = 'blue2',
  children,
  tooltipProps,
  withInput = false,
  isLoading = false,
  ...rest
}) => {
  function socialIcon() {
    switch (social) {
      case 'facebook':
        return <IconFacebook width="20px" height="20px" />;
      case 'twitter':
        return <IconTwitter width="20px" height="20px" />;
      case 'whatsapp':
        return <IconWhatsapp width="20px" height="20px" />;
      case 'linkedin':
        return <IconLinkedin width="20px" height="20px" />;
      case 'telegram':
        return <IconTelegram width="20px" height="20px" />;
      case 'google':
        return <NewIconGmail />;
      default:
        return <div />;
    }
  }

  return social === undefined ? (
    <TooltipComponent
      placement={tooltipProps?.placement ?? 'top'}
      title={tooltipProps?.title ?? ''}
    >
      <>
        <ButtonStyle
          withInput={withInput}
          colorstyle={colorstyle}
          size={size}
          iconSize={iconSize}
          {...rest}
        >
          {Icon && <Icon className="iconButton" />}
          {title && (
            <div>
              {isLoading ? (
                <CircularProgress
                  size={15}
                  style={{ color: defaultTheme.colors.white1 }}
                />
              ) : (
                <h4>{title}</h4>
              )}
            </div>
          )}
          {children}
        </ButtonStyle>
      </>
    </TooltipComponent>
  ) : (
    <TooltipComponent
      placement={tooltipProps?.placement ?? 'top'}
      title={tooltipProps?.title ?? ''}
    >
      <>
        <ButtonSocial social={social} size={size} {...rest}>
          {socialIcon()}
          <h4>{title}</h4>
          {children}
        </ButtonSocial>
      </>
    </TooltipComponent>
  );
};

export default Button;
