import styled from 'styled-components';

export const TagStyle = styled.div`
  min-width: 6.3rem;
  height: 3.4rem;
  padding: 11px 10px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.black5};
  border: none;
  border-radius: 5px;

  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.blue1};
  }
`;
export const CloseIconButton = styled.div`
  margin-left: 8px;
  width: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TagText = styled.text`
  color: ${({ theme }) => theme.colors.white1};
  font-size: 1.2rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  text-align: center;
`;
