/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
import { padWithZeroes } from './padwithzeros';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function formattedDate(date: Date, duration?: number): string {
  const dateWithoutTimezone = getDateWithoutTimezone(date);
  const roundedTime = roundTime(
    dateWithoutTimezone.getHours(),
    dateWithoutTimezone.getMinutes(),
  );

  const formattedTime = `${roundedTime.split(':')[0]}:${
    roundedTime.split(':')[1]
  }`;

  return `${dateWithoutTimezone
    .toLocaleDateString('pt-BR', {
      day: '2-digit',
    })
    .replace('.', ' ')} de ${dateWithoutTimezone.toLocaleDateString('pt-BR', {
    month: 'long',
  })} de ${dateWithoutTimezone.toLocaleDateString('pt-BR', {
    year: 'numeric',
  })} - ${formattedTime}${
    duration
      ? ` às ${incrementMinutesOnTimeString(
          formattedTime.replace('h', ':'),
          duration,
        )}`
      : ''
  }`;
}

export function formatDateWithDuration(
  initialDateTime: string,
  durationInMinutes: number,
): string {
  const initialDate = new Date(initialDateTime);
  const endDate = new Date(initialDate.getTime() + durationInMinutes * 60000); // convert duration to milliseconds

  const formattedDatee = endDate.toLocaleDateString('pt-BR', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

  const formattedStartTime = initialDate.toLocaleTimeString('pt-BR', {
    hour: 'numeric',
    minute: 'numeric',
  });

  const formattedEndTime = endDate.toLocaleTimeString('pt-BR', {
    hour: 'numeric',
    minute: 'numeric',
  });

  return `${formattedDatee} - ${formattedStartTime} às ${formattedEndTime}`;
}
// export function formattedDate(date: Date, duration?: number): string {
//   const dateWithoutTimezone = getDateWithoutTimezone(date);
//   const roundedTime = roundTime(
//     dateWithoutTimezone.getHours(),
//     dateWithoutTimezone.getMinutes(),
//   );

//   const formattedTime = `${roundedTime.split(':')[0]}h${
//     roundedTime.split(':')[1]
//   }`;

//   return `${dateWithoutTimezone
//     .toLocaleDateString('pt-BR', {
//       month: 'short',
//     })
//     .replace('.', ' ')} ${dateWithoutTimezone.toLocaleDateString('pt-BR', {
//     day: '2-digit',
//   })}, ${dateWithoutTimezone.toLocaleDateString('pt-BR', {
//     year: 'numeric',
//   })} - ${formattedTime}${
//     duration
//       ? ` às ${incrementMinutesOnTimeString(
//           formattedTime.replace('h', ':'),
//           duration,
//         )}`
//       : ''
//   }`;
// }

export function formatToIso(
  year: string,
  month: string,
  day: string,
  time: string,
  timezone?: string,
): string {
  return `${year}-${padWithZeroes(Number(month), 2)}-${padWithZeroes(
    Number(day),
    2,
  )}T${time}:00${timezone ?? ''}`;
}

export function addMinutesToDate(date: Date, minutes: number): Date {
  return new Date(date.getTime() + minutes * 60000);
}

export function addPadWithZeroes(value: number): string {
  return String(value).padStart(2, '0');
}

export function roundTime(hours: number, minutes: number): string {
  const roundMinutes = (parseInt(String((minutes + 7.5) / 15), 10) * 15) % 60;
  const roundHours = minutes > 52 ? (hours === 23 ? 0 : ++hours) : hours;

  return `${addPadWithZeroes(roundHours)}:${addPadWithZeroes(roundMinutes)}`;
}

export function getDateWithoutTimezone(value: Date): Date {
  const formatted = new Date(value);
  formatted.setMinutes(formatted.getMinutes() + formatted.getTimezoneOffset());

  return formatted;
}

export function incrementMinutesOnTimeString(
  time: string,
  duration: number,
): string {
  const [hours, minutes] = time.split(':').map(Number);
  const newMinutes = minutes + duration;
  const newHours = hours + Math.floor(newMinutes / 60);
  const newMinutesRemainder = newMinutes % 60;

  return `${newHours}:${addPadWithZeroes(newMinutesRemainder)}`;
}
