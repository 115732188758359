import { CreateEvent } from 'domain/usecases/event/remote';
import NewEvent from 'presentation/components/EventsForm/NewEvent';
import Header from 'presentation/components/Header';
import NewEventProvider, {
  NewEventContext,
} from 'presentation/contexts/NewEventContext';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { schemaCreateEvent } from 'validation/validators/CreateEvent/CreateEventValidator';
import { Container } from './styles';

export const formHookConfig: any = {
  resolver: schemaCreateEvent,
  shouldFocusError: true,
  reValidateMode: 'onChange',
  context: NewEventContext,
  defaultValues: {
    mediaType: 'LIVE',
  },
};

const NewEventPage: React.FC = () => {
  const formHook = useForm<CreateEvent.Params>(formHookConfig);

  return (
    <FormProvider {...formHook}>
      <NewEventProvider>
        <Container>
          <Header notFixed />

          <NewEvent />
        </Container>
      </NewEventProvider>
    </FormProvider>
  );
};

export default NewEventPage;
