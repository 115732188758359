import React from 'react';

import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControlLabelProps,
} from '@material-ui/core';

import { Container } from './styles/RadioStyled';

interface RadioComponentProps {
  title: string;
  onClick: () => void;
  checked: boolean;
}

const RadioComponent: React.FC<RadioComponentProps> = ({
  title,
  checked,
  onClick,
}) => {
  return (
    <Container>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel
            onClick={onClick}
            checked={checked}
            value="Público"
            control={<Radio id="radio" color="primary" />}
            label={title}
          />
        </RadioGroup>
      </FormControl>
    </Container>
  );
};

export default RadioComponent;
