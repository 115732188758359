import { call, put, select } from 'redux-saga/effects';
import { iActionCreate } from 'domain/interfaces/redux/sponsor/create';
import { CreateSponsor } from 'domain/usecases/sponsor/remote/CreateSponsor';
import { makeRemoteCreateSponsor } from 'main/factories/usecases/sponsor/CreateSponsorFactory';
import {
  createSuccess,
  createFailed,
} from 'data/store/reducer/sponsor/actions/create';
import { makeReduxListSponsors } from 'main/factories/usecases/sponsor/ListSponsorsFactory';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import { toast } from 'react-toastify';

export function* onCreate(action: iActionCreate) {
  const remoteCreateSponsor = makeRemoteCreateSponsor();

  try {
    const response: CreateSponsor.Model = yield call(
      remoteCreateSponsor.create,
      action.payload,
    );

    yield put(
      createSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(createFailed());
  }
}

export function onCreateSuccess() {
  makeReduxListSponsors().list({
    limit: 9999,
  });
  makeReduxActiveMessage().active({
    active: 'none',
  });
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onCreateFailed() {
  toast.error('Ocorreu um erro ao criar o modelo de patrocinador.');
}
