import { Dispatch } from 'redux';
import { UpdateWRTCInfo } from 'domain/usecases/auth/redux';
import { iActionUpdateWRTCInfo } from 'domain/interfaces/redux/auth/UpdateWRTCInfo';
import { updateWRTCInfo } from 'data/store/reducer/auth/actions/updateWrtcInfo';
import store from 'data/store';

export class ReduxUpdateWRTCInfo implements UpdateWRTCInfo {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  update = (params: UpdateWRTCInfo.Params): iActionUpdateWRTCInfo =>
    this.send(updateWRTCInfo(params));
}
