/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  Fragment,
} from 'react';
import { iAnimation } from 'presentation/pages/Room/interface';

// import { makeRemoteGetEventOccupancy } from 'main/factories/usecases/event/GetEventOccupancy';
import { AnimationContainer, Square } from './styles/LayoutGridRoomStyled';

// styles
import { Container, Grid } from './styles/styles';
import ArrowScroll from '../ArrowScroll/ArrowScroll';
import BaseAnimation from '../BaseAnimation';

export interface RoomProps {
  isLayout: boolean;
  rooms: {
    peerId?: number;
    peerName?: string;
    label: string;
    oldLabel?: string;
    state?: 'busy' | 'full' | 'normal';
    type?: 'vip' | 'normal';
    disabled?: boolean;
    muted?: boolean;
    isCenter?: boolean;
    actualUser?: boolean;
  }[];
  numCol: number;
  size?: string;
}

const NewGridRoom: React.FC<RoomProps> = ({
  isLayout,
  rooms = [],
  numCol,
  size,
}) => {
  return (
    <Container>
      <ArrowScroll isLayout={isLayout}>
        <Grid numCol={numCol} isLayout={isLayout}>
          {rooms.map((room, index) => (
            <Square
              size={`${size}`}
              className={
                room.peerId && room.peerId > 0 ? 'square-selected' : 'square'
              }
              selected={room.peerId ? room.peerId > 0 : false}
            />
          ))}
        </Grid>
      </ArrowScroll>
    </Container>
  );
};

export default NewGridRoom;
