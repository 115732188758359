import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { AcceptFriendship as UsecaseRemoteAcceptFriendship } from 'domain/usecases/friendship/remote';
// import { AcceptFriendship as UsecaseReduxAcceptFriendship } from 'domain/usecases/friendship/redux';

import { RemoteAcceptFriendship } from 'data/repository/friendship';
// import { ReduxAcceptFriendship } from 'data/store/reducer/friendship/usecases';

/**
 * send request via API.
 */
export const makeRemoteAcceptFriendship = (): UsecaseRemoteAcceptFriendship =>
  new RemoteAcceptFriendship(
    makeApiUrl('/users/{user}/friendships/{friendship}/accept'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
/* export const makeReduxAcceptFriendship =
  (): UsecaseReduxAcceptFriendship =>
    new ReduxAcceptFriendship(); */
