import styled from 'styled-components';

interface RoomContainerProps {
  layout?: 'lounge' | 'cine' | 'theater' | 'arena';
  containerSize?: string; // 'small' | 'medium' | 'large' | 'default'; // | String;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
  /* background: ${props => props.theme.colors.black2}; */
  background: rgba(0, 0, 0, 0.5);
  font-family: 'Poppins';
  border-radius: 5px;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 3rem;
`;

export const Header = styled.div`
  width: 100%;
  height: 5%;
  display: flex;
  justify-content: center;
`;

export const Title = styled.div`
  width: 77%;
  display: flex;
  gap: 13px;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.white1};
  font-size: 2.4rem;
  font-weight: bold;
`;

export const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-items: flex-end;
  /* margin-right: 1rem; */
`;
export const RoomsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -6%;
  height: 90%;
  // resposive: 768px -> 4%
  // resposive: 1024px -> 6%
  // resposive: 1280px -> 8% //optional

  /* @media (max-width: 768px) {
    margin: 0 -14%;
  } */
  /* @media (min-width: 1024px) {
    margin: 0 -14%;
  } */
`;
export const Rooms = styled.div<RoomContainerProps>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* grid-gap: 0.8rem; */
  grid-gap: ${({ containerSize }) => {
    switch (containerSize) {
      case 'smaller':
        return '0.2rem';
      case 'small':
        return '0.3rem';
      default:
        return '2rem';
    }
  }};
  margin: 0 auto;
  margin-top: 1rem;
  max-width: 100%;
  min-width: 78%;
  justify-items: center;

  /* @media (min-width: 1024px) {
    grid-gap: 0rem;
  } */
`;
export const Room = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 12rem;
  /* padding-bottom: 0.5rem;
  margin-top: 0.5rem; */
  width: 100%;

  @media (min-width: 1024px) {
    padding-left: 0;
  }
`;

interface RoomDivProps {
  isCentered?: boolean;
}

export const RoomDiv = styled.div<RoomDivProps>`
  cursor: pointer;
  width: 100%;
  opacity: ${({ isCentered }) => (isCentered ? '1' : '0.5')};

  :hover {
    opacity: 1;
  }
`;

export const FeedBack = styled.div`
  width: 100%;
  height: 5%;
  margin-top: 3rem;
  text-align: center;
  color: ${props => props.theme.colors.white1};
`;
