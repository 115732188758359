import { call, put, select } from 'redux-saga/effects';
import { iActionSendImage } from 'domain/interfaces/redux/reaction/sendImage';
import { SendImageReaction } from 'domain/usecases/reaction/remote/SendImageReaction';
import { makeRemoteSendImageReaction } from 'main/factories/usecases/reaction/SendImageReactionFactory';
import {
  sendImageSuccess,
  sendImageFailed,
} from 'data/store/reducer/reaction/actions/sendImage';

export function* onSendImage(action: iActionSendImage) {
  const remoteSendImageReaction = makeRemoteSendImageReaction();

  try {
    const response: SendImageReaction.Model = yield call(
      remoteSendImageReaction.sendImage,
      action.payload,
    );
    yield put(
      sendImageSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(sendImageFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onSendImageSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onSendImageFailed() {}
