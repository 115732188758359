import styled from 'styled-components';
import { Link } from 'react-router-dom';
import media from 'utils/mediaqueries';

export const ContainerModal = styled.div`
  width: 40.8rem;

  background: ${({ theme }) => theme.colors.black2};

  border-radius: 10px;
`;

export const Content = styled.div`
  ${media.lessThan('sm')} {
    padding: 20px 20px 0;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 2.4rem;
`;

export const TitleContent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Title = styled.h1`
  font-size: 1.8rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white1};
`;

export const Body = styled.form`
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
`;

export const ContainerInputs = styled.div`
  width: 100%;
  padding: 3rem 2.4rem 5.4rem 2.4rem;
`;

export const ContentInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const Label = styled.div`
  font-weight: bold;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.white1};
`;

export const SendDateTimeOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  gap: 20px;

  .nice-dates-popover {
    width: 280px;
    background-color: ${({ theme }) => theme.colors.black2};
    padding: 10px;
    box-shadow: 16px;
  }

  .nice-dates-navigation {
    padding-bottom: 8px;

    a {
      padding-bottom: 24px;

      &:before {
        border-color: ${({ theme }) => theme.colors.blue1};
      }
    }
    .nice-dates-navigation_current {
      color: ${({ theme }) => theme.colors.white6};
      font-size: 15px;
      font-weight: 700;
    }
  }

  .nice-dates-week-header {
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    border-radius: 4px;
    box-shadow: none;
    /* padding-left: 12px; */
    /* padding-right: 12px; */

    .nice-dates-week-header_day {
      width: 100%;
      color: ${({ theme }) => theme.colors.white6};
      font-size: 14px;
      text-transform: uppercase;
    }
  }

  .nice-dates-grid {
    height: 204px !important;
  }

  .nice-dates-grid_container {
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.black3};
    border-radius: 8px;
    /* padding-left: 12px;
    padding-top: 12px; */
  }

  .nice-dates-day {
    font-size: 12px;
    width: 32;
    height: 32px !important;
    color: ${({ theme }) => theme.colors.white1};
    border: 1px solid ${({ theme }) => theme.colors.blue1};
    /* padding: 2px 0; */

    &:after {
      color: ${({ theme }) => theme.colors.white1};
      background-color: ${({ theme }) => theme.colors.blue1};
    }

    .nice-dates-day_month {
      display: none;
      border: ${({ theme }) => theme.colors.blue1};
    }

    &.-wide {
      color: #686868;
    }

    &.-outside {
      color: ${({ theme }) => theme.colors.black3};
      background-color: ${({ theme }) => theme.colors.black1};
    }

    &:hover {
      color: ${({ theme }) => theme.colors.white1};
      background-color: ${({ theme }) => theme.colors.blue1};
      border: ${({ theme }) => theme.colors.blue1};
    }

    &.-selected {
      color: #686868;
      background-color: ${({ theme }) => theme.colors.blue1};

      span {
        color: currentColor;
      }

      &:hover {
        &:after {
          background-color: ${({ theme }) => theme.colors.blue1};
        }
      }

      &:after {
        transform: scale(1);
      }

      &:before {
        background-color: ${({ theme }) => theme.colors.blue1};
      }

      &.-selected-middle {
        &:before {
          background-color: #72dec8;
        }
      }

      &.-selected-end {
        &:after {
          opacity: 1;
          background-color: ${({ theme }) => theme.colors.blue1};
          border-radius: 999px;
        }
        &:before {
          background-color: ${({ theme }) => theme.colors.blue1};
          border-color: #72dec8;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      &.-selected-start {
        &:after {
          opacity: 1;
          background-color: ${({ theme }) => theme.colors.blue1};
          border-radius: 999px;
        }
        &:before {
          background-color: ${({ theme }) => theme.colors.blue1};
          border-color: #72dec8;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }
`;

export const GenreDateDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.white1};
`;

export const GridInputs = styled.main`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1.5rem;
`;

export const Agreements = styled.div`
  width: 100%;
  max-width: 95%;
  display: flex;
  padding: 0 2.4rem 2.4rem 2.4rem;

  font-size: 14px;
  line-height: 18px;

  color: ${({ theme }) => theme.colors.white1};

  span {
    width: 100%;
    font-size: 14px;
    margin-left: 1.5rem;
  }
`;

export const LinkToAgreements = styled(Link)`
  text-decoration: none;
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.pink1};
  white-space: wrap;

  &:hover {
    text-decoration: none;
  }
`;

export const SubTitle = styled.span`
  margin-top: 5px;
  font-family: 'Poppins', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white1};
`;

export const InlineInputs = styled.div`
  width: 100%;
  display: flex;
  gap: 1.6rem;
`;

export const Footer = styled.div`
  width: 100%;
  padding: 0 2.4rem 2.4rem 2.4rem;
`;
