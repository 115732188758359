import styled, { css } from 'styled-components';
import Select from 'react-select';
import { defaultTheme } from 'presentation/base/themes';

interface IContainerProps {
  size: 'small' | 'medium' | 'large' | 'full';
}

interface SelectProps {
  color: keyof typeof defaultTheme.colors;
  placeholder?: string;
  error?: boolean;
}

export const Container = styled.div<IContainerProps>`
  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          width: 10rem;
        `;
      case 'medium':
        return css`
          width: 12.3rem;
        `;
      case 'large':
        return css`
          width: 18.5rem;
        `;
      case 'full':
        return css`
          width: 100%;
        `;

      default:
        return css`
          width: auto;
        `;
    }
  }};
`;

export const Label = styled.label<{ required?: boolean }>`
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 1.2rem;
  ${({ required, theme }) =>
    required
      ? css`
          &:after {
            content: '*';
            color: ${theme.colors.red1};
          }
        `
      : css`
          &:after {
            content: ' (opcional)';
          }
        `};
`;

export const StyledSelect = styled(Select)<SelectProps>`
  .react-select__container {
    background: ${({ color, theme }) => theme.colors[color]};
    max-height: 3.4rem;
  }

  &:focus {
    outline: none;
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      filter: brightness(0.5);
    `}

  .select {
    width: 100%;
    height: 100%;
    &__indicator-separator {
      display: none;
    }

    &__placeholder {
      font-size: 1.4rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.white1};
    }

    &__indicators {
      svg path {
        fill: ${({ theme }) => theme.colors.white1};
      }
    }
    &__control {
      background: ${({ color, theme }) => theme.colors[color]};
      border: none;
      cursor: pointer;
      max-height: 3.4rem !important;

      &__focused {
        border-color: ${({ theme }) => theme.colors.blue1};
      }

      padding-left: 0.5rem;
    }
    &__menu {
      background: ${({ color, theme }) => theme.colors[color]};
      border: none;
      max-height: 22.2rem;
    }

    &__option {
      background: ${({ color, theme }) => theme.colors[color]};
      color: ${({ theme }) => theme.colors.white1};
      padding: 1rem;

      /* &:hover,
      &__is-selected {
        background: ${({ theme, isDisabled }) => theme.colors.black5};
      } */
    }

    &__is-disabled {
      filter: brightness(0.5);
      cursor: initial;
    }

    &__no-options-message {
      color: ${({ theme }) => theme.colors.white1};
    }
    &__value-container {
      color: ${({ theme }) => theme.colors.white1};
      height: 3.4rem;
    }

    &__menu-list {
      scrollbar-width: 0;
      padding: 0;
      margin: 0;
      border: none;
      border-radius: 5px;
      max-height: 22.2rem;
      background: ${({ color, theme }) => theme.colors[color]};
      cursor: pointer;
      &__is-focused {
        background: ${({ theme }) => theme.colors.black3};
      }
    }
    &__single-value,
    &__input-container,
    &__input,
    &__menu-list {
      color: ${({ theme }) => theme.colors.white1};
      font: ${({ theme }) => theme.typography.h7};
    }

    &__placeholder {
      font-size: 1.4rem;
      font-weight: 400;
      color: ${({ color, theme, placeholder }) =>
        placeholder ? theme.colors.white1 : theme.colors[color]};
    }

    &__input-container {
      padding: 0;
      margin: 0;
    }

    &__menu-list::-webkit-scrollbar {
      width: 4px;
      height: 0px;
    }
    &__menu-list::-webkit-scrollbar-track {
      background: #f1f1f1;
      display: none;
      margin-right: 0.5rem;
    }
    &__menu-list::-webkit-scrollbar-thumb {
      background: #fff;
      border-radius: 5px;
      margin-right: 0.5rem;
    }
    &__menu-list::-webkit-scrollbar-thumb:hover {
      background: #555;
      display: none;
      margin-right: 0.5rem;
    }
    &__placeholder {
      font-size: 1.4rem;
      font-weight: 400;
      color: ${({ color, theme, placeholder }) =>
        placeholder ? theme.colors.white1 : theme.colors[color]};
    }
  }
`;

export const StyledOption = styled.div<{
  isDisabled: boolean;
}>`
  filter: ${({ isDisabled }) =>
    isDisabled ? 'brightness(75%)' : 'brightness(100%)'};
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};

  &:hover {
    background: ${({ theme, isDisabled }) =>
      !isDisabled && theme.colors.black5};
  }
`;
