import React from 'react';
import { useHistory } from 'react-router-dom';
import { IconHelp } from 'presentation/base/icons';

import { Button } from '../UI';

import {
  Container,
  SubHeaderDiv,
  RightContainer,
  BackArrow,
  CategoryTitle,
} from './styles';

interface SubHeaderProps {
  title?: string;
  children?: React.ReactNode;
}

const SubHeader: React.FC<SubHeaderProps> = ({ title, children }) => {
  const history = useHistory();

  return (
    <Container>
      <SubHeaderDiv>
        <BackArrow onClick={() => history.push('/')} />
        <CategoryTitle>{title}</CategoryTitle>
        <RightContainer>
          <Button
            icon={IconHelp}
            colorstyle="black2"
            size="very small"
            iconSize={20}
          />
        </RightContainer>
        {children}
      </SubHeaderDiv>
    </Container>
  );
};

export default SubHeader;
