import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 3.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.black4};
  border-radius: 0.5rem;
  overflow: hidden;

  .input_copy {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.8rem 0.8rem 0.8rem 0;

    font-size: 1.4rem;
    font-weight: 400;

    background-color: transparent;
    color: ${({ theme }) => theme.colors.white2};
    border: none;

    &:focus {
      outline: none;
    }
  }

  .button_copy {
    width: 3.6rem;
    height: 3.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: ${({ theme }) => theme.colors.black3};
    color: #fff;
    cursor: pointer;
    padding: 1rem;
  }
`;

export const LeftIcon = styled.div`
  width: 3.6rem;
  height: 3.6rem;
  margin-left: 0.8rem;
  margin-right: 0.8rem;

  display: flex;
  justify-content: center;
  align-items: center;
  .icon {
    width: 1.4rem;
    height: 1 4rem;
  }

  svg path {
    border-color: ${({ theme }) => theme.colors.white3};
  }
`;
