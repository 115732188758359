import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: ${props => props.theme.colors.black1};
  margin-top: 6rem;
`;

interface VirtualAudienceProps {
  isActive?: boolean;
}

export const Options = styled.div<VirtualAudienceProps>`
  display: flex;
  align-items: center;
  margin: 0 1rem 0 0;
  padding-bottom: 0.3rem;
  border-bottom: 3px solid
    ${({ isActive, theme }) =>
      isActive ? theme.colors.blue1 : theme.colors.black5};
  cursor: ${props => (props.isActive ? 'default' : 'pointer')};
  color: ${props =>
    props.isActive ? props.theme.colors.white1 : props.theme.colors.black5};
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  &:hover {
    color: ${({ theme }) => theme.colors.white1};
    border-bottom: 3px solid ${({ theme }) => theme.colors.blue1};
  }
  &:active,
  :focus {
    color: ${({ theme }) => theme.colors.white1};
    border-bottom: 3px solid ${({ theme }) => theme.colors.blue1};
  }
`;

export const StepContent = styled.div`
  width: 100%;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  padding: 3rem;
  justify-content: space-between;
  border-radius: 5px;
  background: ${props => props.theme.colors.black2};
`;

export const Content = styled.div`
  width: 100%;
`;

export const ContentPageNewEvent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const SubTitle = styled.div`
  display: flex;
  width: 100%;
  gap: 1.3rem;
  align-items: center;
  color: ${props => props.theme.colors.white1};
  background: ${props => props.theme.colors.black2};
  padding: 1rem 3rem 1rem 1.4rem;
  margin-top: 3rem;
  font-size: 2.4rem;
  border-radius: 5px;
  font-family: 'Poppins';
  font-weight: bold;
`;

export const Body = styled.div`
  width: 100%;
  margin: 3rem 0;
`;

export const ContainerButton = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 3rem;
  /* align-items: flex-end;
  justify-content: flex-end; */
  padding-right: 1rem;
`;

export const ContentButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 20px;
`;
