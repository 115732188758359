/* eslint-disable react/require-default-props */
import React from 'react';
import { IconChecked, IconErrorCheckBox } from 'presentation/base/icons';

import { defaultTheme } from 'presentation/base/themes';
import { Container } from './styles';

interface Checkbox {
  id?: string;
  onClick: () => void;
  checked: boolean;
  color?: keyof typeof defaultTheme.colors;
  error?: boolean;
  size?: string;
}

const Checkbox: React.FC<Checkbox> = ({
  id,
  checked,
  onClick,
  color = 'black3',
  error = false,
  size,
}) => {
  const iconStyle: React.CSSProperties = size
    ? {
        width: size,
        height: size,
      }
    : {};

  return checked ? (
    <IconChecked style={iconStyle} id={id} onClick={onClick} color={color} />
  ) : (
    <Container
      style={iconStyle}
      id={id}
      onClick={onClick}
      color={color}
      error={error}
    >
      {error ? <IconErrorCheckBox style={iconStyle} color={color} /> : null}
    </Container>
  );
};

export default Checkbox;
