import { SendImageReaction } from 'domain/usecases/reaction/redux/SendImageReaction';
import {
  iActionSendImage,
  iActionSendImageFailed,
  iActionSendImageSuccess,
  ReactionTypes,
} from 'domain/interfaces/redux/reaction/sendImage';

export const sendImageRequest = (
  payload: SendImageReaction.Params,
): iActionSendImage => ({
  type: ReactionTypes.SENDIMAGE,
  payload,
});

export const sendImageSuccess = (
  params: SendImageReaction.Model,
): iActionSendImageSuccess => ({
  type: ReactionTypes.SENDIMAGE_SUCCESS,
  payload: params,
});

export const sendImageFailed = (): iActionSendImageFailed => ({
  type: ReactionTypes.SENDIMAGE_FAILED,
});
