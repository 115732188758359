import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { RequestFriendship as UsecaseRemoteRequestFriendship } from 'domain/usecases/friendship/remote';
// import { RequestFriendship as UsecaseReduxRequestFriendship } from 'domain/usecases/friendship/redux';

import { RemoteRequestFriendship } from 'data/repository/friendship';
// import { ReduxRequestFriendship } from 'data/store/reducer/friendship/usecases';

/**
 * send request via API.
 */
export const makeRemoteRequestFriendship = (): UsecaseRemoteRequestFriendship =>
  new RemoteRequestFriendship(
    makeApiUrl('/users/{user}/friendships'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
/* export const makeReduxRequestFriendship =
  (): UsecaseReduxRequestFriendship =>
    new ReduxRequestFriendship(); */
