import { SendImageReaction } from 'domain/usecases/reaction/remote';
import { UnexpectedError, BadRequestError, NotFound } from 'domain/errors';
import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import 'infra/global/variables';

export class RemoteSendImageReaction implements SendImageReaction {
  private readonly url: string;

  private readonly httpClient: HttpClient<SendImageReaction.Model>;

  constructor(url: string, httClient: HttpClient<SendImageReaction.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  sendImage = async (
    params: SendImageReaction.Params,
  ): Promise<SendImageReaction.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url.replace('{reactionId}', `${params.id}`),
      method: 'patch',
      body: params.body,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      default:
        throw new UnexpectedError();
    }
  };
}
