import React, { useMemo } from 'react';

import { Container, Error, Label, TextArea as StTextArea } from './styles';
import { TextAreaProps } from './types';

const TextArea: React.FC<TextAreaProps> = ({
  label,
  defaultValue,
  value,
  ...rest
}) => {
  const { error, id, required } = rest;

  return (
    <Container>
      <Label htmlFor={id} required={required}>
        {label}
      </Label>
      <StTextArea id={id} value={value} error={error} {...rest} />
      <Error>{error ? 'Esse campo é obrigatório' : null}</Error>
    </Container>
  );
};

export default TextArea;
