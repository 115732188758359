import { defaultTheme } from 'presentation/base/themes';
import styled, { css, keyframes } from 'styled-components';

const downToUpAnimation = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 40rem;
  height: 72.5rem;

  background: ${({ theme }) => theme.colors.black2};

  border-radius: 1rem;

  color: ${({ theme }) => theme.colors.white1};

  transform: translateY(0);
  transition: all 0.5s ease-out;
  animation: ${downToUpAnimation} 0.3s ease-out;

  @media (max-width: 425px) {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0;
    width: 100%;
    height: 67rem;
  }
`;

const padding = css`
  padding: 1.2rem 1.6rem;
`;

// TODO: Make this compoment more generic
export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${padding}

  // put this css on theme
  font-size: 1.8rem;
  font-weight: 600;
`;

type ContentInputProps = {
  error?: boolean;
  direction?: 'column' | 'row';
};

export const ContentInput = styled.div.attrs({
  className: 'input-content',
})<ContentInputProps>`
  width: 100%;
  display: flex;
  flex-direction: ${({ direction }) => direction || 'column'};
  gap: 1.2rem;

  margin-top: 2rem;

  @media (max-width: 425px) {
    & .logo-dropzone-create-event {
      height: 18rem;
    }
  }
`;

// TODO: Create a component for this
export const IconHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 3.6rem;
  height: 3.6rem;

  background: ${({ theme }) => theme.colors.black8};

  svg {
    width: 1.6rem;
    aspect-ratio: 1;
  }
`;

export const LabelHeader = styled.label`
  font-size: 1.8rem;
  font-weight: 600;
  align-self: center;
  margin: 0;
`;

export const Label = styled.label<{ required?: boolean }>`
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 1.2rem;
  ${({ required, theme }) =>
    required
      ? css`
          &:after {
            content: '*';
            color: ${theme.colors.red1};
          }
        `
      : css`
          &:after {
            content: ' (opcional)';
          }
        `};
`;

export const Title = styled.label`
  display: flex;
  align-center: center;
  justify-content: center;
  gap: 1.2rem;
  margin: 0;
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  background: transparent;

  padding: 1.1rem;
  border-radius: 0.5rem;
  outline: none;

  &:hover {
    background: ${({ theme }) => theme.colors.black3};
    color: ${({ theme }) => theme.colors.white2};
  }

  &:focus-visible {
    box-sizing: border-box;
    padding: 0.9rem;
    background: ${({ theme }) => theme.colors.black3};
    border: 0.2rem solid ${({ theme }) => theme.colors.blue1};

    box-shadow: 0 0 1rem ${({ theme }) => theme.colors.blue1};

    svg path {
      fill: ${({ theme }) => theme.colors.white2};
    }
  }

  &:active {
    border: 0;
    padding: 1.1rem;
    background: ${({ theme }) => theme.colors.black3};

    svg path {
      fill: ${({ theme }) => theme.colors.blue1};
    }

    box-shadow: initial;
  }

  svg {
    width: 1.6rem;
    aspect-ratio: 1;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 0.1rem;
  background: ${({ theme }) => theme.colors.black3};
  z-index: 0;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 1rem;

  width: 100%;
  /* height: 46rem; */

  overflow-y: auto;

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 0.1rem;
  }

  ${padding}
  padding-top: 2.4rem;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;

  gap: 1.5rem;

  flex-wrap: wrap;
`;

interface CheckBoxStyledProps {
  active?: boolean;
}

export const CheckBoxStyled = styled.button<CheckBoxStyledProps>`
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.3rem;

  border: 0;
  background: transparent;

  &:focus-visible {
    background: ${({ theme }) => theme.colors.black3};
    outline: 0.2rem solid ${({ theme }) => theme.colors.blue1};
    box-shadow: 0 0 1rem ${({ theme }) => theme.colors.blue1};
  }
`;

interface CircleFillProps {
  color: keyof typeof defaultTheme.colors;
  active?: boolean;
}

export const CircleFill = styled.div<CircleFillProps>`
  width: 2.4rem;
  height: 2.4rem;

  background-color: ${({ color, theme }) => theme.colors[color]};

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 0.3rem;

  ${({ active, theme }) =>
    active &&
    css`
      outline: 0.2rem solid ${theme.colors.blue1};
      box-shadow: 0 0 1rem ${theme.colors.blue1};
    `};

  padding: 0.7rem 0.6rem;

  & .icon-check-color {
    opacity: ${({ active }) => (active ? 1 : 0)};
  }
`;

export const Footer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 2.4rem;

  border-top: 0.1rem solid ${({ theme }) => theme.colors.black3};

  & .btn-template:not(:last-child) {
    margin-bottom: 1.2rem;
  }
`;
