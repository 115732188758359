import styled, { css } from 'styled-components';

const padding = css`
  padding: 1.2rem 1.6rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 40rem;

  background: ${({ theme }) => theme.colors.black2};

  border-radius: 1rem;

  color: ${({ theme }) => theme.colors.white1};
`;

// TODO: Make this compoment more generic
export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${padding}

  // put this css on theme
  font-size: 1.8rem;
  font-weight: 600;
`;

// TODO: Create a component for this
export const IconHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 3.6rem;
  height: 3.6rem;

  background: ${({ theme }) => theme.colors.black8};

  svg {
    width: 1.6rem;
    aspect-ratio: 1;
  }
`;

export const Label = styled.span`
  display: flex;
  align-items: center;
  margin: 0;
`;

export const Title = styled.label`
  display: flex;
  align-center: center;
  justify-content: flex-start;
  gap: 1.2rem;
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  background: transparent;

  padding: 1.1rem;
  border-radius: 0.5rem;
  outline: none;

  &:hover {
    background: ${({ theme }) => theme.colors.black3};
    color: ${({ theme }) => theme.colors.white2};
  }

  &:focus-visible {
    box-sizing: border-box;
    padding: 0.9rem;
    background: ${({ theme }) => theme.colors.black3};
    border: 0.2rem solid ${({ theme }) => theme.colors.blue1};

    box-shadow: 0 0 1rem ${({ theme }) => theme.colors.blue1};

    svg path {
      fill: ${({ theme }) => theme.colors.white2};
    }
  }

  &:active {
    border: 0;
    padding: 1.1rem;
    background: ${({ theme }) => theme.colors.black3};

    svg path {
      fill: ${({ theme }) => theme.colors.blue1};
    }

    box-shadow: initial;
  }

  svg {
    width: 1.6rem;
    aspect-ratio: 1;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 0.1rem;
  background: ${({ theme }) => theme.colors.black3};
  z-index: 0;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  margin-top: 2.4rem;
  gap: 2rem;

  width: 100%;
  height: 46rem;

  ${padding}
  padding-top: 0;
`;

export const OptionToggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
`;

export const OptionToggleLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;

  font-size: 1.4rem;
  font-weight: 500;
`;
