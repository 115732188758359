import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  ComponentType,
  useMemo,
} from 'react';

import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';

type AccessContextData = {
  setPermissions(permissions: string[]): void;
  hasAccess(): boolean;
};

const AccessContext = createContext<AccessContextData>({} as AccessContextData);

interface iACL {
  [key: string]: string[];
}

const AccessProvider: React.FC = ({ children }) => {
  const { auth } = useSelector((store: iStore) => store);

  const setPermissions = useCallback((permissions: string[]) => {
    console.log(permissions);
  }, []);

  const hasAccess = useCallback(
    (): boolean =>
      Boolean(auth?.accessToken && auth?.accessToken !== 'mock-token'),
    [auth],
  );

  return (
    <AccessContext.Provider value={{ setPermissions, hasAccess }}>
      {children}
    </AccessContext.Provider>
  );
};

const useAccess = () => {
  const context = useContext(AccessContext);
  return context;
};

type withAccessProps = {
  resourcers?: string[];
  actions?: string[];
};

function withAccess<P>(
  Component: ComponentType<P>,
): React.FC<P & withAccessProps> {
  const WithAccessComp: React.FC<P & withAccessProps> = ({ ...rest }) => {
    const { hasAccess } = useAccess();

    const access = useMemo(() => {
      return hasAccess();
    }, [hasAccess]);

    return access ? <Component {...(rest as P)} /> : <></>;
  };

  WithAccessComp.displayName = `withPermissions(${
    Component.displayName || Component.name
  })`;

  return WithAccessComp;
}

export { AccessProvider as default, useAccess, withAccess };
