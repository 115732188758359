// import { debounce } from 'lodash';
import { debounce, sortBy } from 'lodash';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import { makeReduxGetAllFriendship } from 'main/factories/usecases/friendship/GetAllFriendshipFactory';
import { makeRemoteRequestFriendship } from 'main/factories/usecases/friendship/RequestFriendshipFactory';
import { IconAddFriend, IconSearch } from 'presentation/base/icons';
import { AudioContext } from 'presentation/contexts/AudioContext';
import { SpotsInfo } from 'data/store/ducks/spotsInfo/types';
import { RandomProfileUser } from '../randomProfileUser';
import { Input } from '../UI';
import {
  ParticipantContainer,
  ParticipantItem,
  ParticipantsActionButton,
  ParticipantsItemActions,
  ParticipantsItemRow,
  ParticipantsMic,
  ParticipantsMutedMic,
  SearchContainer,
} from './styles';

interface ParticipantsProps {
  participants?: SpotsInfo[];
}

export const Participants: React.FC<ParticipantsProps> = ({ participants }) => {
  const { records } = useSelector((state: iStore) => state.friendShip);
  const { user: userLog } = useSelector((store: iStore) => store.auth);
  const {
    isMicMuted,
    handleRemoteMute,
    getMuteState,
    isActualUser,
    localMute,
  } = useContext(AudioContext);

  const [users, setUsers] = useState<SpotsInfo[] | undefined>([]);
  const [usersMap, setUsersMap] = useState<Map<string, SpotsInfo>>(new Map());
  const [usersPosition, setUsersPosition] = useState<SpotsInfo[] | undefined>(
    participants,
  );

  useEffect(() => {
    if (participants) {
      const pLength = participants?.length;
      const uLength = usersMap.size;

      if (pLength > uLength) {
        participants?.forEach(participant => {
          if (!usersMap.has(`${participant.peerId}`)) {
            addItemMap(`${participant.peerId}`, participant);
          }
        });
      } else if (pLength < uLength) {
        const auxSet: Set<string> = new Set();

        participants.forEach(participant =>
          auxSet.add(`${participant.peerId}`),
        );

        usersMap.forEach((value, key, map) => {
          if (!auxSet.has(key)) {
            deleteItemMap(key);
          }
        });
      }
    }

    setUsersPosition(participants);
  }, [participants]);

  useEffect(() => {
    const auxUsers: SpotsInfo[] = [];
    usersMap.forEach((value, key, map) => {
      auxUsers.push(value);
    });

    // setUsers(sortBy(auxUsers, (user: SpotsInfo) => user.peerName));
    setUsers(auxUsers);
  }, [usersMap]);

  const addItemMap = (key: string, value: SpotsInfo) => {
    setUsersMap(new Map(usersMap.set(key, value)));
  };

  const deleteItemMap = (key: string) => {
    setUsersMap(prev => {
      const newState = new Map(prev);
      newState.delete(key);
      return newState;
    });
  };

  const handleSearchParticipants = debounce((value: string) => {
    if (value.length === 0) {
      setUsers(participants);
      return;
    }

    const filtered = participants?.filter((item: any) =>
      item.peerName.toLowerCase().includes(value.toLowerCase()),
    );
    setUsers(filtered);
  }, 750);

  const disableAddFriend = useCallback(
    (friendId: number): boolean => {
      return (
        records?.findIndex(
          el =>
            Number(el.addressee.id) === friendId ||
            Number(el.requester.id) === friendId,
        ) !== -1
      );
    },
    [records],
  );

  const renderMic = useCallback(
    (item: SpotsInfo) => {
      const userPosition = usersPosition?.find(
        (user: SpotsInfo) => user.peerId === item.peerId,
      );

      if (isActualUser(Number(userPosition?.peerId) ?? -1)) {
        return isMicMuted ? (
          <ParticipantsMic onClick={() => localMute?.()} />
        ) : (
          <ParticipantsMutedMic onClick={() => localMute?.()} />
        );
      }

      return getMuteState(Number(userPosition?.peerId)) ? (
        <ParticipantsMutedMic
          onClick={() =>
            handleRemoteMute?.(
              Number(userPosition?.peerId),
              Number(userPosition?.spotCoordJ),
              Number(userPosition?.spotCoordI),
            )
          }
        />
      ) : (
        <ParticipantsMic
          onClick={() =>
            handleRemoteMute?.(
              Number(userPosition?.peerId),
              Number(userPosition?.spotCoordJ),
              Number(userPosition?.spotCoordI),
            )
          }
        />
      );
    },
    [
      getMuteState,
      handleRemoteMute,
      isActualUser,
      isMicMuted,
      localMute,
      usersPosition,
    ],
  );

  return (
    <>
      <SearchContainer>
        <Input
          placeholder="Buscar participante"
          className="input-search-participant"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleSearchParticipants(e.target.value)
          }
        >
          <IconSearch />
        </Input>
      </SearchContainer>
      <ParticipantContainer>
        {users?.map(participant => {
          if (participant.peerName === '') return <></>;
          const friendId = Number(participant.peerName.split(':')[1]);
          const disabled =
            friendId === Number(userLog.id) || disableAddFriend(friendId);

          return (
            <ParticipantItem key={friendId}>
              <ParticipantsItemRow>
                <RandomProfileUser
                  peerId={Number(participant.peerId)}
                  style={{ width: 32, height: 32 }}
                />
                <h4>{participant.peerName.split(':')[0]}</h4>
              </ParticipantsItemRow>
              <ParticipantsItemActions>
                <ParticipantsItemRow>
                  <ParticipantsActionButton>
                    {renderMic(participant)}
                  </ParticipantsActionButton>
                </ParticipantsItemRow>
                <ParticipantsItemRow>
                  <ParticipantsActionButton
                    // TODO: Temporarily disabled while not in Open Beta
                    // disabled={disabled}
                    disabled
                    onClick={async () => {
                      await makeRemoteRequestFriendship()
                        .request({
                          user: Number(userLog.id),
                          addressee: friendId,
                        })
                        .then(() => {
                          makeReduxGetAllFriendship().getAll({
                            user: Number(userLog.id),
                          });
                        });
                    }}
                  >
                    <IconAddFriend />
                  </ParticipantsActionButton>
                </ParticipantsItemRow>
              </ParticipantsItemActions>
            </ParticipantItem>
          );
        })}
      </ParticipantContainer>
    </>
  );
};
