import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 50%;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 15px;
  margin-right: 20px;

  span {
    color: white;
    margin-left: 12px;
  }
`;
