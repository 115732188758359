import { call, put, select } from 'redux-saga/effects';
import { iActionRefresh } from 'domain/interfaces/redux/auth/refresh';
import { RefreshAuth } from 'domain/usecases/auth/remote/RefreshAuth';
import { makeRemoteRefreshAuth } from 'main/factories/usecases/auth/RefreshAuthFactory';
import {
  refreshSuccess,
  refreshFailed,
} from 'data/store/reducer/auth/actions/refresh';
import { makeReduxLogout } from 'main/factories/usecases/auth/LogoutFactory';

export function* onRefresh(action: iActionRefresh) {
  const remoteRefreshAuth = makeRemoteRefreshAuth();

  try {
    const response: RefreshAuth.Model = yield call(
      remoteRefreshAuth.refresh,
      action.payload,
    );
    yield put(
      refreshSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(refreshFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onRefreshSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onRefreshFailed() {
  makeReduxLogout().logout();
}
