export default {
  Texto: 'Texto',
  Esportes: 'Deportes',
  Música: 'Música',
  Entretenimento: 'Entretenimiento',
  NÚMERO: 'NÚMERO',
  'AO VIVO': 'EN VIVO',
  'Título da sessão': 'Título de la sesión',
  'Entidade responsável': 'Entidad responsable',
  Horário: 'Horario',
  'Ticket Regular': 'Ticket regular',
  Realização: 'Realización',
  Data: 'Fecha',
  Categoria: 'Categoría',
  Subcategoria: 'Subcategoría',
  'O usuário precisa estar logado para acessar sala.':
    'El usuario debe estar logado para acceder a la sala.',
};
