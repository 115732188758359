import styled from 'styled-components';

export const Container = styled.div`
  width: 42rem;
  height: 61.4rem;
  background: ${({ theme }) => theme.colors.black2};
  border-radius: 5px;
`;

export const Header = styled.div`
  height: 6.4rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 3rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black5};
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-self: center;
`;

export const ModalTitle = styled.h3`
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.white1};
`;

export const BodyBox = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 48.6rem;
  border-radius: 5px;
  padding: 0 3rem;
  background-color: ${({ theme }) => theme.colors.black2};
  color: white;
  box-shadow: inset 0 2em 3em -1.5em ${({ theme }) => theme.colors.black1},
    inset 0 -2em 3em -2em ${({ theme }) => theme.colors.black1};
`;

export const InsideBodyBox = styled.div`
  overflow-x: hidden;
  overflow-y: auto;

  padding: 3rem 2.5rem 3rem 0;
`;

interface TextProps {
  bold?: boolean;
  marginTop?: boolean;
  small?: boolean;
}

export const Text = styled.div<TextProps>`
  display: flex;
  flex-direction: column;

  font-family: Poppins;
  font-weight: ${props => (props.bold ? 700 : 500)};
  font-size: ${props => `${props.bold && !props.small ? 1.6 : 1.4}rem`};
  line-height: ${props => `${props.bold ? 2.4 : 2.1}rem`};

  margin-top: ${props => (props.marginTop ? '1rem' : '0rem')};
`;

export const ButtonBox = styled.div`
  height: 6.4rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  border-top: 1px solid ${({ theme }) => theme.colors.black5};
`;
