import { iFriendship } from 'domain/interfaces/models/Friendship';
import { FriendshipTypes } from 'domain/interfaces/redux/friendship/types';
import { FriendshipActions } from './actions';

export const initialState: iFriendship = {
  loading: false,
  records: undefined,
  total: 0,
  offset: 0,
  limit: 0,
  required: {},
};

const reducer = (
  state = initialState,
  action: FriendshipActions,
): iFriendship => {
  switch (action.type) {
    case FriendshipTypes.GETALL:
      return { ...state, loading: true };
    case FriendshipTypes.GETALL_FAILED:
      return { ...state, loading: false };
    case FriendshipTypes.GETALL_SUCCESS: {
      return { ...state, ...action.payload, loading: false };
    }
    default:
      return state;
  }
};

export default reducer;
