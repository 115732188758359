import { all } from 'redux-saga/effects';

import AuthTasks from './auth';
import CategoryTasks from './category';
import EventTasks from './event';
import FriendShipTasks from './friendship';
import LayoutTasks from './layout';
import ReactionTasks from './reaction';
import SponsorsTasks from './sponsor';

export default function* rootSaga(): any {
  return yield all([
    ...AuthTasks,
    ...CategoryTasks,
    ...EventTasks,
    ...FriendShipTasks,
    ...LayoutTasks,
    ...ReactionTasks,
    ...SponsorsTasks,
  ]);
}
