import React from 'react';
import { useParams } from 'react-router-dom';
import RoomSelect from '../../../../presentation/pages/RoomSelect';

interface iParams {
  id: string;
}

export const RoomSelectFactory: React.FC = () => {
  const params: iParams = useParams();

  return <RoomSelect eventId={params.id} />;
};
