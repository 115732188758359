import { CreateReaction } from 'domain/usecases/reaction/remote';
import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import {
  NotFound,
  InvalidCredentialsError,
  UnexpectedError,
} from '../../../domain/errors';
import 'infra/global/variables';

export class RemoteCreateReaction implements CreateReaction {
  private readonly url: string;

  private readonly httpClient: HttpClient<CreateReaction.Model>;

  constructor(url: string, httClient: HttpClient<CreateReaction.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  create = async (
    params: CreateReaction.Params,
  ): Promise<CreateReaction.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
