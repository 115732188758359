import { Login as UseCaseReduxLogin } from 'domain/usecases/auth/redux';
import { Login as UseCaseRemoteLogin } from 'domain/usecases/auth/remote';

import { ReduxLogin } from 'data/store/reducer/auth/usecases/ReduxLogin';
import { RemoteAuthLogin } from 'data/repository/auth';
import { makeApiUrl, makeHttpClient } from '../../http';

/**
 * make login via API.
 */
export const makeRemoteLogin = (): UseCaseRemoteLogin =>
  new RemoteAuthLogin(makeApiUrl('/auth'), makeHttpClient());

/**
 * make login via SAGA.
 */
export const makeReduxLogin = (): UseCaseReduxLogin => new ReduxLogin();
