import { ListSponsors } from 'domain/usecases/sponsor/redux/ListSponsors';
import {
  iActionList,
  iActionListFailed,
  iActionListSuccess,
  SponsorTypes,
} from 'domain/interfaces/redux/sponsor/list';

export const listRequest = (payload: ListSponsors.Params): iActionList => ({
  type: SponsorTypes.LIST,
  payload,
});

export const listSuccess = (
  params: ListSponsors.Model,
): iActionListSuccess => ({
  type: SponsorTypes.LIST_SUCCESS,
  payload: params,
});

export const listFailed = (): iActionListFailed => ({
  type: SponsorTypes.LIST_FAILED,
});
