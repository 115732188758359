import { RefreshAuth } from 'domain/usecases/auth/remote';
import { UnexpectedError, BadRequestError, NotFound } from 'domain/errors';
import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import { AlertMessage } from 'presentation/components/messages/AlertMessage';
import 'infra/global/variables';

export class RemoteRefreshAuth implements RefreshAuth {
  private readonly url: string;

  private readonly httpClient: HttpClient<RefreshAuth.Model>;

  constructor(url: string, httClient: HttpClient<RefreshAuth.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  refresh = async (params: RefreshAuth.Params): Promise<RefreshAuth.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
      case HttpStatusCode.unauthorized:
      case HttpStatusCode.forbidden:
        AlertMessage({
          message: 'Credenciais inválidas!',
          type: 'danger',
        });
        throw new NotFound();
      default:
        AlertMessage({
          message: 'Falha ao realizar login!',
          type: 'danger',
        });
        throw new UnexpectedError();
    }
  };
}
