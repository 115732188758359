/* eslint-disable react/require-default-props */
import React, { HTMLAttributes } from 'react';
import IconNoUser from 'presentation/base/icons/iconNoUser.svg';
import { ChatPublicIcon, CloseIcon } from 'presentation/base/icons';
import { UserProps } from './interfaces';
import {
  Header,
  Description,
  Title,
  InfoContainer,
  ActionContainer,
  IconMinus,
  LeftSide,
  AvatarContainer,
  AvatarUser,
} from './styles';
import { RandomProfileUser } from '../randomProfileUser';

interface HeaderChatProps extends HTMLAttributes<HTMLDivElement> {
  handleDeleteChat: () => void;
  user?: UserProps;
}

const HeaderChat: React.FC<HeaderChatProps> = ({
  onClick,
  handleDeleteChat,
  user,
}) => {
  return (
    <Header>
      <LeftSide>
        {user ? <RandomProfileUser peerId={user.id} /> : <ChatPublicIcon />}
        <InfoContainer>
          <Title>{user ? user.name?.split(':')[0] : 'Chat'}</Title>
          <Description>{user ? user.status : 'Chat Público'}</Description>
        </InfoContainer>
      </LeftSide>
      <ActionContainer>
        <IconMinus onClick={onClick} />
        <CloseIcon onClick={handleDeleteChat} />
      </ActionContainer>
    </Header>
  );
};

export default HeaderChat;
