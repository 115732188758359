import styled, { css } from 'styled-components';

interface GridProps {
  numCol: number;
  isLayout: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Grid = styled.div<GridProps>`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: ${props => `repeat(${props.numCol}, 0fr)`};
  cursor: pointer;

  ${props => {
    return props.isLayout
      ? css`
          overflow: visible;
        `
      : css`
          grid-template-rows: 1fr;
          max-height: 50px;
        `;
  }}

  &:hover {
    .square {
      background-color: ${({ theme }) => theme.colors.black3};
      border-color: ${({ theme }) => theme.colors.white5};
    }

    .square-selected {
      background-color: ${({ theme }) => theme.colors.white5};
      border-color: ${({ theme }) => theme.colors.white3};
    }
  }
`;
