import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetAllLayout as UsecaseRemoteGetAllLayout } from 'domain/usecases/layout/remote';
import { GetAllLayout as UsecaseReduxGetAllLayout } from 'domain/usecases/layout/redux';

import { RemoteGetAllLayout } from 'data/repository/layout';
import { ReduxGetAllLayout } from 'data/store/reducer/layout/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetAllLayout = (): UsecaseRemoteGetAllLayout =>
  new RemoteGetAllLayout(makeApiUrl('/layouts/'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxGetAllLayout = (): UsecaseReduxGetAllLayout =>
  new ReduxGetAllLayout();
