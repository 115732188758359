export const moveOnRoom = (
  e: React.KeyboardEvent<HTMLDivElement>,
  rooms: any[],
  layoutId: number,
  onHexagonClick?: (room: any) => any,
  peerId?: number,
): any => {
  const { key } = e;
  const myPlace = rooms.find((room: any) => room.peerId === peerId);

  switch (layoutId) {
    case 1:
      if (myPlace.y > 0 && key === 'ArrowUp') {
        const buttonElement = document.getElementById('arrow-up');

        if (buttonElement) buttonElement.classList.add('active');

        setTimeout(() => {
          if (buttonElement) buttonElement.classList.remove('active');
        }, 250);

        const newPlace = rooms.find(
          (room: any) => room.x === myPlace.x && room.y === myPlace.y - 1,
        );

        if (newPlace.peerId !== -1) return;

        onHexagonClick?.(newPlace);
      }
      if (myPlace.y < 4 && key === 'ArrowDown') {
        const buttonElement = document.getElementById('arrow-down');

        if (buttonElement) buttonElement.classList.add('active');

        setTimeout(() => {
          if (buttonElement) buttonElement.classList.remove('active');
        }, 250);

        const newPlace = rooms.find(
          (room: any) => room.x === myPlace.x && room.y === myPlace.y + 1,
        );

        if (newPlace.peerId !== -1) return;

        onHexagonClick?.(newPlace);
      }
      if (myPlace.x > 0 && key === 'ArrowLeft') {
        const buttonElement = document.getElementById('arrow-left');

        if (buttonElement) buttonElement.classList.add('active');

        setTimeout(() => {
          if (buttonElement) buttonElement.classList.remove('active');
        }, 250);

        const newPlace = rooms.find(
          (room: any) => room.x === myPlace.x - 1 && room.y === myPlace.y,
        );

        if (newPlace.peerId !== -1) return;

        onHexagonClick?.(newPlace);
      }
      if (myPlace.x < 9 && key === 'ArrowRight') {
        const buttonElement = document.getElementById('arrow-right');

        if (buttonElement) buttonElement.classList.add('active');

        setTimeout(() => {
          if (buttonElement) buttonElement.classList.remove('active');
        }, 250);

        const newPlace = rooms.find(
          (room: any) => room.x === myPlace.x + 1 && room.y === myPlace.y,
        );

        if (newPlace.peerId !== -1) return;

        onHexagonClick?.(newPlace);
      }
      break;
    case 2:
      if (myPlace.y > 0 && key === 'ArrowUp') {
        const buttonElement = document.getElementById('arrow-up');

        if (buttonElement) buttonElement.classList.add('active');

        setTimeout(() => {
          if (buttonElement) buttonElement.classList.remove('active');
        }, 250);

        const newPlace = rooms.find(
          (room: any) => room.x === myPlace.x && room.y === myPlace.y - 1,
        );

        if (newPlace.peerId !== -1) return;

        onHexagonClick?.(newPlace);
      }
      if (myPlace.y < 3 && key === 'ArrowDown') {
        const buttonElement = document.getElementById('arrow-down');

        if (buttonElement) buttonElement.classList.add('active');

        setTimeout(() => {
          if (buttonElement) buttonElement.classList.remove('active');
        }, 250);

        const newPlace = rooms.find(
          (room: any) => room.x === myPlace.x && room.y === myPlace.y + 1,
        );

        if (newPlace.peerId !== -1) return;

        onHexagonClick?.(newPlace);
      }
      if (myPlace.x > 0 && key === 'ArrowLeft') {
        const buttonElement = document.getElementById('arrow-left');

        if (buttonElement) buttonElement.classList.add('active');

        setTimeout(() => {
          if (buttonElement) buttonElement.classList.remove('active');
        }, 250);

        const newPlace = rooms.find(
          (room: any) => room.x === myPlace.x - 1 && room.y === myPlace.y,
        );

        if (newPlace.peerId !== -1) return;

        onHexagonClick?.(newPlace);
      }
      if (myPlace.x < 19 && key === 'ArrowRight') {
        const buttonElement = document.getElementById('arrow-right');

        if (buttonElement) buttonElement.classList.add('active');

        setTimeout(() => {
          if (buttonElement) buttonElement.classList.remove('active');
        }, 250);

        const newPlace = rooms.find(
          (room: any) => room.x === myPlace.x + 1 && room.y === myPlace.y,
        );

        if (newPlace.peerId !== -1) return;

        onHexagonClick?.(newPlace);
      }
      break;
    case 3:
      if (
        myPlace.y > 0 &&
        (myPlace.y !== 6 || myPlace.x <= 5 || myPlace.x >= 16) &&
        key === 'ArrowUp'
      ) {
        const buttonElement = document.getElementById('arrow-up');

        if (buttonElement) buttonElement.classList.add('active');

        setTimeout(() => {
          if (buttonElement) buttonElement.classList.remove('active');
        }, 250);

        const newPlace = rooms.find(
          (room: any) => room.x === myPlace.x && room.y === myPlace.y - 1,
        );

        if (newPlace.peerId !== -1) return;

        onHexagonClick?.(newPlace);
      }
      if (myPlace.y < 9 && key === 'ArrowDown') {
        const buttonElement = document.getElementById('arrow-down');

        if (buttonElement) buttonElement.classList.add('active');

        setTimeout(() => {
          if (buttonElement) buttonElement.classList.remove('active');
        }, 250);

        const newPlace = rooms.find(
          (room: any) => room.x === myPlace.x && room.y === myPlace.y + 1,
        );

        if (newPlace.peerId !== -1) return;

        onHexagonClick?.(newPlace);
      }
      if (myPlace.x > 0 && key === 'ArrowLeft') {
        const buttonElement = document.getElementById('arrow-left');

        if (buttonElement) buttonElement.classList.add('active');

        setTimeout(() => {
          if (buttonElement) buttonElement.classList.remove('active');
        }, 250);

        const newPlace = rooms.find(
          (room: any) =>
            room.x ===
              myPlace.x - (myPlace.x === 16 && myPlace.y <= 5 ? 11 : 1) &&
            room.y === myPlace.y,
        );

        if (newPlace.peerId !== -1) return;

        onHexagonClick?.(newPlace);
      }
      if (myPlace.x < 21 && key === 'ArrowRight') {
        const buttonElement = document.getElementById('arrow-right');

        if (buttonElement) buttonElement.classList.add('active');

        setTimeout(() => {
          if (buttonElement) buttonElement.classList.remove('active');
        }, 250);

        const newPlace = rooms.find(
          (room: any) =>
            room.x ===
              myPlace.x + (myPlace.x === 5 && myPlace.y <= 5 ? 11 : 1) &&
            room.y === myPlace.y,
        );

        if (newPlace.peerId !== -1) return;

        onHexagonClick?.(newPlace);
      }
      break;
    case 4:
      if (myPlace.y > 0 && key === 'ArrowUp') {
        const buttonElement = document.getElementById('arrow-up');

        if (buttonElement) buttonElement.classList.add('active');

        setTimeout(() => {
          if (buttonElement) buttonElement.classList.remove('active');
        }, 250);

        const newPlace = rooms.find(
          (room: any) =>
            room.x === myPlace.x &&
            room.y ===
              myPlace.y -
                (myPlace.y === 8 && myPlace.x >= 6 && myPlace.x <= 15 ? 7 : 1),
        );

        if (newPlace.peerId !== -1) return;

        onHexagonClick?.(newPlace);
      }
      if (myPlace.y < 9 && key === 'ArrowDown') {
        const buttonElement = document.getElementById('arrow-down');

        if (buttonElement) buttonElement.classList.add('active');

        setTimeout(() => {
          if (buttonElement) buttonElement.classList.remove('active');
        }, 250);

        const newPlace = rooms.find(
          (room: any) =>
            room.x === myPlace.x &&
            room.y ===
              myPlace.y +
                (myPlace.y === 1 && myPlace.x >= 6 && myPlace.x <= 15 ? 7 : 1),
        );

        if (newPlace.peerId !== -1) return;

        onHexagonClick?.(newPlace);
      }
      if (myPlace.x > 0 && key === 'ArrowLeft') {
        const buttonElement = document.getElementById('arrow-left');

        if (buttonElement) buttonElement.classList.add('active');

        setTimeout(() => {
          if (buttonElement) buttonElement.classList.remove('active');
        }, 250);

        const newPlace = rooms.find(
          (room: any) =>
            room.x ===
              myPlace.x -
                (myPlace.x === 16 && myPlace.y >= 2 && myPlace.y <= 7
                  ? 11
                  : 1) && room.y === myPlace.y,
        );

        if (newPlace.peerId !== -1) return;

        onHexagonClick?.(newPlace);
      }
      if (myPlace.x < 21 && key === 'ArrowRight') {
        const buttonElement = document.getElementById('arrow-right');

        if (buttonElement) buttonElement.classList.add('active');

        setTimeout(() => {
          if (buttonElement) buttonElement.classList.remove('active');
        }, 250);

        const newPlace = rooms.find(
          (room: any) =>
            room.x ===
              myPlace.x +
                (myPlace.x === 5 && myPlace.y >= 2 && myPlace.y <= 7
                  ? 11
                  : 1) && room.y === myPlace.y,
        );

        if (newPlace.peerId !== -1) return;

        onHexagonClick?.(newPlace);
      }
      break;
    default:
      break;
  }
};
