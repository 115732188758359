import { iMessage } from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { History } from 'main/routes';
import React from 'react';
import Lottie, { LottieProps } from 'react-lottie';
import { closeModal } from 'utils/modalFunctions';
import { successAnimation } from 'presentation/base/animations';
import { Button } from '../../UI';
import {
  DescriptionModal,
  ModalContainer,
  ModalContent,
  TitleModal,
} from './styles';

interface props {
  message: iMessage;
}

const defaultOptions: LottieProps = {
  options: {
    loop: false,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
      progressiveLoad: true,
      hideOnTransparent: true,
      clearCanvas: true,
    },
  },
  isClickToPauseDisabled: true,
  width: 200,
  height: 200,
};

export const CreateEventSuccess: React.FC<props> = ({ message }) => {
  const { active } = message;

  const isActive = active === MessageOptions.createEventSuccessModal;

  const actionOk = () => {
    History.push('/');
    closeModal();
    window.location.reload();
  };

  return isActive ? (
    <ModalContainer>
      <ModalContent>
        <DescriptionModal>
          <Lottie {...defaultOptions} />
        </DescriptionModal>
        <TitleModal>Evento criado com sucesso!</TitleModal>

        <Button title="Demais!" size="medium" onClick={actionOk} />
      </ModalContent>
    </ModalContainer>
  ) : null;
};

export default CreateEventSuccess;
