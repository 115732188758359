/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-plusplus */
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { GetAllLayout } from 'domain/usecases/layout/remote';
import { makeRemoteGetAllLayout } from 'main/factories/usecases/layout/GetAllLayoutFactory';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import LogoDropzone from '../Dropzone';
import EventsLayout from '../EventsLayout';
import { Select } from '../UI';
import { iForm } from './NewEditEvent';
import { Message as MessageError } from '../UI/Input/styles';

import {
  Container,
  ContainerDisplayRoom,
  Content,
  ContentInput,
  ContentRadioButtons,
  FormDiv,
  Label,
  Message,
  Title,
} from './styles/EventRoomStyled';

interface roomNumberProps {
  label: string;
  value: string;
}

interface layoutTypeProps {
  label: string;
  value: string;
}

interface placesProps {
  label: string;
  value: string;
}

const EventRoom: React.FC = () => {
  const [state, setState] = useState(false);
  const [rooms, setRooms] = useState<Array<any>>([]);
  const [layouts, setLayouts] = useState<GetAllLayout.Model>([]);
  const [displayRooms, setDisplayRooms] = useState<Array<any>>([]);
  const [roomQty, setRoomQty] = useState<roomNumberProps>({
    label: '',
    value: '',
  });
  const [layoutType, setLayoutType] = useState<layoutTypeProps>({
    label: 'lounge',
    value: '1',
  });
  const [places, setPlaces] = useState<placesProps>({
    label: '',
    value: '',
  });

  const {
    register,
    setValue,
    setError,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext();

  const onClick = () => {
    // console.log('asuhausha');
  };

  const handleChange = useCallback(() => {
    setState(prevState => !prevState);
  }, []);

  const renderRooms = useCallback(() => {
    const array = [];

    for (let letter = 0; letter < 10; letter++) {
      for (let number = 0; number < 15; number++) {
        const isFull =
          15 * letter + number < Number(roomQty.value) ? 'busy' : 'normal';
        const object = {
          // eslint-disable-next-line prefer-template
          id: 15 * letter + number,
          peerId: -1,
          x: number,
          y: letter,
          label: '',
          state: isFull,
          type: 'normal',
          disabled: false,
          actualUser: false,
          muted: false,
        };

        array.push(object);
      }
    }

    setRooms(array);
  }, [roomQty]);

  const renderDisplayRooms = useCallback(() => {
    const arrayDisplayRooms = [];

    for (let letter = 0; letter < 10; letter++) {
      for (let number = 0; number < 4; number++) {
        const isFull =
          4 * letter + number < Number(places.value) ? 'busy' : 'normal';
        const object = {
          // eslint-disable-next-line prefer-template
          id: 4 * letter + number,
          peerId: -1,
          x: number,
          y: letter,
          label: '',
          state: isFull,
          type: 'normal',
          disabled: false,
          actualUser: false,
          muted: false,
        };

        arrayDisplayRooms.push(object);
      }
    }

    setDisplayRooms(arrayDisplayRooms);
  }, [places]);

  useEffect(() => {
    renderRooms();
  }, [roomQty]);

  useEffect(() => {
    renderDisplayRooms();
  }, [places]);

  useEffect(() => {
    makeRemoteGetAllLayout()
      .getAll({})
      .then(res => {
        setLayouts(res);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  console.log('getValues: ', getValues());
  console.log('layouts: ', layouts);

  return (
    <Container>
      <Content>
        <Title>Salas do Evento</Title>
        <FormDiv>
          <ContentInput>
            <Label>Layout das Salas*</Label>
            <Select
              id="layoutType"
              color="black1"
              size="large"
              value={{
                value: getValues()?.layoutId?.value,
                label:
                  layouts?.find(
                    item => item.id === Number(getValues()?.layoutId?.value),
                  )?.name ?? '',
              }}
              onChange={e => {
                setValue('layoutId', e);
                setLayoutType(e);
              }}
              options={
                layouts?.length
                  ? layouts.map(item => ({
                      label: item.name,
                      value: String(item.id),
                    }))
                  : []
              }
            />
            <ContainerDisplayRoom>
              <EventsLayout
                layout={
                  layouts?.find(
                    item => item.id === Number(getValues()?.layoutId?.value),
                  )?.name ?? ''
                }
              />
            </ContainerDisplayRoom>
            {errors?.layoutId?.label && (
              <MessageError
                style={{ marginTop: 10 }}
                error={errors?.layoutId?.label}
              >
                {errors?.layoutId?.label.message}
              </MessageError>
            )}
          </ContentInput>

          <ContentInput>
            <Label>Acesso*</Label>

            <ContentRadioButtons>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="Público"
                    control={
                      <Radio
                        id="radio"
                        color="primary"
                        checked={watch('joinPolicy') === 'PUBLIC'}
                        onClick={() => {
                          setValue('joinPolicy', 'PUBLIC');
                        }}
                      />
                    }
                    label="Público"
                  />
                  <FormControlLabel
                    value="Privado"
                    control={
                      <Radio
                        checked={watch('joinPolicy') === 'RESTRICTED'}
                        id="radio"
                        onClick={() => {
                          setValue('joinPolicy', 'RESTRICTED');
                        }}
                      />
                    }
                    label="Privado"
                  />
                </RadioGroup>
              </FormControl>
            </ContentRadioButtons>
            <Message>
              Os eventos privados não serão listados no catálogo principal e só
              poderão ser acessados com um link exclusivo.
            </Message>
            {errors?.joinPolicy && (
              <MessageError
                style={{ marginTop: 10 }}
                error={Boolean(errors?.joinPolicy)}
              >
                {errors?.joinPolicy.message}
              </MessageError>
            )}
          </ContentInput>

          <ContentInput>
            <Label>Bilheteria*</Label>

            <ContentRadioButtons>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="Sim"
                    control={
                      <Radio
                        id="radio"
                        checked={watch('paymentPolicy') === 'FREE'}
                        onClick={() => {
                          setValue('paymentPolicy', 'FREE');
                        }}
                      />
                    }
                    label="Sim"
                  />
                  <FormControlLabel
                    value="Não"
                    control={
                      <Radio
                        id="radio"
                        checked={watch('paymentPolicy') === 'REQUIRED'}
                        onClick={() => {
                          setValue('paymentPolicy', 'REQUIRED');
                        }}
                      />
                    }
                    label="Não"
                  />
                </RadioGroup>
              </FormControl>
            </ContentRadioButtons>
            <Message>
              Se houver bilheteria, o usuário só poderá acessar a transmissão
              com um ingresso comprado na Loja Netfans.
            </Message>
            {errors?.paymentPolicy && (
              <MessageError
                style={{ marginTop: 10 }}
                error={Boolean(errors?.paymentPolicy)}
              >
                {errors?.paymentPolicy.message}
              </MessageError>
            )}
          </ContentInput>

          <ContentInput>
            <Label>Banner do Evento</Label>
            <LogoDropzone
              defaultImage={getValues()?.banner}
              handleFileUpload={e => {
                setValue('banner', e);
                setError('banner', { type: 'onBlur' });
              }}
              fixedHeight
            />
          </ContentInput>
        </FormDiv>
      </Content>
    </Container>
  );
};

export default EventRoom;
