import styled from 'styled-components';

export const Container = styled.button`
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 0.5rem;

  border: 0;
  background: transparent;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.2s ease-in-out;
  cursor: pointer;
  outline: none;

  .icon path {
    fill: ${({ theme }) => theme.colors.white1};
  }

  .disabled path {
    fill: ${({ theme }) => theme.colors.red1};
  }

  &:focus-visible {
    outline: none;
    padding: 0.8rem;
    border: 0.2rem solid ${({ theme }) => theme.colors.blue1};
    box-shadow: 0px 0px 10px #4754bc;

    .disabled path {
      fill: ${({ theme }) => theme.colors.red2};
    }
  }

  &:hover {
    background: ${({ theme }) => theme.colors.black3};
    .icon path {
      fill: ${({ theme }) => theme.colors.white2};
    }

    .disabled path {
      fill: ${({ theme }) => theme.colors.red2};
    }
  }

  &:active {
    border: 0;
    padding: 1rem;
    box-shadow: initial;
    background: ${({ theme }) => theme.colors.black5};
    .icon path {
      fill: ${({ theme }) => theme.colors.blue1};
    }
  }

  &:disabled {
    cursor: default;
    background: ${({ theme }) => theme.colors.black1};
    .icon path {
      fill: ${({ theme }) => theme.colors.white4};
    }
  }
`;
