import styled, { css } from 'styled-components';

// Chat
export const Provider = styled.div`
  display: flex;
  /* width: 100%;
  height: 100vh; */
  height: 90%;
  padding: 0 3rem;
  color: ${({ theme }) => theme.colors.white1};
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1;

  .chats {
    margin-right: 11rem;
    display: flex;
    position: fixed;
    bottom: 8rem;
    right: 3rem;
    transition: all 2s ease-in-out;
    max-height: 8rem;
  }
`;

interface ChatContainerProps {
  isOpen?: boolean;
  isMobile?: boolean;
}

export const Container = styled.div<ChatContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: ${({ isOpen }) => (isOpen ? '60.2rem' : 'auto')};
  width: 41.6rem;
  min-width: 10.6rem;
  background: ${({ theme, isOpen }) => isOpen && theme.colors.black2};
  margin: ${({ isMobile }) => !isMobile && '0 0.75rem'};
  /* position: relative; */
  /* bottom: 8rem; */
  /* right: 8rem; */
  overflow: hidden;
  border-radius: 0.5rem 0.5rem 0 0;

  /* position: relative;   */
  bottom: ${({ isOpen }) => (isOpen ? '52.2rem' : 'auto')};

  &.content,
  &.messages,
  &.input-container {
    display: ${({ isOpen }) => (!isOpen ? 'block' : 'none')};
    height: auto;
  }
  ${({ isOpen }) =>
    !isOpen &&
    css`
      & .content,
      & .messages,
      & .input-container {
        display: none;
        height: 0;
      }
      .icon-minus {
        background: transparent;
        border: 2px solid ${({ theme }) => theme.colors.white1};
        width: 14px;
        height: 14px;
      }
    `}

  ${({ isMobile }) =>
    !isMobile
      ? css`
          position: relative;
        `
      : css`
          & {
            width: 100%;
            background: ${({ theme }) => theme.colors.black1};
          }
          & .content {
            width: 100%;
            height: 100%;
            margin: 0;
            /* max-height: calc(100vh - 53rem); */
            background: ${({ theme }) => theme.colors.black1};
          }

          height: 100%;
          & .messages {
            color: #fff;
          }
          & .input-container {
            width: 100%;
            padding: 1rem;

            input {
              background: ${({ theme }) => theme.colors.black2};
              color: ${({ theme }) => theme.colors.white1};
              ::placeholder {
                color: ${({ theme }) => theme.colors.blue1};
              }
              border-radius: 0.5rem 0 0 0.5rem;
            }
          }

          .content {
            padding: 0;
          }
        `};
`;

interface ContentChatProps {
  isChatEmpty?: boolean;
}

export const Content = styled.div.attrs({
  className: 'content',
})<ContentChatProps>`
  display: flex;
  width: 41.6rem;
  height: 45rem;
  overflow-y: scroll;
  max-height: 45rem;
  flex-direction: column;
  align-items: ${({ isChatEmpty }) => (isChatEmpty ? 'center' : 'flex-start')};
  justify-content: ${({ isChatEmpty }) =>
    isChatEmpty ? 'center' : 'flex-start'};

  background: ${({ theme }) => theme.colors.black2};
  padding: 2rem 1.5rem;
  padding-bottom: 0;
`;

// Header
export const Header = styled.div`
  width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
  z-index: 10;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  background: ${({ theme }) => theme.colors.black2};
  border-radius: 5px 5px 0 0;
`;

export const InfoContainer = styled.div`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Title = styled.h4`
  font: ${({ theme }) => theme.typography.h4};
  text-align: left;
`;

export const Description = styled.p`
  font: ${({ theme }) => theme.typography.h7};
  text-align: left;
  margin: 0;
  width: 100%;
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 20%;

  svg path {
    fill: ${({ theme }) => theme.colors.white1};
    cursor: pointer;
  }
`;

export const IconMinus = styled.div.attrs({
  className: 'icon-minus',
})`
  height: 2px;
  background: ${({ theme }) => theme.colors.white1};
  width: 1.2rem;
  cursor: pointer;
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  svg {
    margin-right: 1.5rem;
  }
`;

// Message
export const Messages = styled.div.attrs({
  className: 'messages',
})`
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* justify-content: flex-end; */
  padding: 2.8rem;
  padding-bottom: 0;
  z-index: 1;
`;

interface MessageProps {
  isMine?: boolean;
  isMobile?: boolean;
}

export const MessageContainer = styled.div<MessageProps>`
  width: 100%;
  display: flex;
  justify-content: ${({ isMine }) => (isMine ? 'flex-end' : 'flex-start')};

  .message-content {
    span {
      color: ${({ isMine, theme }) =>
        isMine ? theme.colors.white1 : theme.colors.white2};
    }

    ${({ isMobile, isMine }) =>
      isMobile
        ? css`
            background: ${({ theme }) =>
              isMine ? theme.colors.black5 : theme.colors.black2}; ;
          `
        : css`
            background: ${({ theme }) =>
              isMine ? theme.colors.black5 : theme.colors.black1};
          `}
  }
`;

export const MessageContent = styled.div.attrs({
  className: 'message-content',
})`
  padding: 1.5rem;
  /* background: ${({ theme }) => theme.colors.blue1}; */
  border-radius: 0.5rem;
  display: flex;
  margin: 0.75rem 0;
  width: 25rem;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  font: ${({ theme }) => theme.typography.h7};
`;
export const MessageText = styled.p`
  /* color: ${({ theme }) => theme.colors.white1}; */
  margin: 0;
  display: block;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  max-width: 20rem;
  margin-top: 1rem;
`;

// Input
export const InputContainer = styled.div.attrs({
  className: 'input-container',
})`
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 3rem;
  margin-top: 1.5rem;

  span {
    width: 100%;
  }
`;

export const AvatarContainer = styled.img`
  min-width: 2.5rem;
  margin-right: 1.5rem;
  max-height: 3.4rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.white1};
  border-radius: 0.5rem;
`;

interface AvatarStatusProps {
  status: 'online' | 'offline' | string;
}

export const AvatarUser = styled.div<AvatarStatusProps>`
  position: relative;
  height: 100%;
  ${AvatarContainer} {
    height: 3.4rem;
    height: 100%;
  }
  div {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 20px;
    left: 34px;
    border-radius: 100px;
    background: ${({ theme, status }) =>
      status === 'online' ? theme.colors.green1 : theme.colors.white2};
    border: 3px solid ${({ theme }) => theme.colors.black2};
  }
`;

export const UserMessageInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NoMessageContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    font: ${({ theme }) => theme.typography.h5};
    margin: 1.5rem 0 1rem 0;
    text-align: center;
  }

  span {
    font: ${({ theme }) => theme.typography.h7};
    max-width: 24rem;
    text-align: center;
  }
`;
