import { GetByIdReaction } from 'domain/usecases/reaction/redux/GetByIdReaction';
import {
  iActionGetById,
  iActionGetByIdFailed,
  iActionGetByIdSuccess,
  ReactionTypes,
} from 'domain/interfaces/redux/reaction/getById';

export const getByIdRequest = (
  payload: GetByIdReaction.Params,
): iActionGetById => ({
  type: ReactionTypes.GETBYID,
  payload,
});

export const getByIdSuccess = (
  params: GetByIdReaction.Model,
): iActionGetByIdSuccess => ({
  type: ReactionTypes.GETBYID_SUCCESS,
  payload: params,
});

export const getByIdFailed = (): iActionGetByIdFailed => ({
  type: ReactionTypes.GETBYID_FAILED,
});
