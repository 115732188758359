import styled, { css } from 'styled-components';

interface propsCategory {
  active: boolean;
  error?: boolean;
}

export const Container = styled.div`
  width: 100%;
  font-family: 'Poppins';
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  border-radius: 5px;
  padding: 3rem;
  background: ${({ theme }) => theme.colors.black2};
`;

export const Title = styled.h3`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;

  color: ${({ theme }) => theme.colors.white1};
`;

export const SubTitle = styled.h4`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  color: ${({ theme }) => theme.colors.white1};

  margin-top: 30px;
  margin-bottom: 10px;
`;

export const CategoriesContent = styled.div`
  display: flex;
`;

export const Category = styled.div<propsCategory>`
  border: ${({ error }) => error && '1px solid red'};
  display: flex;
  justify-content: center;
  height: 3.4rem;
  border-radius: 5px;
  align-items: center;
  padding: 2rem;

  margin-right: 21px;

  cursor: ${({ active }) => (active ? 'default' : 'pointer')};

  .tabs-text {
    color: ${({ theme, active }) =>
      active ? theme.colors.black1 : theme.colors.white1};
  }

  background: ${({ theme, active }) =>
    active ? theme.colors.white1 : theme.colors.black3};

  &:hover {
    background-color: ${({ theme, active }) => !active && theme.colors.black5};
  }
`;

export const MultiInputStyle = styled.div`
  width: 356px;
  height: 102px;

  background-color: ${({ theme }) => theme.colors.black3};
`;

export const InputView = styled.div`
  width: 30%;
`;

export const MultipleInputValuesView = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: initial;
  gap: 10px;
`;

// todo: Nova estilização

type TransmitionTextProps = {
  required?: boolean;
};

export const TransmitionText = styled.h4<TransmitionTextProps>`
  /* margin-top: 15px; */

  font-weight: 500;
  font-size: 1.4rem;

  color: #ffffff;

  ${({ required, theme }) =>
    required &&
    css`
      &:after {
        content: '*';
        color: ${theme.colors.red1};
      }
    `}

  &:last-letter {
    color: red;
  }
`;

export const TabsText = styled.div.attrs({
  className: 'tabs-text',
})`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 100%;
`;

export const TabsView = styled.div`
  display: flex;
  align-items: center;

  gap: 10px;
  margin-top: 12px;
  flex-wrap: wrap;
`;

export interface TabsProps {
  active?: boolean;
  disabled?: boolean;
}

export const Tabs = styled.button<TabsProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  background: ${({ active, theme }) =>
    active ? theme.colors.white2 : theme.colors.black3};

  font-size: 1.3rem;
  font-weight: 500;
  border: none;
  border-radius: 0.5rem;

  padding: 1.2rem 2rem;

  color: ${({ active, theme }) =>
    active ? theme.colors.black1 : theme.colors.white1};

  &:hover {
    background: ${({ active, theme }) =>
      active ? theme.colors.white1 : theme.colors.white5};
  }

  &:focus-visible {
    outline: 0.2rem solid ${({ theme }) => theme.colors.blue1};
    box-shadow: 0 0 1rem ${({ theme }) => theme.colors.blue1};
  }

  &:disabled {
    cursor: default;
    color: ${({ theme }) => theme.colors.white5};
    background: ${({ theme }) => theme.colors.black1};
  }
`;
