import React, { useEffect, useState } from 'react';

import { BrowserRouter, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import MessagesPublicChatProvider from 'presentation/contexts/MessagesPublicChatContext';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import AccessControl from './presentation/hooks/access';

import store from './data/store';
import translations from './infra/i18n/locales';
import { Routes } from './main/routes';
import { GlobalStyle } from './presentation/base/styles/global';
import Notifications from './presentation/components/messages';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import './infra/global/variables';
import { AudioDeviceConfigureProvider } from './presentation/contexts/AudioDeviceConfigureContext';
import { ThemeProviderNetfans } from './presentation/contexts/themeContext';

const persistor = persistStore(store);

export interface iSupportedLanguages {
  language:
    | 'pt-BR'
    | 'pt-br'
    | 'en-US'
    | 'en-us'
    | 'es'
    | 'es-ES'
    | 'es-es'
    | 'es-DO'
    | 'es-do'
    | 'es-GT'
    | 'es-gt'
    | 'es-HN'
    | 'es-hn'
    | 'es-MX'
    | 'es-mx'
    | 'es-PA'
    | 'es-pa'
    | 'es-PE'
    | 'es-pe'
    | 'es-SV'
    | 'es-SE'
    | 'es-sv'
    | 'es-se';
}

const App: React.FC = () => {
  const [selectedLocale, setSelectedLocale] =
    useState<iSupportedLanguages['language']>('pt-BR');

  useEffect(() => {
    let userLanguage = localStorage.getItem('@netfans/react-intl');

    if (!userLanguage) {
      userLanguage = navigator.language;
      localStorage.setItem('@netfans/react-intl', userLanguage);
    }

    setSelectedLocale(userLanguage as iSupportedLanguages['language']);
  }, []);

  console.log('test deploy');

  return (
    <IntlProvider
      messages={translations[selectedLocale]}
      locale={String(selectedLocale)}
      defaultLocale="pt-BR"
    >
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <AccessControl>
            <BrowserRouter>
              <AudioDeviceConfigureProvider>
                <ThemeProviderNetfans>
                  <MessagesPublicChatProvider>
                    {/* <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}> */}
                    <Notifications>
                      <div className="App">
                        <Switch>
                          <Routes />
                        </Switch>
                        <ToastContainer
                          position="top-right"
                          autoClose={5000}
                          closeOnClick
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                        />
                        <GlobalStyle />
                      </div>
                    </Notifications>
                    {/* </ThemeProvider> */}
                  </MessagesPublicChatProvider>
                </ThemeProviderNetfans>
              </AudioDeviceConfigureProvider>
            </BrowserRouter>
          </AccessControl>
        </PersistGate>
      </Provider>
    </IntlProvider>
  );
};

export default App;
