export default {
  colors: {
    colorTextGoogle: '#757575',
    colorButtonTwitter: '#5899e3',
    colorButtonFacebook: '#1877f2',
    colorButtonTelegram: '#169ce3',
    colorButtonLinkedin: '#2064a8',
    colorButtonWhats: '#20bf5b',
    colorButtonFacebookHover: '#3e92ff',
    colorButtonTwitterHover: '#75b5ff',
    colorButtonGoogleHover: '#cdcfd8',
    colorButtonWhatsHover: '#51e287',
    colorButtonTelegramHover: '#5ec7ff',
    colorButtonLinkedinHover: '#4092e4',
    colorError: '#ea3e4f',
    colorBlack: '#000000',

    // New colors

    white1: '#E1E1E1',
    white2: '#FBFBFB',
    white3: '#828282',
    white4: '#F1F1F1',
    white5: '#575555',
    white6: '#CCCCCC',
    black1: '#121212',
    black2: '#252424',
    black3: '#3D3B3B',
    black4: '#333333',
    black5: '#1E1E1E',
    black6: '#040404',
    black7: '#222222',
    black8: '#363636',
    blue1: '#758AFA',
    blue2: '#5662CF',
    yellow1: '#FFC64C',
    pink1: '#FE498A',
    pink2: '#FF71A4',
    red1: '#ED5353',
    red2: '#FF6666',
    green1: '#1CCAA0',
    green2: '#61E0C1',

    purple1: '#8951ff',
  },
  typography: {
    h1: `700 6rem 'Poppins'`,
    h2: `700 3.2rem 'Poppins'`,
    h3: `700 2.4rem 'Poppins'`,
    h4: `700 1.6rem 'Poppins'`,
    h5: `500 1.4rem 'Poppins'`,
    h6: `700 1.2rem 'Poppins'`,
    h7: `400 1.2rem 'Poppins'`,
    h8: `400 1.1rem 'Poppins'`,
  },
  breakpoints: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1536px',
  },
};
