import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ArrowDown,
  IconArrowUp,
  IconEntertainment,
  IconMusic,
  IconSport,
  LogoNetfans,
} from 'presentation/base/icons';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import {
  Container,
  LogoContent,
  Border,
  Title,
  Categories,
  List,
  Category,
} from './styles/StyledCategoriesMenu';

interface iCategories {
  sport: string[];
  music: string[];
  entertaiment: string[];
}

const CategoriesMenu: React.FC = () => {
  const [openSpots, setOpenSpots] = useState(false);
  const [openMusic, setOpenMusic] = useState(false);
  const [openEntertaiment, setOpenEntertaiment] = useState(false);
  const [categories, setCategories] = useState<iCategories>({
    sport: [],
    music: [],
    entertaiment: [],
  });

  const onlyDemo = window.config?.featureToggles?.onlyDemo || false;

  const { results } = useSelector((store: iStore) => store.category);

  useEffect(() => {
    results.forEach(el => {
      if (`${el.parent}` === '1') {
        if (!categories.sport.find(name => name === el.name))
          categories.sport.push(el.name);
      }
      if (`${el.parent}` === '2') {
        if (!categories.music.find(name => name === el.name))
          categories.music.push(el.name);
      }
      if (`${el.parent}` === '3') {
        if (!categories.entertaiment.find(name => name === el.name))
          categories.entertaiment.push(el.name);
      }
    });

    setCategories(categories);
  }, [results]);

  return (
    <Container>
      <LogoContent>
        <Link to="/" data-testid="logo">
          <LogoNetfans />
          <div className="name">Netfans</div>
        </Link>
      </LogoContent>
      <Border />
      <Title>CATEGORIAS DE CONTEÚDO</Title>
      <Categories onClick={() => setOpenSpots(!openSpots)}>
        <div className="name">
          <IconSport />
          Esporte
        </div>
        {openSpots ? <IconArrowUp /> : <ArrowDown />}
      </Categories>
      <List open={openSpots}>
        {categories.sport.map(el => {
          return (
            <Category>
              {onlyDemo ? (
                <a href="https://netfans.tv" target="_blank" rel="noreferrer">
                  {el}
                </a>
              ) : (
                <Link to="/sports">{el}</Link>
              )}
            </Category>
          );
        })}
      </List>
      <Categories onClick={() => setOpenMusic(!openMusic)}>
        <div className="name">
          <IconMusic />
          Música
        </div>
        {openMusic ? <IconArrowUp /> : <ArrowDown />}
      </Categories>
      <List open={openMusic}>
        {categories.music.map(el => {
          return (
            <Category>
              {onlyDemo ? (
                <a href="https://netfans.tv" target="_blank" rel="noreferrer">
                  {el}
                </a>
              ) : (
                <Link to="/music">{el}</Link>
              )}
            </Category>
          );
        })}
      </List>
      <Categories onClick={() => setOpenEntertaiment(!openEntertaiment)}>
        <div className="name">
          <IconEntertainment />
          Entretenimento
        </div>
        {openEntertaiment ? <IconArrowUp /> : <ArrowDown />}
      </Categories>
      <List open={openEntertaiment}>
        {categories.entertaiment.map(el => {
          return (
            <Category>
              {onlyDemo ? (
                <a href="https://netfans.tv" target="_blank" rel="noreferrer">
                  {el}
                </a>
              ) : (
                <Link to="/entertainment">{el}</Link>
              )}
            </Category>
          );
        })}
      </List>
    </Container>
  );
};

export default CategoriesMenu;
