import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetAllFriendship as UsecaseRemoteGetAllFriendship } from 'domain/usecases/friendship/remote';
import { GetAllFriendship as UsecaseReduxGetAllFriendship } from 'domain/usecases/friendship/redux';

import { RemoteGetAllFriendship } from 'data/repository/friendship';
import { ReduxGetAllFriendship } from 'data/store/reducer/friendship/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetAllFriendship = (): UsecaseRemoteGetAllFriendship =>
  new RemoteGetAllFriendship(
    makeApiUrl('/users/{user}/friendships'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
export const makeReduxGetAllFriendship = (): UsecaseReduxGetAllFriendship =>
  new ReduxGetAllFriendship();
