/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { iStore } from 'domain/interfaces/models';
import GridRoom from 'presentation/components/GridRoom';
import Subheader from 'presentation/components/SubHeader';
import InfoEvent from 'presentation/components/InfoEvent';
import LivePlataform from 'presentation/components/LivePlataform';

import { makeReduxUpdateWrtc } from 'main/factories/usecases/wrtcClient/UpdateFactory';
import { WRTCClient } from 'services/wrtccli/netfans-wrtc-wrapper.js';

import { iEventItem } from 'domain/interfaces/models/Event';
import { GetAllLayout } from 'domain/usecases/layout/remote';
import { makeReduxUpdateWRTCInfo } from 'main/factories/usecases/auth/UpdateWRTCInfoFactory';
import { makeReduxGetAllCategory } from 'main/factories/usecases/category/GetAllCategoryFactory';
import { makeReduxGetAllEvent } from 'main/factories/usecases/event/GetAllEventFactory';
import {
  makeReduxGetAllLayout,
  makeRemoteGetAllLayout,
} from 'main/factories/usecases/layout/GetAllLayoutFactory';
import { makeReduxUpdateSpotsInfo } from 'main/factories/usecases/spotsInfo/UpdateFactory';
import useWindowDimensions from 'presentation/hooks/useWindowDimensions';
import { convertFromWRTCRoomName, onJoinRoomFailed } from 'utils';
import { formattedDate } from 'utils/formattedDate';
import MessagesPublicChatProvider from 'presentation/contexts/MessagesPublicChatContext';
import { IconPersonGray } from 'presentation/base/icons';
import Header from '../../components/Header';
import {
  AboutContainer,
  AboutDescription,
  AboutTitle,
  Box,
  Container,
  EventContainer,
  EventPlayerClappr,
  HexagonContainer,
  LeftContainer,
  RoomsContainer,
} from './styles';

interface iEvent {
  eventId: string;
}
interface iLivePlataformContentInfo {
  title?: string;
  description?: string;
  usersInRoom?: number;
}

const SPORTS_CATEGORY_ID = 1;
const MUSIC_CATEGORY_ID = 2;
const ENTERTAINMENT_CATEGORY_ID = 3;

const RoomSelect: React.FC<iEvent> = ({ eventId }) => {
  const wrtc = useSelector((state: iStore) => state.wrtc);
  const info = useSelector((state: iStore) => state.auth.user);
  const wrtcInfo = useSelector((state: iStore) => state.auth.wrtcInfo);
  const event = useSelector((state: iStore) => state.event);
  const category = useSelector((state: iStore) => state.category);

  const [rooms, setRooms] = useState<Array<any>>([]);
  const [occupancy, setOccupancy] = useState<Array<any>>([]);
  const [floors, setFloors] = useState<Array<any>>([]);
  const [layouts, setLayouts] = useState<GetAllLayout.Model>([]);
  const [updateInsideRoom, setUpdateInsideRoom] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<iEventItem>(
    {} as iEventItem,
  );

  const history = useHistory();

  const { isMobile } = useWindowDimensions();

  const renderRooms = useCallback(() => {
    const array = [];

    for (let letter = 0; letter < 10; letter++) {
      for (let number = 0; number < 15; number++) {
        const object = {
          // eslint-disable-next-line prefer-template
          id: 15 * letter + number,
          peerId: -1,
          x: number,
          y: letter,
          label: 15 * letter + number + 1,
          oldLabel: `${String.fromCharCode(65 + letter)}${number + 1}`,
          state: 'normal',
          type: 'normal',
          disabled: false,
          actualUser: false,
          muted: false,
        };

        array.push(object);
      }
    }

    setRooms(array);
  }, []);

  const onRoomClick = useCallback(
    (room: any) => {
      console.log('click: ', room);

      if (info?.email) {
        history.push({
          pathname: `/rooms/${eventId}/room/${room.label}`,
        });
      } else {
        toast.error('Você precisa estar logado para acessar salas');
      }
    },
    [eventId, history, info],
  );

  const getMaxOccupation = useCallback(() => {
    if (selectedEvent?.layout?.id) {
      const { id } = selectedEvent?.layout;
      return layouts.find(layout => layout.id === id)?.seats ?? 40;
    }
  }, [layouts, selectedEvent]);

  useEffect(() => {
    // Desconectar o usuário de qualquer sala quando o mesmo estiver na seleção de páginas
    if (wrtc.leaveRoom) {
      makeReduxUpdateWRTCInfo().update({ insideRoom: false });
      wrtc.leaveRoom();
    }

    if (wrtc.disconnect) wrtc.disconnect();

    renderRooms();
    makeReduxUpdateSpotsInfo().update([]);
    makeReduxGetAllEvent().getAll({
      limit: 9999,
    });
    makeReduxGetAllCategory().getAll({});
    makeRemoteGetAllLayout()
      .getAll({})
      .then(res => {
        setLayouts(res);
      })
      .catch(err => {
        console.log(err);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const client = new WRTCClient(window.config.clientConfig);

    const onCastOccupancy = (roomsOccupancies: any): void => {
      const array: any = [];
      let roomName;

      console.debug(
        `occupancy handler : ${rooms.length}, occ.length: ${roomsOccupancies.length}`,
      );

      roomsOccupancies.forEach((occ: any) => {
        roomName = convertFromWRTCRoomName(occ.name);
        console.debug(`roomname: ${roomName}, occ: ${occ.occup}`);
        array.push({
          roomname: roomName,
          amount: occ.occup,
        });
      });

      console.log('Novo occupancy: ', array);
      setOccupancy(array);
    };

    client.oncastroomsoccupancies = onCastOccupancy;
    const onRequestAllFloorsInfosSuccess = (data: any) => {
      if (data?.floors) setFloors(data?.floors);
    };

    const loginSuccess = () => {
      const peerId = client.getLocalPeersId();
      if (peerId) makeReduxUpdateWRTCInfo().update({ peerId });
      if (client.requestAllFloorsInfo)
        client.requestAllFloorsInfo(0, onRequestAllFloorsInfosSuccess);

      setUpdateInsideRoom(true);
    };

    const loginFailed = (cause?: any) => {
      toast(
        'Não foi possível estabelecer comunicação com o servidor, tente novamente mais tarde',
        { type: 'error' },
      );
      client.disconnect();
    };

    client.onconnect = (name: string) => {
      client.loginPeer(name, loginSuccess, loginFailed);
    };

    if (!wrtc.oncastroomsoccupancies) {
      makeReduxUpdateWRTCInfo().update({
        peerId: undefined,
        insideRoom: false,
      });
      makeReduxUpdateWrtc().update(client);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateInsideRoom, history]);

  useEffect(() => {
    const notConnectedToWRTC =
      (!wrtcInfo?.peerId || wrtcInfo?.peerId === -1) && info?.email;

    if (!wrtc.connect) {
      // Se o usuário estiver autenticado e não houver WRTC, instanciar WRTC e conectar
      const client = new WRTCClient(window.config.clientConfig);

      const onCastOccupancy = (roomsOccupancies: any): void => {
        const array: any = [];
        let roomName;

        console.debug(
          `occupancy handler : ${rooms.length}, occ.length: ${roomsOccupancies.length}`,
        );

        roomsOccupancies.forEach((occ: any) => {
          roomName = convertFromWRTCRoomName(occ.name);
          console.debug(`roomname: ${roomName}, occ: ${occ.occup}`);
          array.push({
            roomname: roomName,
            amount: occ.occup,
          });
        });

        console.log('Novo occupancy: ', array);
        setOccupancy(array);
      };

      const onJoinRoomSuccess = () => {
        console.debug('Join room success');

        if (client.getRoomName) {
          const roomName = convertFromWRTCRoomName(client.getRoomName());

          history.push({
            pathname: `/rooms/${eventId}/room/${roomName}`,
          });
        }
      };

      client.oncastroomsoccupancies = onCastOccupancy;
      client.onjoinroomsuccess = onJoinRoomSuccess;
      client.onjoinroomfailed = onJoinRoomFailed;
      const onRequestAllFloorsInfosSuccess = (data: any) => {
        if (data?.floors) setFloors(data?.floors);
      };

      const loginSuccess = () => {
        const peerId = client.getLocalPeersId();
        if (peerId) makeReduxUpdateWRTCInfo().update({ peerId });
        if (client.requestAllFloorsInfos)
          client.requestAllFloorsInfos(0, onRequestAllFloorsInfosSuccess);

        setUpdateInsideRoom(true);
      };

      const loginFailed = (cause?: any) => {
        toast(
          'Não foi possível estabelecer comunicação com o servidor, tente novamente mais tarde',
          { type: 'error' },
        );
        client.disconnect();
      };

      client.onconnect = (name: string) => {
        client.loginPeer(name, loginSuccess, loginFailed);
      };

      if (notConnectedToWRTC) {
        makeReduxUpdateWrtc().update(client);
        try {
          client.connect(`${info.firstName} ${info.lastName}:${info.id}`);
        } catch (e) {
          console.log(e);
        }
      } else {
        makeReduxUpdateWrtc().update(client);
      }
    }

    if (wrtc.connect && notConnectedToWRTC) {
      try {
        wrtc.connect(`${info.firstName} ${info.lastName}:${info.id}`);
      } catch (e) {
        console.log(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrtc, info, wrtcInfo, history]);

  useEffect(() => {
    event?.results?.forEach((item: iEventItem) => {
      if (item.id === Number(eventId)) setSelectedEvent(item);
    });
  }, [event, eventId]);

  useEffect(() => {
    if (floors.length && wrtc.joinFloor) {
      const found = floors.find(
        (item: any) => item?.name === `floor_${selectedEvent?.id}`,
      );

      let x = 0;
      let y = 0;

      if (selectedEvent?.layout?.id === 1) {
        x = 4;
        y = 6;
      }

      if (selectedEvent?.layout?.id === 2) {
        x = 4;
        y = 20;
      }

      if (selectedEvent?.layout?.id === 3 || selectedEvent?.layout?.id === 4) {
        x = 10;
        y = 20;
      }

      if (!found && wrtc.createFloor)
        wrtc.createFloor(`floor_${selectedEvent?.id}`, 0, 15, 10); // TODO: Remove mock when receiving room space from server

      wrtc.joinFloor(
        `floor_${selectedEvent?.id}`,
        0,
        () => console.log('Deu bom'),
        () => console.log('Deu ruim'),
      );
    }
  }, [floors]);

  useEffect(() => {
    if (!info?.email || info?.email === '') {
      makeReduxGetAllEvent().getAll({
        limit: 9999,
      });
      makeReduxGetAllLayout().getAll({});
      makeReduxGetAllCategory().getAll({});
    }
  }, [info]);

  return (
    <Container>
      <Header />
      <Subheader title="Selecione uma sala do evento" />
      <Box isMobile={isMobile}>
        <LeftContainer>
          <HexagonContainer isMobile={isMobile}>
            <RoomsContainer>
              <GridRoom
                // TODO: Atualizar número de spots
                isLayout={false}
                numCol={15}
                rooms={rooms}
                handleClick={onRoomClick}
                size="4.8rem"
                occupancyIds={occupancy}
                roomMax={getMaxOccupation()}
              />
            </RoomsContainer>
          </HexagonContainer>
        </LeftContainer>
        {!isMobile && (
          <EventContainer>
            <EventPlayerClappr>
              <LivePlataform selectedEvent={selectedEvent} hidePlayerControls>
                <AboutTitle>{selectedEvent?.name}</AboutTitle>
                <AboutDescription capitalize>
                  {selectedEvent?.schedule
                    ? formattedDate(
                        new Date(selectedEvent?.schedule),
                        selectedEvent?.duration,
                      )
                    : 'Jan 1,2022 - 00h00 às 00h30'}
                  <span>
                    <IconPersonGray
                      width={12}
                      height={12}
                      style={{ marginRight: '0.5rem' }}
                    />
                    {getMaxOccupation() as number}
                  </span>
                </AboutDescription>
              </LivePlataform>
            </EventPlayerClappr>
            <AboutContainer>
              <InfoEvent />
            </AboutContainer>
          </EventContainer>
        )}
      </Box>
    </Container>
  );
};

export default RoomSelect;
