import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  border-radius: 10px;
`;

export const AdContainer = styled.div`
  width: 100%;
  height: 7rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1.8rem;

  border-radius: 0px 0px 10px 10px;

  background: #252424;
`;

export const LeftAdContent = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1.3rem;
`;

export const LeftAdInfo = styled.div`
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Title = styled.h4`
  font: ${({ theme }) => theme.typography.h4};
  font-size: 13px;
  color: #f2f2f2;
`;

export const Subtitle = styled.h6`
  font: ${({ theme }) => theme.typography.h7};
  color: #d5d5e4;

  // make ellipsis
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RightAdContent = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;
`;

export const Banner = styled.img.attrs({
  className: 'event-banner',
})`
  width: 100%;
  max-height: 16rem;
  height: 100%;
  display: flex;
  border-radius: 10px 10px 0px 0px;

  object-fit: cover;
`;
