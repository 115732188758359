import { takeLatest } from 'redux-saga/effects';
import { CategoryTypes } from 'domain/interfaces/redux/category/types';
import { onCreate, onCreateSuccess, onCreateFailed } from './Create';
import { onGetAll, onGetAllSuccess, onGetAllFailed } from './GetAll';

const tasks = [
  takeLatest(CategoryTypes.CREATE, onCreate),
  takeLatest(CategoryTypes.CREATE_SUCCESS, onCreateSuccess),
  takeLatest(CategoryTypes.CREATE_FAILED, onCreateFailed),
  takeLatest(CategoryTypes.GETALL, onGetAll),
  takeLatest(CategoryTypes.GETALL_SUCCESS, onGetAllSuccess),
  takeLatest(CategoryTypes.GETALL_FAILED, onGetAllFailed),
];

export default tasks;
