import { GetAllFriendship } from 'domain/usecases/friendship/remote';
import {
  NotFound,
  BadRequestError,
  Forbidden,
  UnexpectedError,
  BadGateway,
} from 'domain/errors';
import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import 'infra/global/variables';

export class RemoteGetAllFriendship implements GetAllFriendship {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetAllFriendship.Model>;

  constructor(url: string, httClient: HttpClient<GetAllFriendship.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  getAll = async (
    params: GetAllFriendship.Params,
  ): Promise<GetAllFriendship.Model> => {
    let query = '';
    Object.entries(params).forEach(([key, value]) => {
      query += `${key}=${value}&`;
    });
    console.log(
      '### da onça',
      `${this.url.replace('{user}', `${params.user}`)}?${query}`,
    );
    const httpResponse = await this.httpClient.request({
      url: `${this.url.replace('{user}', `${params.user}`)}?${query}`,
      method: 'get',
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.accepted:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.badGateway:
        throw new BadGateway();
      default:
        throw new UnexpectedError();
    }
  };
}
