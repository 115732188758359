/* eslint-disable react/react-in-jsx-scope */
import Clappr from 'clappr';
import { useEffect, useRef } from 'react';

import YouTubePlayback from '@guzzj/clappr-youtube-playback';
import DashShakaPlayback from 'dash-shaka-playback';
import { isYoutubeUrl } from 'utils/isYoutubeUrl';
import { Container } from './styles';
import { ClapprComponentProps } from './types';

import { AudioOutputRedirector } from '../../../services/wrtccli/AudioOutputRedirector.js';

const audioRedirector = new AudioOutputRedirector();

const ClapprComponent: React.FC<ClapprComponentProps> = ({
  id,
  source,
  rounded,
  playerRef,
}) => {
  function redirectAudio() {
    const videoContainerTag = document.getElementById('player');
    let eventLoopbackAudioTag;
    let arr;

    if (videoContainerTag != null) {
      arr = videoContainerTag.getElementsByTagName('video');
      const [eventMediaTag] = document.getElementsByTagName('video');
      console.log(`videos length: ${arr.length}`);
      eventLoopbackAudioTag = document.createElement('audio');
      eventLoopbackAudioTag.loop = true;
      eventLoopbackAudioTag.autoplay = true;
      eventLoopbackAudioTag.id = 'eventLoopbackAudio';
      videoContainerTag.appendChild(eventLoopbackAudioTag);
      setTimeout(
        audioRedirector.redirectAudio,
        500,
        eventMediaTag,
        eventLoopbackAudioTag,
      );
    }
  }

  useEffect(() => {
    let newPlayer: any = null;

    if (source) {
      newPlayer = new Clappr.Player({
        parentId: `#${id ?? 'player'}`,
        source,
        youtubeShowRelated: false,
        height: '100%',
        width: '100%',
        plugins: { playback: [YouTubePlayback, DashShakaPlayback] },
        playback: { crossOrigin: 'anonymous' },
        events: {
          onReady: (e: any) => {
            console.log('BLAH on ready');
          },
          onPlay: (e: any) => {
            console.log('BLAH on play');
            // startRecorder();
            if (!isYoutubeUrl(source)) {
              redirectAudio();
            }
          },
          onPause: (e: any) => {
            console.log('BLAH on pause');
            // stopRecorder();
          },
          onSeek: (e: any) => {
            console.log('BLAH on seek');
          },
          onStop: (e: any) => {
            console.log('BLAH on stop');
          },
        },
      });

      playerRef?.(newPlayer);
    }

    return () => {
      if (newPlayer) {
        newPlayer.destroy();
      }
    };
  }, [id, playerRef, source]);

  return (
    <Container rounded={rounded}>
      <div
        id={id ?? 'player'}
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
          borderTopLeftRadius: rounded ? '10px' : '0px',
          borderTopRightRadius: rounded ? '10px' : '0px',
        }}
      />
    </Container>
  );
};

export default ClapprComponent;
