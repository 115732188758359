import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { iEventItem } from 'domain/interfaces/models/Event';
import bannerBeta from 'presentation/base/images/bannerBeta.png';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { iStore } from 'domain/interfaces/models';
import { NewIconJCB } from 'presentation/base/icons';
import { Avatar } from '@mui/material';
import {
  Container,
  AdContainer,
  LeftAdContent,
  LeftAdInfo,
  RightAdContent,
  Subtitle,
  Title,
  Banner,
} from './styles';
import { Button } from '../UI';

export interface iParams {
  id: string;
  roomId: string;
}

export interface ITemplate {
  id: number;
  short: string;
  name: string;
  descr: string;
  logo: FormData | string;
  color: string;
  label: string;
  url: string;
}

type InfoEventProps = {
  template?: ITemplate;
};

export const InfoEvent: React.FC<InfoEventProps> = ({ template }) => {
  const [selectedEvent, setSelectedEvent] = useState<iEventItem>(
    {} as iEventItem,
  );

  const { results: events } = useSelector((store: iStore) => store.event);

  const { id }: iParams = useParams();

  const openNewTab = useCallback((url?: string) => {
    if (!url) return;

    const withHttp = !/^https?:\/\//i.test(url) ? `http://${url}` : url;
    window.open(withHttp, '_blank', 'noopener,noreferrer');
  }, []);

  useEffect(() => {
    events?.forEach(item => {
      if (item.id === Number(id)) {
        setSelectedEvent(item);
      }
    });
  }, [events, id]);

  const isTemplate = useMemo(() => {
    return template !== undefined;
  }, [template]);

  const mapTemplate = useMemo(() => {
    return {
      id: template?.id ?? 0,
      short: template?.short ?? '',
      name: template?.name ?? '',
      descr: template?.descr ?? '',
      banner: String(template?.logo) ?? '',
      color: template?.color ?? '',
      label: template?.label ?? '',
      url: template?.url ?? '',
      sponsor: {
        name: template?.name ?? '',
        url: template?.url ?? '',
        label: template?.label ?? '',
        color: template?.color ?? '',
      },
    };
  }, [template]);

  return (
    <Container>
      <Banner
        src={(isTemplate ? mapTemplate : selectedEvent)?.banner ?? bannerBeta}
        alt={`Banner do evento: ${
          (isTemplate ? mapTemplate : selectedEvent)?.name
        }`}
      />
      <AdContainer>
        <LeftAdContent>
          <Avatar sx={{ bgcolor: '#3D3B3B', width: '50px', height: '50px' }}>
            <NewIconJCB />
          </Avatar>
          <LeftAdInfo>
            <Title>
              {(isTemplate ? mapTemplate : selectedEvent)?.sponsor?.name}
            </Title>
            <Subtitle>
              {`Ad • ${
                (isTemplate ? mapTemplate : selectedEvent)?.sponsor?.url
              }`}
            </Subtitle>
          </LeftAdInfo>
        </LeftAdContent>
        <RightAdContent>
          <Button
            title={(isTemplate ? mapTemplate : selectedEvent)?.sponsor?.label}
            color={(isTemplate ? mapTemplate : selectedEvent)?.sponsor?.color}
            onClick={() =>
              openNewTab(
                (isTemplate ? mapTemplate : selectedEvent)?.sponsor?.url,
              )
            }
          />
        </RightAdContent>
      </AdContainer>
    </Container>
  );
};

export default InfoEvent;
