import styled, { css } from 'styled-components';

interface ContainerProps {
  isLayout: boolean;
  maxWidth: string;
  maxHeight: string;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  ${props => {
    return props.isLayout
      ? css`
          overflow: visible;
        `
      : css`
          max-height: ${props.maxHeight};
          max-width: ${props.maxWidth};
          position: relative;
        `;
  }}

  .arrow {
    position: absolute;
    z-index: 1;
    background: linear-gradient(
      270deg,
      ${({ theme }) => theme.colors.black1} 33.33%,
      rgba(18, 20, 30, 0) 100%
    );
    opacity: 0.9;
    width: 75px;
    height: 100%;
    border: none;
    color: white;
    outline: none;

    ${props => {
      return (
        props.isLayout &&
        css`
          display: none;
        `
      );
    }}
  }

  .arrow-left {
    transform: rotate(180deg);
    left: 0;
  }

  .arrow-right {
    right: 0;
  }

  .arrow-top {
    top: 0;
    background: linear-gradient(
      180deg,
      ${({ theme }) => theme.colors.black1} 33.33%,
      rgba(18, 20, 30, 0) 100%
    );
    height: 50px;
    width: 100%;
  }

  .arrow-bottom {
    bottom: 0;
    background: linear-gradient(
      180deg,
      ${({ theme }) => theme.colors.black1} 33.33%,
      rgba(18, 20, 30, 0) 100%
    );
    height: 50px;
    width: 100%;
    transform: rotate(180deg);
  }

  .scroll-container {
    width: 100%;
    height: 100%;
    overflow: scroll;

    &::-webkit-scrollbar {
      background: transparent; /* make scrollbar transparent */
      -webkit-appearance: none;
      width: 0;
      height: 0;
    }
  }
`;

export const ScrollContainer = styled.div``;
