import { EventTypes } from 'domain/interfaces/redux/event/types';
import { takeLatest } from 'redux-saga/effects';

import { onCreate, onCreateFailed, onCreateSuccess } from './Create';
import { onDelete, onDeleteFailed, onDeleteSuccess } from './Delete';
import { onGetAll, onGetAllFailed, onGetAllSuccess } from './GetAll';

const tasks = [
  takeLatest(EventTypes.CREATE, onCreate),
  takeLatest(EventTypes.CREATE_SUCCESS, onCreateSuccess),
  takeLatest(EventTypes.CREATE_FAILED, onCreateFailed),
  takeLatest(EventTypes.GETALL, onGetAll),
  takeLatest(EventTypes.GETALL_SUCCESS, onGetAllSuccess),
  takeLatest(EventTypes.GETALL_FAILED, onGetAllFailed),
  takeLatest(EventTypes.DELETE, onDelete),
  takeLatest(EventTypes.DELETE_SUCCESS, onDeleteSuccess),
  takeLatest(EventTypes.DELETE_FAILED, onDeleteFailed),
];

export default tasks;
