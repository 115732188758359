import {
  iActionUpdateWrtc,
  WrtcClientTypes,
} from 'domain/interfaces/redux/wrtcClient/update';

import { UpdateWrtc } from 'domain/usecases/wrtcClient/redux';

export const updateWrtc = (payload: UpdateWrtc.Params): iActionUpdateWrtc => ({
  type: WrtcClientTypes.UPDATE,
  payload,
});
