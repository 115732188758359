import { GetAllEvent } from 'domain/usecases/event/remote';
import { UnexpectedError, BadRequestError, NotFound } from 'domain/errors';
import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import 'infra/global/variables';

export class RemoteGetAllEvent implements GetAllEvent {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetAllEvent.Model>;

  constructor(url: string, httClient: HttpClient<GetAllEvent.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  getAll = async (params: GetAllEvent.Params): Promise<GetAllEvent.Model> => {
    let query = '';

    Object.entries(params).forEach(([key, value]) => {
      query += `${key}=${value}&`;
    });

    const httpResponse = await this.httpClient.request({
      url: `${this.url}/?${query}`,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      default:
        throw new UnexpectedError();
    }
  };
}
