import { createIntl, createIntlCache } from 'react-intl';
import { toast } from 'react-toastify';
import translations from 'infra/i18n/locales';

import { Login } from 'domain/usecases/auth/remote';
import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import { UnexpectedError, BadRequestError, NotFound } from 'domain/errors';
import { AlertMessage } from 'presentation/components/messages/AlertMessage';

const cache = createIntlCache();

export class RemoteAuthLogin implements Login {
  private readonly url: string;

  private readonly httpClient: HttpClient<Login.Model>;

  constructor(url: string, httpClient: HttpClient<Login.Model>) {
    this.url = url;
    this.httpClient = httpClient;
  }

  login = async (params: Login.Params): Promise<Login.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
      case HttpStatusCode.unauthorized:
      case HttpStatusCode.forbidden:
        toast.error('Credenciais inválidas!');
        throw new NotFound();
      default:
        toast.error('Falha ao realizar login!');
        throw new UnexpectedError();
    }
  };
}
