import styled from 'styled-components';

export const Container = styled.div`
  width: 275px;
  height: 100%;
  background-color: #202433;
  padding: 20px 0px;
`;

export const LogoContent = styled.div`
  display: flex;
  align-items: center;
  margin-right: 30px;
  margin-bottom: 20px;
  padding: 0px 20px;

  a {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;

    .name {
      margin-left: 13px;
      padding-top: 2px;
    }
  }
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #ffffff;
  opacity: 0.5;
  margin-top: 20px;
  margin-bottom: 5px;
  padding: 0px 20px;
`;

export const Border = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.08);
  width: calc(100% -40px);
  margin: 0px 20px;
`;

export const Categories = styled.div`
  width: 100%;
  height: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  padding: 0px 20px;
  cursor: pointer;

  &:hover {
    background: #3f4660;
  }

  .name {
    gap: 10px;

    svg {
      margin-right: 10px;
    }
  }
`;

interface iList {
  open: boolean;
}

export const List = styled.div<iList>`
  display: ${props => (props.open ? 'block' : 'none')};
`;

export const Category = styled.div`
  width: 100%;
  height: 40px;
  padding-left: 43px;

  a {
    height: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }
`;
