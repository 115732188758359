/* eslint-disable no-nested-ternary */
import styled, { css, keyframes } from 'styled-components';
import media from 'utils/mediaqueries';

interface SquareRoomProps {
  size: string;
  state?: 'full' | 'busy' | 'normal';
  disabled?: boolean;
  hasBorder?: boolean;
  isLayout?: boolean;
  isSpeaking?: boolean;
  isCenter?: boolean;
  preview?: boolean;
  isChangingRoom?: boolean;
  isHovering?: boolean;
  isActualUser?: boolean;
  type?: 'vip' | 'normal';
}

const Type = css<SquareRoomProps>`
  font: ${({ theme }) => theme.typography.h7};
  ${props => {
    switch (props.type) {
      case 'vip':
        return css`
          width: ${props.size};
          height: ${props.size};
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          svg {
            width: 0.7rem;
            fill: ${({ theme }) => theme.colors.white1};
          }

          border: ${props.hasBorder
            ? `0.2rem solid ${props.theme.colors.yellow1}`
            : 0};
          border-radius: ${props.preview ? '2px' : '5px'};
          background: ${({ theme }) => theme.colors.yellow1};
          color: ${({ theme }) => theme.colors.white1};
          margin: ${props.preview ? 0 : '0.2rem'};
        `;
      default:
        return css`
          display: flex;
          align-items: center;
          justify-content: center;

          border: ${props.hasBorder
            ? `0.2rem solid ${
                props.isLayout
                  ? props.theme.colors.white5
                  : props.theme.colors.black3
              }`
            : 0};
          border-radius: ${props.preview || props.isChangingRoom
            ? '2px'
            : '5px'};

          background: ${({ theme }) => theme.colors.black2};
          color: ${({ theme }) => theme.colors.white1};
          margin: ${props.preview ? 0 : '0.2rem'};
        `;
    }
  }}
`;

const Hover = css<SquareRoomProps>`
  ${props =>
    !props.disabled && !props.isChangingRoom && props.type === 'normal'
      ? css`
          &:hover {
            background: ${({ theme }) => theme.colors.white1};
            color: ${({ theme }) => theme.colors.colorBlack};
          }
        `
      : !props.disabled &&
        !props.isChangingRoom &&
        css`
          &:hover {
            background: ${({ theme }) => theme.colors.yellow1};
          }
        `}

  ${props =>
    !props.disabled &&
    !props.isChangingRoom &&
    props.state === 'full' &&
    css`
      &:hover {
        border: 2px solid ${({ theme }) => theme.colors.pink1};
        background: ${({ theme }) => theme.colors.pink1};
        color: ${({ theme }) => theme.colors.white1};
      }
    `}
`;

const Stretch = keyframes`
  0% {
    height: 4px;
  }
  50% {

    height: 16px;
  }
  100% {
    height: 4px;
  }
`;

export const IsSpeakingAnimate = styled.div<{ isSpeaking: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  height: 5.4rem;
  width: 5.4rem;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.5s ease-out;

  opacity: ${({ isSpeaking }) => (isSpeaking ? 1 : 0)};

  & div {
    width: 4px;
    height: 4px;
    background: #ffffff;
    border-radius: 100px;
    align-items: center;
    animation: ${Stretch} 0.7s infinite;
  }

  & .center {
    height: 16px;
    animation: ${Stretch} 0.7s infinite reverse;
  }
`;

export const Content = styled.div<SquareRoomProps>`
  border-radius: ${({ preview }) => (preview ? '2px' : '5px')};
  color: ${({ theme }) => theme.colors.white1};
  margin: ${({ preview }) => (preview ? 0 : '0.2rem')};
  transition: all 0.2s ease-in-out;

  position: relative;
  overflow: hidden;

  ${Hover}

  width: ${({ size }) => size};
  height: ${({ size }) => size};

  ${props => {
    switch (props.state) {
      case 'busy':
        return Busy;
      case 'full':
        return Full;
      default:
        return Type;
    }
  }}

  ${({ isSpeaking, theme }) =>
    isSpeaking &&
    css`
      z-index: 0;
      box-shadow: 0 3px 20px ${`${theme.colors.white1}99`};
    `}


  cursor: pointer;
  opacity: 0.75;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.2;
      filter: saturate(0);
      /* cursor: not-allowed; */
    `}

  ${props =>
    props.isCenter &&
    css`
      background: ${({ theme }) => theme.colors.white1};
      color: ${({ theme }) => theme.colors.black2};
    `}


  ${props =>
    props.isChangingRoom &&
    props.isHovering &&
    css`
      background: ${({ theme }) => theme.colors.black5};
      border: 2px solid ${({ theme }) => theme.colors.blue1};
    `}

    ${props =>
    props.isActualUser &&
    css`
      border: ${props.isActualUser ? '2px solid #FFFFFF' : 0};
      filter: drop-shadow(0px 4px 20px #0d1222);
      transform: scale(1.18);
      opacity: ${props.disabled ? 0.4 : 1};
    `}
`;

const Busy = css<SquareRoomProps>`
  ${Type}
  ${props => {
    switch (props.type) {
      case 'vip':
        return css`
          background: ${({ theme }) => theme.colors.yellow1};
          border: 0.2rem solid ${({ theme }) => theme.colors.yellow1};
        `;
      default:
        return css`
          background: ${({ theme }) => theme.colors.black5};
          border: 0.2rem solid ${({ theme }) => theme.colors.blue1};
        `;
    }
  }}
`;

const Full = css`
  ${Type}
  background: ${({ theme }) => theme.colors.pink1};
  border: 0.2rem solid ${({ theme }) => theme.colors.pink2};
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .eSDvUJ {
    z-index: 1;
  }

  &:hover .random-profile-user::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.white1};
    opacity: 0.3;
  }
`;

export const Muted = styled.div<SquareRoomProps>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => `calc(${size} - 0.2rem)`};
  height: ${({ size }) => `calc(${size} - 0.2rem)`};
  background-color: ${({ theme }) => `${theme.colors.red1}66`};
  border-radius: ${({ preview, isChangingRoom }) =>
    preview || isChangingRoom ? '2px' : '5px'};
  margin: ${({ preview }) => (preview ? 0 : '0.2rem')};
  border: ${({ theme }) => `0.2rem solid ${theme.colors.black5}`};

  ${props =>
    props.isActualUser &&
    css`
      border: 2px solid #ffffff;
      filter: drop-shadow(0px 4px 20px #0d1222);
      transform: scale(1.18);
      opacity: 1;
    `}
`;
