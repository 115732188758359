import React, { useMemo } from 'react';

import { NewCloseIcon, IconSound } from 'presentation/base/icons';
import {
  Container,
  Box,
  Header,
  TitleContent,
  Title,
  Content,
  VolumeContainer,
  VolumeTitle,
  VolumeContentText,
  TransmissionVolumeSlider,
  PublicVolumeSlider,
} from './styles/StyledVolumeSliderContent';

import { Button } from '../UI';
import Checkbox from '../Checkbox/Checkbox';

interface ownProps {
  eventVolume: number;
  publicVolume: number;
  handleEventVolumeChange: (value: number) => void;
  handlePublicVolumeChange: (value: number) => void;
  handleClose: () => void;
}

const VolumeSliderContent: React.FC<ownProps> = ({
  eventVolume,
  publicVolume,
  handleEventVolumeChange,
  handlePublicVolumeChange,
  handleClose,
}) => {
  return (
    <Container>
      <Header>
        <TitleContent>
          <Box>
            <IconSound />
          </Box>
          <Title>Ajustar volume</Title>
        </TitleContent>
        <div>
          <div>
            <Button
              id="btn_close"
              onClick={handleClose}
              colorstyle="black2"
              size="very small"
              icon={NewCloseIcon}
            />
          </div>
        </div>
      </Header>
      <Content>
        <div>
          <VolumeTitle>Volume da transmissão</VolumeTitle>
          <VolumeContainer>
            <IconSound />
            <TransmissionVolumeSlider
              value={eventVolume}
              onChange={(e, newVolume) =>
                handleEventVolumeChange(newVolume as number)
              }
            />
            {/* <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '12px',
              }}
            >
              <Checkbox checked={false} onClick={() => console.log('error')} />
              <VolumeContentText>Sem som</VolumeContentText>
            </div> */}
          </VolumeContainer>
        </div>

        <div>
          <VolumeTitle>Volume da platéia</VolumeTitle>
          <VolumeContainer>
            <IconSound />
            <PublicVolumeSlider
              value={publicVolume}
              onChange={(e, newVolume) =>
                handlePublicVolumeChange(newVolume as number)
              }
            />
            {/* <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '12px',
              }}
            >
              <Checkbox checked={false} onClick={() => console.log('error')} />
              <VolumeContentText>Sem som</VolumeContentText>
            </div> */}
          </VolumeContainer>
        </div>
      </Content>
    </Container>
  );
};

export default VolumeSliderContent;
