/* eslint-disable consistent-return */
import React, { useEffect, useMemo, useState } from 'react';
import { IconMicDisabled } from 'presentation/base/icons';
import { defaultTheme } from 'presentation/base/themes';

interface ownProps {
  peerId?: number;
  size?: string;
  micDisabled?: boolean;
  style?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
}

export const RandomProfileUser: React.FC<ownProps> = ({
  peerId,
  size,
  style,
  micDisabled = false,
  containerStyle,
}) => {
  const [image, setImage] = useState('');
  const [filename, setFilename] = useState('');

  const iconStyle: React.CSSProperties = useMemo(
    () =>
      size
        ? {
            width: size,
            height: size,
            minWidth: size,
            minHeight: size,
            borderRadius: '2px',
            ...style,
          }
        : {
            borderRadius: '2px',
            ...style,
          },
    [size, style],
  );

  useEffect(() => {
    if (!peerId) return;

    const normalizedPeerId = Math.round(peerId / 2 ** 16);

    const name = `${Math.floor(normalizedPeerId % 120)
      .toString()
      .padStart(3, '0')}.png`;

    const fetchImage = async () => {
      try {
        const response = await import(
          `presentation/base/images/avatar/${name}`
        );
        setImage(response.default);
        setFilename(name);
      } catch (err) {
        console.log(err);
      }
    };

    fetchImage();
  }, [peerId, size]);

  const renderUser = useMemo(() => {
    return (
      image && (
        <img
          src={image}
          alt={filename}
          width="46px"
          height="46px"
          style={iconStyle}
        />
      )
    );
  }, [filename, iconStyle, image]);

  return (
    <div className="random-profile-user" style={containerStyle}>
      {micDisabled && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: size,
            height: size,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            className="mic-disabled"
            style={{
              width: size,
              height: size,
              borderRadius: style?.borderRadius,
              background: defaultTheme.colors.red1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 1,
              opacity: 0.4,
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          />
          <IconMicDisabled
            color={defaultTheme.colors.red2}
            width="1.5rem"
            height="1.9rem"
            style={{
              zIndex: 2,
              width: '2rem',
              stroke: '1px solid black',
            }}
          />
        </div>
      )}
      {renderUser}
    </div>
  );
};
