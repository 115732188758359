import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { DeleteReaction as UsecaseRemoteDeleteReaction } from 'domain/usecases/reaction/remote';
import { DeleteReaction as UsecaseReduxDeleteReaction } from 'domain/usecases/reaction/redux';

import { RemoteDeleteReaction } from 'data/repository/reaction';
import { ReduxDeleteReaction } from 'data/store/reducer/reaction/usecases';

/**
 * send request via API.
 */
export const makeRemoteDeleteReaction = (): UsecaseRemoteDeleteReaction =>
  new RemoteDeleteReaction(
    makeApiUrl('/reactions/{reactionId}'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
export const makeReduxDeleteReaction = (): UsecaseReduxDeleteReaction =>
  new ReduxDeleteReaction();
