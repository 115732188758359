import { Dispatch } from 'redux';
import { iActionUpdateWrtc } from 'domain/interfaces/redux/wrtcClient/update';
import { UpdateWrtc } from 'domain/usecases/wrtcClient/redux';
import store from '../../..';
import { updateWrtc } from '../actions/update';

export class ReduxUpdateWrtc implements UpdateWrtc {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  update = (params: UpdateWrtc.Params): iActionUpdateWrtc =>
    this.send(updateWrtc(params));
}
