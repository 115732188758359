import { CreateReaction } from 'domain/usecases/reaction/redux/CreateReaction';
import {
  iActionCreate,
  iActionCreateFailed,
  iActionCreateSuccess,
  ReactionTypes,
} from 'domain/interfaces/redux/reaction/create';

export const createRequest = (
  payload: CreateReaction.Params,
): iActionCreate => ({
  type: ReactionTypes.CREATE,
  payload,
});

export const createSuccess = (
  params: CreateReaction.Model,
): iActionCreateSuccess => ({
  type: ReactionTypes.CREATE_SUCCESS,
  payload: params,
});

export const createFailed = (): iActionCreateFailed => ({
  type: ReactionTypes.CREATE_FAILED,
});
