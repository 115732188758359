import styled, { css } from 'styled-components';

type ContainerProps = {
  isMobile?: boolean;
  isOpen?: boolean;
};

export const Container = styled.div<ContainerProps>`
  height: 100%;
  background: ${({ theme }) => theme.colors.black5};
  border-radius: 0 0 1rem 1rem;
  overflow: hidden;

  ${({ isMobile, isOpen }) =>
    isMobile &&
    css`
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 9999;
      max-height: calc(100vh - 20.2rem - 12rem);

      transform: translateY(${isOpen ? '0' : '100%'});

      visibility: ${isOpen ? 'visible' : 'hidden'};

      transition: transform 0.3s ease-in-out;
    `}
`;

export const HeaderToBottomSheet = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem;
  background: ${({ theme }) => theme.colors.black2};
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.black3};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  & #icon-participants {
    width: 3.2rem;
    height: 3.2rem;
    padding: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1.2rem;
    border-radius: 50rem;
    background: ${({ theme }) => theme.colors.black4};
  }
`;

export const TitleLabel = styled.h1`
  color: ${({ theme }) => theme.colors.white1};
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.2rem;
`;

export const CloseButton = styled.button`
  width: 3.2rem;
  height: 3.2rem;
  padding: 0.8rem;

  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent;
  border-color: transparent;
  border-radius: 0.8rem;

  transition: background 0.3s ease-in-out;

  &:hover {
    background: ${({ theme }) => theme.colors.black5};
  }

  &:focus-visible {
    outline: 0.2rem solid ${({ theme }) => theme.colors.blue2};
  }
`;

export const SearchBar = styled.div`
  width: 100%;
  padding: 1.6rem;
  background: ${({ theme }) => theme.colors.black2};
`;

export const Body = styled.ul`
  width: 100%;
  height: calc(100% - 7rem);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  gap: 1.2rem;
  padding: 3.2rem;
  overflow-y: auto;

  background: ${({ theme }) => theme.colors.black5};

  &::-webkit-scrollbar {
    width: 0.8rem;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.black4};
    border-radius: 0.4rem;
  }
`;

export const AvatarContainer = styled.div.attrs({
  className: 'avatar',
})`
  width: 4rem;
  height: 4rem;
  min-width: 4rem;
  min-height: 4rem;
  background: ${({ theme }) => theme.colors.white1};
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const Info = styled.div.attrs({
  className: 'info',
})`
  display: flex;
  flex-direction: column;
`;

export const Name = styled.span`
  font-size: 1.4rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white2};
`;

export const Role = styled.span`
  font-size: 1.4rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white6};
`;

type ListItemProps = {
  micDisabled?: boolean;
};

export const ListItem = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.2rem;

  ${({ micDisabled }: ListItemProps) =>
    micDisabled
      ? css`
          .info {
            opacity: 0.5;
          }
        `
      : null}
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1.2rem;
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1.2rem;
`;
