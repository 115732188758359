import { CreateUser } from 'domain/usecases/user/remote';
import {
  UnexpectedError,
  BadRequestError,
  NotFound,
  Conflict,
} from 'domain/errors';
import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import 'infra/global/variables';
import { toast } from 'react-toastify';

export class RemoteCreateUser implements CreateUser {
  private readonly url: string;

  private readonly httpClient: HttpClient<CreateUser.Model>;

  constructor(url: string, httClient: HttpClient<CreateUser.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  create = async (params: CreateUser.Params): Promise<CreateUser.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.conflict:
        if ((httpResponse?.body as any)?.message?.includes('Username'))
          toast.error('Nome de usuário já foi utilizado!');

        if ((httpResponse?.body as any)?.message?.includes('Email'))
          toast.error('Email já foi utilizado!');

        throw new Conflict();
      default:
        throw new UnexpectedError();
    }
  };
}
