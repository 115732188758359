import styled from 'styled-components';

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-top: 6rem; */
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.white1};
  width: 100%;
  height: 100%;
  padding: 1.6rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  background: ${({ theme }) => theme.colors.black2};
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);

  margin-top: 2.4rem;

  //font
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.5rem;

  @media (max-width: 425px) {
    border-radius: 0;
    margin-top: 1rem;
    border-top: 0.1rem solid ${({ theme }) => theme.colors.black3};
  }
`;

export const SecondaryHeader = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0rem;
  z-index: 2;

  width: 100%;
  height: 6rem;
  padding: 1.2rem 4.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: ${({ theme }) => theme.colors.black2};
  color: ${({ theme }) => theme.colors.white2};

  box-shadow: 0px 0.4rem 1rem rgba(0, 0, 0, 0.25);
`;

export const SecondaryHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.5rem;

  svg.arrowBack {
    margin-right: 1.2rem;
    cursor: pointer;
  }
`;

export const SecondaryHeaderActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.6rem;
`;
