import React from 'react';
import { IconRecoveryPassword, CloseIcon } from 'presentation/base/icons';
import { Input, Button } from 'presentation/components/UI';

import { iMessage } from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';
import {
  Container,
  ContentHeader,
  Header,
  CloseModalDiv,
  Message,
  Form,
  ButtonsDiv,
  Content,
} from './styles';

import { Container as GlobalContainer } from '../messages/Background';

interface propsPasswordRecoveryModal {
  message: iMessage;
}

const PasswordRecoveryModal: React.FC<propsPasswordRecoveryModal> = ({
  message,
}) => {
  const msgName = MessageOptions.recoveryPassword;

  const { active, actionOk, actionCancel } = message;

  return (
    <>
      {msgName === active ? (
        <GlobalContainer>
          <Container>
            <Content>
              <ContentHeader>
                <Header>
                  <IconRecoveryPassword />
                  <text>Redefinir senha</text>
                </Header>
                <CloseModalDiv>
                  <CloseIcon id="button_close" onClick={actionCancel} />
                </CloseModalDiv>
              </ContentHeader>
              <Message>
                Por favor, informe o e-mail usado para o cadastro na plataforma
                Netfans. Por meio dele, você receberá um link para cadastrar uma
                nova senha.
              </Message>
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  if (actionOk) actionOk();
                }}
              >
                <Input label="E-mail" />
              </Form>
              <ButtonsDiv>
                <Button
                  type="submit"
                  colorstyle="blue2"
                  title="Enviar link"
                  onClick={actionOk}
                />
                <Button
                  colorstyle="black5"
                  title="Voltar"
                  onClick={actionCancel}
                />
              </ButtonsDiv>
            </Content>
          </Container>
        </GlobalContainer>
      ) : undefined}
    </>
  );
};

export default PasswordRecoveryModal;
