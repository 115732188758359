import styled from 'styled-components';
import Slider from '@mui/material/Slider';

interface iMobileSliderProps {
  isDisplayed: boolean;
}

export const MobileSliderContainer = styled.div<iMobileSliderProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ isDisplayed }) => (isDisplayed ? '23%' : '0')};
  background-color: ${({ theme }) => theme.colors.black2};
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  transition: ${({ isDisplayed }) =>
    isDisplayed ? 'height 0.400s ease-in' : 'height 0.400s ease-out'};
`;

export const MobileVolumeType = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px;

  span {
    font-size: 16px;
    font-weight: bold;
  }
`;

export const MobileVolumeDivisor = styled.div`
  width: 100%;
  height: 50%;
  gap: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
`;

export const TransmissionVolumeSlider = styled(Slider)`
  .MuiSlider-track {
    background-color: #5cdfbf;
  }

  .MuiSlider-thumb {
    height: 19px;
    width: 19px;
    background-color: #fff;
  }
`;

export const PublicVolumSelider = styled(Slider)`
  .MuiSlider-track {
    background-color: #5667f6;
  }

  .MuiSlider-thumb {
    height: 19px;
    width: 19px;
    background-color: #fff;
  }
`;
