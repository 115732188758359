import { iSpotsInfo } from 'domain/interfaces/models';
import { SpotsInfoTypes } from 'domain/interfaces/redux/spotsInfo/types';
import { SpotsInfoActions } from './actions';

export const initialState: iSpotsInfo = {
  loading: false,
  results: [],
};

const reducer = (
  state = initialState,
  action: SpotsInfoActions,
): iSpotsInfo => {
  switch (action.type) {
    case SpotsInfoTypes.UPDATE:
      return { ...state, results: action.payload };
    default:
      return state;
  }
};

export default reducer;
