/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Chat from 'presentation/components/Chat/Chat';
import GridRoom from '../../presentation/components/GridRoom';
import SearchPage from '../../presentation/pages/SearchPage';
import { ChatFactory } from '../factories/pages/Chat';

import { DemoFactory } from '../factories/pages/Demo';
import { NewEditEventFactory } from '../factories/pages/EditNewEvent';
import { EntertainmentFactory } from '../factories/pages/Entertainment';
import { HomeFactory } from '../factories/pages/Home';
import { MusicFactory } from '../factories/pages/Music';
import { NewEventFactory } from '../factories/pages/NewEvent';
import { RoomFactory } from '../factories/pages/Room';
import { RoomSelectFactory } from '../factories/pages/RoomSelect';
import { SportsFactory } from '../factories/pages/Sports/SportsFactory';

const Routes: React.FC = () => {
  const onlyDemo = window.config?.featureToggles?.onlyDemo || false;

  return (
    <BrowserRouter>
      {!onlyDemo && <Route exact path="/" component={HomeFactory} />}
      {!onlyDemo && <Route exact path="/sports" component={SportsFactory} />}
      {!onlyDemo && <Route exact path="/music" component={MusicFactory} />}
      {!onlyDemo && (
        <Route exact path="/entertainment" component={EntertainmentFactory} />
      )}

      <Route exact path="/rooms/:id" component={RoomSelectFactory} />
      <Route exact path="/rooms/:id/room/:roomId" component={RoomFactory} />
      <Route exact path="/chat" component={ChatFactory} />
      <Route
        exact
        path="/embed/rooms/:id/room/:roomId"
        component={RoomFactory}
      />

      <Route exact path="/demo" component={DemoFactory} />

      {!onlyDemo && <Route exact path="/square" component={GridRoom} />}
      {!onlyDemo && (
        <Route exact path="/new-event" component={NewEventFactory} />
      )}
      {!onlyDemo && (
        <Route exact path="/edit-event/:id" component={NewEditEventFactory} />
      )}
      {!onlyDemo && <Route exact path="/search" component={SearchPage} />}
    </BrowserRouter>
  );
};

export default Routes;
