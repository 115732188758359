import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { UpdateReaction as UsecaseRemoteUpdateReaction } from 'domain/usecases/reaction/remote';
import { UpdateReaction as UsecaseReduxUpdateReaction } from 'domain/usecases/reaction/redux';

import { RemoteUpdateReaction } from 'data/repository/reaction';
import { ReduxUpdateReaction } from 'data/store/reducer/reaction/usecases';

/**
 * send request via API.
 */
export const makeRemoteUpdateReaction = (): UsecaseRemoteUpdateReaction =>
  new RemoteUpdateReaction(
    makeApiUrl('/reactions/{reactionId}'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
export const makeReduxUpdateReaction = (): UsecaseReduxUpdateReaction =>
  new ReduxUpdateReaction();
