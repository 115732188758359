/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-nested-ternary */
import React, { cloneElement, ReactElement, useMemo } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import { toast } from 'react-toastify';
import {
  IconFacebook,
  IconGoogle,
  IconTwitter,
  IconWhatsapp,
  IconTelegram,
  IconTransfer,
  IconCalendar,
  CalendarIcon,
  IconTicket,
  IconFlag,
  IconPencil,
  IconTrash,
} from 'presentation/base/icons';
import useWindowDimensions from 'presentation/hooks/useWindowDimensions';
import Button from '../UI/Button';
import { InviteButtonsContainer, OptionItem } from './styles';
import VolumeSlider from '../VolumeSlider';

interface ownProps {
  title?: string;
  placement: 'top' | 'bottom' | 'left' | 'right';
  pathName?: string;
  open?: boolean;
  children: ReactElement;
  disableFocusListener?: boolean;
  disableHoverListener?: boolean;
  tooltipType?:
    | 'default'
    | 'share'
    | 'reactions'
    | 'options'
    | 'users'
    | 'volume';
  handleDelete?: () => void;
  handleEdit?: () => void;
  className?: string;
  titleContent?:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal;
}

const useStyles = makeStyles(theme => ({
  customTooltip: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    pointerEvents: 'auto',
  },
  customTooltipNoPadding: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    pointerEvents: 'auto',
    padding: 0,
  },
  customTooltipReactions: {
    display: 'flex',
    backgroundColor: '#252424',
    pointerEvents: 'auto',
    width: '35rem',
    height: '35rem',
    maxWidth: '100%',
    maxHeight: '100%',
    fontWeigth: '700',
    fontSize: '2.1rem',
    borderRadius: '10px',
    margin: '0px 0 40px 0 !important',
  },
  customTooltipVolume: {
    display: 'flex',
    backgroundColor: '#252424',
    pointerEvents: 'auto',
    width: '41.6rem',
    height: '24.3rem',
    maxWidth: '100%',
    maxHeight: '100%',
    fontWeigth: '700',
    fontSize: '2.1rem',
    borderRadius: '10px',
    margin: '0px 0 40px 0 !important',
  },
  customArrow: {
    color: '#252424',
  },
  customTooltipReactionsMobile: {
    display: 'flex',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    pointerEvents: 'auto',
    width: '32.5rem',
    height: '30.5rem',
    maxWidth: '100%',
    maxHeight: '100%',
    padding: '30px',
  },
  customTooltipVolumeMobile: {
    display: 'flex',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    pointerEvents: 'auto',
    width: '39.1rem',
    height: '19.8rem',
    maxWidth: '100%',
    maxHeight: '100%',
    padding: '30px',
  },
}));

export const TooltipComponent: React.FC<ownProps> = ({
  children,
  title,
  placement,
  pathName,
  handleEdit,
  open = false,
  disableFocusListener = false,
  disableHoverListener = false,
  tooltipType = 'default',
  handleDelete,
  className,
  titleContent,
}) => {
  const { isMobile } = useWindowDimensions();
  const classes = useStyles();
  const { origin } = window.location;
  const link = origin + pathName;

  const renderTooltip = useMemo(() => {
    switch (tooltipType) {
      case 'share':
        return (
          <Tooltip
            className={className}
            arrow
            classes={{
              tooltip: classes.customTooltip,
              arrow: classes.customArrow,
            }}
            title={
              pathName ? (
                <div style={{ padding: '10px' }}>
                  <h4>Compartilhar via:</h4>
                  <InviteButtonsContainer>
                    <Button
                      tooltipProps={{
                        title: 'Facebook',
                        placement: 'bottom',
                      }}
                      size="very small"
                      onClick={e => {
                        e.preventDefault();
                        window.open(
                          `https://www.facebook.com/dialog/share?app_id=87741124305&href=${link}`,
                        );
                      }}
                      icon={IconFacebook}
                      style={{
                        backgroundColor: '#1877F2',
                        height: '40px',
                      }}
                      iconSize={20}
                    />
                    <Button
                      tooltipProps={{
                        title: 'Gmail',
                        placement: 'bottom',
                      }}
                      size="very small"
                      onClick={e => {
                        e.preventDefault();
                        window.open(
                          `mailto:email@example.com?subject=Subject&body=${link}`,
                        );
                      }}
                      icon={IconGoogle}
                      colorstyle="white1"
                      style={{
                        height: '40px',
                      }}
                      iconSize={20}
                    />
                    <Button
                      tooltipProps={{
                        title: 'Twitter',
                        placement: 'bottom',
                      }}
                      size="very small"
                      onClick={e => {
                        e.preventDefault();
                        window.open(
                          `https://twitter.com/intent/tweet?url=${link}&text=Vem%20comigo%20no%20netfans`,
                        );
                      }}
                      icon={IconTwitter}
                      style={{
                        height: '40px',
                        backgroundColor: '#5899E3',
                      }}
                      iconSize={20}
                    />
                    <Button
                      tooltipProps={{
                        title: 'Whatsapp',
                        placement: 'bottom',
                      }}
                      size="very small"
                      onClick={e => {
                        e.preventDefault();
                        window.open(
                          `https://api.whatsapp.com/send/?phone&text=Vem%20comigo%20no%20netfans%20${link}`,
                        );
                      }}
                      style={{
                        backgroundColor: '#25D366',
                        height: '40px',
                      }}
                      icon={IconWhatsapp}
                      iconSize={20}
                    />
                    <Button
                      tooltipProps={{
                        title: 'Telegram',
                        placement: 'bottom',
                      }}
                      size="very small"
                      onClick={e => {
                        e.preventDefault();
                        window.open(
                          `http://t.me/share/url?url=${link}&text=Vem comigo no netfans`,
                        );
                      }}
                      icon={IconTelegram}
                      style={{
                        height: '40px',
                        backgroundColor: '#37AFE2',
                      }}
                      iconSize={20}
                    />
                    <Button
                      tooltipProps={{
                        title: 'Copiar link',
                        placement: 'bottom',
                      }}
                      size="very small"
                      onClick={e => {
                        e.preventDefault();
                        navigator.clipboard.writeText(`${link}`);
                        toast.info('Link copiado para a área de transferência');
                      }}
                      style={{
                        height: '40px',
                        backgroundColor: '#3F4660',
                      }}
                      icon={IconTransfer}
                      iconSize={20}
                    />
                  </InviteButtonsContainer>
                </div>
              ) : (
                ''
              )
            }
            placement={placement}
            open={open}
            disableFocusListener={disableFocusListener}
            disableHoverListener={disableHoverListener}
          >
            {children}
          </Tooltip>
        );
      case 'volume':
        return (
          <Tooltip
            className={className}
            arrow
            classes={{
              tooltip: isMobile
                ? classes.customTooltipVolumeMobile
                : classes.customTooltipVolume,
              arrow: classes.customArrow,
            }}
            title={titleContent ?? ''}
            placement={placement}
            open={open}
            disableFocusListener={disableFocusListener}
            disableHoverListener={disableHoverListener}
          >
            {children}
          </Tooltip>
        );
      case 'reactions':
      case 'users':
        return (
          <Tooltip
            className={className}
            arrow
            classes={{
              tooltip: isMobile
                ? classes.customTooltipReactionsMobile
                : classes.customTooltipReactions,
              arrow: classes.customArrow,
            }}
            title={titleContent ?? ''}
            placement={placement}
            open={open}
            disableFocusListener={disableFocusListener}
            disableHoverListener={disableHoverListener}
          >
            {children}
          </Tooltip>
        );

      case 'options':
        return (
          <Tooltip
            arrow
            classes={{
              tooltip: classes.customTooltipNoPadding,
              arrow: classes.customArrow,
            }}
            title={
              tooltipType && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <OptionItem className="Disable">
                    <CalendarIcon />
                    Adicionar à agenda
                  </OptionItem>
                  <OptionItem className="Disable">
                    <IconTicket />
                    Comprar Ingresso
                  </OptionItem>
                  <OptionItem className="Disable">
                    <IconFlag />
                    Denunciar
                  </OptionItem>
                  <OptionItem onClick={handleEdit}>
                    <IconPencil />
                    Editar Informações
                  </OptionItem>
                  <OptionItem onClick={handleDelete}>
                    <IconTrash />
                    Excluir Evento
                  </OptionItem>
                </div>
              )
            }
            open={open}
            placement={placement}
            disableFocusListener={disableFocusListener}
            disableHoverListener={disableHoverListener}
          >
            {children}
          </Tooltip>
        );
      case 'default':
        return (
          <Tooltip
            arrow
            classes={{
              tooltip: classes.customTooltip,
              arrow: classes.customArrow,
            }}
            title={
              pathName ? (
                <div
                  style={{
                    padding: '10px',
                  }}
                >
                  <h4>Compartilhar via:</h4>
                  <InviteButtonsContainer>
                    <Button
                      tooltipProps={{
                        title: 'Facebook',
                        placement: 'bottom',
                      }}
                      size="very small"
                      onClick={e => {
                        e.preventDefault();
                        window.open(
                          `https://www.facebook.com/dialog/share?app_id=87741124305&href=${link}`,
                        );
                      }}
                      icon={IconFacebook}
                      style={{
                        backgroundColor: '#1877F2',
                        width: '50px',
                        height: '40px',
                      }}
                      iconSize={20}
                    />
                    <Button
                      tooltipProps={{
                        title: 'Gmail',
                        placement: 'bottom',
                      }}
                      size="very small"
                      onClick={e => {
                        e.preventDefault();
                        window.open(
                          `mailto:email@example.com?subject=Subject&body=${link}`,
                        );
                      }}
                      icon={IconGoogle}
                      colorstyle="white1"
                      style={{
                        width: '50px',
                        height: '40px',
                      }}
                      iconSize={20}
                    />
                    <Button
                      tooltipProps={{
                        title: 'Twitter',
                        placement: 'bottom',
                      }}
                      size="very small"
                      onClick={e => {
                        e.preventDefault();
                        window.open(
                          `https://twitter.com/intent/tweet?url=${link}&text=Vem%20comigo%20no%20netfans`,
                        );
                      }}
                      icon={IconTwitter}
                      style={{
                        width: '50px',
                        height: '40px',
                        backgroundColor: '#5899E3',
                      }}
                      iconSize={20}
                    />
                    <Button
                      tooltipProps={{
                        title: 'Whatsapp',
                        placement: 'bottom',
                      }}
                      size="very small"
                      onClick={e => {
                        e.preventDefault();
                        window.open(
                          `https://api.whatsapp.com/send/?phone&text=Vem%20comigo%20no%20netfans%20${link}`,
                        );
                      }}
                      style={{
                        backgroundColor: '#25D366',
                        width: '50px',
                        height: '40px',
                      }}
                      icon={IconWhatsapp}
                      iconSize={20}
                    />
                    <Button
                      tooltipProps={{
                        title: 'Telegram',
                        placement: 'bottom',
                      }}
                      size="very small"
                      onClick={e => {
                        e.preventDefault();
                        window.open(
                          `http://t.me/share/url?url=${link}&text=Vem comigo no netfans`,
                        );
                      }}
                      icon={IconTelegram}
                      style={{
                        width: '50px',
                        height: '40px',
                        backgroundColor: '#37AFE2',
                      }}
                      iconSize={20}
                    />
                    <Button
                      tooltipProps={{
                        title: 'Copiar link',
                        placement: 'bottom',
                      }}
                      size="very small"
                      onClick={e => {
                        e.preventDefault();
                        navigator.clipboard.writeText(`${link}`);
                        toast.info('Link copiado para a área de transferência');
                      }}
                      style={{
                        width: '50px',
                        height: '40px',
                        backgroundColor: '#3F4660',
                      }}
                      icon={IconTransfer}
                      iconSize={20}
                    />
                  </InviteButtonsContainer>
                </div>
              ) : title ? (
                <h4>{title}</h4>
              ) : (
                ''
              )
            }
            placement={placement}
            disableFocusListener={disableFocusListener}
            disableHoverListener={disableHoverListener}
          >
            {children}
          </Tooltip>
        );
      default:
        return null;
    }
  }, [
    children,
    className,
    classes,
    disableFocusListener,
    disableHoverListener,
    handleDelete,
    open,
    pathName,
    placement,
    title,
    titleContent,
    tooltipType,
  ]);

  return <>{renderTooltip}</>;
};
