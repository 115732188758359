export default {
  Texto: 'Text',
  Esportes: 'Sports',
  Música: 'Music',
  Entretenimento: 'Entertainment',
  NÚMERO: 'NUMBER',
  'AO VIVO': 'LIVE',
  'Título da sessão': 'Session Title',
  'Entidade responsável': 'Responsible Entity',
  Horário: 'Time',
  'Ticket Regular': 'Regular Ticket',
  'Termos de Serviço e Política de Privacidade':
    'Terms of Service and Privacy Policy',
  'Política de Privacidade': 'Privacy Policy',
  Cancelar: 'Cancel',
  Aceitar: 'Accept',
  Rejeiter: 'Reject',
  Realização: 'Achievement',
  Data: 'Date',
  Categoria: 'Category',
  Subcategoria: 'Subcategory',
  'O usuário precisa estar logado para acessar sala.':
    'You must be logged in to access the room.',
};
