import styled, { keyframes } from 'styled-components';

const transition = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  /* width: 100%; */
  /* max-width: 36rem; */
  padding: 3rem;

  position: relative;

  background: ${({ theme }) => theme.colors.black2};
  border-radius: 0.5rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: ${({ theme }) => theme.colors.white1};

  animation: ${transition} 0.5s ease-in-out;
`;

export const Header = styled.header`
  display: flex;
  width: 100%;
  height: 3rem;
  align-items: center;
  justify-content: flex-end;
`;

export const Content = styled.main`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 3rem;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const TitleModal = styled.h3`
  font: ${({ theme }) => theme.typography.h3};
  font-size: 18px;
  color: #f2f2f2;
  text-align: center;
`;

export const SubTitleModal = styled.h4`
  font: ${({ theme }) => theme.typography.h4};
  color: #f2f2f2;
  text-align: center;
`;

export const DescriptionModal = styled.h6`
  font: ${({ theme }) => theme.typography.h7};
  font-size: 13px;
  color: #dadaea;
  text-align: center;
`;
