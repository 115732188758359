import styled from 'styled-components';
import media from 'utils/mediaqueries';

export const Container = styled.div`
  width: 40.8rem;

  background: ${({ theme }) => theme.colors.black2};

  border-radius: 10px;
`;

export const Content = styled.div`
  ${media.lessThan('sm')} {
    padding: 20px 20px 0;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 2.4rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
`;

export const Title = styled.h1`
  font-size: 1.8rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white1};
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  background: transparent;

  padding: 1.1rem;
  border-radius: 0.5rem;
  outline: none;

  &:hover {
    background: ${({ theme }) => theme.colors.black3};
    color: ${({ theme }) => theme.colors.white2};
  }

  &:focus-visible {
    box-sizing: border-box;
    padding: 0.9rem;
    background: ${({ theme }) => theme.colors.black3};
    border: 0.2rem solid ${({ theme }) => theme.colors.blue1};

    box-shadow: 0 0 1rem ${({ theme }) => theme.colors.blue1};

    svg path {
      fill: ${({ theme }) => theme.colors.white2};
    }
  }

  &:active {
    border: 0;
    padding: 1.1rem;
    background: ${({ theme }) => theme.colors.black3};

    svg path {
      fill: ${({ theme }) => theme.colors.blue1};
    }

    box-shadow: initial;
  }

  svg {
    width: 1.6rem;
    aspect-ratio: 1;
  }
`;

export const TitleContent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const InviteContainer = styled.div`
  width: 100%;
`;

export const ContainerSocialButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2.4rem;
  gap: 1.6rem;

  #socialMediaIcons:hover {
    opacity: 0.5;
  }
`;

export const SocialMedia = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.4rem;
`;

export const SocialMediaButtons = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors.white1};
  }
`;

export const Text = styled.span`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.9rem;
  color: ${({ theme }) => theme.colors.white2};
`;

export const ParticipantItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px 0 10px;
`;

export const ParticipantsItemRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 11px;
  color: white;
`;

export const InviteButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  width: 100%;
  padding: 2.4rem 2.2rem 2.4rem 2.2rem;
`;

export const SearchInput = styled.div`
  width: 100%;
  max-width: 495px;
  border: none;
`;
