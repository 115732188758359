import styled, { css } from 'styled-components';

import { defaultTheme } from 'presentation/base/themes';

interface ContainerProps {
  width?: string;
  maxWidth?: string;
  orientation: 'column' | 'row';
  required?: boolean;
  size?: keyof typeof sizes;
}

interface InputContainerProps {
  height: string;
  focus: boolean;
  disabled: boolean | undefined;
  error: boolean;
  hasButton?: boolean;
  color: keyof typeof defaultTheme.colors;
}

interface MessageProps {
  error?: boolean;
}

interface ButtonContainer {
  focus: boolean;
  hasButton?: boolean;
}

const sizes = {
  xl: '53.6rem',
  m: '36rem',
  s: '16.8rem',
  ss: '9.2rem',
  xs: '7.6rem',
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: ${({ orientation }) => orientation};
  align-items: ${({ orientation }) =>
    orientation === 'row' ? 'center' : 'inherit'};
  justify-content: ${({ orientation }) =>
    orientation === 'row' ? 'inherit' : 'flex-end'};

  max-width: ${({ size }) => (size ? sizes[size] : '100%')};
`;

export const InputGroup = styled.div<InputContainerProps>`
  display: flex;
  flex-direction: column;

  border-radius: 5px;
  font-weight: lighter;
  padding: 0 1.6rem;
  background: ${({ color, theme }) => theme.colors[color]};
`;

export const Label = styled.span<ContainerProps>`
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: ${props => props.theme.colors.white2};

  margin-right: ${({ orientation }) =>
    orientation === 'row' ? '15px' : '0px'};
  font-weight: 600;

  :after {
    content: ${({ required }) => (required ? '"*"' : '""')};
    color: ${props => props.theme.colors.red1};
  }
`;

export const InputContainer = styled.span.attrs({
  className: 'input-container',
})<InputContainerProps>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: ${props => props.height};
  border-radius: 5px;
  border-color: ${props =>
    props.focus
      ? `${props.theme.colors.blue1}`
      : `${props.theme.colors.black3}`};
  background: ${({ color, theme }) => theme.colors[color]};

  outline: ${props =>
    !props.focus ? 'transparent' : `2px solid ${props.theme.colors.blue1}`};

  button {
    min-height: 3.4rem;
    min-width: 3.4rem;
    outline: ${props =>
      !props.focus ? 'transparent' : `0.5px solid ${props.theme.colors.blue1}`};
    :hover {
      outline: ${props =>
        props.focus
          ? 'transparent'
          : `0.5rem solid ${props.theme.colors.blue1}`};
    }
  }

  input {
    /* font-family: inherit; */
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    color: ${props => props.theme.colors.white1};
    padding-left: 1.4rem;

    ::placeholder {
      color: ${props => props.theme.colors.white3};
    }

    :focus-visible {
      outline: ${props =>
        !props.hasButton && props.focus
          ? 'transparent'
          : `0.2rem solid ${props.theme.colors.blue1}`};
      box-shadow: 0 0 1rem
        ${({ theme, error }) => theme.colors[error ? 'red1' : 'blue1']};

      outline: ${({ error, theme }) =>
        error ? `0.2rem solid ${theme.colors.red1}` : 'default'};

      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      border-radius: ${props => !props.hasButton && '0.5rem'};
    }

    :focus {
      #spanfocus {
        border-color: ${props => props.theme.colors.blue1};
      }
    }

    ${({ error, focus, theme, hasButton }) =>
      error &&
      !focus &&
      css`
        outline: 0.2rem solid ${theme.colors.red1};
        border-radius: 0.5rem;
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        :hover {
          cursor: not-allowed;
        }
      `}
  }

  .icon {
    margin-right: 13px;
  }

  :hover {
    /* border-color: ${props => props.theme.colors.blue1}; */
  }

  ${({ error }) =>
    error &&
    css`
      border-color: ${props => props.theme.colors.colorError};

      :hover {
        border-color: ${props => props.theme.colors.colorError};
      }
    `}
`;

export const Message = styled.span<MessageProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  color: #7b8591;
  margin-top: 1.2rem;

  ${({ error }) =>
    error
      ? css`
          color: ${props => props.theme.colors.colorError};
        `
      : css`
          color: ${props => props.theme.colors.green1};
        `}
`;
