import {
  iActionUpdateSpotsInfo,
  SpotsInfoTypes,
} from 'domain/interfaces/redux/spotsInfo/update';

import { UpdateSpotsInfo } from 'domain/usecases/spotsInfo/redux';

export const updateSpotsInfo = (
  payload: UpdateSpotsInfo.Params,
): iActionUpdateSpotsInfo => ({
  type: SpotsInfoTypes.UPDATE,
  payload,
});
