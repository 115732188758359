import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { CreateCategory as UsecaseRemoteCreateCategory } from 'domain/usecases/category/remote';
import { CreateCategory as UsecaseReduxCreateCategory } from 'domain/usecases/category/redux';

import { RemoteCreateCategory } from 'data/repository/category';
import { ReduxCreateCategory } from 'data/store/reducer/category/usecases';

/**
 * send request via API.
 */
export const makeRemoteCreateCategory = (): UsecaseRemoteCreateCategory =>
  new RemoteCreateCategory(makeApiUrl('/categories/'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxCreateCategory = (): UsecaseReduxCreateCategory =>
  new ReduxCreateCategory();
