// create a context to store and load the participants
//
// Path: src/presentation/contexts/Participants.tsx
// Compare this snippet from src/presentation/contexts/MessagesPublicChatContext.tsx

import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import { MessageProps } from '../components/Chat/interfaces';

type ParticipantsContextProps = {
  participants: any[];
};

export const ParticipantsContext = createContext<ParticipantsContextProps>({
  participants: [],
} as ParticipantsContextProps);

const ParticipantsProvider: React.FC = ({ children }) => {
  const wrtc = useSelector((store: iStore) => store.wrtc);

  const [users, setUsers] = useState<any[]>([]);

  useEffect(() => {
    if (wrtc && wrtc.onrequestinsideroompeersinfosresponse) {
      setUsers(wrtc.onrequestinsideroompeersinfosresponse);
    }
  }, [wrtc]);

  const participants = useMemo(() => {
    if (users && users.length > 0) {
      console.log('users', users);
      return users;
    }
    return [];
  }, [users]);

  return (
    <ParticipantsContext.Provider
      value={{
        participants,
      }}
    >
      {children}
    </ParticipantsContext.Provider>
  );
};

export const useParticipants = () => {
  const context = React.useContext(ParticipantsContext);

  if (!context) {
    throw new Error(
      'useParticipants must be used within a ParticipantsProvider',
    );
  }

  return context;
};

export default ParticipantsProvider;
