import { call, put, select } from 'redux-saga/effects';
import { iActionGetAll } from 'domain/interfaces/redux/event/getAll';
import { GetAllEvent } from 'domain/usecases/event/remote/GetAllEvent';
import { makeRemoteGetAllEvent } from 'main/factories/usecases/event/GetAllEventFactory';
import {
  getAllSuccess,
  getAllFailed,
} from 'data/store/reducer/event/actions/getAll';

export function* onGetAll(action: iActionGetAll) {
  const remoteGetAllEvent = makeRemoteGetAllEvent();

  try {
    const response: GetAllEvent.Model = yield call(
      remoteGetAllEvent.getAll,
      action.payload,
    );
    yield put(
      getAllSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(getAllFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetAllSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetAllFailed() {}
