import styled from 'styled-components';
import media from 'utils/mediaqueries';

import NewImageBanner from 'presentation/base/images/new_image_banner.png';

export const ContainerImage = styled.div`
  width: 100%;
  aspect-ratio: 18/5;
  position: relative;
  background: url(${NewImageBanner}) no-repeat center right;
  background-size: contain;

  ${media.lessThan('sm')} {
    display: none;
  }
`;

export const LeftContent = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const RightContent = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const Title = styled.div`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 4.4rem;
  color: ${({ theme }) => theme.colors.white2};
`;

export const Paragraph = styled.div`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.9rem;
  color: ${({ theme }) => theme.colors.white1};
`;

export const ImageShadowLeft = styled.img`
  height: 100%;
`;

export const ContainerLeft = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 30%;
  height: 100%;
  position: absolute;
  padding-left: 6.6rem;
  top: 0;
`;
