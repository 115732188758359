import React from 'react';
import { StButtonToAddNewTemplate } from './styles/PublicityStyled';

interface ButtonToAddNewTemplateProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  icon: React.ReactNode;
}

const ButtonToAddNewTemplate: React.FC<ButtonToAddNewTemplateProps> = ({
  label,
  icon,
  ...rest
}) => {
  return (
    <StButtonToAddNewTemplate
      type="button"
      className="button-to-add-new-template"
      {...rest}
    >
      {icon}
      <p>{label}</p>
    </StButtonToAddNewTemplate>
  );
};

export default ButtonToAddNewTemplate;
