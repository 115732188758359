/* eslint-disable @typescript-eslint/no-empty-function */
import { iStore } from 'domain/interfaces/models';
import { iEventItem } from 'domain/interfaces/models/Event';
import bannerBeta from 'presentation/base/images/bannerBeta.png';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { formattedDate } from 'utils/formattedDate';
import { RoomContext } from 'presentation/contexts/RoomContext';
import { SpotsInfo } from 'data/store/ducks/spotsInfo/types';
import { IconInfo, IconLogoJockey } from 'presentation/base/icons';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import PublicChat from '../Chat/PublicChat';
import TabNavigation from '../TabNavigation';
import About from './about';
import { Participants } from './participants';
import {
  AdContainer,
  Banner,
  Container,
  LeftAdContent,
  LeftAdInfo,
  RightAdContent,
  Subtitle,
  Title,
} from './styles';
import { Button } from '../UI';

interface SideBarInfoEventProps {
  publicChat: any;
  newMessage: any;
  rooms: any[];
  renderLayouts?: any;
  handleNewMessageAdded: () => void;
  participants?: SpotsInfo[];
}

export interface iParams {
  id: string;
  roomId: string;
}

export type Event = {
  id: number;
  name: string;
  descr: string;
  date: string;
  tags: string[];
  type: string;
  category: string;
  subCategory: string;
  organization: string;
};

export const SideBarInfoEvent: React.FC<SideBarInfoEventProps> = ({
  publicChat,
  newMessage,
  rooms,
  renderLayouts,
  handleNewMessageAdded,
  participants,
}) => {
  const [active, setActive] = useState<number>(0);

  const { isFullScreen, isMobile } = useContext(RoomContext);

  const options = useMemo(
    () => [
      { label: 'Mapa Interativo' },
      { label: 'Bate-papo ao vivo' },
      { label: 'Participantes' },
      { label: 'Sobre o evento' },
      { label: 'Linha do tempo', disabled: true },
    ],
    [],
  );

  const [selectedEvent, setSelectedEvent] = useState<iEventItem>(
    {} as iEventItem,
  );

  const { id }: iParams = useParams();

  const { results: events } = useSelector((store: iStore) => store.event);
  const { results: categories } = useSelector(
    (store: iStore) => store.category,
  );

  const eventType = useMemo(() => {
    return selectedEvent?.contentType?.name;
  }, [selectedEvent]);

  const eventDate = useMemo(() => {
    return selectedEvent?.schedule
      ? formattedDate(
          new Date(selectedEvent?.schedule),
          selectedEvent?.duration,
        )
      : 'Jan 1,2022 - 00h00';
  }, [selectedEvent]);

  const eventTags = useMemo(() => {
    return selectedEvent?.keywords?.map(item => {
      return item;
    });
  }, [selectedEvent]);

  useEffect(() => {
    events?.forEach(item => {
      if (item.id === Number(id)) {
        setSelectedEvent(item);
      }
    });
  }, [events, id]);

  const tabs = [
    <ScrollContainer
      className="scroll-container"
      horizontal
      style={{
        margin: '0 2rem',
      }}
    >
      {renderLayouts}
    </ScrollContainer>,
    <PublicChat
      chat={publicChat}
      key={publicChat?.id}
      chatId={publicChat?.id}
      handleNewMessageAdded={handleNewMessageAdded}
      newMessage={newMessage}
    />,
    <Participants participants={participants} />,
    <About
      event={{
        ...selectedEvent,
        tags: eventTags ?? [],
        category: selectedEvent?.category?.name || '--',
        subCategory: selectedEvent?.subCategories?.[0]?.subCategory ?? '--',
        date: eventDate,
        type: eventType ?? '',
      }}
    />,
    <div style={{ color: '#fff' }}>Linha do tempo</div>,
  ];

  const openNewTab = useCallback((url?: string) => {
    if (!url) return;

    const withHttp = !/^https?:\/\//i.test(url) ? `http://${url}` : url;
    window.open(withHttp, '_blank', 'noopener,noreferrer');
  }, []);

  return !isMobile ? (
    <Container isFullScreen={isFullScreen}>
      <Banner
        src={selectedEvent?.banner ?? bannerBeta}
        alt={`Banner do evento: ${selectedEvent?.name}`}
      />
      <AdContainer>
        <LeftAdContent>
          <IconLogoJockey style={{ width: '20%' }} />
          <LeftAdInfo>
            <Title>{selectedEvent?.sponsor?.name}</Title>
            <Subtitle>{`Ad • ${selectedEvent?.sponsor?.url}`}</Subtitle>
          </LeftAdInfo>
        </LeftAdContent>
        <RightAdContent>
          <Button
            size="dynamic"
            title={selectedEvent?.sponsor?.label}
            color={selectedEvent?.sponsor?.color}
            onClick={() => openNewTab(selectedEvent?.sponsor?.url)}
          />
          <IconInfo
            style={{ cursor: 'pointer' }}
            onClick={() => {
              makeReduxActiveMessage().active({
                active: 'infoEvent',
                componentProps: {
                  infoEvent: {
                    event: selectedEvent,
                  },
                },
              });
            }}
          />
        </RightAdContent>
      </AdContainer>
      <TabNavigation
        options={isFullScreen ? options : options.slice(1, options.length)}
        active={active}
        setState={setActive}
      />

      {isFullScreen ? tabs[active] : tabs.slice(1, tabs.length)[active]}
    </Container>
  ) : null;
};

export default SideBarInfoEvent;
