export default {
  Texto: 'Texto',
  Esportes: 'Esportes',
  Música: 'Música',
  Entretenimento: 'Entretenimento',
  NÚMERO: 'NÚMERO',
  'AO VIVO': 'AO VIVO',
  'Título da sessão': 'Título da sessão',
  'Entidade responsável': 'Entidade responsável',
  Horário: 'Horário',
  'Ticket Regular': 'Ticket Regular',
  'Termos de Serviço e Política de Privacidade':
    'Termos de Serviço e Política de Privacidade',
  'Política de Privacidade': 'Política de Privacidade',
  Cancelar: 'Cancelar',
  Aceitar: 'Aceitar',
  Rejeitar: 'Rejeitar',
  Realização: 'Realização',
  Data: 'Data',
  Categoria: 'Categoria',
  Subcategoria: 'Subcategoria',
  'O usuário precisa estar logado para acessar sala.':
    'O usuário precisa estar logado para acessar sala.',
};
