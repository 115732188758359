/* eslint-disable react/require-default-props */
import { iStore } from 'domain/interfaces/models';
import { IconChatBallon, SendIcon } from 'presentation/base/icons';
import { MessagesPublicChatContext } from 'presentation/contexts/MessagesPublicChatContext';
import React, { HTMLAttributes, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Input } from '../../UI';
import { ChatInfoProps, MessageProps } from '../interfaces';
import Message from '../Message';

import { Messages, NoMessageContent } from '../styles';
import { Container, ContentPublicChat } from './styles';

interface ChatProps extends HTMLAttributes<HTMLDivElement> {
  chatId: number;
  chat: ChatInfoProps;
  newMessage?: MessageProps;
  isMobile?: boolean;
  handleNewMessageAdded?: () => void;
}

const PublicChat: React.FC<ChatProps> = ({
  chat,
  chatId,
  newMessage,
  isMobile,
  handleNewMessageAdded,
}) => {
  const wrtc = useSelector((store: iStore) => store.wrtc);
  const user = useSelector((store: iStore) => store.auth.user);

  const [chatMessages, setChatMessages] = React.useState<MessageProps[]>(
    chat?.messages,
  );

  const [inputValue, setInputValue] = React.useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const { handleAddNewMessage, messages, isChatEmpty } = useContext(
    MessagesPublicChatContext,
  );

  const sendChatMessage = (data: any) => {
    if (wrtc.sendChatMessage) {
      if (chat?.isPublic) {
        wrtc.sendChatMessage(data, -1);
      } else {
        wrtc.sendChatMessage(data, chat?.to?.id);
      }
    }
  };

  const handleSubmit = () => {
    const hour = new Date().getHours();
    const minute = new Date().getMinutes();

    if (inputValue !== '' && chat?.from?.id && chat?.to?.id) {
      const message: MessageProps = {
        chat: {
          ...chat,
          id: chat?.isPublic ? -1 : chat?.id,
          to: {
            ...chat?.to,
            name: chat?.isPublic ? String(user.fullName) : chat?.to?.name,
          },
        },
        message: inputValue,
        type: 'sent',
        timestamp: `${hour < 10 ? `0${hour}` : hour}:${
          minute < 10 ? `0${minute}` : minute
        }`,
        userId: chat?.from.id,
        messageType: 'chat',
      };
      setChatMessages([...chatMessages, message]);
      sendChatMessage({
        ...message,
        type: 'received',
      });
      handleAddNewMessage({ ...message, type: 'received' });
      setInputValue('');
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  useEffect(() => {
    document.getElementById(`bottom`)?.scrollIntoView({
      behavior: 'auto',
    });
  }, [chatMessages, newMessage, messages, chatId]);

  useEffect(() => {
    if (!newMessage) return;

    if (!newMessage?.isAdded) {
      handleNewMessageAdded?.();

      if (newMessage?.chat?.isPublic && chat?.isPublic) {
        // Tratando mensagens para o chat público
        if (newMessage?.chat?.id === chat?.id) {
          setChatMessages([...chatMessages, newMessage]);
          handleAddNewMessage(newMessage);
        }
      } else if (
        !chat?.isPublic &&
        !newMessage?.chat?.isPublic &&
        (newMessage?.userId === chat?.from?.id ||
          newMessage?.userId === chat?.to?.id)
      )
        // Tratando mensagens para o chat privado
        setChatMessages([...chatMessages, newMessage]);
    }
  }, [chat, chatMessages, handleNewMessageAdded, newMessage]);

  return (
    <>
      <Container isMobile={isMobile} onKeyPress={handleKeyPress}>
        <ContentPublicChat
          isChatEmpty={isChatEmpty}
          isMobile={isMobile}
          id="chat1"
          className="content"
        >
          {isChatEmpty ? (
            <NoMessageContent>
              <IconChatBallon />
              <h3>Não há novas mensagens</h3>
              <span>
                Até agora, nenhum usuário enviou uma mensagem neste bate-papo.
              </span>
            </NoMessageContent>
          ) : (
            <Messages>
              {messages?.map((messageMap, index) => (
                <Message
                  message={messageMap}
                  user={
                    messageMap?.userId === chat?.to?.id
                      ? chat?.to
                      : messageMap?.chat?.from
                  }
                  id={
                    index + 1 >= chatMessages?.length
                      ? `last${chatId}`
                      : `${index}`
                  }
                />
              ))}
              <div id="bottom" />
            </Messages>
          )}
        </ContentPublicChat>
        <div className="input-container">
          <Input
            autoFocus
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Envie uma mensagem"
            isSearch
            onClickBtn={handleSubmit}
          >
            <Button
              style={{ height: '3.8rem' }}
              iconSize={20}
              withInput
              size="very small"
              icon={SendIcon}
              onClick={handleSubmit}
            />
          </Input>
        </div>
      </Container>
    </>
  );
};

export default PublicChat;
