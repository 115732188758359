import React, { useEffect, useRef, useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

import { ArrowRightIcon, ArrowUpIcon } from 'presentation/base/icons';

import { Container } from './styles/ArrowScrollStyled';

interface OwnProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  isLayout: boolean;
  maxWidth?: string;
  maxHeight?: string;
  disableArrow?: boolean;
  scrollSlice?: number;
  positionX?: 'start' | 'half' | 'end';
  positionY?: 'start' | 'half' | 'end';
}

const ArrowScroll: React.FC<OwnProps> = ({
  isLayout,
  children,
  disableArrow = false,
  maxWidth = '810px',
  maxHeight = '540px',
  scrollSlice = 100,
  positionX = 'start',
  positionY = 'start',
  style,
}) => {
  const scrl = useRef<HTMLDivElement>(null);
  const [scrollX, setScrollX] = useState(0);
  const [scrollXEnd, setScrollXEnd] = useState(false);
  const [hasScrollBarX, setHasScrollBarX] = useState(true);
  const [scrollY, setScrollY] = useState(0);
  const [scrollYEnd, setScrollYEnd] = useState(false);
  const [hasScrollBarY, setHasScrollBarY] = useState(true);

  useEffect(() => {
    if (scrl.current) {
      switch (positionX) {
        case 'start':
          scrl.current.scrollLeft = 0;
          setScrollX(0);
          setScrollXEnd(false);
          break;
        case 'half':
          scrl.current.scrollLeft =
            (scrl.current.scrollWidth - scrl.current.offsetWidth) / 2;
          setScrollX(scrl.current.scrollLeft);
          setScrollXEnd(false);
          break;
        case 'end':
          scrl.current.scrollLeft = scrl.current.scrollWidth;
          setScrollX(scrl.current.scrollLeft);
          setScrollXEnd(true);
          break;
        default:
      }

      switch (positionY) {
        case 'start':
          scrl.current.scrollTop = 0;
          setScrollY(0);
          setScrollYEnd(false);
          break;
        case 'half':
          scrl.current.scrollTop =
            (scrl.current.scrollHeight - scrl.current.offsetHeight) / 2;
          setScrollY(scrl.current.scrollTop);
          setScrollYEnd(false);
          break;
        case 'end':
          scrl.current.scrollTop = scrl.current.scrollHeight;
          setScrollY(scrl.current.scrollTop);
          setScrollYEnd(true);
          break;
        default:
      }
    }
  }, [scrl, positionX, positionY]);

  useEffect(() => {
    function updateState() {
      const el = scrl.current;
      if (!el) return;

      if (el.scrollWidth > el.getBoundingClientRect().width) {
        setHasScrollBarX(true);
        scrollCheck();
      } else {
        setHasScrollBarX(false);
        // setScrollXEnd(false);
      }

      if (el.scrollHeight > el.getBoundingClientRect().height) {
        setHasScrollBarY(true);
        scrollCheck();
      } else {
        setHasScrollBarY(false);
        // setScrollYEnd(false);
      }
    }

    updateState();
    window.addEventListener('resize', updateState);
    return () => window.removeEventListener('resize', updateState);
  }, [hasScrollBarX, scrl]);

  const slideX = (shift: any) => {
    if (scrl.current) {
      scrl.current.scrollLeft += shift;
      setScrollX(scrl.current.scrollLeft);

      if (
        Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
        scrl.current.offsetWidth
      ) {
        setScrollXEnd(true);
      } else {
        setScrollXEnd(false);
      }
    }
  };

  const slideY = (shift: any) => {
    if (scrl.current) {
      scrl.current.scrollTop += shift;
      setScrollY(scrl.current.scrollTop);

      if (
        Math.floor(scrl.current.scrollHeight - scrl.current.scrollTop) <=
        scrl.current.offsetHeight
      ) {
        setScrollYEnd(true);
      } else {
        setScrollYEnd(false);
      }
    }
  };

  const scrollCheck = () => {
    if (scrl.current) {
      setScrollX(scrl.current.scrollLeft);
      if (
        Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
        scrl.current.offsetWidth
      ) {
        setScrollXEnd(true);
      } else {
        setScrollXEnd(false);
      }

      setScrollY(scrl.current.scrollTop);
      if (
        Math.floor(scrl.current.scrollHeight - scrl.current.scrollTop) <=
        scrl.current.offsetHeight
      ) {
        setScrollYEnd(true);
      } else {
        setScrollYEnd(false);
      }
    }
  };

  const renderArrows = () => {
    return !disableArrow ? (
      <>
        {hasScrollBarX && scrollX !== 0 && (
          <button
            className="arrow arrow-left"
            type="button"
            onClick={() => slideX(-scrollSlice)}
          >
            <ArrowRightIcon />
          </button>
        )}
        {hasScrollBarX && !scrollXEnd && (
          <button
            className="arrow arrow-right"
            type="button"
            onClick={() => slideX(+scrollSlice)}
          >
            <ArrowRightIcon />
          </button>
        )}
        {hasScrollBarY && scrollY !== 0 && (
          <button
            className="arrow arrow-top"
            type="button"
            onClick={() => slideY(-scrollSlice)}
          >
            <ArrowUpIcon />
          </button>
        )}
        {hasScrollBarY && !scrollYEnd && (
          <button
            className="arrow arrow-bottom"
            type="button"
            onClick={() => slideY(scrollSlice)}
          >
            <ArrowUpIcon />
          </button>
        )}
      </>
    ) : (
      <></>
    );
  };

  return (
    <Container
      isLayout={isLayout}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      onKeyDown={() => {
        slideX(-50);
      }}
    >
      {renderArrows()}
      {isLayout ? (
        <div style={{ width: '100%', height: '100%', overflow: 'visible' }}>
          {children}
        </div>
      ) : (
        <ScrollContainer
          innerRef={scrl}
          onScroll={scrollCheck}
          nativeMobileScroll
          className="scroll-container"
          buttons={[]}
          style={style}
        >
          {children}
        </ScrollContainer>
      )}
    </Container>
  );
};

export default ArrowScroll;
