import { GetAllLayout } from 'domain/usecases/layout/redux/GetAllLayout';
import {
  iActionGetAll,
  iActionGetAllFailed,
  iActionGetAllSuccess,
  LayoutTypes,
} from 'domain/interfaces/redux/layout/getAll';

export const getAllRequest = (payload: GetAllLayout.Params): iActionGetAll => ({
  type: LayoutTypes.GETALL,
  payload,
});

export const getAllSuccess = (
  params: GetAllLayout.Model,
): iActionGetAllSuccess => ({
  type: LayoutTypes.GETALL_SUCCESS,
  payload: params,
});

export const getAllFailed = (): iActionGetAllFailed => ({
  type: LayoutTypes.GETALL_FAILED,
});
