import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { CreateSponsor as UsecaseRemoteCreateSponsor } from 'domain/usecases/sponsor/remote';
import { CreateSponsor as UsecaseReduxCreateSponsor } from 'domain/usecases/sponsor/redux';

import { RemoteCreateSponsor } from 'data/repository/sponsor';
import { ReduxCreateSponsor } from 'data/store/reducer/sponsor/usecases';

/**
 * send request via API.
 */
export const makeRemoteCreateSponsor = (): UsecaseRemoteCreateSponsor =>
  new RemoteCreateSponsor(makeApiUrl('/sponsors/form-data'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxCreateSponsor = (): UsecaseReduxCreateSponsor =>
  new ReduxCreateSponsor();
