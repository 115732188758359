import styled, { keyframes } from 'styled-components';

const transition = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  /* width: 100%; */
  /* max-width: 36rem; */
  padding: 3rem;

  position: relative;

  // background: ${({ theme }) => theme.colors.black2};
  border-radius: 0.5rem;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  color: ${({ theme }) => theme.colors.white1};

  animation: ${transition} 0.5s ease-in-out;
`;

export const TitleModal = styled.h3`
  font: ${({ theme }) => theme.typography.h3};
  margin-bottom: 2rem;
`;

export const SubTitleModal = styled.h4`
  font: ${({ theme }) => theme.typography.h4};
  margin-bottom: 1.2rem;
`;

export const DescriptionModal = styled.h6`
  font: ${({ theme }) => theme.typography.h7};
  margin-bottom: 1.5rem;
`;

export const StLabel = styled.label`
  position: relative;
  background: ${({ theme }) => theme.colors.black3};
  padding: 0.8rem;
  display: flex;
  align-items: center;
  margin-bottom: 3rem;

  border-radius: 0.5rem;
  min-height: 3.8rem;

  .button {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
  }

  .icon-link {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.8rem;
  }

  input {
    color: ${({ theme }) => theme.colors.blue1};
    width: 100%;
    border: 0;
    background: transparent;
    font-size: 1.2rem;
    font-weight: 400;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.blue1};
    }
  }
`;
