import { useState, useEffect } from 'react';

const whitelistDevices = [
  'Linux',
  'Windows',
  'Win16',
  'Win32',
  'Win64',
  'WinCE',
  'Mac OS',
];

const blacklistDevices = ['Linux i686', 'Linux armv7l'];

function getWindowDimensions() {
  const { availWidth: width, availHeight: height } = window.screen;

  const deviceOrientation = width > height ? 'landscape' : 'portrait';

  const isDesktopDevice =
    whitelistDevices.includes(navigator.platform) &&
    !blacklistDevices.includes(navigator.platform);

  return {
    width,
    height,
    widthBreakPointSquareModal: getBreakPointSquareSize(height),
    orientation: deviceOrientation,
    isMobile:
      (width <= 768 && deviceOrientation === 'portrait') ||
      (!isDesktopDevice && height <= 768 && deviceOrientation === 'landscape'),
  };
}

function getBreakPointSquareSize(windowHeight: number) {
  // TODO: IMPROVE IMPL
  if (windowHeight) {
    if (windowHeight >= 1400) {
      return 'medium';
    }
    if (windowHeight >= 920 && windowHeight < 1400) {
      return 'small';
    }
    return 'smaller';
  }
  return undefined;
}

export default function useWindowDimensions() {
  // eslint-disable-next-line no-console
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
