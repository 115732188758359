import { CircularProgress } from '@material-ui/core';
import { iMessage, iStore } from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { LogoNetfans } from 'presentation/base/icons';
import React from 'react';

import { useSelector } from 'react-redux';
import { ModalContainer, ModalContent } from './styles';

interface props {
  message: iMessage;
}

export const Loading: React.FC<props> = ({ message }) => {
  const { active } = message;
  const { loading } = useSelector((state: iStore) => state.sponsor);
  const isActive = active === MessageOptions.creatingEvent;

  return isActive || loading ? (
    <ModalContainer style={{ zIndex: '9999' }}>
      <ModalContent>
        <LogoNetfans style={{ position: 'absolute' }} fontSize={30} />
        <CircularProgress size={90} style={{ color: '#fff' }} />
      </ModalContent>
    </ModalContainer>
  ) : null;
};

export default Loading;
