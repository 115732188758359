import ScrollContainer from 'react-indiana-drag-scroll';
import styled, { css } from 'styled-components';

interface tagProps {
  isActive?: boolean;
  index?: number;
  disabled?: boolean;
}

interface iContainerProps {
  isMenu?: boolean;
}

const colors: Record<number, string> = {
  0: '#5667f6',
  1: '#5cdfbf',
  2: '#ff75a6',
  3: '#f48503',
};

export const Container = styled.div<iContainerProps>`
  display: flex;
  width: 100%;
  height: 4.9rem;
  flex-direction: row;
  align-items: center;
  padding: 0 2rem;
  gap: 3rem;
  white-space: nowrap;
  transition: all 0.1s ease-in-out;

  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);

  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;

  position: relative;

  & .menu {
    scroll-snap-align: center;
  }

  ${({ isMenu }) =>
    isMenu &&
    css`
      background-color: #202433;
    `}

  /* Width */
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
`;

export const ControlScrollViewContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  & .button {
    margin: 0;
    width: 30px;
    height: 4.9rem;
    border: 0;
    position: absolute;
    padding: 0.5rem;
    z-index: 5;
    background: linear-gradient(
      270deg,
      #202433 51.33%,
      rgba(32, 36, 51, 0) 102.5%
    );
  }

  & .left {
    left: 0;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  & .right {
    right: 0;
  }
`;

export const Menu = styled.div<tagProps>`
  display: flex;
  padding: 1.2rem 0 0.9rem 0;
  cursor: ${props => (props.isActive ? 'default' : 'pointer')};

  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  color: ${props => `${props.theme.colors.white1}CC`};

  &:hover {
    color: ${props => `${props.theme.colors.white1}`};
  }

  ${({ isActive, index, disabled }) => {
    if (isActive && !disabled) {
      const tabActive = index ?? 0;
      const selectedColor =
        tabActive > 3 ? colors[tabActive % 4] : colors[tabActive];

      return css`
        color: ${props => `${props.theme.colors.white1}`};
        border-bottom: 3px solid ${selectedColor};
      `;
    }
    return null;
  }};

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      color: ${props => props.theme.colors.white5};

      &:hover {
        color: ${props => props.theme.colors.white5};
      }
    `}
`;
