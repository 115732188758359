/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-loop-func */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
import { iReactions, iStore } from 'domain/interfaces/models';
import { iEventItem } from 'domain/interfaces/models/Event';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { makeReduxUpdateWRTCInfo } from 'main/factories/usecases/auth/UpdateWRTCInfoFactory';
import { makeReduxGetAllCategory } from 'main/factories/usecases/category/GetAllCategoryFactory';
import { makeReduxGetAllEvent } from 'main/factories/usecases/event/GetAllEventFactory';
import { makeReduxGetAllLayout } from 'main/factories/usecases/layout/GetAllLayoutFactory';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import { makeRemoteGetAllReaction } from 'main/factories/usecases/reaction/GetAllReactionFactory';
import { makeReduxUpdateWrtc } from 'main/factories/usecases/wrtcClient/UpdateFactory';
import { IconUser } from 'presentation/base/icons';
import { Chat } from 'presentation/components/Chat';
import { MessageProps } from 'presentation/components/Chat/interfaces';
import FooterInsideRoom from 'presentation/components/FooterInsideRoom';
import { RandomProfileUser } from 'presentation/components/randomProfileUser';
import DemoSideBarInfoEvent from 'presentation/components/DemoSideBarInfoEvent';
import { TooltipComponent } from 'presentation/components/TooltipComponent';
import AudioProvider from 'presentation/contexts/AudioContext';
import MessagesPublicChatProvider, {
  MessagesPublicChatContext,
} from 'presentation/contexts/MessagesPublicChatContext';
import useBreakPoint from 'presentation/hooks/useBreakPoint';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { WRTCClient } from 'services/wrtccli/netfans-wrtc-wrapper.js';
import {
  convertFromWRTCRoomName,
  convertToWRTCRoomName,
  getSpotIndexFromWRTCRoomName,
  onJoinRoomFailed,
} from 'utils';
import { getSpatialization } from 'utils/getSpatialization';
import { closeModal } from 'utils/modalFunctions';
import { moveOnRoom } from 'utils/moveOnRoom';
import RoomProvider from 'presentation/contexts/RoomContext';
import {
  TimingObject,
  TimingSampler,
  TimingProgress,
} from 'services/timingsrc/index.js';
import mainStore from '../../../data/store';
import { updateSpotsInfo } from '../../../data/store/reducer/spotsInfo/actions/update';
import Header from '../../components/Header';
import Layout from './layouts';
import {
  BackArrow,
  CategoryBar,
  CategoryTitle,
  Container,
  RoomHeader,
  StyledPaginationItem,
  StyledPaginationReaction,
} from './styles';

interface SpotsInfo {
  muted: boolean;
  peerId: number | string;
  peerName: string;
  roomName?: string;
  spotCoordI: number | string;
  spotCoordJ: number | string;
}

export interface ownProps {
  eventId: string;
  roomName: string;
  // eslint-disable-next-line react/no-unused-prop-types
  spotsInfo?: SpotsInfo[];
}

export interface iAnimation {
  peerId: number;
  animationUrl: string;
  active?: boolean;
  triggerFadeout?: boolean;
}

interface iGifs {
  name: string;
  animationUrl: string;
}

export interface iMutedUsers {
  [key: string]: boolean;
}

const SPORTS_CATEGORY_ID = 1;
const MUSIC_CATEGORY_ID = 2;
const ENTERTAINMENT_CATEGORY_ID = 3;

const mockEvent: iEventItem = {
  id: 9999,
  created: new Date().toLocaleDateString(),
  updated: new Date().toLocaleDateString(),
  short: 'Short Demo',
  name: 'Demo',
  organization: 'Org Demo',
  external: 'https://www.google.com',
  media: 'Midia Demo',
  descr: 'Descr Demo',
  keywords: ['keyword1', 'keyword2'],
  competitive: false,
  thumbnail: 'https://i.imgur.com/J5LVg76.png',
  duration: 90,
  invitationLink: 'https://www.google.com',
  joinPolicy: 'PUBLIC',
  listPolicy: 'PUBLIC',
  paymentPolicy: 'FREE',
  schedule: new Date().toISOString(),
  category: {
    id: 1,
    descr: 'Sport',
    name: 'Sport',
  },
  contentType: {
    id: 1,
    descr: 'Sport',
    name: 'Sport',
  },
  layout: {
    id: 1,
    name: 'Layout 1',
    descr: 'Layout 1',
    competitive: false,
    seats: 50,
  },
  mediaType: 'Test',
  rooms: 120,
};

// timing object
const to = new TimingObject({ range: [0, 100] });

// MCorp App
const app = (window as any)?.MCorp.app('5023369861542200042', { anon: true });

const Room: React.FC<ownProps> = ({ eventId, roomName }) => {
  const wrtc = useSelector((state: iStore) => state.wrtc);
  const { user } = useSelector((state: iStore) => state.auth);
  const { user: userLog, wrtcInfo: infoUser } = useSelector(
    (store: iStore) => store.auth,
  );
  const { active } = useSelector((state: iStore) => state.message);
  const event = useSelector((state: iStore) => state.event);
  const wrtcInfo = useSelector((state: iStore) => state.auth.wrtcInfo);
  const spotsInfo = useSelector((state: iStore) => state.spotsInfo);

  const [speakingPeerIds, setSpeakingPeerIds] = useState<any>({});
  const [rooms, setRooms] = useState<Array<any>>([]);
  const [floors, setFloors] = useState<Array<any>>([]);
  const [chats, setChats] = useState<any[]>([]);
  const [newChatMessage, setNewChatMessage] = useState<any>(null);
  const [sendChatToProvider, setSendChatToProvider] = useState<
    MessageProps | undefined
  >(undefined);
  const [reactionButtons, setReactionButtons] = useState<Array<iReactions>>([]);
  const [reactionGif, setReactionGif] = useState<Array<iGifs>>([]);
  const [displayedLabels, setDisplayedLabels] = useState<number[]>([]);
  const [displayedUsers, setDisplayedUsers] = useState<number[]>([]);
  const [activeUsers, setActiveUsers] = useState<Array<SpotsInfo>>([]);
  const [isLogged, setLoggedState] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const [updateInsideRoom, setUpdateInsideRoom] = useState(false);
  const [isVirtualRoomOpen, setIsVirtualRoomOpen] = useState(false);
  const [triggerAnimation, setTriggerAnimation] = useState<iAnimation>(
    {} as iAnimation,
  );
  const [selectedEvent, setSelectedEvent] = useState<iEventItem>(mockEvent);
  const [isMicMuted, setIsMicMuted] = useState(true);
  const [isAudioMuted, setIsAudioMuted] = useState(true);
  const [openReactions, setOpenReactions] = useState(false);
  const [openVolumeBox, setOpenVolumeBox] = useState(false);
  const [hasMutedUsers, setHasMutedUsers] = useState<iMutedUsers>(
    {} as iMutedUsers,
  );

  const history = useHistory();
  const location = useLocation();

  const updateReaction = useCallback((peerId: number, animationUrl: string) => {
    setTriggerAnimation({
      peerId,
      animationUrl,
    });
  }, []);

  const muteMic = useCallback(
    (state: boolean) => {
      if (wrtc.setLocalInputDeviceMuteState)
        wrtc.setLocalInputDeviceMuteState(!state);
    },
    [wrtc],
  );

  const handleChangeRoomModal = useCallback(() => {
    makeReduxActiveMessage().active({
      active: MessageOptions.changeRoomModal,
      // actionOk: () => closeModal(),
      actionCancel: () => closeModal(),
      componentProps: {
        changeRoom: { layout: selectedEvent?.layout?.id },
      },
    });
  }, [selectedEvent]);

  const handleInviteModal = useCallback(() => {
    makeReduxActiveMessage().active({
      active: MessageOptions.inviteModal,
      // actionOk: () => closeModal(),
      actionCancel: () => closeModal(),
    });
  }, []);

  const handleReactionClick = useCallback(
    (name: string) => {
      const gifUrl = reactionGif.find(item =>
        item.name.includes(name),
      )?.animationUrl;

      if (gifUrl && wrtcInfo?.peerId) {
        updateReaction(wrtcInfo.peerId, gifUrl);

        if (wrtc.sendChatMessage)
          wrtc.sendChatMessage(
            {
              messageType: 'reaction',
              userId: wrtcInfo.peerId,
              animationUrl: gifUrl,
            },
            -1,
          );
      }
    },
    [reactionGif, updateReaction, wrtc, wrtcInfo?.peerId],
  );

  const handleGoBack = () => {
    if (wrtc.leaveRoom) wrtc.leaveRoom();
    if (wrtc.leaveFloor) wrtc.leaveFloor();
    if (wrtc.disconnect) wrtc.disconnect();

    window.location.pathname = `/rooms/${eventId}`;
  };

  const renderHexagons = async () => {
    const array = [];
    let flag = true;

    for (let letter = 0; letter < 10; letter++) {
      for (let number = 0; number < 22; number++) {
        const object = {
          // eslint-disable-next-line prefer-template
          id: 10 * letter + number,
          peerId: -1,
          peerName: '',
          x: getSpatialization(selectedEvent?.layout?.id, number, letter),
          y: letter,
          // label: `${String.fromCharCode(65 + letter)}${number + 1}`,
          label: '',
          state: 'normal',
          type: 'normal',
          disabled: false,
          actualUser: false,
          muted: false,
        };

        await Promise.all(
          // eslint-disable-next-line array-callback-return
          activeUsers.map(item => {
            if (
              !item.roomName ||
              (item.roomName &&
                convertFromWRTCRoomName(item.roomName) === roomName)
            ) {
              if (
                item.spotCoordJ ===
                  getSpatialization(selectedEvent.layout?.id, number, letter) &&
                item.spotCoordI === letter
              ) {
                const name = item.peerName.split(' ');

                object.label = `${name[0]
                  .substring(0, 1)
                  .toUpperCase()}${name[1].substring(0, 1).toUpperCase()}`;

                object.peerId = item.peerId as number;
                object.peerName = item.peerName;

                object.muted =
                  (hasMutedUsers[object.peerId] || item.muted) ?? false;

                if (wrtc.getRemotePeerInputEdgeGain && !object.muted) {
                  object.muted =
                    wrtc.getRemotePeerInputEdgeGain(item.peerId) === 0 ||
                    hasMutedUsers[object.peerId];
                }

                if (wrtcInfo?.peerId === item.peerId) {
                  flag = false;
                  object.actualUser = true;
                }
              }
            }
          }),
        );

        array.push(object);
      }
    }

    setRooms(array);
  };

  const localMute = useCallback(
    async (data: any) => {
      if (wrtc.connect) {
        const updatedArray: Array<any> = [];

        await Promise.all(
          // eslint-disable-next-line array-callback-return
          rooms.map(hexagon => {
            const object = {
              ...hexagon,
            };
            const itSelfMuted = hasMutedUsers[object.peerId] ?? false;
            if (hexagon.x === data.x && hexagon.y === data.y) {
              object.muted = !hexagon.muted || itSelfMuted;
            }

            updatedArray.push(object);
          }),
        );

        setRooms(updatedArray);
      } else {
        toast('Servidor ainda não iniciado, tente novamente mais tarde', {
          type: 'error',
        });
      }
    },
    [rooms, wrtc.connect],
  );

  const muteAll = useCallback(
    async (state: boolean) => {
      if (wrtc.connect) {
        const updatedArray: Array<any> = [];

        await Promise.all(
          // eslint-disable-next-line array-callback-return
          rooms.map(hexagon => {
            const object = {
              ...hexagon,
            };

            if (hexagon.peerId !== -1 && !hexagon.actualUser) {
              object.muted = state;

              if (wrtc.setRemotePeerInputEdgeGain) {
                !state
                  ? wrtc.setRemotePeerInputEdgeGain(hexagon.peerId, 1)
                  : wrtc.setRemotePeerInputEdgeGain(hexagon.peerId, 0);
              }
            }

            updatedArray.push(object);
          }),
        );

        setRooms(updatedArray);
      } else {
        toast('Servidor ainda não iniciado, tente novamente mais tarde', {
          type: 'error',
        });
      }
    },
    [rooms, wrtc],
  );

  const updatePosition = useCallback(
    async (data: any) => {
      if (wrtc.connect) {
        const updatedArray: Array<any> = [];

        await Promise.all(
          // eslint-disable-next-line array-callback-return
          rooms.map(hexagon => {
            const object = {
              ...hexagon,
            };

            if (wrtc.getRemotePeerInputEdgeGain) {
              object.muted =
                wrtc.getRemotePeerInputEdgeGain(object.peerId) === 0;
            }

            if (hexagon.actualUser === true) {
              object.label = '';
              object.actualUser = false;
            }

            if (hexagon.x === data.x && hexagon.y === data.y) {
              object.label = `${String(user?.firstName)
                .substring(0, 1)
                .toUpperCase()}${String(user?.lastName)
                .substring(0, 1)
                .toUpperCase()}`;

              object.actualUser = true;
            }

            updatedArray.push(object);
          }),
        );

        setRooms(updatedArray);
      } else {
        toast('Servidor ainda não iniciado, tente novamente mais tarde', {
          type: 'error',
        });
      }
    },
    [rooms, user?.firstName, user?.lastName, wrtc],
  );

  const onHexagonClick = useCallback(
    (hexagon: any) => {
      if (hexagon.peerId !== -1) {
        if (!hexagon.actualUser) {
          // const object = {
          //   x: hexagon.x,
          //   y: hexagon.y,
          // };
          // localMute(object);
          // if (wrtc.setRemotePeerInputEdgeGain) {
          //   hexagon.muted
          //     ? wrtc.setRemotePeerInputEdgeGain(hexagon.peerId, 1)
          //     : wrtc.setRemotePeerInputEdgeGain(hexagon.peerId, 0);
          // }
          // toast(
          //   hexagon.muted
          //     ? 'Usuário não mais silenciado!'
          //     : 'Usuário silenciado!',
          //   { type: hexagon.muted ? 'info' : 'error' },
          // );
        }
      } else {
        if (wrtc.tryChangeInsideRoomSpot) {
          // Linha x Coluna
          wrtc.tryChangeInsideRoomSpot(hexagon.x, hexagon.y);
        }
        // Atualizar posição de um usuário na sala e atualizar tudo pros outros
        updatePosition(hexagon);
      }
    },
    [updatePosition, wrtc],
  );

  const onRequestInsideRoomPeersInfosResponse = (updatedSpotsInfo: any) => {
    mainStore.dispatch(updateSpotsInfo(updatedSpotsInfo));
    setActiveUsers(updatedSpotsInfo);
  };

  const onInsideRoomPeersSpotChanged = (
    j: number,
    i: number,
    pId: number,
    pName: string,
  ) => {
    const formattedArray = [];

    const curSpotsInfo = mainStore.getState().spotsInfo.results;
    for (let k = 0; k < curSpotsInfo.length; k++) {
      if (
        curSpotsInfo[k].spotCoordI !== i ||
        curSpotsInfo[k].spotCoordJ !== j
      ) {
        if (curSpotsInfo[k].peerId !== pId) {
          const obj = {
            peerId: curSpotsInfo[k].peerId,
            peerName: curSpotsInfo[k].peerName,
            roomName: '',
            spotCoordI: curSpotsInfo[k].spotCoordI,
            spotCoordJ: curSpotsInfo[k].spotCoordJ,
            muted: curSpotsInfo[k].muted,
          };
          formattedArray.push(obj);
        }
      }
    }
    if (pId !== -1) {
      formattedArray.push({
        peerId: pId,
        peerName: pName,
        roomName: '',
        spotCoordI: i,
        spotCoordJ: j,
        muted: hasMutedUsers[pId] ?? false,
      });
    }
    mainStore.dispatch(updateSpotsInfo(formattedArray));
    setActiveUsers(formattedArray);
  };

  const onInsideRoomPeerMuteStateChanged = (
    newMuteState: boolean,
    peerId: number,
  ) => {
    setHasMutedUsers((prevState: iMutedUsers) => ({
      ...prevState,
      [peerId]: newMuteState,
    }));
  };

  const onRemoteStreamAvailable = (stream: any, edgeId: number) => {
    let audio: any;
    const container = document.getElementById('audioContainer');
    if (container != null) {
      audio = document.getElementById(`edge_${edgeId}`);
      if (audio == null) {
        audio = document.createElement('audio');
        audio.controls = false;
        audio.muted = false;
        audio.autoplay = true;
        audio.id = `edge_${edgeId}`;
        container.appendChild(audio);
      }
      audio.srcObject = stream;
    }
  };

  const onRemoteStreamRemoved = (edgeId: number) => {
    const container = document.getElementById('audioContainer');
    if (container != null) {
      const audio = document.getElementById(`edge_${edgeId}`);
      if (audio != null) {
        container.removeChild(audio);
      }
    }
  };

  const openChat = useCallback(
    async (peerId: number, isPublic = false, name?: string) => {
      const chat = chats.find(item => {
        if (isPublic) {
          return item?.isPublic && item?.id === peerId;
        }
        return (
          !item.isPublic &&
          (peerId === item?.from?.id || peerId === item?.to?.id)
        );
      });

      if (chat) {
        const newChats = await Promise.all(
          chats.map(item => {
            if (isPublic) {
              return item?.isPublic && item?.id === peerId
                ? { ...item, isDisplayed: !item?.isDisplayed }
                : item;
            }
            return !item.isPublic &&
              (peerId === item?.from?.id || peerId === item?.to?.id)
              ? { ...item, isDisplayed: !item?.isDisplayed }
              : item;
          }),
        );

        setChats(newChats);
      } else {
        const newChat = {
          // TODO: Alterar lógica do PeerID para evitar chats com mesmo ID
          id: peerId,
          messages: [],
          isPublic,
          isOpen: true,
          isDisplayed: true,
          to: {
            id: isPublic ? -1 : peerId,
            name,
            avatar: '',
            status: 'online',
          },
          from: {
            id: infoUser?.peerId,
            name: userLog.fullName,
            avatar: '',
            status: 'online',
          },
        };

        setChats([...chats, newChat]);
      }
    },
    [chats, infoUser?.peerId, userLog?.fullName],
  );

  const receiveMessage = (message: MessageProps) => {
    if (message && message.messageType === 'chat') setNewChatMessage(message);
    if (message && message.messageType === 'reaction')
      updateReaction(message.userId, String(message.animationUrl));
  };

  const onAudioStreamLevelChanged = (newLevel: number, peer: number) => {
    // console.debug(`Peer[${peer}] new audio level: `, newLevel);
    // Threshold para ativar indicação visual
    if (newLevel > 0.1) {
      if (setSpeakingPeerIds)
        setSpeakingPeerIds((prevState: any) => ({
          ...prevState,
          [peer]: true,
        }));
    } else if (setSpeakingPeerIds)
      setSpeakingPeerIds((prevState: any) => ({
        ...prevState,
        [peer]: false,
      }));
  };

  const handlePreviousUser = () => {
    if (!spotsInfo?.results.length) return;

    const ids = spotsInfo.results
      .map((item, index) => {
        if (displayedLabels.includes(Number(item.peerId)) && index - 1 >= 0) {
          return spotsInfo.results[index - 1].peerId;
        }
      })
      .filter(Boolean);

    if (displayedUsers.length <= ids.length) setDisplayedUsers(ids as number[]);
  };

  const handleNextUser = () => {
    if (!spotsInfo?.results?.length) return;

    const ids = spotsInfo.results
      .map((item, index) => {
        if (
          displayedUsers.includes(Number(item.peerId)) &&
          index + 1 < spotsInfo.results.length
        ) {
          return spotsInfo.results[index + 1].peerId;
        }
      })
      .filter(Boolean);

    if (displayedUsers.length <= ids.length) setDisplayedUsers(ids as number[]);
  };

  const handleMuteMicrophone = useCallback(() => {
    setIsMicMuted(!isMicMuted);
    muteMic(!isMicMuted);
    if (!isAudioMuted) {
      muteAll(isMicMuted);
      setIsAudioMuted(!isMicMuted);
    }
  }, [isMicMuted, muteMic, muteAll, isAudioMuted]);

  // const handleMuteAudio = useCallback(() => {
  //   muteAll(isAudioMuted);
  //   setIsAudioMuted(!isAudioMuted);
  //   if (isMicMuted) {
  //     setIsMicMuted(!isAudioMuted);
  //     muteMic(!isAudioMuted);
  //   }
  // }, [isAudioMuted, muteMic, muteAll, isMicMuted]);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      moveOnRoom(
        e,
        rooms,
        selectedEvent?.layout?.id,
        onHexagonClick,
        wrtcInfo?.peerId,
      );
    },
    [onHexagonClick, rooms, selectedEvent?.layout?.id, wrtcInfo?.peerId],
  );

  const renderPaginationItems = useMemo(() => {
    let renderReactions =
      reactionButtons?.map(item => {
        if (!displayedLabels.includes(item.id) || !item.enabled) return null;

        return (
          <TooltipComponent title={item.name} key={item.id} placement="right">
            <StyledPaginationItem key={item.id}>
              <StyledPaginationReaction
                onClick={() => handleReactionClick(item.name)}
                src={item.url}
                alt={item.name}
              />
            </StyledPaginationItem>
          </TooltipComponent>
        );
      }) ?? [];
    if (renderReactions?.length < 4) {
      renderReactions = [
        ...renderReactions,
        ...Array(4 - renderReactions.length).fill(
          <StyledPaginationItem>
            <IconUser />
          </StyledPaginationItem>,
        ),
      ];
    }

    return renderReactions;
  }, [reactionButtons, displayedLabels, handleReactionClick]);

  const getCategoryName = useCallback(():
    | 'Música'
    | 'Esportes'
    | 'Entretenimento' => {
    if (selectedEvent?.contentType?.name === 'music') return 'Música';
    if (selectedEvent?.contentType?.name === 'sports') return 'Esportes';
    if (selectedEvent?.contentType?.name === 'entertainment')
      return 'Entretenimento';

    return 'Música';
  }, [selectedEvent]);

  const breakpoint = useBreakPoint();

  const rangeRender = useMemo(() => {
    switch (breakpoint) {
      case 'sm':
        return 0;
      case 'md':
        return 3;
      case 'lg':
        return 4;
      case 'xl':
        return 5;
      case 'xxl':
        return 6;
      default:
        return 8;
    }
  }, [breakpoint]);

  const publicChat = useMemo(() => {
    if (!chats) return null;
    return chats.find(item => item.isPublic);
  }, [chats, openChat, userLog.fullName]);

  const renderUsersItems = useMemo(() => {
    let renderUsers =
      spotsInfo?.results?.map(item => {
        if (!displayedUsers.includes(Number(item.peerId))) return null;
        return (
          <TooltipComponent title={item.peerName.split(':')[0]} placement="top">
            <StyledPaginationItem
              className="item"
              key={item.peerId}
              style={{ position: 'relative' }}
              onClick={() => {
                if (item.peerId !== wrtcInfo?.peerId)
                  openChat(item.peerId, false, item.peerName);
              }}
            >
              {/* <IconUser />
            <CategoryTitle style={{ position: 'absolute' }}>
              {item.peerName.substring(0, 2).toLocaleUpperCase()}
            </CategoryTitle> */}

              <RandomProfileUser peerId={item.peerId} size="5rem" />
            </StyledPaginationItem>
          </TooltipComponent>
        );
      }) ?? [];

    if (renderUsers?.length < rangeRender) {
      renderUsers = [
        ...renderUsers,
        ...Array(rangeRender - renderUsers.length).fill(
          <StyledPaginationItem>
            <IconUser width="5rem" height="5rem" />
          </StyledPaginationItem>,
        ),
      ];
    }

    return renderUsers;
  }, [
    spotsInfo?.results,
    displayedUsers,
    wrtcInfo?.peerId,
    openChat,
    rangeRender,
  ]);

  useEffect(() => {
    const client = new WRTCClient(window.config.clientConfig);

    client.onremotestreamavailable = onRemoteStreamAvailable;
    client.onremotestreamremoved = onRemoteStreamRemoved;
    client.oninsideroompeersspotchanged = onInsideRoomPeersSpotChanged;
    client.oninsideroompeermutestatechanged = onInsideRoomPeerMuteStateChanged;
    client.onaudiostreamlevelchanged = onAudioStreamLevelChanged;
    client.onrequestinsideroompeersinfosresponse =
      onRequestInsideRoomPeersInfosResponse;
    client.onchatmessage = receiveMessage;

    const onRequestAllFloorsInfosResponse = (data: any) => {
      if (data?.floors) setFloors(data?.floors);
    };

    client.setReverbImpulseResponse(
      'https://minio.v4h.cloud/public/EmptyApartmentBedroom.m4a',
    );

    const loginSuccess = () => {
      const peerId = client.getLocalPeersId();
      if (peerId) makeReduxUpdateWRTCInfo().update({ peerId });

      setUpdateInsideRoom(true);
    };

    const loginFailed = (cause?: any) => {
      toast(
        'Não foi possível estabelecer comunicação com o servidor, tente novamente mais tarde',
        { type: 'error' },
      );
      client.disconnect();
    };

    client.onconnect = (name: string) => {
      client.loginPeer(name, loginSuccess, loginFailed);
    };

    // Se a página for recarregada ou acessada via link
    if (
      !isLogged &&
      wrtc.connect &&
      updateInsideRoom &&
      selectedEvent?.name !== ''
    ) {
      if (wrtc.requestAllFloorsInfos) {
        wrtc.requestAllFloorsInfos(0, onRequestAllFloorsInfosResponse);
      }
    }

    if (!wrtc.onaudiostreamlevelchanged) {
      makeReduxUpdateWRTCInfo().update({
        peerId: undefined,
        insideRoom: false,
      });
      makeReduxUpdateWrtc().update(client);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateInsideRoom, selectedEvent]);

  useEffect(() => {
    setActiveUsers([]);
  }, [location.pathname]);

  // Atualizar usuários ativos quando houver mudanças no spotsInfo
  useEffect(() => {
    const visibleUsers: Array<number> = [];
    spotsInfo?.results?.forEach((item, index) => {
      if (index < 4) visibleUsers.push(Number(item.peerId));

      return null;
    });

    setDisplayedUsers(visibleUsers);
    // if (spotsInfo) setActiveUsers(spotsInfo.results);
  }, [spotsInfo]);

  // Atualizar usuários hexágonos quando houver mudanças nos usuários da sala
  useEffect(() => {
    renderHexagons();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUsers, hasMutedUsers]);

  useEffect(() => {
    if (!wrtcInfo?.insideRoom) {
      makeReduxUpdateWRTCInfo().update({ insideRoom: true });
    }

    renderHexagons();
  }, [wrtcInfo?.insideRoom]);

  useEffect(() => {
    const notConnectedToWRTC =
      (!wrtcInfo?.peerId || wrtcInfo?.peerId === -1) && user?.email;

    if (!wrtc.connect && !connecting) {
      // Se o usuário estiver autenticado e não houver WRTC, instanciar WRTC e conectar
      const client = new WRTCClient(window.config.clientConfig);

      const onJoinRoomSuccess = () => {
        console.debug('Join room success');

        if (client.getRoomName) {
          const wrtcRoomName = convertFromWRTCRoomName(client.getRoomName());
          const query = new URLSearchParams(location.search);
          if (wrtc.disconnect) wrtc.disconnect();

          history.push({
            pathname: `/rooms/${eventId}/room/${wrtcRoomName}?${query}`,
          });
        }
      };

      client.onrequestinsideroompeersinfosresponse =
        onRequestInsideRoomPeersInfosResponse;
      client.onremotestreamavailable = onRemoteStreamAvailable;
      client.onremotestreamremoved = onRemoteStreamRemoved;
      client.oninsideroompeersspotchanged = onInsideRoomPeersSpotChanged;
      client.oninsideroompeermutestatechanged =
        onInsideRoomPeerMuteStateChanged;
      client.onaudiostreamlevelchanged = onAudioStreamLevelChanged;
      client.onchatmessage = receiveMessage;

      client.setReverbImpulseResponse(
        'https://minio.v4h.cloud/public/EmptyApartmentBedroom.m4a',
      );

      const loginSuccess = () => {
        const peerId = client.getLocalPeersId();
        if (peerId) makeReduxUpdateWRTCInfo().update({ peerId });

        setUpdateInsideRoom(true);
      };

      const loginFailed = (cause?: any) => {
        toast(
          'Não foi possível estabelecer comunicação com o servidor, tente novamente mais tarde',
          { type: 'error' },
        );
        client.disconnect();
      };

      client.onconnect = (name: string) => {
        setConnecting(true);
        client.loginPeer(name, loginSuccess, loginFailed);
      };

      if (notConnectedToWRTC) {
        try {
          client.connect(`${user.firstName} ${user.lastName}:${user.id}`);
          makeReduxUpdateWrtc().update(client);
        } catch (e) {
          console.log(e);
        }
      } else {
        makeReduxUpdateWrtc().update(client);
      }
    }

    if (wrtc.connect && notConnectedToWRTC) {
      try {
        wrtc.connect(`${user.firstName} ${user.lastName}:${user.id}`);
      } catch (e) {
        console.log(e);
      }
    }

    if (active === 'none' && user.email) {
      makeReduxUpdateWRTCInfo().update({ insideRoom: true });
      renderHexagons();
    } else {
      renderHexagons();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, location.pathname, wrtc.connect, wrtcInfo?.peerId, history]);

  const { messages } = useContext(MessagesPublicChatContext);

  useEffect(() => {
    const chatPublic = chats?.filter(chat => chat.isPublic);
    if (wrtcInfo?.peerId && !chatPublic?.length) {
      openChat(-1, true, userLog?.fullName);
      const newChat = {
        id: -1,
        messages: messages ?? [],
        isPublic: true,
        isOpen: false,
        isDisplayed: false,
        to: {
          id: -1,
          name: '',
          avatar: '',
          status: 'online',
        },
        from: {
          id: wrtcInfo?.peerId,
          name: userLog?.fullName,
          avatar: '',
          status: 'online',
        },
      };

      setChats([...chats, newChat]);
    }
  }, [chats, messages, openChat, userLog?.fullName, wrtcInfo]);

  useEffect(() => {
    if (!newChatMessage) return;

    const chat = chats.find(item => {
      if (newChatMessage?.chat?.isPublic) {
        return item.isPublic && item?.id === newChatMessage?.chat?.id;
      }

      return (
        !item.isPublic &&
        (newChatMessage?.chat?.from?.id === item?.from?.id ||
          newChatMessage?.chat?.from?.id === item?.to?.id)
      );
    });

    if (chat) {
      const newChats = chats.map(item => {
        if (newChatMessage?.chat?.isPublic) {
          return item.isPublic && item?.id === newChatMessage?.chat?.id
            ? { ...item, isDisplayed: false }
            : item;
        }
        return !item.isPublic &&
          (newChatMessage?.chat?.from?.id === item?.from?.id ||
            newChatMessage?.chat?.from?.id === item?.to?.id)
          ? { ...item, isDisplayed: true }
          : item;
      });

      setChats(newChats);
    } else {
      const newChat = {
        id: newChatMessage.chat?.isPublic ? -1 : newChatMessage.chat?.to?.id,
        messages: newChatMessage.chat?.messages,
        isPublic: newChatMessage.chat?.isPublic,
        isOpen: newChatMessage.chat?.isOpen,
        isDisplayed: newChatMessage.chat?.isDisplayed,
        to: {
          id: newChatMessage.chat?.from?.id,
          name: newChatMessage.chat?.from?.name,
          avatar: '',
          status: 'online',
        },
        from: {
          id: newChatMessage.chat?.isPublic
            ? wrtcInfo?.peerId
            : newChatMessage.chat?.to?.id,
          name: newChatMessage.chat?.isPublic
            ? userLog.fullName
            : newChatMessage.chat?.to?.name,
          avatar: '',
          status: 'online',
        },
      };

      setChats([...chats, newChat]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newChatMessage]);

  useEffect(() => {
    const onRequestAllFloorsInfosResponse = (data: any) => {
      if (data?.floors) setFloors(data?.floors);
    };

    if (wrtc.disconnect) wrtc.disconnect();
    if (wrtc.requestAllFloorsInfos)
      wrtc.requestAllFloorsInfos(0, onRequestAllFloorsInfosResponse);

    makeReduxGetAllEvent().getAll({
      limit: 9999,
    });

    makeRemoteGetAllReaction()
      .getAll({
        limit: 9999,
      })
      .then(res => {
        const reaction: iReactions[] = [];
        const gifs: iGifs[] = [];
        const displayed: number[] = [];

        res.records.forEach((item, index) => {
          if (item.type === 'GIF')
            gifs.push({ name: item.name, animationUrl: item.url });
          if (item.type === 'REACTION' && item.enabled) {
            if (displayed.length < 4) displayed.push(item.id);

            reaction.push(item);
          }
        });

        setReactionButtons(reaction);
        setReactionGif(gifs);
        setDisplayedLabels(displayed);
      })
      .catch((err: any) => {
        console.log('Erro ao buscar reações', err);
      });

    if (!userLog?.email || userLog?.email === '') {
      makeReduxActiveMessage().active({
        active: MessageOptions.loginModal,
        actionOk: () => closeModal(),
        actionCancel: () => {
          closeModal();
          history.push('/');
        },
      });
    }
  }, []);

  useEffect(() => {
    if (floors.length && roomName && wrtc.joinFloor) {
      const onJoinRoomSuccess = () => {
        console.debug('Join room success');

        // if (roomName) {
        //   history.push({
        //     pathname: `/rooms/${eventId}/room/${roomName}`,
        //   });
        // }
      };

      const WRTCRoomName = convertToWRTCRoomName(roomName);
      const spotIndex = getSpotIndexFromWRTCRoomName(WRTCRoomName);

      const found = floors.find(
        (item: any) => item?.name === `floor_${selectedEvent?.id}`,
      );

      let x = 0;
      let y = 0;

      if (selectedEvent.layout?.id === 1) {
        x = 4;
        y = 10;
      }

      if (selectedEvent.layout?.id === 2) {
        x = 4;
        y = 20;
      }

      if (selectedEvent.layout?.id === 3 || selectedEvent.layout?.id === 4) {
        x = 10;
        y = 22;
      }

      if (!found && wrtc.createFloor)
        wrtc.createFloor(`floor_${selectedEvent?.id}`, 0, 10, 15); // TODO: Remove mock when receiving room space from server

      wrtc.joinFloor(
        `floor_${selectedEvent?.id}`,
        0,
        () => console.log('Deu bom'),
        () => console.log('Deu ruim'),
      );

      if (wrtc.setVirtualRoomFrameSize) wrtc.setVirtualRoomFrameSize(y, x);

      if (wrtc.joinRoom) {
        console.log(WRTCRoomName, spotIndex);
        wrtc.joinRoom(
          WRTCRoomName,
          spotIndex.toString(),
          onJoinRoomSuccess,
          onJoinRoomFailed,
        );

        setLoggedState(true);
      }
    }
  }, [floors]);

  useEffect(() => {
    if (!userLog?.email || userLog?.email === '') {
      makeReduxGetAllEvent().getAll({
        limit: 9999,
      });
      makeReduxGetAllLayout().getAll({});
      makeReduxGetAllCategory().getAll({});
    }
  }, [userLog?.email]);

  useEffect(() => {
    if (!newChatMessage) return;

    if (newChatMessage.chat?.isPublic) {
      setSendChatToProvider({ ...newChatMessage, userId: wrtcInfo?.peerId });
    } else {
      setSendChatToProvider(newChatMessage);
    }

    setNewChatMessage(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chats, wrtcInfo?.peerId]);

  useEffect(() => {
    app.ready.then(() => {
      console.log('[debug] App ready');

      to.timingsrc = app.motions.sharedall; // Motion (R,W FOR ALL)
    });

    // refresh position every 100 ms
    const sampler = new TimingSampler(to, { period: 100 });

    // position
    const posElem = document.getElementById('position');

    (sampler as any)?.on('change', () => {
      if (posElem) posElem.innerHTML = `${to.pos.toFixed(2)}`;
    });

    // progress
    const progressElem = document.getElementsByClassName('bar-container')?.[1];

    const progress = new TimingProgress(to, progressElem, {
      sampler,
    });

    // Set up video sync
    const sync1 = (window as any)?.MCorp.mediaSync(
      document.getElementById('player1')?.getElementsByTagName('video')?.[0],
      to,
    );
  }, []);

  return (
    <RoomProvider rooms={rooms} setRooms={setRooms}>
      <AudioProvider
        remoteMute={localMute}
        localMute={handleMuteMicrophone}
        isMicMuted={isMicMuted}
        hasMutedUsers={hasMutedUsers}
      >
        <MessagesPublicChatProvider>
          <DemoSideBarInfoEvent
            publicChat={publicChat}
            handleNewMessageAdded={() => setSendChatToProvider(undefined)}
            newMessage={sendChatToProvider}
            rooms={rooms}
            participants={activeUsers}
          />
          <Container onKeyUp={handleKeyPress} tabIndex={0}>
            <Header />

            <RoomHeader>
              <BackArrow onClick={handleGoBack} />
              <CategoryBar type={getCategoryName()} />
              <CategoryTitle>{`${selectedEvent?.name} - Sala ${roomName}`}</CategoryTitle>
            </RoomHeader>

            <Layout
              handleClick={onHexagonClick}
              rooms={rooms}
              speakingPeerIds={speakingPeerIds}
              triggerAnimation={triggerAnimation}
              setTriggerAnimation={setTriggerAnimation}
              selectedEvent={selectedEvent}
              layout={selectedEvent?.layout?.id}
              reactionsButtons={reactionButtons}
              displayedLabelsByMap={displayedLabels}
              spotsInfo={spotsInfo}
              displayedUsersByMap={displayedUsers}
              roomName={roomName}
              handleChangeRoomModal={handleChangeRoomModal}
              isRoomsPage
              openChat={openChat}
              isMicMuted={isMicMuted}
              openReactions={openReactions}
              setOpenReactions={setOpenReactions}
              handleReactionClick={handleReactionClick}
              handleMuteMicrophone={handleMuteMicrophone}
              onHexagonClick={onHexagonClick}
              remoteMute={localMute}
              publicChat={publicChat}
              newMessage={sendChatToProvider}
              handleNewMessageAdded={() => setSendChatToProvider(undefined)}
              participants={activeUsers}
              to={to}
            />

            <FooterInsideRoom
              handleChangeRoomModal={handleChangeRoomModal}
              handleKeyPress={handleKeyPress}
              handleMuteMicrophone={handleMuteMicrophone}
              handleNextUser={handleNextUser}
              handlePreviousUser={handlePreviousUser}
              handleReactionClick={handleReactionClick}
              isMicMuted={isMicMuted}
              isVirtualRoomOpen={isVirtualRoomOpen}
              openChat={openChat}
              openReactions={openReactions}
              setOpenReactions={setOpenReactions}
              openVolumeHandler={openVolumeBox}
              setOpenVolumeHandler={setOpenVolumeBox}
              reactionButtons={reactionButtons}
              setIsVirtualRoomOpen={setIsVirtualRoomOpen}
              roomName={roomName}
              selectedEvent={selectedEvent}
              renderUsersItems={renderUsersItems}
            />

            <Chat
              chats={chats}
              isListParticipantsOpen={isVirtualRoomOpen}
              selectedEvent={selectedEvent}
              isMicMuted={isMicMuted}
              setIsVirtualRoomOpen={setIsVirtualRoomOpen}
              updateChats={setChats}
              newMessage={sendChatToProvider}
              localMute={localMute}
              handleMuteMicrophone={handleMuteMicrophone}
              handleNewMessageAdded={() => setSendChatToProvider(undefined)}
            />
          </Container>
        </MessagesPublicChatProvider>
      </AudioProvider>
    </RoomProvider>
  );
};

export default Room;
