/* eslint-disable consistent-return */
/* eslint-disable react/require-default-props */
import React, { memo, useMemo } from 'react';
import { StarVip, IconMicDisabledFooter } from 'presentation/base/icons';

import { RandomProfileUser } from '../randomProfileUser';
// styles
import {
  Container,
  Content,
  Muted,
  IsSpeakingAnimate,
} from './styles/SquareRoom';
import { TooltipComponent } from '../TooltipComponent';

interface SquareRoomProps {
  size?: string;
  state?: 'normal' | 'full' | 'busy';
  disabled?: boolean;
  hasBorder?: boolean;
  preview?: boolean;
  isSpeaking?: boolean;
  isCenter?: boolean;
  isHovering?: boolean;
  isChangingRoom?: boolean;
  isActualUser?: boolean;
  peerId?: number;
  peerName?: string;
  type?: 'vip' | 'normal';
  onClick?: () => any;
  label: string;
  isLayout?: boolean;
}

const SquareRoomComponent: React.FC<SquareRoomProps> = ({
  size = '3rem',
  state = 'normal',
  isCenter,
  disabled = false,
  type = 'normal',
  hasBorder = true,
  preview = false,
  isSpeaking = false,
  isHovering = false,
  isChangingRoom = false,
  isActualUser = false,
  peerId,
  peerName,
  onClick,
  label,
  isLayout = false,
}) => {
  const formattedSize = useMemo(() => {
    return size;
  }, [size]);

  return (
    <TooltipComponent placement="top" title={peerName?.split(':')[0] || ''}>
      <Container>
        <Content
          onClick={onClick}
          type={type}
          disabled={disabled}
          preview={preview}
          hasBorder={hasBorder}
          state={state}
          size={formattedSize}
          isSpeaking={isSpeaking}
          isCenter={isCenter}
          isHovering={isHovering}
          isChangingRoom={isChangingRoom}
          isActualUser={isActualUser}
          isLayout={isLayout}
        >
          {peerId === -1 && type === 'vip' && <StarVip />}
          {peerId === -1 && label}
          {peerId !== -1 && <RandomProfileUser peerId={peerId} />}
          <IsSpeakingAnimate isSpeaking={isSpeaking && !disabled && !!peerName}>
            <div />
            <div className="center" />
            <div />
          </IsSpeakingAnimate>
        </Content>
        {peerId !== -1 && disabled && (
          <Muted
            isActualUser={isActualUser}
            preview={preview}
            isChangingRoom={isChangingRoom}
            size={formattedSize}
          >
            <IconMicDisabledFooter width={17} height={22} />
          </Muted>
        )}
      </Container>
    </TooltipComponent>
  );
};

const SquareRoom = memo(SquareRoomComponent, (prevProps, nextProps) => {
  return (
    prevProps.peerId === nextProps.peerId &&
    prevProps.isSpeaking === nextProps.isSpeaking &&
    prevProps.isHovering === nextProps.isHovering &&
    prevProps.isActualUser === nextProps.isActualUser &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.onClick === nextProps.onClick
  );
});

export default SquareRoom;
