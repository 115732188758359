/* eslint-disable import/no-duplicates */
/* eslint-disable prefer-template */
import { ptBR } from 'date-fns/locale';
import React from 'react';
import { DatePicker } from 'react-nice-dates';
import 'react-nice-dates/build/style.css';
import {
  CalendarHexIcon,
  CalendarIcon,
  IconCalendar,
} from 'presentation/base/icons';
import {
  CalendarIconDiv,
  Div,
  Label,
  SendDateTimeOptions,
} from './styles/StyledDatepicker';

import { Input } from '../UI';

interface ownProps {
  label?: string;
  onDateChange: (date: Date | null) => void;
  date: Date;
  required?: boolean;
  orientation?: 'column' | 'row';
  breakpointSize?: 'xs' | 's' | 'm' | 'xl';
  minimumDate?: Date;
  error?: boolean;
  labelType?: 'default' | 'small';
}

export const Calendar: React.FC<ownProps> = ({
  label,
  onDateChange,
  date,
  required,
  orientation,
  breakpointSize,
  minimumDate,
  error,
}) => {
  return (
    <Div size={breakpointSize}>
      {label && (
        <Label required={required} orientation={orientation}>
          {label}
        </Label>
      )}
      <SendDateTimeOptions>
        <DatePicker
          date={date}
          onDateChange={onDateChange}
          locale={ptBR}
          minimumDate={minimumDate ?? new Date()}
          weekdayFormat="EEEEEE"
          format="dd/MM/yyyy"
        >
          {({ inputProps, focused }) => (
            <Input
              {...inputProps}
              value={inputProps.value
                .replace(/\D/g, '')
                .replace(/(\d{2})(\d)/, '$1/$2')
                .replace(/(\d{2})(\d)/, '$1/$2')
                .replace(/(\d{4})(\d)/, '$1')}
              className={
                'input' + (focused ? ' -focused' : '') + ' input-z-index'
              }
              width="180px"
              autoComplete="off"
              placeholder="DD/MM/AAAA"
              color="black4"
              error={error}
            >
              <CalendarIconDiv isFocused={focused}>
                <CalendarIcon />
              </CalendarIconDiv>
            </Input>
          )}
        </DatePicker>
      </SendDateTimeOptions>
    </Div>
  );
};
