import { iEvent } from 'domain/interfaces/models/Event';
import { EventTypes } from 'domain/interfaces/redux/event/types';
import { EventActions } from './actions';

export const initialState: iEvent = {
  loading: false,
  results: undefined,
};

const reducer = (state = initialState, action: EventActions): iEvent => {
  switch (action.type) {
    case EventTypes.CREATE:
      return { ...state, loading: true };
    case EventTypes.CREATE_FAILED:
      return { ...state, loading: false };
    case EventTypes.CREATE_SUCCESS: {
      return { ...state, loading: false };
    }

    case EventTypes.GETALL:
      return { ...state, loading: true };
    case EventTypes.GETALL_FAILED:
      return { ...state, loading: false };
    case EventTypes.GETALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        results: action.payload.records,
      };
    }

    case EventTypes.DELETE:
      return { ...state, loading: true };
    case EventTypes.DELETE_FAILED:
      return { ...state, loading: false };
    case EventTypes.DELETE_SUCCESS: {
      return { ...state, loading: false };
    }

    default:
      return state;
  }
};

export default reducer;
