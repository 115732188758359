import React from 'react';
// import Skeleton from '@material-ui/lab/Skeleton';
import { Container } from './styles';
import { StSkeleton } from '../Skeleton/styles';

export const SkeletonEventSmallPreview = () => {
  return (
    <Container skeleton>
      <StSkeleton
        animation="wave"
        variant="rect"
        width="35.6rem"
        height="20rem"
      />
      <StSkeleton variant="text" width="35.6rem" height="5rem" />
      <StSkeleton variant="text" width="35.6rem" height="3rem" />
    </Container>
  );
};
