import { iCategory } from 'domain/interfaces/models/Category';
import { CategoryTypes } from 'domain/interfaces/redux/category/types';
import { CategoryActions } from './actions';

export const initialState: iCategory = {
  loading: false,
  results: [],
};

const reducer = (state = initialState, action: CategoryActions): iCategory => {
  switch (action.type) {
    case CategoryTypes.CREATE:
      return { ...state, loading: true };
    case CategoryTypes.CREATE_FAILED:
      return { ...state, loading: false };
    case CategoryTypes.CREATE_SUCCESS:
      return { ...state, loading: false };

    case CategoryTypes.GETALL:
      return { ...state, loading: true };
    case CategoryTypes.GETALL_FAILED:
      return { ...state, loading: false };
    case CategoryTypes.GETALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        results: Object.values(action.payload),
      };
    }
    default:
      return state;
  }
};

export default reducer;
