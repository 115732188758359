import { motion } from 'framer-motion';
import React from 'react';
import { AnimationContainer } from './styles';

interface props {
  children: React.ReactNode;
}

export const BaseAnimation: React.FC<props> = ({ children }) => {
  return (
    <AnimationContainer>
      <motion.div
        animate={{
          width: 60,
          height: 66,
          scaleX: [
            1.3, 1, 0.75, 0.6, 0.75, 1.2, 0.95, 0.9, 1.2, 1, 0.95, 1, 1, 1,
          ],
          scaleY: [
            0.8, 1, 1.2, 1.3, 1.1, 0.8, 1.05, 1.1, 0.9, 1, 1.05, 1, 1, 1,
          ],
          opacity: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0],
        }}
        transition={{
          duration: 2,
          easings: 'linear',
          times: [
            0, 0.07, 0.13, 0.2, 0.28, 0.33, 0.38, 0.42, 0.47, 0.515, 0.565,
            0.62, 0.8, 1,
          ],
          delay: 0.2,
        }}
      >
        {children}
      </motion.div>
    </AnimationContainer>
  );
};

export default BaseAnimation;
