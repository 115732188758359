import styled, { css, keyframes } from 'styled-components';
import { defaultTheme } from 'presentation/base/themes';

export const Container = styled.div`
  width: 100%;
  // gap: 3.2rem;
  display: flex;

  transition: all 0.2s ease-in-out;

  &::-webkit-scrollbar {
    display: none;
  }
`;

type TabProps = {
  color: keyof typeof defaultTheme['colors'];
  isActive?: boolean;
  disabled?: boolean;
};

export const Tab = styled.button<TabProps>`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  white-space: nowrap;
  border: 0;

  padding: 1.6rem;
  color: ${({ theme }) => theme.colors.white1};

  font-size: 1.6rem;
  font-weight: 600;

  background: ${({ theme }) => theme.colors.black2};

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: auto;
    right: auto;
    width: 80%;
    height: 0.3rem;
    background: ${({ color, theme, isActive }) =>
      isActive ? theme.colors[color] : theme.colors.black2};
    transition: all 0.2s ease-in-out;
  }

  &:focus-visible {
    outline: none;
    border: 0.2rem solid ${({ theme }) => theme.colors.blue1};
    background: ${({ theme }) => theme.colors.black3};
    color: ${({ theme }) => theme.colors.white2};

    &:before {
      display: none;
    }

    padding: 1.4rem;

    box-shadow: 0px 0px 10px ${({ theme }) => theme.colors.blue1};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.white2};
    background: ${({ theme }) => theme.colors.black3};

    ${({ isActive }) =>
      !isActive &&
      css`
        &:before {
          display: none;
        }
      `}
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.white5};
    background: ${({ theme }) => theme.colors.black2};
    cursor: default;
  }
`;
