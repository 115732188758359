/* eslint-disable react/require-default-props */
import React, { HTMLAttributes } from 'react';

import { iStore } from 'domain/interfaces/models';
import useBreakpoint from 'presentation/hooks/useBreakPoint';
import { useSelector } from 'react-redux';
import Linkify, { Props } from 'react-linkify';
import { RandomProfileUser } from '../randomProfileUser';
import {
  MessageContainer,
  MessageContent,
  MessageText,
  UserMessageInfo,
} from './styles';

export interface User {
  id: number;
  name: string;
  avatar?: string;
}

interface Message {
  message: string;
  type: 'sent' | 'received' | string;
  timestamp: string;
}

interface MessageProps extends HTMLAttributes<HTMLDivElement> {
  message?: Message;
  user?: User;
}

const Message: React.FC<MessageProps> = ({ message, user, ...rest }) => {
  const wrtcInfo = useSelector((store: iStore) => store.auth.wrtcInfo);
  const breakpoint = useBreakpoint();

  const isMobile =
    breakpoint === 'xxs' || breakpoint === 'xs' || breakpoint === 'sm';

  return (
    <MessageContainer
      isMobile={isMobile}
      isMine={user?.id === wrtcInfo?.peerId}
      {...rest}
    >
      <MessageContent>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <RandomProfileUser
            peerId={user?.id}
            size="2.5rem"
            style={{
              marginRight: '1.5rem',
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'hidden',
              borderRadius: '0.5rem',
            }}
          />
          <UserMessageInfo>
            <b>{user?.name?.split(':')[0]}</b>
            <span>{message?.timestamp}</span>
          </UserMessageInfo>
        </div>
        <MessageText>
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a
                href={decoratedHref}
                key={key}
                target="_blank"
                rel="noreferrer"
                style={{ color: '#fff', fontWeight: 'bold' }}
              >
                {decoratedText}
              </a>
            )}
          >
            {message?.message}
          </Linkify>
          {/* {message?.message} */}
        </MessageText>
      </MessageContent>
    </MessageContainer>
  );
};

export default Message;
