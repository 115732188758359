import styled, { css } from 'styled-components';

interface ownProps {
  fixedHeight?: boolean;
  height?: string;
  color?: string;
  error?: boolean;
}

export const DropContainer = styled.label<ownProps>`
  width: 100%;
  height: ${({ height }) => height};

  max-height: 26rem;

  ${({ fixedHeight }) =>
    fixedHeight &&
    css`
      max-height: 10.5rem;
    `}
  cursor: pointer;
  transition: height 0.2s ease;
  box-sizing: border-box;
  background: ${props => props.theme.colors.black5};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: 0.2rem dashed ${({ theme }) => theme.colors.white5};
  object-fit: contain;
  overflow: hidden;

  /* padding: ${({ fixedHeight }) => !fixedHeight && '8rem 20rem'}; */

  .icon-upload-file {
    font-size: 4.5rem;
    margin-bottom: 1rem;
  }

  img {
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: contain;
  }

  ${({ error }) =>
    error &&
    css`
      border: 0.2rem dashed ${({ theme }) => theme.colors.red1};
    `}

  &:focus-within {
    outline: 0.2rem solid
      ${({ theme, error }) => theme.colors[error ? 'red1' : 'blue1']};
    outline-offset: 0.2rem;
    box-shadow: 0 0 1rem
      ${({ theme, error }) => theme.colors[error ? 'red1' : 'blue1']};
  }
`;

export const DropText = styled.div`
  text-align: center;
  text-overflow: ellipsis;

  p {
    height: 3.8rem;
    color: ${({ theme }) => theme.colors.white1};
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 2rem;
    cursor: pointer;
    margin: 0;
    white-space: nowrap;
  }
`;

export const DropInput = styled.input``;

export const RemoveImage = styled.button`
  background-color: transparent;
  border: none;

  display: flex;
  flex-direction: row;
  margin-bottom: 260px;
  margin-left: 220px;
  position: absolute;
  z-index: 1;

  font-size: 24px;
  font-weight: bolder;
  color: red;
  cursor: pointer;
`;

export const Label = styled.label<{ required?: boolean }>`
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 1.2rem;
  ${({ required, theme }) =>
    required
      ? css`
          &:after {
            content: '*';
            color: ${theme.colors.red1};
          }
        `
      : css`
          &:after {
            content: ' (opcional)';
          }
        `};
`;

interface MessageProps {
  error: boolean;
}

export const Message = styled.p<MessageProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  color: #7b8591;
  margin-top: 1.2rem;

  ${({ error }) =>
    error &&
    css`
      color: ${props => props.theme.colors.colorError}; ;
    `}
`;
