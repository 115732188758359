/* eslint-disable react/jsx-one-expression-per-line */
import React, { useCallback } from 'react';
import { iEventItem } from 'domain/interfaces/models/Event';
import { CloseIcon, IconLogoJockey } from 'presentation/base/icons';

import {
  MobileContentContainer,
  Content,
  DescriptionModal,
  Header,
  ModalContent,
  SubTitleModal,
  TitleModal,
} from './styles';
import { Button } from '../UI';

interface MobileAdContentScreenProps {
  selectedEvent: iEventItem;
  isDisplayed: boolean;
  onClose: () => void;
}

const MobileAdContentScreen: React.FC<MobileAdContentScreenProps> = ({
  selectedEvent,
  isDisplayed,
  onClose,
}) => {
  const openNewTab = useCallback((url?: string) => {
    if (!url) return;

    const withHttp = !/^https?:\/\//i.test(url) ? `http://${url}` : url;
    window.open(withHttp, '_blank', 'noopener,noreferrer');
  }, []);

  return (
    <MobileContentContainer isDisplayed={isDisplayed}>
      {isDisplayed && (
        <div>
          <ModalContent>
            <Header>
              <CloseIcon style={{ cursor: 'pointer' }} onClick={onClose} />
            </Header>
            <Content>
              <IconLogoJockey
                style={{
                  width: 54,
                  height: 54,
                  marginBottom: 10,
                  maxWidth: 300,
                }}
              />
              <SubTitleModal style={{ marginBottom: 4, maxWidth: 300 }}>
                {selectedEvent?.sponsor?.name}
              </SubTitleModal>
              <DescriptionModal style={{ marginBottom: 45, maxWidth: 300 }}>
                {selectedEvent?.sponsor?.url}
              </DescriptionModal>
              {selectedEvent?.sponsor?.slogan && (
                <TitleModal style={{ marginBottom: 15, maxWidth: 300 }}>
                  {selectedEvent?.sponsor?.slogan}
                </TitleModal>
              )}
              {selectedEvent?.sponsor?.descr && (
                <DescriptionModal style={{ marginBottom: 30, maxWidth: 300 }}>
                  {selectedEvent?.sponsor?.descr}
                </DescriptionModal>
              )}
              <Button
                size="large"
                title={selectedEvent?.sponsor?.label}
                color={selectedEvent?.sponsor?.color}
                onClick={() => {
                  openNewTab(selectedEvent?.sponsor?.url);
                  onClose();
                }}
              />
            </Content>
          </ModalContent>
        </div>
      )}
    </MobileContentContainer>
  );
};

export default MobileAdContentScreen;
