import { takeLatest } from 'redux-saga/effects';
import { LayoutTypes } from 'domain/interfaces/redux/layout/types';
import { onCreate, onCreateSuccess, onCreateFailed } from './Create';
import { onGetAll, onGetAllSuccess, onGetAllFailed } from './GetAll';

const tasks = [
  takeLatest(LayoutTypes.CREATE, onCreate),
  takeLatest(LayoutTypes.CREATE_SUCCESS, onCreateSuccess),
  takeLatest(LayoutTypes.CREATE_FAILED, onCreateFailed),
  takeLatest(LayoutTypes.GETALL, onGetAll),
  takeLatest(LayoutTypes.GETALL_SUCCESS, onGetAllSuccess),
  takeLatest(LayoutTypes.GETALL_FAILED, onGetAllFailed),
];

export default tasks;
