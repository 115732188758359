import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import {
  BadRequestError,
  InvalidCredentialsError,
  UnexpectedError,
  UnProcessableEntity,
} from 'domain/errors';
import { GetEventById } from 'domain/usecases/event/remote';
import 'infra/global/variables';

export class RemoteGetEventById implements GetEventById {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetEventById.Model>;

  constructor(url: string, httClient: HttpClient<GetEventById.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  getById = async (
    params: GetEventById.Params,
  ): Promise<GetEventById.Model> => {
    let query = '';

    Object.entries(params).forEach(([key, value]) => {
      query += `${key}=${value}&`;
    });

    const httpResponse = await this.httpClient.request({
      url: this.url.replace('{eventId}', `${params.id}`),
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.unprocessableEntity:
        throw new UnProcessableEntity();
      default:
        throw new UnexpectedError();
    }
  };
}
