/* eslint-disable react/require-default-props */
import { EmojiTransportationSharp } from '@material-ui/icons';
import { defaultTheme } from 'presentation/base/themes';
import React, { useEffect, useMemo } from 'react';
import SelectComponent from 'react-select';
import { useTheme } from 'styled-components';
import { Message } from '../Input/styles';
import Option from './Option';
import { Container, Label, StyledSelect } from './styles';

export interface iOption {
  label: string;
  value: string | number;
  isDisabled?: boolean;
}

interface SelectProps {
  id?: string;
  menuPlacement?: 'auto' | 'top' | 'bottom';
  options: iOption[];
  label?: string;
  required?: boolean;
  placeholder?: string;
  message?: string;
  error?: boolean;
  variant?: 'primary' | 'secondary';
  size?: 'small' | 'medium' | 'large' | 'full';
  color?: keyof typeof defaultTheme.colors;
  value?: iOption;
  disabled?: boolean;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
}

const Select: React.FC<SelectProps> = ({
  id,
  options,
  label,
  required,
  placeholder,
  size = 'full',
  color = 'black2',
  menuPlacement = 'auto',
  variant,
  message,
  error = false,
  value,
  disabled = false,
  onChange,
  onBlur,
}) => {
  const style = {
    control: (styles: any) => ({
      ...styles,
      backgroundColor: defaultTheme.colors.black2,
      color: defaultTheme.colors.white1,
      outline: defaultTheme.colors.blue1,
      disabled: {
        ...styles.disabled,
        cursor: 'not-allowed',
        backgroundColor: defaultTheme.colors.black2,
      },
    }),
    option: (styles: any) => ({
      ...styles,
      cursor: 'not-allowed',
      color: 'red',
    }),
  };

  return (
    <Container size={size}>
      <Label required={required}>{label}</Label>
      <StyledSelect
        id={id}
        color={variant === 'secondary' ? 'black3' : color}
        error={error}
        placeholder={placeholder}
        styles={style}
        classNamePrefix="select"
        options={options}
        value={value}
        isDisabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        components={{ Option }}
        menuPlacement={menuPlacement}
      />
      <Message error={error}>{message}</Message>
    </Container>
  );
};

export default Select;
