import React, { createContext, useCallback, useEffect, useState } from 'react';
import { MessageProps } from '../components/Chat/interfaces';

type MessagesPublicChatContextProps = {
  messages: MessageProps[];
  isLoadingServer: boolean;
  isChatEmpty: boolean;
  handleAddNewMessage: (message: any) => void;
};

export const MessagesPublicChatContext =
  createContext<MessagesPublicChatContextProps>(
    {} as MessagesPublicChatContextProps,
  );

const MessagesPublicChatProvider: React.FC = ({ children }) => {
  const isLoadingServer = false;

  const [messages, setMessages] = useState<any[]>([]);
  const [isChatEmpty, setIsChatEmpty] = useState(true);

  const handleAddNewMessage = useCallback(
    message => {
      setMessages([...messages, message]);
    },
    [messages],
  );

  useEffect(() => {
    if (messages.length > 0) {
      setIsChatEmpty(false);
    } else {
      setIsChatEmpty(true);
    }
  }, [messages]);

  console.log('isChatEmpty', isChatEmpty);

  return (
    <MessagesPublicChatContext.Provider
      value={{
        messages,
        isLoadingServer,
        isChatEmpty,
        handleAddNewMessage,
      }}
    >
      {children}
    </MessagesPublicChatContext.Provider>
  );
};

export default MessagesPublicChatProvider;
