import { css } from 'styled-components';
import { isObjKey } from 'utils/isObjectKey';

interface DefaultBreakpoints {
  xxs: string;
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
  xxl: string;
}

const defaultBreakpoints: DefaultBreakpoints = {
  xxs: '360px',
  xs: '480px',
  sm: '768px',
  md: '1024px',
  lg: '1366px',
  xl: '1440px',
  xxl: '1920px',
};

const getSizeFromBreakpoint = (
  breakpointValue: keyof DefaultBreakpoints | string,
  breakpoints: DefaultBreakpoints,
) => {
  if (isObjKey(breakpointValue, defaultBreakpoints)) {
    return breakpoints[breakpointValue];
  }

  return breakpointValue;
};

const media = () => {
  const lessThan = (breakpoint: keyof DefaultBreakpoints | string) => {
    const query = `@media (max-width: ${getSizeFromBreakpoint(
      breakpoint,
      defaultBreakpoints,
    )})`;
    return css`
      ${query}
    `;
  };

  const greaterThan = (breakpoint: keyof DefaultBreakpoints | string) => {
    const query = `@media (min-width: ${getSizeFromBreakpoint(
      breakpoint,
      defaultBreakpoints,
    )})`;
    return css`
      ${query}
    `;
  };

  const between = (
    firstBreakpoint: keyof DefaultBreakpoints | string,
    secondBreakpoint: keyof DefaultBreakpoints | string,
  ) => {
    const query = `@media (min-width: ${getSizeFromBreakpoint(
      firstBreakpoint,
      defaultBreakpoints,
    )}) and
    (max-width: ${getSizeFromBreakpoint(
      secondBreakpoint,
      defaultBreakpoints,
    )})`;
    return css`
      ${query}
    `;
  };

  return { lessThan, greaterThan, between };
};

export default media();
