import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  /*check scrolling carousel*/
  .slick-list {
    overflow: visible;
  }

  .slick-slider {
    position: unset;
  }

  .slick-track {
    display: flex;
    margin-left: 0;
  }
`;

interface iScrollSlide {
  type: string;
  disable: string;
}

export const ScrollSlide = styled.div<iScrollSlide>`
  width: 68px;
  height: 200px;
  display: ${props => (props.disable ? 'none' : 'flex')};
  align-items: center;
  position: absolute;
  z-index: 4;
  background: #05060c;
  box-shadow: 0px 4px 20px #0d1222;
  border-radius: 0px;
  top: 0;
  opacity: 0.6;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }

  svg,
  img {
    width: 34px;
    height: 34px;
    transform: none;
    position: unset;
  }

  ${props =>
    props.type === 'left'
      ? css`
          padding: 88px 18px 88px 16px;
          left: 0;

          svg,
          img {
            left: 0;
          }
        `
      : css`
          padding: 88px 16px 88px 18px;
          right: 0;

          svg,
          img {
            right: 0;
          }
        `}
`;
